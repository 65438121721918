import { CellValueChangedEvent, GetRowIdParams, RowHeightParams } from 'ag-grid-community'
import { Box, CircularProgress, Modal, Typography } from '@mui/material'
import { submitCellData } from '../AgGridKsgPeople/AgGridKsgPeople.service'
import ConfirmationModal from '../../shared/components/ConfirmationModal/ConfirmationModal'
import CheckboxPopoverWorkersMimKsg from '../../components/CheckboxPopoverWorkersMim/CheckboxPopoverWorkersMimKsg'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { filtersWorkersMimSelector } from '../../store/slices/filtersWorkersMimSlice'
import { projectsSelector } from '../../store/slices/projectsSlice'
import { profileSelector } from '../../store/slices/profileSlice'
import { useGetHeadersCPGQuery } from '../../api/ksg/ksg.api'
import { useDeleteKsgEquipMutation, useGetKsgEquipQuery } from '../../api/ksgEquip/ksgEquip.api'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import EmptyPlaceholder from '../../shared/components/EmptyPlaceholder/EmptyPlaceholder'
import { setAgGrid, toggleUploadKsgEquip } from '../../store/slices/drawersSlice'
import DownloadingSharpIcon from '@mui/icons-material/DownloadingSharp'
import { KsgEquipI } from '../../api/ksgEquip/ksgEquip.def'
import { getWorkManagmentResourceRowSlyles, modalStyles, preserveScroll } from '../../shared/utils'
import { AG_GRID_DEFAULT_PARAMS_KSG } from '../WorkManagment/components/AgGridService/AgGridColumnDef.service'
import { req } from '../WorkManagment/api/api'
import { getKsgMimColDefs } from './AgGridKsgMim.colDef'
import { MemoAgGrid } from '../AgGridKsgPeople/MemoAgGrid'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../shared/rolePermissions'
import { ArrowBack } from '@mui/icons-material'
import { CellChangedEvent } from 'ag-grid-community/dist/lib/interfaces/iRowNode'

export default function AgGridKsgMim() {
    const [openModal, setOpenModal] = useState(false)
    const [warningModal, setWarningModal] = useState({ openState: false, deleteId: 0 })

    const { projectId } = useParams()

    const { titlesArr } = useTypedSelector(filtersWorkersMimSelector)
    const { headerSearch } = useTypedSelector(projectsSelector)
    const { profile } = useTypedSelector(profileSelector)
    const navigate = useNavigate()
    const location = useLocation()

    const gridRef = useRef<AgGridReact>(null)

    const headers = useGetHeadersCPGQuery({
        id: Number(projectId),
        isTech: true,
    })

    const equipKsgData = useGetKsgEquipQuery({
        id: Number(projectId),
        limit: 9999,
        offset: 0,
        filter: headerSearch,
    })

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation('mutations')
    const { t: tMiMTable } = useTranslation('mimTable')

    const dispatch = useAppDispatch()

    const [deleteEquipReq, deleteEquipRes] = useDeleteKsgEquipMutation()

    useEffect(() => {
        gridRef?.current && dispatch(setAgGrid(gridRef?.current))
        return () => {
            dispatch(setAgGrid(null))
        }
    }, [gridRef?.current])

    useEffect(() => {
        equipKsgData?.refetch()
        //eslint-disable-next-line
    }, [titlesArr.length])

    const getRowId = useCallback((params: GetRowIdParams) => {
        return params.data.id
    }, [])

    const deleteWork = useCallback(
        (id: number) => {
            setWarningModal((d) => ({ ...d, openState: false }))
            const error = (e?: any) => {
                console.error(e)
                return enqueueSnackbar('Ошибка, для дополнительной информации откройте консоль', {
                    variant: 'error',
                })
            }

            const success = () => {
                return enqueueSnackbar(t('work_deleted'), {
                    variant: 'success',
                })
            }

            deleteEquipReq({ id: Number(projectId), workId: id })
                .unwrap()
                .then(success)
                .then(() => {
                    gridRef.current?.api.applyTransaction({ remove: [{ id: id }] })
                })
                .catch(error)
        },
        [deleteEquipReq, enqueueSnackbar, projectId, t]
    )

    if (!headers.data?.isCreatedKSG) {
        if (headers.isFetching) {
            return <CircularProgress />
        }
        if (!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_KSG_MIM')) {
            return (
                <EmptyPlaceholder
                    text={
                        <Typography>
                            Здравствуйте, {profile?.firstName}, КСГ еще не был создан. <br />
                            Создание КСГ не доступно для пользователей с Вашими правами. <br /> Обратитесь к
                            администратору
                        </Typography>
                    }
                    onClick={() => navigate(-1)}
                    icon={<ArrowBack />}
                    buttonText="Назад"
                />
            )
        }
        return (
            <EmptyPlaceholder
                text={`Здравствуйте, ${profile?.firstName}! Данные для отображения отсутствуют.`}
                onClick={() => dispatch(toggleUploadKsgEquip())}
                icon={<DownloadingSharpIcon />}
                buttonText="загрузить"
            />
        )
    }

    return (
        <>
            <ConfirmationModal
                openState={warningModal.openState}
                onCancel={() => setWarningModal((d) => ({ ...d, openState: false }))}
                onConfirm={() => deleteWork(warningModal.deleteId)}
                title="Вы уверены что хотите удалить работу?"
                content="После удаления работы эти данные будут безвозвратно утеряны."
            />
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Box sx={modalStyles}>
                    <CheckboxPopoverWorkersMimKsg />
                </Box>
            </Modal>
            <div className="ag-theme-alpine" style={{ height: '100%', width: '100%', paddingRight: '0.5rem' }}>
                <MemoAgGrid
                    ref={gridRef}
                    getRowId={getRowId}
                    rowData={equipKsgData.data?.data}
                    columnDefs={getKsgMimColDefs({
                        profile: profile,
                        dispatch: dispatch,
                        setWarningModal: setWarningModal,
                        deleteEquipRes: deleteEquipRes,
                        setOpenModal: setOpenModal,
                        warningModal: warningModal,
                        equipKsgData: equipKsgData,
                        location: location,
                        headers: headers,
                    })}
                    getRowHeight={(data: RowHeightParams<KsgEquipI>) => {
                        return data.data?.id === 4353246543 ? 200 : 80
                    }}
                    getRowClass={getWorkManagmentResourceRowSlyles}
                    {...AG_GRID_DEFAULT_PARAMS_KSG(location)}
                    onCellValueChanged={(event: CellValueChangedEvent) => {
                        submitCellData({
                            request: req.post(`/projects/${projectId}/technics/${event.data?.id}/update`, {
                                name: event.data.name,
                                category: event.data.category,
                                [event.colDef.field as string]: event.newValue,
                            }),
                            event: event,
                            enqueueSnackbar: enqueueSnackbar,
                            projectID: projectId as string,
                            dispatch: dispatch,
                            translate: tMiMTable,
                        })
                    }}
                    onFirstDataRendered={(event) => {
                        preserveScroll(event, location)
                    }}
                    {...(!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_KSG_MIM_ROW') && {
                        suppressClickEdit: true,
                    })}
                />
            </div>
        </>
    )
}
