/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const InfoSvg: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M10 0C4.47692 0 0 4.47692 0 10C0 15.5231 4.47692 20 10 20C15.5231 20 20 15.5231 20 10C20 4.47692 15.5231 0 10 0ZM11.5685 13.6792C10.8138 14.8123 10.0462 15.6854 8.75462 15.6854C7.87308 15.5415 7.51077 14.91 7.70154 14.2662L9.36308 8.76308C9.40385 8.62846 9.33615 8.48462 9.21308 8.44077C9.09077 8.39769 8.85077 8.55692 8.64308 8.78462L7.63846 9.99308C7.61154 9.79 7.63539 9.45462 7.63539 9.31923C8.39 8.18615 9.63 7.29231 10.4708 7.29231C11.27 7.37385 11.6485 8.01308 11.5092 8.71539L9.83615 14.2454C9.81385 14.37 9.88 14.4969 9.99308 14.5369C10.1162 14.58 10.3746 14.4208 10.5831 14.1931L11.5869 12.9854C11.6138 13.1885 11.5685 13.5438 11.5685 13.6792ZM11.3446 6.49154C10.7092 6.49154 10.1938 6.02846 10.1938 5.34692C10.1938 4.66539 10.7092 4.20308 11.3446 4.20308C11.98 4.20308 12.4954 4.66615 12.4954 5.34692C12.4954 6.02923 11.98 6.49154 11.3446 6.49154Z"
                //@ts-ignore
                fill={props.sx?.color || "#6D9ADC"}
                fill-opacity="1"
                // fontSize={"2px"}
            />
        </SvgIcon>
    )
}

export default InfoSvg
