import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { dailyChart } from '@/api/msg/msg.def';
import {
    getWorkResourcesResI,
    updateFactExecutorResourcesReqI,
    updateWorkExecutorResourcesReqI,
} from '@/api/snzResources/snzResources.def';
import { PublicUserProfile } from '@/api/users/users.def';

import { deployLinks } from '@/shared/config/config';

import { AppDispatch, RootState } from '../store';
import { setProfile } from './profileSlice';

// TODO:
// 1. Вынести все типы, интерфейсы
type toUpdateWorkMsg = { fact: string; workID: number };

interface ExecutorMsgRes {
    total: number;
    data: ExecutorMsgI[];
    project: string;
}

interface successDescription {
    description: string;
    success: boolean;
}

interface sendFactResI extends successDescription {
    data: ExecutorMsgI;
}

interface sendFactMultipleResI extends successDescription {
    data: ExecutorMsgI[];
}

interface ExecutorMsgI {
    objName: string | null;
    planMonth: number | null;
    rdCode: string | null;
    unit: string | null;
    volumeDoneFact: number | null;
    volumeDonePlan: number | null;
    workID: number;
    workName: string;
    dailyChart: dailyChart;
}

interface ExecutorMsgPeqResI {
    data: ExecutorMsgPeqI[];
    total: number;
}

interface ExecutorMsgPeqI {
    dailyChart: dailyChart;
    id: number;
    name: string;
    planMonth: number | null;
}

interface sendFactPeqResI extends successDescription {
    data: ExecutorMsgPeqI;
}

interface sendFactMultiplePeqResI extends successDescription {
    data: ExecutorMsgPeqI[];
}

const config = (token?: string) => ({
    headers: {
        Authorization: `Bearer ${token}`,
    },
});

//profile
export const getProfileData = createAsyncThunk<
    PublicUserProfile,
    void,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('msg/getProfileDataStatus', async (_, { dispatch, getState }) => {
    const { data } = await axios.get<PublicUserProfile>(
        `${deployLinks.server}/profile/get`,
        config(getState().msg.queryParams?.token)
    );
    dispatch(setProfile(data));
    return data;
});
//profile end

//works start
export const getWorksExecutor = createAsyncThunk<
    ExecutorMsgRes,
    void,
    {
        state: RootState;
    }
>('msg/getWorksExecutorStatus', async (_, { getState }) => {
    const { data } = await axios.get<ExecutorMsgRes>(
        `${deployLinks.server}/projects/${getState().msg.queryParams?.id}/works/msg/executor`,
        config(getState().msg.queryParams?.token)
    );

    return data;
});

export const sendFactExecutor = createAsyncThunk<
    sendFactResI,
    toUpdateWorkMsg,
    {
        state: RootState;
    }
>('msg/sendFactExecutorStatus', async (updateWork, { getState }) => {
    const state = getState();
    const { data } = await axios.post<sendFactResI>(
        `${deployLinks.server}/projects/${state.msg.queryParams?.id}/works/msg/executor/update-fact`,
        updateWork,
        config(getState().msg.queryParams?.token)
    );

    return data;
});

export const sendFactMultipleExecutor = createAsyncThunk<
    sendFactMultipleResI,
    void,
    {
        state: RootState;
    }
>('msg/sendFactMultipleExecutorStatus', async (_, { getState }) => {
    const { data } = await axios.post<sendFactMultipleResI>(
        `${deployLinks.server}/projects/${getState().msg.queryParams?.id}/works/msg/executor/mass-update`,
        {
            toUpdate: getState().msg.multipleInputsArrMsg,
        },
        config(getState().msg.queryParams?.token)
    );

    return data;
});
//works end

//people start
export const getWorksMsgPeopleExecutor = createAsyncThunk<
    ExecutorMsgPeqResI,
    void,
    {
        state: RootState;
    }
>('msg/getWorksMsgPeopleExecutorStatus', async (_, { getState }) => {
    const { data } = await axios.get<ExecutorMsgPeqResI>(
        `${deployLinks.server}/projects/${getState().msg.queryParams?.id}/staffs/msg/executor`,
        config(getState().msg.queryParams?.token)
    );

    return data;
});

export const sendFactPeopleExecutor = createAsyncThunk<
    sendFactPeqResI,
    toUpdateWorkMsg,
    {
        state: RootState;
    }
>('msg/sendFactPeopleExecutorStatus', async (updateWork, { getState }) => {
    const state = getState();
    const { data } = await axios.post<sendFactPeqResI>(
        `${deployLinks.server}/projects/${state.msg.queryParams?.id}/staffs/msg/executor/update-fact`,
        { ...updateWork, id: updateWork.workID },
        config(getState().msg.queryParams?.token)
    );

    return data;
});

export const sendFactMultiplePeopleExecutor = createAsyncThunk<
    sendFactMultiplePeqResI,
    void,
    {
        state: RootState;
    }
>('msg/sendFactMultiplePeopleExecutorStatus', async (_, { getState }) => {
    const { data } = await axios.post<sendFactMultiplePeqResI>(
        `${deployLinks.server}/projects/${getState().msg.queryParams?.id}/staffs/msg/executor/mass-update`,
        {
            toUpdate: getState().msg.multipleInputsArrMsg.map((work) => {
                return {
                    ...work,
                    id: work.workID,
                };
            }),
        },
        config(getState().msg.queryParams?.token)
    );

    return data;
});
//end people

// equip start
export const getWorksMsgEquipExecutor = createAsyncThunk<
    ExecutorMsgPeqResI,
    void,
    {
        state: RootState;
    }
>('msg/getWorksMsgEquipExecutorStatus', async (_, { getState }) => {
    const { data } = await axios.get<ExecutorMsgPeqResI>(
        `${deployLinks.server}/projects/${getState().msg.queryParams?.id}/technics/msg/executor`,
        config(getState().msg.queryParams?.token)
    );

    return data;
});

export const sendFactEquipExecutor = createAsyncThunk<
    sendFactPeqResI,
    toUpdateWorkMsg,
    {
        state: RootState;
    }
>('msg/sendFactEquipExecutorStatus', async (updateWork, { getState }) => {
    const state = getState();
    const { data } = await axios.post<sendFactPeqResI>(
        `${deployLinks.server}/projects/${state.msg.queryParams?.id}/technics/msg/executor/update-fact`,
        { ...updateWork, id: updateWork.workID },
        config(getState().msg.queryParams?.token)
    );

    return data;
});

export const sendFactMultipleEquipExecutor = createAsyncThunk<
    sendFactMultiplePeqResI,
    void,
    {
        state: RootState;
    }
>('msg/sendFactMultipleEquipExecutorStatus', async (_, { getState }) => {
    const { data } = await axios.post<sendFactMultiplePeqResI>(
        `${deployLinks.server}/projects/${getState().msg.queryParams?.id}/technics/msg/executor/mass-update`,
        {
            toUpdate: getState().msg.multipleInputsArrMsg.map((work) => {
                return {
                    ...work,
                    id: work.workID,
                };
            }),
        },
        config(getState().msg.queryParams?.token)
    );

    return data;
});
//end equip

// new methods 30.06.23 start
export const getWorkResources = createAsyncThunk<
    getWorkResourcesResI,
    void,
    {
        state: RootState;
    }
>('msg/getWorkResourcesStatus', async (_, { getState }) => {
    const { data } = await axios.get<getWorkResourcesResI>(
        `${deployLinks.server}/projects/${getState().msg.queryParams?.id}/works/msg/resources/executor`,
        config(getState().msg.queryParams?.token)
    );

    return data;
});

export const updateFactExecutorResources = createAsyncThunk<
    unknown,
    updateFactExecutorResourcesReqI,
    {
        state: RootState;
    }
>('msg/updateFactExecutorResourcesStatus', async (body, { getState }) => {
    const { data } = await axios.post<unknown>(
        `${deployLinks.server}/projects/${getState().msg.queryParams?.id}/works/msg/resources/executor/update-fact`,
        body,
        config(getState().msg.queryParams?.token)
    );

    return data;
});

export const updateWorkExecutorResources = createAsyncThunk<
    unknown,
    updateWorkExecutorResourcesReqI,
    {
        state: RootState;
    }
>('msg/updateWorkExecutorResourcesStatus', async (body, { getState }) => {
    const { data } = await axios.post<unknown>(
        `${deployLinks.server}/projects/${getState().msg.queryParams?.id}/works/msg/resources/executor/update-work`,
        body,
        config(getState().msg.queryParams?.token)
    );

    return data;
});

export const updateStaffExecutorResources = createAsyncThunk<
    unknown,
    updateWorkExecutorResourcesReqI,
    {
        state: RootState;
    }
>('msg/updateStaffExecutorResourcesStatus', async (body, { getState }) => {
    const { data } = await axios.post<unknown>(
        `${deployLinks.server}/projects/${getState().msg.queryParams?.id}/works/msg/resources/executor/update-staff`,
        body,
        config(getState().msg.queryParams?.token)
    );

    return data;
});

export const updateMimExecutorResources = createAsyncThunk<
    unknown,
    updateWorkExecutorResourcesReqI,
    {
        state: RootState;
    }
>('msg/updateMimExecutorResourcesStatus', async (body, { getState }) => {
    const { data } = await axios.post<unknown>(
        `${deployLinks.server}/projects/${getState().msg.queryParams?.id}/works/msg/resources/executor/update-mim`,
        body,
        config(getState().msg.queryParams?.token)
    );

    return data;
});
// new methods 30.06.23 end

const worksAdapter = createEntityAdapter<ExecutorMsgI>({
    selectId: (work) => work.workID,
});
const peopleWorksAdapter = createEntityAdapter<ExecutorMsgPeqI>();
const equipWorksAdapter = createEntityAdapter<ExecutorMsgPeqI>();

type initialStateType = {
    header: string;
    multipleInputsArrMsg: { fact: string; workID: number }[];
    queryParams: queryParamsType | null;
    openedRowsArr: number[];
    worksArr: EntityState<ExecutorMsgI>;
    peopleWorksArr: EntityState<ExecutorMsgPeqI>;
    equipWorksArr: EntityState<ExecutorMsgPeqI>;
    worksResourcesArr: getWorkResourcesResI;
    isLoadingGet: boolean;
    isLoadingPost: boolean;
};

const initialState: initialStateType = {
    header: '',
    multipleInputsArrMsg: [],
    queryParams: null,
    openedRowsArr: [],
    worksArr: worksAdapter.getInitialState(),
    peopleWorksArr: peopleWorksAdapter.getInitialState(),
    equipWorksArr: equipWorksAdapter.getInitialState(),
    worksResourcesArr: {
        doneList: [],
        project: '',
        waitList: [],
    },
    isLoadingGet: false,
    isLoadingPost: false,
};

enum executorStorage {
    executorAuth = 'executorAuth',
}

if (localStorage.getItem(executorStorage.executorAuth)) {
    initialState.queryParams = JSON.parse(localStorage.getItem(executorStorage.executorAuth) || '{}');
}
type queryParamsType = {
    id: string;
    token: string;
};

const executorSlice = createSlice({
    name: 'msg',
    initialState,
    reducers: {
        setQueryParams(state, action: PayloadAction<queryParamsType | null>) {
            state.queryParams = action.payload;
            localStorage.setItem(executorStorage.executorAuth, JSON.stringify(action.payload));
        },
        setMultipleInputsArrMsg(state, action: PayloadAction<toUpdateWorkMsg>) {
            state.multipleInputsArrMsg.push(action.payload);
            state.multipleInputsArrMsg = Array.from(
                new Map(state.multipleInputsArrMsg.map((item) => [item['workID'], item])).values()
            );

            state.multipleInputsArrMsg = state.multipleInputsArrMsg.filter((f) => f.fact !== '');
        },
        setOpenedRowsArrExecutor(state, action: PayloadAction<number>) {
            if (!state.openedRowsArr.includes(action.payload)) {
                state.openedRowsArr.push(action.payload);
            }
        },
        refreshOpenedRowsArrExecutor(state) {
            state.openedRowsArr = [];
        },
        refreshMultipleInputsArrMsg(state) {
            state.multipleInputsArrMsg = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWorksExecutor.pending, (state) => {
                state.isLoadingGet = true;
            })
            .addCase(getWorksExecutor.fulfilled, (state, action) => {
                state.isLoadingGet = false;
                worksAdapter.setAll(state.worksArr, action.payload.data);
            })
            .addCase(getWorksExecutor.rejected, (state) => {
                state.isLoadingGet = false;
            })
            .addCase(sendFactExecutor.pending, (state) => {
                state.isLoadingPost = true;
            })
            .addCase(sendFactExecutor.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.multipleInputsArrMsg = [];
                state.openedRowsArr = [];
                state.isLoadingPost = false;
                worksAdapter.upsertOne(state.worksArr, data);
            })
            .addCase(sendFactMultipleExecutor.pending, (state) => {
                state.isLoadingPost = true;
            })
            .addCase(sendFactMultipleExecutor.fulfilled, (state, action) => {
                state.isLoadingPost = false;
                state.multipleInputsArrMsg = [];
                state.openedRowsArr = [];
                worksAdapter.upsertMany(state.worksArr, action.payload.data);
            })
            .addCase(getWorksMsgPeopleExecutor.pending, (state) => {
                state.isLoadingGet = true;
            })
            .addCase(getWorksMsgPeopleExecutor.fulfilled, (state, action) => {
                state.isLoadingGet = false;
                peopleWorksAdapter.setAll(state.peopleWorksArr, action.payload.data);
            })
            .addCase(getWorksMsgPeopleExecutor.rejected, (state) => {
                state.isLoadingGet = false;
            })
            .addCase(sendFactPeopleExecutor.pending, (state) => {
                state.isLoadingPost = true;
            })
            .addCase(sendFactPeopleExecutor.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.multipleInputsArrMsg = [];
                state.openedRowsArr = [];
                state.isLoadingPost = false;
                peopleWorksAdapter.upsertOne(state.peopleWorksArr, data);
            })
            .addCase(sendFactMultiplePeopleExecutor.pending, (state) => {
                state.isLoadingPost = true;
            })
            .addCase(sendFactMultiplePeopleExecutor.fulfilled, (state, action) => {
                state.isLoadingPost = false;
                state.multipleInputsArrMsg = [];
                state.openedRowsArr = [];
                peopleWorksAdapter.upsertMany(state.peopleWorksArr, action.payload.data);
            })
            .addCase(getWorksMsgEquipExecutor.pending, (state) => {
                state.isLoadingGet = true;
            })
            .addCase(getWorksMsgEquipExecutor.fulfilled, (state, action) => {
                state.isLoadingGet = false;
                equipWorksAdapter.setAll(state.equipWorksArr, action.payload.data);
            })
            .addCase(getWorksMsgEquipExecutor.rejected, (state) => {
                state.isLoadingGet = false;
            })
            .addCase(sendFactEquipExecutor.pending, (state) => {
                state.isLoadingPost = true;
            })
            .addCase(sendFactEquipExecutor.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.multipleInputsArrMsg = [];
                state.openedRowsArr = [];
                state.isLoadingPost = false;
                equipWorksAdapter.upsertOne(state.equipWorksArr, data);
            })
            .addCase(sendFactMultipleEquipExecutor.pending, (state) => {
                state.isLoadingPost = true;
            })
            .addCase(sendFactMultipleEquipExecutor.fulfilled, (state, action) => {
                state.isLoadingPost = false;
                state.multipleInputsArrMsg = [];
                state.openedRowsArr = [];
                equipWorksAdapter.upsertMany(state.equipWorksArr, action.payload.data);
            })
            .addCase(getWorkResources.pending, (state) => {
                state.isLoadingGet = true;
            })
            .addCase(getWorkResources.fulfilled, (state, action) => {
                state.isLoadingGet = false;
                state.worksResourcesArr = action.payload;
            })
            .addCase(getWorkResources.rejected, (state) => {
                state.isLoadingGet = false;
            })
            .addCase(updateFactExecutorResources.pending, (state) => {
                state.isLoadingGet = true;
            })
            .addCase(updateFactExecutorResources.fulfilled, (state) => {
                state.isLoadingGet = false;
            })
            .addCase(updateFactExecutorResources.rejected, (state) => {
                state.isLoadingGet = false;
            })
            .addCase(updateWorkExecutorResources.pending, (state) => {
                state.isLoadingGet = true;
            })
            .addCase(updateWorkExecutorResources.fulfilled, (state) => {
                state.isLoadingGet = false;
            })
            .addCase(updateWorkExecutorResources.rejected, (state) => {
                state.isLoadingGet = false;
            })
            .addCase(updateStaffExecutorResources.pending, (state) => {
                state.isLoadingGet = true;
            })
            .addCase(updateStaffExecutorResources.fulfilled, (state) => {
                state.isLoadingGet = false;
            })
            .addCase(updateStaffExecutorResources.rejected, (state) => {
                state.isLoadingGet = false;
            })
            .addCase(updateMimExecutorResources.pending, (state) => {
                state.isLoadingGet = true;
            })
            .addCase(updateMimExecutorResources.fulfilled, (state) => {
                state.isLoadingGet = false;
            })
            .addCase(updateMimExecutorResources.rejected, (state) => {
                state.isLoadingGet = false;
            });
    },
});

export const {
    setQueryParams,
    setMultipleInputsArrMsg,
    setOpenedRowsArrExecutor,
    refreshOpenedRowsArrExecutor,
    refreshMultipleInputsArrMsg,
} = executorSlice.actions;
export const { selectAll: worksArrSelectAll } = worksAdapter.getSelectors((s: RootState) => s.msg.worksArr);
export const { selectAll: worksArrSelectAllPeople } = peopleWorksAdapter.getSelectors(
    (s: RootState) => s.msg.peopleWorksArr
);
export const { selectAll: worksArrSelectAllEquip } = equipWorksAdapter.getSelectors(
    (s: RootState) => s.msg.equipWorksArr
);

export default executorSlice.reducer;

export const executorSelector = (state: RootState) => state.msg;
