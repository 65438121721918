import { TextField } from '@mui/material';

import { FormErrorAg, inputStylesAddWorkAg } from '../AddLevel.service';
import s from '../AddLevelKsg.module.scss';

export default function TextLineEl({
    errorTitle,
    touched,
    title,
    required,
    titleAsNode,
    ...props
}: {
    errorTitle?: string;
    touched?: boolean;
    title: string;
    titleAsNode?: React.ReactNode;
    required?: boolean;
}) {
    return (
        <div className={s.input_line}>
            <p>
                {titleAsNode ?? title}
                {required && (
                    <>
                        {''}
                        <span>*</span>
                    </>
                )}
                :
            </p>
            <div className='relative'>
                <TextField
                    InputLabelProps={{ shrink: false }}
                    placeholder={title}
                    sx={inputStylesAddWorkAg}
                    error={Boolean(touched) && Boolean(errorTitle?.length)}
                    {...props}
                />
                <FormErrorAg
                    isTouched={touched}
                    error={errorTitle}
                />
            </div>
        </div>
    );
}
