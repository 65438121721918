import { Box, Paper, Popper, PopperProps, Switch, Typography } from '@mui/material'
import { FlexColumnWrapper, FlexRowWrapper } from '../../../pages/NewExecutorView/components/components.styles'
import { filtersSelector, handleChangeCritPathFilter } from '../../../store/slices/filtersSlice'
import { useAppDispatch, useTypedSelector } from '../../../store/store'

export function CritPathPopper(props: PopperProps) {
    const dispatch = useAppDispatch()

    const {
        filters: { criticalFilter },
    } = useTypedSelector(filtersSelector)

    return (
        <Popper {...props} sx={{ top: 10, right: 52 }} placement="bottom-end">
            <Paper elevation={8} sx={{ mt: 1 }}>
                <FlexColumnWrapper
                    p={2}
                    sx={{ paddingRight: '8px !important', position: 'relative' }}
                    gap={0}
                    zIndex={2}
                >
                    <FlexRowWrapper gap={0} justifyContent={'space-between'}>
                        <Typography>Только работы на критическом пути</Typography>{' '}
                        <Switch
                            checked={criticalFilter.enable}
                            onChange={(e, checked) => {
                                dispatch(
                                    handleChangeCritPathFilter({
                                        enable: checked,
                                        showParent: true,
                                    })
                                )
                            }}
                        />
                    </FlexRowWrapper>
                    {/* <FlexRowWrapper gap={0} justifyContent={'space-between'}>
                        <Typography color={!criticalFilter.enable ? 'disabled' : 'text'}>Скрыть родителей</Typography>{' '}
                        <Switch
                            disabled={!criticalFilter.enable}
                            checked={!criticalFilter.showParent}
                            onChange={(e, checked) => {
                                dispatch(
                                    handleChangeCritPathFilter({
                                        showParent: !checked,
                                    })
                                )
                            }}
                        />
                    </FlexRowWrapper> */}
                </FlexColumnWrapper>
                <Box id="scene" sx={{ position: 'absolute', top: -24, right: -0, bottom: -0, left: -0 }}></Box>
            </Paper>
        </Popper>
    )
}
