import { ICellRendererParams } from 'ag-grid-community'
import { WorkMsg, dailyChart } from '../../../../api/msg/msg.def'
import { IGantLinkActiveChoiseProps } from '../../../../layouts/TableLayout/components/GantLinkActiveChoise/GantLinkActiveChoise.def'
import { IGetGantDepIDArgs, TLinkSide } from './GantCell.def'

export function getDateWithLeadingZero(day: number | string, month: number | string, year: number) {
    const _day = day.toString()
    const _month = month.toString()
    return `${_day.length === 1 ? 0 + _day : _day}.${_month.length === 1 ? 0 + _month : _month}.${year}`
}

export function getDateFromCellParams(cellRendererParams: ICellRendererParams | null | undefined, chartIndex: number) {
    if (cellRendererParams && chartIndex != undefined) {
        const { month, year } = cellRendererParams?.data as WorkMsg
        const day = cellRendererParams?.data?.dailyCharts[chartIndex].day.toString()

        return getDateWithLeadingZero(day, month, year)
    }
    return 'InvalidDate'
}

export function getHideIndicatorConditionArray(
    cellRendererParams: ICellRendererParams,
    chartIndex: number,
    activeGantChoice: IGantLinkActiveChoiseProps | null | undefined
) {
    const doesWorkHaveNoBrigade = !cellRendererParams.data?.brigade?.id
    const isWorkActive =
        activeGantChoice?.cellRendererParams &&
        activeGantChoice?.cellRendererParams?.data?.id === cellRendererParams.data?.id
    const isAnotherBrigade =
        activeGantChoice?.cellRendererParams &&
        activeGantChoice?.cellRendererParams?.data?.brigade?.id !== cellRendererParams.data?.brigade?.id
    const isStartStartDateConflict =
        activeGantChoice?.cellRendererParams &&
        activeGantChoice?.linkType === 'start' &&
        (cellRendererParams?.data?.dailyCharts[chartIndex]?.day || 0) !==
            (activeGantChoice?.cellRendererParams?.data?.dailyCharts[activeGantChoice?.chartIndex]?.day || 0)
    const isEndDateConflict =
        activeGantChoice?.cellRendererParams &&
        activeGantChoice?.linkType === 'finish' &&
        (cellRendererParams?.data?.dailyCharts[chartIndex]?.day || 0) <
            (activeGantChoice?.cellRendererParams?.data?.dailyCharts[activeGantChoice?.chartIndex]?.day || 0)
    const isChosenWorkStart = activeGantChoice?.linkType === 'start'
    const isChosenWorkFinish = activeGantChoice?.linkType === 'finish'
    return {
        doesWorkHaveNoBrigade: doesWorkHaveNoBrigade,
        isWorkActive: isWorkActive,
        isAnotherBrigade: isAnotherBrigade,
        isStartStartDateConflict: isStartStartDateConflict,
        isEndDateConflict: isEndDateConflict,
        isChosenWorkStart: isChosenWorkStart,
        isChosenWorkFinish: isChosenWorkFinish,
    }
}

export function getGantBarPlacement(
    cellRendererParams: ICellRendererParams,
    chartKey: keyof dailyChart,
    chartIndex: number
) {
    const doesPrevValueExist =
        cellRendererParams?.data?.dailyCharts[chartIndex - 1] &&
        !!cellRendererParams?.data?.dailyCharts[chartIndex - 1][chartKey]
    const doesNextValueExist =
        cellRendererParams?.data?.dailyCharts[chartIndex + 1] &&
        !!cellRendererParams?.data?.dailyCharts[chartIndex + 1][chartKey]
    if (!doesPrevValueExist && !doesNextValueExist) {
        return 'center'
    }
    if (!doesPrevValueExist) {
        return 'start'
    }
    if (!doesNextValueExist) {
        return 'end'
    }
    return 'fullWidth'
}

export function createPathArrowV2({
    pathID,
    from,
    to,
    color,
    canvasID,
}: {
    pathID: string
    from: {
        x: number
        y: number
    }
    to: {
        x: number
        y: number
    }
    color: string
    canvasID: string
}) {
    const canvas = document.getElementById(canvasID)
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')

    const arrow = `
    M${to.x - 5},${to.y - 3} 
    L${to.x - 5},${to.y + 3}, 
    L${to.x},${to.y} Z`

    if (from.x < to.x) {
        if (from.y < to.y) {
            if (to.x - from.x === 46) {
                path.setAttribute(
                    'd',
                    `M${from?.x} ${from?.y},
                    L${(from.x += 12)}, ${from.y},
                    a10,10 0 0 1 10,10
                    L${(from.x += 10)} ${to.y - 5}
                    a 7 7 0 0 0 5 5
                    L${to.x} ${to.y}
                   ${arrow}
                `
                )
            } else {
                path.setAttribute(
                    'd',
                    `M${from?.x} ${from?.y},
                    L${(from.x += 12)}, ${from.y},
                    a10,10 0 0 1 10,10
                    L${(from.x += 10)} ${(from.y += 30)},
                    a 10 10 0 0 0 10 10
                    L${(to.x -= 33)} ${(from.y += 10)},
                    a 10 10 0 0 1 10 10
                    L${(to.x += 10)} ${to.y - 5}
                    a 7 7 0 0 0 5 5
                    L${(to.x += 22)} ${to.y}
                   ${arrow}
                `
                )
            }
        } else {
            if (to.x - from.x === 46) {
                path.setAttribute(
                    'd',
                    `M${from?.x} ${from?.y},
                    L${(from.x += 12)}, ${from.y},
                    a10,10 0 0 0 10,-10
                    L${(from.x += 10)} ${(to.y += 5)}
                    a 7 7 0 0 1 5 -5
                    L${from.x + 22} ${(to.y -= 5)}
                   ${arrow}
                `
                )
            } else {
                path.setAttribute(
                    'd',
                    `M${from?.x} ${from?.y},
                    L${(from.x += 12)}, ${from.y},
                        a10,10 0 0 0 10,-10
                        L${(from.x += 10)} ${(from.y -= 30)},
                        a 10 10 0 0 1 10 -10
                        L${(to.x -= 33)} ${(from.y -= 10)},
                        a 10 10 0 0 0 10 -10
                        L${(to.x += 10)} ${to.y + 5}
                        a 7 7 0 0 1 5 -5
                        L${(to.x += 22)} ${to.y}
                        ${arrow}
                `
                )
            }
        }
    }
    if (from.x === to.x) {
        if (from.y < to.y) {
            path.setAttribute(
                'd',
                `M${from?.x} ${from?.y},
                a 10 10 0 0 0 -10 10
                L${from.x - 10} ${to.y - 5}
                a 7 7 0 0 0 5 5
                L${to.x - 3} ${to.y}
                ${arrow}
                `
            )
        } else {
            path.setAttribute(
                'd',
                `M${from?.x} ${from?.y},
                a 10 10 0 0 1 -10 -10
                L${from.x - 10} ${to.y + 5}
                a 7 7 0 0 1 5 -5
                L${to.x - 3} ${to.y}
                ${arrow}
                `
            )
        }
    }

    if (from.x > to.x) {
        if (from.y < to.y) {
            path.setAttribute(
                'd',
                `M${from?.x} ${from?.y}, 
                L${(from.x += 12)}, ${from.y},
                    a10,10 0 0 1 10,10
                    L${(from.x += 10)} ${from.y + 40 - 10},
                    a 10 10 0 0 1 -10 10
                    L${(to.x -= 13)} ${(from.y += 40)},
                    a 10 10 0 0 0 -10 10
                    L${(to.x -= 10)} ${(to.y -= 5)} 
                    a 7 7 0 0 0 5 5
                    L${(to.x += 22)} ${(to.y += 5)} 
                    ${arrow}
                    `
            )
        } else {
            path.setAttribute(
                'd',
                `M${from?.x} ${from?.y}, 
                L${(from.x += 12)}, ${from.y},
                    a10,10 0 0 0 10,-10
                    L${from?.x + 10} ${(from.y -= 31)},
                    a 10,10 0 0 0 -10,-10
                    L${(to.x -= 13)} ${(from.y -= 9)},
                    a 10 10 0 0 1 -10 -10
                    L${to.x - 10} ${to.y + 5} 
                    a 7 7 0 0 1 5 -5
                    L${(to.x += 12)} ${to.y} 
                    ${arrow}
                    `
            )
        }
    }

    path.setAttribute('fill', 'transparent')
    path.setAttribute('stroke', color)
    path.setAttribute('filter', 'saturate(650%) contrast(70%)')
    path.setAttribute('stroke-width', '2px')
    path.setAttribute('stroke-linejoin', 'round')
    path.setAttribute('stroke-linecap', 'round')
    path.setAttribute('id', pathID)
    canvas?.append(path)
}

const ROW_HEIGHT = 40
const CELL_WIDTH = 64

export function getIndicatorCoords(rowIndex: number, chartIndex: number, side: TLinkSide) {
    const offset = {
        x: -CELL_WIDTH,
        y: 0,
    }
    const offsetEndIndicatorCoord = {
        x: side === 'start' ? 23 : 41,
        y: ROW_HEIGHT / 2,
    }
    const targetCellCoords = {
        x: chartIndex * CELL_WIDTH + offset.x + offsetEndIndicatorCoord.x,
        y: rowIndex * ROW_HEIGHT + offset.y + offsetEndIndicatorCoord.y,
    }

    return targetCellCoords
}

export function getGantDepId({ workFromID, workToID, workFromDate, workToDate }: IGetGantDepIDArgs) {
    return `${workFromID}${workToID}${workFromDate}${workToDate}`
}
