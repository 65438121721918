import { onEditAgKsg } from '../../../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import {
    parseStringToNumber,
    onSubmitSuccess,
    onSubmitRejected,
} from '../../../../components/EditChartCell/EditChartCell.service'
import { req } from '../../../WorkManagment/api/api'
import { TResourceType } from '../../../WorkManagment/api/api.types'
import {
    IEditChartCellProps,
    IOnSubmitChartDataArgs,
    IUpdateChartBodyReq,
} from '../../../../components/EditChartCell/EditChartCell.def'
import { AxiosPromise } from 'axios'

function getTargetRequestByValueType(
    chartValueKey: IEditChartCellProps['chartValueKey'],
    projectID: string,
    resourceType: TResourceType,
    body: any
): AxiosPromise {
    let baseURL = `/projects/${projectID}/${resourceType === 'staff' ? 'staffs' : 'technics'}/update-charts`

    switch (chartValueKey) {
        case 'verifiedFact':
            baseURL += '-vf'
            break
        case 'plan':
            break
    }

    return req.post(baseURL, body)
}

export const submitKSGResourceMonthlyChart = (args: IOnSubmitChartDataArgs & { resourceType: TResourceType }) => {
    const { cellRendererParams, formData, chartValueKey, chartIndex, projectID, dispatch, resourceType } = args

    const targetMonthlyChart = cellRendererParams.api.getValue('monthlyCharts', cellRendererParams.node)[
        chartIndex as number
    ]

    let updatedChart = {
        ...targetMonthlyChart,
        [chartValueKey]: parseStringToNumber(formData[chartValueKey]),
    }

    delete updatedChart.verifiedFact

    if (chartValueKey === 'verifiedFact') {
        delete updatedChart.plan
        delete updatedChart.fact
        delete updatedChart.verifiedFact
        updatedChart.verFact = parseStringToNumber(formData[chartValueKey])
    }

    const chartForReqBody = {
        toUpdate: [
            {
                [resourceType === 'staff' ? 'staffID' : 'techID']: cellRendererParams?.data?.id,
                charts: [updatedChart],
            },
        ],
    } as IUpdateChartBodyReq

    getTargetRequestByValueType(chartValueKey, projectID as string, resourceType, chartForReqBody)
        .then(({ data }) => {
            cellRendererParams.api.applyTransaction({ update: data.data })
            dispatch(onEditAgKsg(data.data))
            onSubmitSuccess(args)
        })
        .catch((e) => {
            onSubmitRejected(args)
        })
}

// export const submitKSGResourceMonthlyChart = (args: IOnSubmitChartDataArgs & { resourceType: TResourceType }) => {
//     const { cellRendererParams, formData, chartValueKey, chartIndex, projectID, dispatch, resourceType } = args

//     const targetMonthlyChart = cellRendererParams.api.getValue('monthlyCharts', cellRendererParams.node)[
//         chartIndex as number
//     ]

//     const updatedChart = {
//         ...targetMonthlyChart,
//         [chartValueKey]: parseStringToNumber(formData[chartValueKey]),
//     }

//     const chartForReqBody = {
//         toUpdate: [
//             {
//                 [resourceType === 'staff' ? 'staffID' : 'techID']: cellRendererParams?.data?.id,
//                 charts: [updatedChart],
//             },
//         ],
//     } as IUpdateChartBodyReq

//     req.post(
//         `/projects/${projectID}/${resourceType === 'staff' ? 'staffs' : 'technics'}/update-charts`,
//         chartForReqBody
//     )
//         .then(({ data }) => {
//             cellRendererParams.api.applyTransaction({ update: data.data })
//             dispatch(onEditAgKsg(data.data))
//             onSubmitSuccess(args)
//         })
//         .catch((e) => {
//             onSubmitRejected(args)
//         })
// }
