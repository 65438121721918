import { Stack, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import UserRoleSelection from '../UserRoleSelection/UserRoleSelection'
import { AccessStatusBlock } from './styles'
import { theme } from '../../../../styles/theme'
import Button from '../Button'
import { UserAccessesProps } from './UserAccesses.types'
import UserAccessesSelection from '../UserAccessesSelection/UserAccessesSelection'
import { useFormikContext } from 'formik'
import { UserFormData } from '../UserForm/UserForm.types'

function UserAccesses({ isEditUser }: UserAccessesProps) {
    const { values: formValues } = useFormikContext<UserFormData>()
    const role = formValues.role

    const renderAccessesBlock = useCallback(() => {
        switch (role) {
            case 'admin':
            case 'client':
                return (
                    <UserAccessesSelection>
                        <AccessStatusBlock>
                            <Typography variant="body1">Доступ ко всем проектам</Typography>
                        </AccessStatusBlock>
                    </UserAccessesSelection>
                )

            case 'scheduler':
            case 'controller':
                return <UserAccessesSelection/>
            case 'executor':
            case 'none':
                return (
                    <UserAccessesSelection>
                        <AccessStatusBlock color={theme.palette.error.main}>
                            <Typography variant="body1">Нет доступа к проектам</Typography>
                        </AccessStatusBlock>
                    </UserAccessesSelection>
                )
        }
    }, [role])

    return (
        <Stack
            sx={{
                minWidth: '396px',
                maxWidth: '476px',
            }}
            flex={1}
            spacing={4.375}
        >
            <UserRoleSelection />
            {renderAccessesBlock()}
            <Button
                sx={{
                    fontSize: '14px !important',
                    fontWeight: '700 !important',
                }}
                type="submit"
                color="success"
                width="100%"
                style={{ alignSelf: 'end' }}
            >
                {isEditUser ? 'Сохранить' : 'Создать'}
            </Button>
        </Stack>
    )
}

export default UserAccesses
