import { api } from '../api'
import { triggerKsgByBasePlanChange } from '../../store/slices/basePlan'

export const basePlanApi = api.injectEndpoints({
    endpoints: (build) => ({
        basePlanKsg: build.mutation<unknown, { projectId: number }>({
            query: ({ projectId }) => ({
                url: `/projects/${projectId}/works/save-bp`,
                method: 'POST',
            }),
            async onQueryStarted(arg, api) {
                try {
                    await api.queryFulfilled
                    api.dispatch(triggerKsgByBasePlanChange())
                } catch {}
            },
        }),
    }),
    overrideExisting: false,
})

export const { useBasePlanKsgMutation } = basePlanApi
