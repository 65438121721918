import React from "react";
import { StyledButton } from "./styles";
import { ButtonProps } from "./Button.types";

function Button({
  customsize = "small",
  color = "primary",
  variant = "contained",
  children,
  ...props
}: ButtonProps) {
  return (
    <StyledButton
      variant={variant}
      color={color}
      customsize={customsize}
      disableRipple
      {...props}>
      {children}
    </StyledButton>
  );
}

export default Button;
