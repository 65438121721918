import { Typography } from '@mui/material'
import styled from 'styled-components'

export const WorkManagmentResourceBlock = styled(Typography)`
    padding: ${({ theme }) => theme.spacing(0.75, 0.5)};
    border: 1px solid #6daaff;
    color: #6daaff;
    border-radius: ${({ theme }) => theme.spacing(0.5)};
    line-height: 100%;
    align-self: center;
    font-size: ${({ theme }) => theme.spacing(1)}; ;
`

WorkManagmentResourceBlock.defaultProps = {
    variant: 'button',
}
