export const formControlStyles = {
    minWidth: 56,
    marginLeft: '2rem',

    '.MuiInput-root': {
        '&:before': {
            display: 'none',
        },
    },

    '.MuiSvgIcon-root': {
        fontSize: '1.2rem',
        top: 'calc(50% - 0.55em)',
        fill: '#0044b4',
    },
}
