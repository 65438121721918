import { NumericFormatProps  } from "react-number-format";

export const DEFAULT_PROPS: NumericFormatProps  = {
    displayType: 'text',
    fixedDecimalScale: true,
    decimalScale: 2,
    decimalSeparator: ',',
    thousandSeparator: ' ',
    allowNegative: false,
    suffix: ' р.'
}