import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Button, IconButton, LinearProgress, TextField, Tooltip } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parse } from 'date-fns';
import { ru } from 'date-fns/locale';
import omit from 'lodash/omit';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Updater, useImmer } from 'use-immer';

import { useGetRelationsQuery, useGetWorksNamesQuery, useSetRelationsMutation } from '@/api/relations/relations.api';
import { bindWork } from '@/api/relations/relations.def';

import { agGridKsgSelector } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import s from './GanttRelations.module.scss';
import { calendarStylesGanttRelations } from './GanttRelations.service';

function SearchWorkElTop({
    initialWorkData,
    isStatic,
    setIsStatic,
    period,
    workId,
    setRelationsWorksData,
}: {
    isStatic: boolean;
    setIsStatic: Updater<boolean>;
    workId?: number;
    initialWorkData: {
        workName: string;
        startDate: string | null;
        endDate: string | null;
    };
    period?: 'before' | 'after';
    setRelationsWorksData: Updater<relationsWorksDataI[]>;
}) {
    const [value, setValue] = useState<string>('');
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        setValue(initialWorkData.workName);
    }, [initialWorkData.workName]);

    return (
        <div className={s.work_container}>
            {period && (
                <div className={s.work_top_line}>
                    <div className={s.work_top_line_left}>
                        <IconButton
                            onClick={() => {
                                setRelationsWorksData((d) => {
                                    if (!workId) return;
                                    return d.filter((f) => f.workId !== workId);
                                });
                            }}
                        >
                            <CloseIcon
                                sx={{
                                    color: '#D2DCFF',
                                }}
                            />
                        </IconButton>
                        <p>Зависимая работа</p>
                    </div>
                    <div className={s.button_tabs}>
                        <button
                            onClick={() => {
                                setRelationsWorksData((d) => {
                                    if (!workId) return;
                                    const itemToChange = d.find((f) => f.workId === workId);
                                    if (!itemToChange) return;
                                    itemToChange.type = 'before';
                                });
                            }}
                            className={period === 'before' ? s.act : s.not_act}
                        >
                            ДО
                        </button>
                        <button
                            onClick={() => {
                                setRelationsWorksData((d) => {
                                    if (!workId) return;
                                    const itemToChange = d.find((f) => f.workId === workId);
                                    if (!itemToChange) return;
                                    itemToChange.type = 'after';
                                });
                            }}
                            className={period === 'after' ? s.act : s.not_act}
                        >
                            ПОСЛЕ
                        </button>
                    </div>
                </div>
            )}
            <Autocomplete
                sx={{
                    width: 388,
                    '.MuiOutlinedInput-root': {
                        padding: '0 39px 0 1rem',
                    },
                    '.MuiInputLabel-root': {
                        transform: 'translate(14px, 10px) scale(1)',
                        fontSize: 14,
                    },
                    '.MuiInputLabel-shrink': {
                        transform: 'translate(14px, -9px) scale(0.75)',
                    },
                }}
                value={value}
                onChange={(event: any, newValue: string | null) => {
                    setValue(newValue || '');
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                disabled
                options={['']}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label='Название работы'
                        />
                    );
                }}
            />
            <div
                style={{
                    paddingLeft: '0.5rem',
                }}
            >
                {/* <FormGroup>
                    <FormControlLabel
                        sx={{
                            '.MuiTypography-root': {
                                fontSize: 14,
                            },
                        }}
                        control={
                            <Checkbox
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    if (!period) {
                                        setIsStatic(event.target.checked)
                                    }
                                    setRelationsWorksData((d) => {
                                        if (!workId) return
                                        const itemToChange = d.find((f) => f.workId === workId)
                                        if (!itemToChange) return
                                        itemToChange.static = event.target.checked
                                    })
                                }}
                                checked={isStatic}
                            />
                        }
                        label='Статичная дата'
                    />
                </FormGroup> */}
            </div>

            <div className={s.dates_line}>
                <LocalizationProvider
                    adapterLocale={ru}
                    dateAdapter={AdapterDateFns}
                >
                    <DesktopDatePicker
                        label='Дата начала'
                        value={
                            initialWorkData.startDate
                                ? parse(initialWorkData.startDate as string, 'dd.MM.yyyy', new Date())
                                : null
                        }
                        onChange={() => {}}
                        disabled
                        inputFormat='dd.MM.yyyy'
                        renderInput={(params) => {
                            if (params.inputProps) params.inputProps.placeholder = 'дд.мм.гггг';
                            return (
                                <TextField
                                    {...params}
                                    disabled
                                    sx={calendarStylesGanttRelations}
                                />
                            );
                        }}
                    />
                </LocalizationProvider>

                <LocalizationProvider
                    adapterLocale={ru}
                    dateAdapter={AdapterDateFns}
                >
                    <DesktopDatePicker
                        label='Дата окончания'
                        value={
                            initialWorkData.endDate
                                ? parse(initialWorkData.endDate as string, 'dd.MM.yyyy', new Date())
                                : null
                        }
                        onChange={() => {}}
                        disabled
                        inputFormat='dd.MM.yyyy'
                        renderInput={(params) => {
                            if (params.inputProps) params.inputProps.placeholder = 'дд.мм.гггг';

                            return (
                                <TextField
                                    {...params}
                                    disabled
                                    sx={calendarStylesGanttRelations}
                                />
                            );
                        }}
                    />
                </LocalizationProvider>
            </div>
        </div>
    );
}

function SearchWorkEl({
    setRelationsEmptyWorksData,
    relationsEmptyWorksData,
    workIds,
}: {
    relationsEmptyWorksData: relationsEmptyWorksDataI;
    setRelationsEmptyWorksData: Updater<relationsEmptyWorksDataI[]>;
    workIds: number[];
}) {
    const [value, setValue] = useState<string>('');
    const [inputValue, setInputValue] = useState('');

    const { projectId } = useParams();

    const namesQuery = useGetWorksNamesQuery({
        projectId: Number(projectId),
        filter: inputValue,
        limit: 100,
        offset: 0,
    });

    const filteredNamesQuery = useMemo(() => {
        return namesQuery.data?.data.filter((item) => {
            return workIds.every((v) => item.id !== v);
        });
    }, [namesQuery.data?.data, workIds]);

    const parsedStartDate = value.split(':')[5]?.replace(/"|,|end/g, '');
    const parsedEndDate = value.split(':')[6]?.replace(/"|}/g, '');

    const startDate = parsedStartDate !== 'null' ? parsedStartDate : null;
    const endDate = parsedEndDate !== 'null' ? parsedEndDate : null;

    return (
        <div className={s.work_container}>
            <div className={s.work_top_line}>
                <div className={s.work_top_line_left}>
                    <IconButton
                        onClick={() => {
                            setRelationsEmptyWorksData((d) => {
                                if (!d) return;
                                return d.filter((f) => f.id !== relationsEmptyWorksData.id);
                            });
                        }}
                    >
                        <CloseIcon
                            sx={{
                                color: '#D2DCFF',
                            }}
                        />
                    </IconButton>

                    <p>Зависимая работа</p>
                </div>
                <div className={s.button_tabs}>
                    <button
                        onClick={() =>
                            setRelationsEmptyWorksData((d) => {
                                const itemToChange = d.find((f) => f.id === relationsEmptyWorksData.id);
                                if (!itemToChange) return;
                                itemToChange.type = 'before';
                            })
                        }
                        className={relationsEmptyWorksData.type === 'before' ? s.act : s.not_act}
                    >
                        ДО
                    </button>
                    <button
                        onClick={() =>
                            setRelationsEmptyWorksData((d) => {
                                const itemToChange = d.find((f) => f.id === relationsEmptyWorksData.id);
                                if (!itemToChange) return;
                                itemToChange.type = 'after';
                            })
                        }
                        className={relationsEmptyWorksData.type === 'after' ? s.act : s.not_act}
                    >
                        ПОСЛЕ
                    </button>
                </div>
            </div>

            <Autocomplete
                sx={{
                    width: 388,
                    '.MuiOutlinedInput-root': {
                        padding: '0 39px 0 1rem',
                    },
                    '.MuiInputLabel-root': {
                        transform: 'translate(14px, 10px) scale(1)',
                        fontSize: 14,
                    },
                    '.MuiInputLabel-shrink': {
                        transform: 'translate(14px, -9px) scale(0.75)',
                    },
                }}
                value={value}
                onChange={(event: any, newValue: string | null) => {
                    setRelationsEmptyWorksData((d) => {
                        const itemToChange = d.find((f) => f.id === relationsEmptyWorksData.id);
                        if (!itemToChange) return;
                        itemToChange.workId = Number(newValue?.split(',')[0].replace(/{"id":/g, ''));
                    });
                    setValue(newValue || '');
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                getOptionLabel={(option) => {
                    if (!option) return '';

                    return `${option.split(':')[2]?.split(',')[0]}. ${option.split(':')[3]?.split(',')[0]}`.replace(
                        /"+/g,
                        ''
                    );
                }}
                renderOption={(props, option) => {
                    if (!option) return '';
                    return (
                        <li {...props}>
                            {`${option.split(':')[2]?.split(',')[0]}. ${option.split(':')[3]?.split(',')[0]}`.replace(
                                /"+/g,
                                ''
                            )}
                        </li>
                    );
                }}
                options={
                    filteredNamesQuery?.map((item) => {
                        return JSON.stringify(item);
                    }) || ['']
                }
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label='Название работы'
                        />
                    );
                }}
            />
            <div
                style={{
                    paddingLeft: '0.5rem',
                }}
            >
                {/* <FormGroup>
                    <FormControlLabel
                        sx={{
                            '.MuiTypography-root': {
                                fontSize: 14,
                            },
                        }}
                        control={
                            <Checkbox
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setRelationsEmptyWorksData((d) => {
                                        if (!d) return
                                        const itemToChange = d.find((f) => f.id === relationsEmptyWorksData.id)
                                        if (!itemToChange) return
                                        itemToChange.static = event.target.checked
                                    })
                                }}
                                checked={relationsEmptyWorksData.static}
                            />
                        }
                        label='Статичная дата'
                    />
                </FormGroup> */}
            </div>

            <div className={s.dates_line}>
                <LocalizationProvider
                    adapterLocale={ru}
                    dateAdapter={AdapterDateFns}
                >
                    <DesktopDatePicker
                        label='Дата начала'
                        value={startDate ? parse(startDate as string, 'dd.MM.yyyy', new Date()) : null}
                        onChange={() => {}}
                        disabled
                        inputFormat='dd.MM.yyyy'
                        renderInput={(params) => {
                            if (params.inputProps) params.inputProps.placeholder = 'дд.мм.гггг';
                            return (
                                <TextField
                                    {...params}
                                    disabled
                                    sx={calendarStylesGanttRelations}
                                />
                            );
                        }}
                    />
                </LocalizationProvider>

                <LocalizationProvider
                    adapterLocale={ru}
                    dateAdapter={AdapterDateFns}
                >
                    <DesktopDatePicker
                        label='Дата окончания'
                        value={endDate ? parse(endDate as string, 'dd.MM.yyyy', new Date()) : null}
                        onChange={() => {}}
                        disabled
                        inputFormat='dd.MM.yyyy'
                        renderInput={(params) => {
                            if (params.inputProps) params.inputProps.placeholder = 'дд.мм.гггг';

                            return (
                                <TextField
                                    {...params}
                                    disabled
                                    sx={calendarStylesGanttRelations}
                                />
                            );
                        }}
                    />
                </LocalizationProvider>
            </div>
        </div>
    );
}

/*export interface bindWork {
    static: boolean
    type: 'after' | 'before'
    workId: number
}*/

interface relationsWorksDataI extends bindWork {
    workName: string;
    startDate: string | null;
    endDate: string | null;
}

interface relationsEmptyWorksDataI extends bindWork {
    id: number;
}

export default function GanttRelations({
    setRelationsOpened,
    initialWorkData,
    increaseRelationsCount,
}: {
    setRelationsOpened: Dispatch<SetStateAction<boolean>>;
    initialWorkData: {
        workName: string;
        startDate: string | null;
        endDate: string | null;
        id: number;
    };
    increaseRelationsCount?: (count: number) => void;
}) {
    const [isStatic, setIsStatic] = useImmer(false);
    const [relationsWorksData, setRelationsWorksData] = useImmer<relationsWorksDataI[]>([]);
    const [relationsEmptyWorksData, setRelationsEmptyWorksData] = useImmer<relationsEmptyWorksDataI[]>([]);
    const [localState, setLocalState] = useImmer<typeof initialWorkData | null>(null);

    const dispatch = useAppDispatch();

    const { worksList } = useTypedSelector(agGridKsgSelector);

    const { projectId } = useParams();

    const relations = useGetRelationsQuery({
        projectId: Number(projectId),
        workId: initialWorkData.id,
    });
    const [setRelationsReq, setRelationsRes] = useSetRelationsMutation();

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');

    useEffect(() => {
        if (!relations.data) return;
        setLocalState({
            id: relations.data!.mainWork.workId,
            endDate: relations.data!.mainWork.workPeriod.end,
            startDate: relations.data!.mainWork.workPeriod.start,
            workName: relations.data!.mainWork.workName,
        });
        setIsStatic(relations.data!.mainWork.static);
        setRelationsWorksData([
            ...relations.data.worksAfter.map((work) => {
                return {
                    static: work.static,
                    type: 'after' as 'after' | 'before',
                    workId: work.workId,
                    workName: work.workName,
                    startDate: work.workPeriod?.start,
                    endDate: work.workPeriod?.end,
                };
            }),
            ...relations.data.worksBefore.map((work) => {
                return {
                    static: work.static,
                    type: 'before' as 'after' | 'before',
                    workId: work.workId,
                    workName: work.workName,
                    startDate: work.workPeriod?.start,
                    endDate: work.workPeriod?.end,
                };
            }),
        ]);
        //eslint-disable-next-line
    }, [relations.data, setRelationsWorksData]);

    const bindsArray = [
        // ...relationsWorksData,
        // ...relationsEmptyWorksData
        ...relationsWorksData.map((work) => {
            return omit(work, 'workName', 'startDate', 'endDate');
        }),
        ...relationsEmptyWorksData.map((work) => {
            return omit(work, 'id');
        }),
    ];
    console.log(worksList);
    const submitRelations = useCallback(() => {
        // const flag = (bindsArray as any).every((b:any)=>b.startDate && b.endDate)
        // if (initialWorkData.startDate && initialWorkData.endDate) {
        //     const mainDate = new Date(initialWorkData!.startDate!)
        //     const afterDate = bindsArray.filter(b=>b.type === 'after')
        //     const beforeDate = bindsArray.filter(b=>b.type === 'before')
        //     const errorsDates:any[] = [];
        //     (beforeDate as any ).forEach((b:any)=> {
        //         isBefore(mainDate,new Date(b.startDate))&& errorsDates.push(b);
        //     });
        //     (afterDate as any ).forEach((b:any)=> {
        //         isAfter(mainDate,new Date(b.startDate))&& errorsDates.push(b);
        //     });
        //     console.log(errorsDates)
        //
        //     console.log('bindsArray>>>',bindsArray)
        //     console.log('main>>>', {
        //         static: isStatic,
        //         workId: initialWorkData.id,
        //         work: initialWorkData,
        //     })
        // }

        // {
        //     "mainWork": {
        //     "workId": 495434,
        //         "numOrder": 3,
        //         "workName": "Возведение опор",
        //         "workPeriod": {
        //         "start": "01.05.2023",
        //             "end": "31.01.2024"
        //     },
        //     "static": false
        // },
        //     "worksBefore": [],
        //     "worksAfter": [
        //     {
        //         "workId": 495435,
        //         "numOrder": 4,
        //         "workName": "Сооружение пролетов",
        //         "workPeriod": {
        //             "start": "01.05.2024",
        //             "end": "31.07.2024"
        //         },
        //         "static": false
        //     }
        // ]
        // }

        if (!relationsWorksData) return;
        setRelationsReq({
            projectId: Number(projectId),
            body: {
                binds: bindsArray,
                // mainWork: {
                // static: isStatic,
                workId: initialWorkData.id,
                // },
            },
        })
            .unwrap()
            .then((res) => {
                setRelationsOpened(false);

                /* TODO: На будущее функция которая будет проходить по массиву и обновлять связи в работах */
                // function updateWorks(arrWorks: number[],idMainWork:number,isAfter:boolean) {
                //     const updates: Work[] = []
                //     if (worksList) {
                //         arrWorks.forEach(_ => {
                //             const index = worksList.data.findIndex(_ => _.id === initialWorkData.id)
                //             if (index) {
                //                 const activeWork = worksList.data[index];
                //
                //                 const worksAfter = activeWork.workBonds.worksAfter
                //                 const worksBefore = activeWork.workBonds.worksBefore
                //
                //                 let isIncludesAfter = worksAfter.findIndex(bond => bond.id === idMainWork)
                //                 let isIncludesBefore = worksBefore.findIndex(bond => bond.id === idMainWork)
                //
                //                 if (isAfter) {
                //                     isIncludesAfter?worksAfter.slice(isIncludesAfter,1):worksAfter.concat({id:_})
                //                 }else{
                //                     isIncludesBefore?worksBefore.slice(isIncludesBefore,1):worksBefore.concat({id:_})
                //                 }
                //
                //                 updates.push({
                //                     ...activeWork,
                //                     workBonds: {
                //                         ...activeWork.workBonds,
                //                         worksAfter,
                //                         worksBefore,
                //                         count: [...worksAfter, ...worksBefore].length,
                //                     },
                //                 })
                //             }
                //         })
                //         dispatch(onEditAgKsg(updates))
                //     }
                // }

                // if (worksList) {
                //     const index = worksList.data.findIndex(_ => _.id === initialWorkData.id)
                //     if (index) {
                //         const activeWork = worksList.data[index]
                //         const updateMain = {
                //             ...activeWork,
                //             workBonds: {
                //                 ...activeWork.workBonds,
                //                 worksAfter: res.worksAfter.map(_ => ({ id: _.workId })),
                //                 worksBefore: res.worksBefore.map(_ => ({ id: _.workId })),
                //                 count: [...res.worksAfter, ...res.worksBefore].length,
                //             },
                //
                //         }
                //         dispatch(onEditAgKsg([updateMain]))
                //
                //     }
                // }
                if (increaseRelationsCount) {
                    increaseRelationsCount(res.worksBefore.length + res.worksAfter.length);
                }

                enqueueSnackbar(t('relations_set'), {
                    variant: 'success',
                });
            })
            .catch((e) => {
                setRelationsOpened(false);
                if (e.originalStatus === 400) {
                    enqueueSnackbar(t(`bind_errors.${e.data}`), {
                        variant: 'error',
                    });
                } else {
                    enqueueSnackbar('Ошибка', {
                        variant: 'error',
                    });
                }
                console.error(e);
            });
        //eslint-disable-next-line
    }, [relationsEmptyWorksData, relationsWorksData, projectId]);

    return (
        <section className={s.main}>
            {setRelationsRes.isLoading && <LinearProgress />}
            <div className={s.top}>
                <p className={s.top_sign}>Зависимые работы</p>
                <div className={s.dates_line}>
                    <Button
                        onClick={() => submitRelations()}
                        sx={{
                            backgroundColor: '#6FCCBC',
                            color: '#fff',
                            height: '2rem',
                            '&:hover': {
                                backgroundColor: '#14b8a6',
                            },
                        }}
                    >
                        Готово
                    </Button>
                    <Tooltip title='Закрыть'>
                        <IconButton
                            sx={{
                                backgroundColor: '#f6f7fb',
                                borderRadius: '6px',
                                width: 30,
                                height: 30,
                            }}
                            color='primary'
                            component='span'
                            onClick={() => setRelationsOpened(false)}
                        >
                            <CloseIcon
                                sx={{
                                    fontSize: '1.2rem',
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div className={s.works_container}>
                <SearchWorkElTop
                    setRelationsWorksData={setRelationsWorksData}
                    isStatic={isStatic}
                    setIsStatic={setIsStatic}
                    initialWorkData={localState || initialWorkData}
                />
                {!!relationsWorksData.length &&
                    relationsWorksData.map((work) => (
                        <SearchWorkElTop
                            setRelationsWorksData={setRelationsWorksData}
                            key={work.workId}
                            isStatic={work.static}
                            setIsStatic={setIsStatic}
                            workId={work.workId}
                            initialWorkData={{
                                workName: work.workName,
                                startDate: work.startDate,
                                endDate: work.endDate,
                            }}
                            period={work.type}
                        />
                    ))}

                {!!relationsEmptyWorksData.length &&
                    relationsEmptyWorksData.map((item) => (
                        <SearchWorkEl
                            setRelationsEmptyWorksData={setRelationsEmptyWorksData}
                            relationsEmptyWorksData={item}
                            workIds={[
                                initialWorkData.id,
                                ...bindsArray.map((work) => {
                                    return work.workId;
                                }),
                            ]}
                            key={item.id}
                        />
                    ))}
            </div>
            <div className={s.bottom_line}>
                <p>Зависимая работа</p>
                <Tooltip title='Добавить'>
                    <IconButton
                        sx={{
                            backgroundColor: '#f6f7fb',
                            borderRadius: '6px',
                            width: 24,
                            height: 24,
                        }}
                        color='primary'
                        component='span'
                        onClick={() => {
                            setRelationsEmptyWorksData((d) => {
                                d.push({
                                    static: false,
                                    type: 'after',
                                    id: Number((Math.random() * 100).toFixed(4)),
                                    workId: 0,
                                });
                            });
                        }}
                    >
                        <AddIcon
                            sx={{
                                fontSize: '1.2rem',
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </div>
        </section>
    );
}
