import styled from 'styled-components'
import { FlexRowWrapper } from '../../../NewExecutorView/components/components.styles'
import { Popper } from '@mui/material'

export const ColorPickerWrapper = styled(FlexRowWrapper)`
    flex: 2 0 100px !important;
    height: ${({ theme }) => theme.spacing(4)};
    padding: ${({ theme }) => theme.spacing(1)};
    background: #f6f7fb;
    border-radius: ${({ theme }) => theme.spacing(0.5)};
    outline: 1px solid rgba(92, 110, 140, 0.1);
    cursor: pointer;
    gap: 0;
    max-width: 145px;
    margin-left: auto;
`

export const ColorPickerPopper = styled(Popper)`
    z-index: 2;
    .sketch-picker {
        padding-top: 0 !important;
        width: 126px !important;
        .flexbox-fix:nth-of-type(2) {
            > div > div:nth-of-type(2) {
                display: none !important;
            }
        }
        .flexbox-fix:nth-of-type(3) {
            display: none !important;
        }
        .flexbox-fix:nth-of-type(4) > div {
            width: 24px !important;
            height: 24px !important;
        }
    }
`
