import s from '../../TableLayout.module.scss'
import { SvgCashBag, SvgMultipleBlankPage, SvgWorker } from '../../../../shared/SVG/Svg'
import {
    TChangeViewType,
    cpgViewEnum,
    cpgViewSelector,
    setCpgView,
    setMsgView,
} from '../../../../store/slices/cpgViewSlice'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { Paper, Popper, SvgIcon, Typography } from '@mui/material'
import { Fragment, RefObject, useEffect, useRef, useState } from 'react'
import { FlexColumnWrapper, FlexRowWrapper } from '../../../../pages/NewExecutorView/components/components.styles'
import { Settings } from '@mui/icons-material'
import { theme } from '../../../../styles/theme'
import { priceSettingsSelector } from '../../../../store/slices/priceSettings'
import { PRICE_TYPES, VOLUME_TYPES } from './ViewButtons.service'
import { useTranslation } from 'react-i18next'
import { IViewButtonsProps } from './ViewButtons.def'
import { StyledArrow, StyledPriceSettingsRowWrapper, StyledTab, StyledTabs } from './ViewButtons.styles'
import {
    useGetPriceSettingsMutation,
    useSavePriceSettingsMutation,
} from '../../../../api/priceSettings/priceSettings.api'
import { useParams } from 'react-router-dom'

export default function ViewButtons({ tableType = 'ksg' }: IViewButtonsProps) {
    const { projectId } = useParams()
    const { priceSettings } = useTypedSelector(priceSettingsSelector)
    const { t } = useTranslation('priceSettings')
    const [savePriceSettingsReq] = useSavePriceSettingsMutation()
    const [getPriceSettingsReq] = useGetPriceSettingsMutation()
    const { cpgView, msgView } = useTypedSelector(cpgViewSelector)
    const [isChoosePricePopperOpen, setIsChoosePricePopperOpen] = useState(false)
    const dispatch = useAppDispatch()
    const onViewChange =
        tableType === 'ksg'
            ? (view: { term: string }) => dispatch(setCpgView(cpgViewEnum[view.term as TChangeViewType]))
            : (view: { term: string }) => dispatch(setMsgView(cpgViewEnum[view.term as TChangeViewType]))

    const chosenView = tableType === 'ksg' ? cpgView : msgView
    const ref = useRef() as RefObject<HTMLDivElement>

    useEffect(() => {
        getPriceSettingsReq({ projectId: projectId || '', tableType: tableType })
    }, [projectId])

    return (
        <>
            <div className={s.buttons_bar} ref={ref} onMouseLeave={(e) => setIsChoosePricePopperOpen(() => false)}>
                {[
                    {
                        term: 'blank',
                        svg: <SvgMultipleBlankPage />,
                    },
                    {
                        term: 'cash',
                        svg: (
                            <SvgIcon onMouseEnter={(e) => setIsChoosePricePopperOpen(() => true)}>
                                <SvgCashBag />
                            </SvgIcon>
                        ),
                    },
                    {
                        term: 'digger',
                        svg: <SvgWorker fill={chosenView !== cpgViewEnum.digger ? '#0044B4' : ''} />,
                    },
                ].map((v) => (
                    <Fragment>
                        <button
                            key={v.term}
                            onClick={() => onViewChange(v)}
                            className={
                                chosenView === cpgViewEnum[v.term as TChangeViewType]
                                    ? s.buttonsbar_active
                                    : s.buttonsbar_not_active
                            }
                        >
                            {v.svg}
                        </button>
                    </Fragment>
                ))}
                {chosenView === 'cash' && (
                    <Popper
                        open={isChoosePricePopperOpen}
                        anchorEl={ref.current}
                        sx={{ top: 28, right: 52 }}
                        placement="bottom-start"
                        onMouseLeave={(e) => setIsChoosePricePopperOpen(() => false)}
                    >
                        <Paper elevation={8}>
                        <FlexColumnWrapper p={2.5} bgcolor={'white'} borderRadius={1} mt={1.6} width={384} gap={1.5}>
                            <StyledArrow />
                            <FlexRowWrapper justifyContent={'center'} color={theme.palette.primary.main} gap={1}>
                                <Settings color={'primary'} />{' '}
                                <Typography color={'primary'}>Выражение стоимости СМР</Typography>
                            </FlexRowWrapper>
                            <FlexColumnWrapper gap={0}>
                                {VOLUME_TYPES[tableType].map((volumeType) => {
                                    return (
                                        <StyledPriceSettingsRowWrapper key={volumeType}>
                                            <Typography fontSize={14}>{t('volume_type.' + volumeType)}</Typography>
                                            <StyledTabs
                                                value={priceSettings[tableType][volumeType]}
                                                onChange={
                                                    (e, newValue) => {
                                                        savePriceSettingsReq({
                                                            projectId: projectId || '',
                                                            tableType: tableType,
                                                            priceSettings: {
                                                                [tableType]: {
                                                                    [volumeType]: newValue,
                                                                },
                                                            },
                                                        })
                                                    }
                                                }
                                            >
                                                {PRICE_TYPES.map((priceType) => {
                                                    return (
                                                        <StyledTab
                                                            key={priceType}
                                                            label={t('price_type.' + priceType)}
                                                            value={priceType}
                                                        />
                                                    )
                                                })}
                                            </StyledTabs>
                                        </StyledPriceSettingsRowWrapper>
                                    )
                                })}
                            </FlexColumnWrapper>
                        </FlexColumnWrapper>
                        </Paper>
                    </Popper>
                )}
            </div>
        </>
    )
}
