import { ArrowBack } from '@mui/icons-material'
import DownloadingSharpIcon from '@mui/icons-material/DownloadingSharp'
import { Box, Dialog, Modal, Typography } from '@mui/material'
import { GetRowIdParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useGetExecutorsQuery } from '@/api/company/company.api'
import { useGetHeadersCPGQuery } from '@/api/ksg/ksg.api'
import { useGetMsgEquipQuery } from '@/api/msgEquip/msgEquip.api'

import CheckboxPopoverWorkersMimKsg from '@/components/CheckboxPopoverWorkersMim/CheckboxPopoverWorkersMimKsg'
import ShowMsgDialog from '@/components/ShowMsgDialog/ShowMsgDialog'

import EmptyPlaceholder from '@/shared/components/EmptyPlaceholder/EmptyPlaceholder'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions'
import { getWorkManagmentResourceRowSlyles, modalStyles } from '@/shared/utils'

import { setAgGrid, toggleUploadKsgEquip } from '@/store/slices/drawersSlice'
import { filtersWorkersMimSelector } from '@/store/slices/filtersWorkersMimSlice'
import { monthMsgSelector } from '@/store/slices/monthMsgSlice'
import { paginationSelector } from '@/store/slices/paginationSlice'
import { profileSelector } from '@/store/slices/profileSlice'
import { projectsSelector } from '@/store/slices/projectsSlice'
import { getSettings } from '@/store/slices/settings/settingsViewAgGrid'
import { useAppDispatch, useTypedSelector } from '@/store/store'

import { AG_GRID_DEFAULT_PARAMS_KSG } from '../WorkManagment/components/AgGridService/AgGridColumnDef.service'
import { getMSGEquipColDefs } from './AgGridMSGEquip.colDef'

export default function AgGridMsgEquip() {
    const [openModal, setOpenModal] = useState(false)

    const { titlesArr } = useTypedSelector(filtersWorkersMimSelector)
    const { month, year } = useTypedSelector(monthMsgSelector)
    const { page, rowsPerPage } = useTypedSelector(paginationSelector)
    const { headerSearch } = useTypedSelector(projectsSelector)
    const { profile } = useTypedSelector(profileSelector)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const gridRef = useRef<AgGridReact>(null)
    const location = useLocation()

    const { msgForecast } = useTypedSelector(getSettings)

    const { projectId } = useParams()

    const { t } = useTranslation('chartKeys')
    const { enqueueSnackbar } = useSnackbar()

    const headers = useGetHeadersCPGQuery({
        id: Number(projectId),
        isTech: true
    })

    const getExecutors = useGetExecutorsQuery(
        {
            companyID: profile?.company?.companyID
        },
        {
            skip: !profile?.company?.companyID
        }
    )

    useEffect(() => {
        gridRef?.current && dispatch(setAgGrid(gridRef?.current))
        // gridRef.current && preserveScroll(gridRef.current, location)
        return () => {
            dispatch(setAgGrid(null))
        }
    }, [gridRef?.current])

    const msgEquip = useGetMsgEquipQuery({
        id: Number(projectId),
        year: year,
        month: month + 1,
        filter: headerSearch,
        limit: 9999,
        offset: 0
    })

    useEffect(() => {
        msgEquip?.refetch()
    }, [titlesArr.length])

    const isCreatedMsg = useMemo(() => {
        return headers.data?.allHeaders.filter(header => {
            if (header.isCreatedMSG) {
                return header.month === month + 1 && header.year === year
            }
        })
    }, [headers.data?.allHeaders, month])

    const getRowId = useCallback((params: GetRowIdParams) => {
        return params.data.id
    }, [])

    if ((headers.data?.allHeaders || []).length === 0) {
        if (!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_KSG_MIM')) {
            return (
                <EmptyPlaceholder
                    text={
                        <Typography>
                            Здравствуйте, {profile?.firstName}, КСГ еще не был создан. <br />
                            Создание КСГ не доступно для пользователей с Вашими правами. <br /> Обратитесь к
                            администратору
                        </Typography>
                    }
                    onClick={() => navigate(-1)}
                    icon={<ArrowBack />}
                    buttonText='Назад'
                />
            )
        }
        return (
            <EmptyPlaceholder
                text={`Здравствуйте, ${profile?.firstName}! Данные для отображения отсутствуют.`}
                onClick={() => dispatch(toggleUploadKsgEquip())}
                icon={<DownloadingSharpIcon />}
                buttonText='загрузить'
            />
        )
    }

    if (isCreatedMsg?.length === 0 && DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_MSG_MIM')) {
        return (
            <Dialog
                open={true}
                onClose={() => {}}
            >
                <ShowMsgDialog equip />
            </Dialog>
        )
    }

    return (
        <>
            {' '}
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                <Box sx={modalStyles}>
                    <CheckboxPopoverWorkersMimKsg />
                </Box>
            </Modal>
            <div
                className='ag-theme-alpine'
                style={{ height: '100%', width: '100%', paddingRight: '0.5rem' }}
            >
                {msgEquip.data?.data && (
                    <AgGridReact
                        ref={gridRef}
                        getRowId={getRowId}
                        rowData={msgEquip.data?.data}
                        columnDefs={getMSGEquipColDefs({
                            projectId: projectId as string,
                            setOpenModal: setOpenModal,
                            getExecutors: getExecutors,
                            enqueueSnackbar: enqueueSnackbar,
                            msgEquip: msgEquip,
                            msgForecast: msgForecast
                        })}
                        getRowClass={getWorkManagmentResourceRowSlyles}
                        {...AG_GRID_DEFAULT_PARAMS_KSG(location)}
                        animateRows={false}
                        rowHeight={80}
                    ></AgGridReact>
                )}
            </div>
        </>
    )
}
