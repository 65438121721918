import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'
import { theme } from '../../styles/theme'

const PathSVG: React.FC<SvgIconProps & { isActive?: boolean }> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17 18C16.35 18 15.7667 17.8125 15.25 17.4375C14.7333 17.0625 14.375 16.5833 14.175 16H9C7.9 16 6.95833 15.6083 6.175 14.825C5.39167 14.0417 5 13.1 5 12C5 10.9 5.39167 9.95833 6.175 9.175C6.95833 8.39167 7.9 8 9 8H11C11.55 8 12.0208 7.80417 12.4125 7.4125C12.8042 7.02083 13 6.55 13 6C13 5.45 12.8042 4.97917 12.4125 4.5875C12.0208 4.19583 11.55 4 11 4H5.825C5.60833 4.58333 5.24583 5.0625 4.7375 5.4375C4.22917 5.8125 3.65 6 3 6C2.16667 6 1.45833 5.70833 0.875 5.125C0.291667 4.54167 0 3.83333 0 3C0 2.16667 0.291667 1.45833 0.875 0.875C1.45833 0.291667 2.16667 0 3 0C3.65 0 4.22917 0.1875 4.7375 0.5625C5.24583 0.9375 5.60833 1.41667 5.825 2H11C12.1 2 13.0417 2.39167 13.825 3.175C14.6083 3.95833 15 4.9 15 6C15 7.1 14.6083 8.04167 13.825 8.825C13.0417 9.60833 12.1 10 11 10H9C8.45 10 7.97917 10.1958 7.5875 10.5875C7.19583 10.9792 7 11.45 7 12C7 12.55 7.19583 13.0208 7.5875 13.4125C7.97917 13.8042 8.45 14 9 14H14.175C14.3917 13.4167 14.7542 12.9375 15.2625 12.5625C15.7708 12.1875 16.35 12 17 12C17.8333 12 18.5417 12.2917 19.125 12.875C19.7083 13.4583 20 14.1667 20 15C20 15.8333 19.7083 16.5417 19.125 17.125C18.5417 17.7083 17.8333 18 17 18ZM3 4C3.28333 4 3.52083 3.90417 3.7125 3.7125C3.90417 3.52083 4 3.28333 4 3C4 2.71667 3.90417 2.47917 3.7125 2.2875C3.52083 2.09583 3.28333 2 3 2C2.71667 2 2.47917 2.09583 2.2875 2.2875C2.09583 2.47917 2 2.71667 2 3C2 3.28333 2.09583 3.52083 2.2875 3.7125C2.47917 3.90417 2.71667 4 3 4Z"
                    fill={props.isActive ? '#0044B4' : theme.palette.disabled}
                />
            </svg>
        </SvgIcon>
    )
}

export default PathSVG
