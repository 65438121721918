import { req } from "../../WorkManagment/api/api"
import { getHeaders } from "./api.service"
import { IMSGResourceItem, IMSGResourcesServerResponse, IMSGUpdateResource, ISNZRequestParams } from "./api.types"

export class MSGResourceAPI {
    static baseURL = (projectID: ISNZRequestParams['projectID'], resourceType: ISNZRequestParams['resourceType']) => `/projects/${projectID}/${resourceType}/msg/resources/executor`

    static async getSNZ({projectID, resourceType}: ISNZRequestParams):Promise<IMSGResourcesServerResponse> {
        return await req.get(this.baseURL(projectID, resourceType), undefined, getHeaders())
            .then(({ data }) => {
                return data
            })
            .catch(e => console.log(e))
    }

    static async updateItemFact({projectID, resourceType, body}: IMSGUpdateResource):Promise<IMSGResourceItem> {
        return await req.post(`/projects/${projectID}/${resourceType}/msg/executor/update-fact`, body, undefined, undefined, getHeaders())
            .then(({ data }) => {
                return data
            })
            // .catch(e => console.log(e))
    }

}