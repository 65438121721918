import { Typography, TypographyProps } from '@mui/material'
import { ICellRendererParams } from 'ag-grid-community'
import { NumericFormatProps } from 'react-number-format'
import { OverflowableTypographyWithTooltip } from '../../../../../components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip'
import { StyledNumberFormat } from '../../components.styles'

interface INumberCellRendererProps extends Partial<ICellRendererParams> {
    suppressTooltip?: boolean
    NumericFormatProps?: NumericFormatProps
    TypographyProps?: TypographyProps
    noValuePlug?: string | number
}

function getDecimalNumbers(number: number) {
    return number.toString().split('.')[1]
}

function getDecimalQty(number: number) {
    if (!number) {
        return 0
    }
    if (Math.floor(number) === number) return 0
    return getDecimalNumbers(number)?.length || 0
}

export function showTooltipTitle(NumericFormatProps: NumericFormatProps | undefined, value: any) {
    const suffix = NumericFormatProps?.suffix ? NumericFormatProps?.suffix + '.' : ''
    if (getDecimalQty(value) > 2) {
        return Number(getDecimalNumbers(value)) * 1 !== 0 && value.toFixed(6).replaceAll('.', ',') + suffix
    }
    return ''
}

export function NumberCellRenderer(props: INumberCellRendererProps) {
    if (props.value === null || (props.getValue && props.getValue() == null)) return <></>

    const value =
        Number(props.valueFormatted) || (props.getValue && props.getValue()) || props.value || props.noValuePlug || 0

    return (
        //@ts-ignore
        <StyledNumberFormat
            value={value}
            displayType="text"
            allowedDecimalSeparators={[',', '.']}
            thousandSeparator={' '}
            decimalSeparator=","
            decimalScale={2}
            // fixedDecimalScale
            renderText={(_value: string) => {
                if (props.suppressTooltip) {
                    return <Typography {...props.TypographyProps}>{_value}</Typography>
                }
                return (
                    <OverflowableTypographyWithTooltip
                        maxRows={1}
                        TooltipProps={{ title: showTooltipTitle(props.NumericFormatProps, value) }}
                        TypographyProps={{ ...props.TypographyProps }}
                    >
                        {_value}
                    </OverflowableTypographyWithTooltip>
                )
            }}
            {...props.NumericFormatProps}
        />
    )
}
