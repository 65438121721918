import * as Yup from 'yup'

export const initialFormValuesGanttRelations = {
    dateStart: null,
    dateEnd: null,
}

export const validationSchemaGanttRelations = Yup.object({
    dateStart: Yup.date()
        .nullable()
        .required('Выберите дату начала')
        .typeError('Неверный формат, введите дату')
        .min(new Date(2000, 0, 1), 'Не раньше 2000 года'),
    dateEnd: Yup.date()
        .nullable()
        .required('Выберите дату окончания')
        .typeError('Неверный формат, введите дату')
        .min(Yup.ref('dateStart'), 'Дата окончания не может быть раньше даты начала')
        .max(new Date(2050, 0, 1), 'Не позднее 2050 года'),
})

export const calendarStylesGanttRelations = {
    borderRadius: '8px',
    '.MuiInputBase-input': {
        padding: '0.6rem',
        maxWidth: '8rem',
        fontSize: 14,
    },
    '.MuiInputLabel-root': {
        transform: 'translate(14px, 9px) scale(1)',
        fontSize: 14,
    },
    '.MuiInputLabel-shrink': {
        transform: 'translate(14px, -9px) scale(0.75)',
    },
}
