import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Button } from '@mui/material';
import cn from 'classnames';
import React, { ReactNode } from 'react';

import s from './EmptyPlaceholder.module.scss';
import pic from './backgroundStart.png';

interface IProps {
    text: string | ReactNode;
    onClick?: () => void;
    icon?: ReactJSXElement;
    buttonText?: string;
    mt?: boolean;
    buttons?: React.ReactNode;
    contentStyle?: React.CSSProperties;
}

export default function EmptyPlaceholder({ onClick, text, icon, buttonText, mt, buttons, contentStyle }: IProps) {
    const renderButtons = () => {
        if (buttons) {
            return buttons;
        }

        if (buttonText) {
            return (
                <Button
                    sx={{
                        marginTop: '1.5rem',
                    }}
                    onClick={onClick}
                    variant='contained'
                    startIcon={icon}
                >
                    {buttonText}
                </Button>
            );
        }

        return null;
    };

    return (
        <section
            className={s.container}
            style={{
                marginTop: mt ? '5rem' : '',
            }}
        >
            <div
                className={cn(s.content)}
                style={contentStyle}
            >
                <p>{text}</p>
                {renderButtons()}
            </div>
            <img
                src={pic}
                alt='Background'
            />
        </section>
    );
}
