import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface initialStateI {
    addFourthRowIds: number[]
    addThirdRowIds: number[]
    autoCompleteArr: (string | null)[]
}

const initialState: initialStateI = {
    addFourthRowIds: [],
    addThirdRowIds: [],
    autoCompleteArr: [],
}

const cpgSlice = createSlice({
    name: 'cpg',
    initialState,
    reducers: {
        setThirdRowIds(state, action: PayloadAction<number>) {
            if (!state.addFourthRowIds.includes(action.payload)) {
                state.addFourthRowIds.push(action.payload)
            } else {
                state.addFourthRowIds = state.addFourthRowIds.filter((e) => e !== action.payload)
            }
        },

        emptyThirdRowIds(state) {
            state.addFourthRowIds = []
        },

        setSecondRowIds(state, action: PayloadAction<number>) {
            if (!state.addThirdRowIds.includes(action.payload)) {
                state.addThirdRowIds.push(action.payload)
            } else {
                state.addThirdRowIds = state.addThirdRowIds.filter((e) => e !== action.payload)
            }
        },

        emptySecondRowIds(state) {
            state.addThirdRowIds = []
        },

        setAutoCompleteArr(state, action: PayloadAction<(string | null)[]>) {
            state.autoCompleteArr = action.payload
        },
    },
})

export const { setThirdRowIds, emptyThirdRowIds, setSecondRowIds, emptySecondRowIds, setAutoCompleteArr } =
    cpgSlice.actions
export default cpgSlice.reducer

export const cpgSelector = (state: RootState) => state.cpg
