import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Updater, useImmer } from 'use-immer';

import { useUpdateChartsKs3Mutation } from '@/api/ksg/ksg.api';
import { Work, monthlyChart } from '@/api/ksg/ksg.def';

import { HtmlTooltip } from '@/shared/components/HtmlTooltip';
import g from '@/shared/stylesheets/TableGrid.module.scss';

import { updateKs3KsgAg } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { useAppDispatch } from '@/store/store';

export function Ks3Input({
    charts,
    setMutationsLoading,
    cell,
    setIsInputMode,
    setWorksList,
}: {
    charts: monthlyChart[];
    setMutationsLoading: Dispatch<SetStateAction<boolean>>;
    setIsInputMode: Dispatch<SetStateAction<string>>;
    setWorksList?: Updater<{ data: Work[]; total: number } | null>;
    cell: {
        month: number;
        year: number;
    };
}) {
    const [values, setValues] = useImmer(charts);

    const [updateChartReq, updateChartRes] = useUpdateChartsKs3Mutation();

    const { projectId } = useParams();

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');

    const onFormMonthsSubmit = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault();

            setMutationsLoading(true);

            updateChartReq({
                id: Number(projectId),
                body: {
                    data: values.map((v) => {
                        return {
                            kc3: v.kc3,
                            month: v.month,
                            year: v.year,
                        };
                    }),
                },
            })
                .unwrap()
                .then(() => {
                    setMutationsLoading(false);
                    setIsInputMode('');
                    setWorksList &&
                        setWorksList((d) => {
                            if (!d) return;
                            d.data[0].monthlyCharts.forEach((f, index) => {
                                if (f.year === cell.year && f.month === cell.month) {
                                    f.kc3 = values[index].kc3;
                                }
                            });
                        });
                    dispatch(updateKs3KsgAg(values));

                    enqueueSnackbar(t('saved'), {
                        variant: 'success',
                    });
                })
                .catch((e) => {
                    setMutationsLoading(false);
                    enqueueSnackbar('Ошибка', {
                        variant: 'error',
                    });
                    console.error(e);
                    setIsInputMode('');
                });
        },
        [
            cell.month,
            cell.year,
            dispatch,
            enqueueSnackbar,
            projectId,
            setIsInputMode,
            setMutationsLoading,
            setWorksList,
            t,
            updateChartReq,
            values,
        ]
    );

    const getValue = () => {
        const val = values.find((f) => f.month === cell.month && f.year === cell.year);
        if (!val) return '';
        return val.kc3 === null ? '' : val.kc3;
    };

    return (
        <HtmlTooltip
            title='Нажмите enter чтобы сохранить!'
            placement='top-end'
        >
            <form onSubmit={onFormMonthsSubmit}>
                <input
                    step='0.000001'
                    value={getValue()}
                    onChange={(e) => {
                        setValues((d) => {
                            const value =
                                e.target.value === '0' ? 0 : e.target.value === '' ? null : Number(e.target.value);
                            const fItem = d.find((v) => v.month === cell.month && v.year === cell.year);
                            if (!fItem) return;
                            fItem.kc3 = value;
                        });
                    }}
                    className={g.month_input}
                    type='number'
                    max='99999999999'
                    min='0'
                    autoFocus
                    disabled={updateChartRes.isLoading}
                />
                <button
                    className={g.d_none}
                    type='submit'
                ></button>
            </form>
        </HtmlTooltip>
    );
}
