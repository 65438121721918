import { parse } from 'date-fns';

import { CPGHeader } from '@/api/ksg/ksg.def';

export const parseCurrentDate = (month: number, year: number) => parse(`${month + 1}:${year}`, 'MM:yyyy', new Date());

export const isPrevMonthExistInHeaders = (headers: CPGHeader[] | undefined, prevMonth: Date) => {
    if (!headers) return false;

    const isCreatedMSG = true;

    const key = `${prevMonth.getMonth() + 1}_${prevMonth.getFullYear()}_${isCreatedMSG}`;
    const set = new Set(headers.map((header) => `${header.month}_${header.year}_${header.isCreatedMSG}`));
    return set.has(key);
};

export const isNextMonthExistInHeaders = (
    headers: CPGHeader[] | undefined,
    nextMonth: Date,
    hasCreateMSGAccess: boolean
): boolean => {
    if (!headers) return false;

    const key = `${nextMonth.getMonth() + 1}_${nextMonth.getFullYear()}`;
    const map = new Map(headers.map((header) => [`${header.month}_${header.year}`, header.isCreatedMSG]));

    if (hasCreateMSGAccess) {
        return map.has(key);
    }
    return map.has(key) && map.get(key)!;
};
