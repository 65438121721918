import { addMonths, isAfter, isBefore, subMonths } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useGetHeadersCPGQuery } from '@/api/ksg/ksg.api';

import { usePermissionsByRole } from '@/hooks/usePermissionsByRole';

import { CANVAS_ID } from '@/pages/AgGridMsg/AgGridMsg.service';

import { monthsList } from '@/shared/constants/date';

import { monthDownMsg, monthMsgSelector, monthUpMsg } from '@/store/slices/monthMsgSlice';
import { setTotal } from '@/store/slices/paginationSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { ARROW_ICONS, BOUNDARY_MONTH_STEP, MONTH_STEP } from './MonthSwitcher.config';
import { ArrowContainer, EmptyArrowContainer } from './MonthSwitcher.styles';
import { TRenderArrow } from './MonthSwitcher.types';
import { isNextMonthExistInHeaders, isPrevMonthExistInHeaders, parseCurrentDate } from './MonthSwitcher.utils';

const useGetHeaders = () => {
    const { projectId } = useParams();
    const location = useLocation();

    const headersCPG = useGetHeadersCPGQuery({
        id: Number(projectId),
        isStaff: location.pathname.includes('workers'),
        isTech: location.pathname.includes('mim'),
    });
    const headersData = headersCPG.data;
    const headers = headersData?.allHeaders;
    const hasHeaders = Boolean(headersData && (headers ?? []).length > 0);

    return { headers, hasHeaders };
};

const useWithinBoundariesOfCurrentDate = (date: Date) => {
    const startBoundary = useMemo(() => subMonths(date, BOUNDARY_MONTH_STEP), []);
    const endBoundary = useMemo(() => addMonths(date, BOUNDARY_MONTH_STEP), []);

    return { isStartAfter: isAfter(date, startBoundary), isEndBefore: isBefore(date, endBoundary) };
};

const useDate = () => {
    const { month, year } = useTypedSelector(monthMsgSelector);

    const currentDate = parseCurrentDate(month, year);
    const withinBoundaries = useWithinBoundariesOfCurrentDate(currentDate);

    const prevMonth = subMonths(currentDate, MONTH_STEP);
    const nextMonth = addMonths(currentDate, MONTH_STEP);

    return {
        monthName: monthsList[month],
        withinBoundaries,
        prevMonth,
        nextMonth,
    };
};

const useArrow = (canGoPrev: boolean, canGoNext: boolean) => {
    const dispatch = useAppDispatch();

    const renderArrow: TRenderArrow = ({ isVisible, onClick, icon }) => {
        if (!isVisible) {
            return <EmptyArrowContainer>&nbsp;</EmptyArrowContainer>;
        }
        return <ArrowContainer onClick={onClick}>{icon}</ArrowContainer>;
    };

    const prevArrow = renderArrow({
        isVisible: canGoPrev,
        onClick: () => {
            dispatch(setTotal(1));
            dispatch(monthDownMsg());
        },
        icon: ARROW_ICONS.PREV,
    });

    const nextArrow = renderArrow({
        isVisible: canGoNext,
        onClick: () => {
            dispatch(setTotal(1));
            dispatch(monthUpMsg());
        },
        icon: ARROW_ICONS.NEXT,
    });

    return {
        prev: prevArrow,
        next: nextArrow,
    };
};

const useRemoveCanvasIfMonthChanged = () => {
    const { month, year } = useTypedSelector(monthMsgSelector);

    useEffect(() => {
        return () => document.getElementById(CANVAS_ID + 'wrapper')?.remove();
    }, [month, year]);
};

export const useMonthSwitcher = () => {
    const { headers, hasHeaders } = useGetHeaders();
    const { hasFeatureAccess } = usePermissionsByRole();
    const hasCreateMSGAccess = hasFeatureAccess('CREATE_MSG_SMR');

    const { monthName, withinBoundaries, prevMonth, nextMonth } = useDate();

    const canGoPrev = withinBoundaries.isStartAfter && isPrevMonthExistInHeaders(headers, prevMonth);
    const canGoNext = withinBoundaries.isEndBefore && isNextMonthExistInHeaders(headers, nextMonth, hasCreateMSGAccess);

    const arrow = useArrow(canGoPrev, canGoNext);

    useRemoveCanvasIfMonthChanged();

    return {
        hasHeaders,
        arrow,
        monthName,
    };
};
