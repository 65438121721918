import { TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru } from 'date-fns/locale';

import { PROJECT_END_DATE_MAX, PROJECT_START_DATE_MIN } from '@/shared/constants/project';

import { FormErrorAg, calendarStylesAddWorkAg } from '../AddLevel.service';
import s from '../AddLevelKsg.module.scss';

export default function DateLine({
    errorTitle,
    touched,
    title,
    onChange,
    onBlur,
    value,
    required,
}: {
    errorTitle?: string;
    touched?: boolean;
    title: string;
    onChange: (date: Date | null) => void;
    onBlur: () => void;
    value: Date | null;
    required?: boolean;
}) {
    return (
        <div className={s.input_line}>
            <p>
                {title}
                {required && (
                    <>
                        <span>*</span>
                    </>
                )}
                :
            </p>
            <div className='relative'>
                <LocalizationProvider
                    adapterLocale={ru}
                    dateAdapter={AdapterDateFns}
                >
                    <DesktopDatePicker
                        value={value}
                        onChange={onChange}
                        inputFormat='dd.MM.yyyy'
                        minDate={PROJECT_START_DATE_MIN}
                        maxDate={PROJECT_END_DATE_MAX}
                        components={{
                            OpenPickerIcon: () => (
                                <svg
                                    width='16'
                                    height='18'
                                    viewBox='0 0 16 18'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        d='M12.1667 9.99999H8V14.1667H12.1667V9.99999ZM11.3333 0.833328V2.49999H4.66667V0.833328H3V2.49999H2.16667C1.24167 2.49999 0.508333 3.24999 0.508333 4.16666L0.5 15.8333C0.5 16.75 1.24167 17.5 2.16667 17.5H13.8333C14.75 17.5 15.5 16.75 15.5 15.8333V4.16666C15.5 3.24999 14.75 2.49999 13.8333 2.49999H13V0.833328H11.3333ZM13.8333 15.8333H2.16667V6.66666H13.8333V15.8333Z'
                                        fill='#668FD2'
                                    />
                                </svg>
                            ),
                        }}
                        renderInput={(params) => {
                            if (params.inputProps) params.inputProps.placeholder = 'дд.мм.гггг';

                            return (
                                <TextField
                                    data-testid={'dateStart'}
                                    {...params}
                                    onBlur={onBlur}
                                    error={Boolean(touched) && Boolean(errorTitle?.length)}
                                    sx={calendarStylesAddWorkAg}
                                />
                            );
                        }}
                    />
                </LocalizationProvider>
                <FormErrorAg
                    isTouched={touched}
                    error={errorTitle}
                />
            </div>
        </div>
    );
}
