import s from '../EisReportsDrawer.module.scss'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

export default function SelectEl({ status, onClick }: { status: 'active' | 'not_active'; onClick: () => void }) {
    return (
        <div onClick={onClick} className={`${s.select} ${status === 'active' ? s.actual : s.not_actual}`}>
            <ArrowDropDownIcon
                sx={{
                    color: status === 'active' ? '#19681D' : '#F46B6B',
                }}
            />{' '}
            <p>{status === 'active' ? 'Актуальный' : 'Не актуальный'}</p>
        </div>
    )
}
