import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import { IStatItemProps, IStatItemTitleProps } from "./StatItem.types";
import { STAT_TITLE_SIZES } from "./StatItem.service";
import { OverflowableTypography } from "../components.styles";

export const StatItemWrapper = styled(Box)<Partial<IStatItemProps>>`
    display: flex;
    ${({variant, theme}) => {
        if (variant == 'row') {
            return {
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                lineHeight: '143%',
                letterSpacing: '0.17px',
                gap: theme.spacing(2)
            }
        }
        if (variant == 'column') {
            return {
                flexDirection: 'column',
                justifyContent: 'flex-start'
            }
        }
    }}
    &.MuiBox-root:nth-child(2) p {
        text-align: right !important;
    }
`

export const StatItemTitle = styled(Typography)<IStatItemTitleProps>`
    color: ${props => props.theme.palette.secondary.gray};
    font-size: ${props => STAT_TITLE_SIZES[props.size]};
`

export const StatItemValue = styled(OverflowableTypography)`
    font-size: 14px;
`

export const WorkStatus = styled(Typography)`
    font-size: 14px;
    color: ${props => props.theme.palette.primary.main};
    line-height: 150%;
    letter-spacing: 0.25px;
`