import { IconButton, IconButtonProps } from '@mui/material'
import styled from 'styled-components'
import { GanttChartsMode } from './GanttLinks'

type GanttChartsModeIconButtonProps = {
    mode: GanttChartsMode
}

export const GanttChartsModeIconButton = styled(IconButton)<IconButtonProps & GanttChartsModeIconButtonProps>`
    background-color: ${({ mode }) => (mode === 'on' ? '#0044b4' : '#f6f7fb')};
    color: ${({ mode }) => (mode === 'on' ? '#f6f7fb' : '#0044b4')};
    border-radius: 6px;
    &:hover {
        background-color: ${({ mode }) => (mode === 'on' ? '#1d4ed8' : null)};
    }
`

export const StyledIconBtn = styled(IconButton)<IconButtonProps & { isActive?: boolean }>`
    background-color: #f6f7fb;
    border-radius: 6px;
    ${({ isActive, theme }) =>
        isActive && {
            ':hover': {
                backgroundColor: theme.palette.primary.main,
            },
            backgroundColor: theme.palette.primary.main,
        }};
    > svg {
        cursor: pointer;
    }
`

StyledIconBtn.defaultProps = {
    color: 'primary',
}
