import { Tooltip, TooltipProps } from '@mui/material';

export function StyledTooltip({ children, title, ...rest }: TooltipProps) {
    return (
        <Tooltip
            title={title}
            {...rest}
            componentsProps={{
                tooltip: {
                    sx: {
                        display: 'flex',
                        width: 'fit-content',
                        maxWidth: 300,
                        backgroundColor: 'rgb(151, 162, 181,0.85)',
                        color: '#fff',
                        fontSize: '14px',
                        border: '1px solid #dadde9',
                        ...rest.componentsProps?.tooltip?.sx,
                    },
                },
                arrow: {
                    sx: {
                        ...rest.componentsProps?.arrow?.sx,
                    },
                },
            }}
        >
            {children}
        </Tooltip>
    );
}
