import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';

import { OverflowableTypographyWithTooltip } from '@/components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';

import { theme } from '@/styles/theme';

import { IFilterDialogTableOption } from './FilterDialog.def';

export function FilterOptionCellRenderer(props: ICellRendererParams<IFilterDialogTableOption>) {
    return (
        <FormGroup>
            <FormControlLabel
                sx={{
                    '.MuiTypography-root': {
                        textAlign: 'left',
                    },
                    '.MuiCheckbox-root': {
                        pr: 1.5,
                    },
                }}
                control={
                    <Checkbox
                        disableRipple
                        color={'primary'}
                        checked={props.data?.checked}
                        onChange={(e, checked) => {
                            props.api.applyTransaction({
                                update: [
                                    {
                                        ...(props?.data as IFilterDialogTableOption),
                                        checked: checked,
                                    },
                                ],
                            });
                            props.api.redrawRows({
                                rowNodes: [props.node],
                            });
                        }}
                        sx={{
                            svg: {
                                path: {
                                    fill: theme.palette.primary.main,
                                },
                            },
                            padding: 0,
                        }}
                    />
                }
                label={
                    <OverflowableTypographyWithTooltip maxRows={1}>
                        {props.data?.label}
                    </OverflowableTypographyWithTooltip>
                }
            />
        </FormGroup>
    );
}
