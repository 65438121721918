import { Typography } from "@mui/material";
import styled from "styled-components";

export const DrawerTitle = styled(Typography)`
    padding: ${props => props.theme.spacing(1.75, 0)};
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #D1D8FA;
    ${props => {
        return {
            ...props.theme.typography.h2 as any
        }
    }}
`