import { Draft } from 'immer';
import { WritableDraft } from 'immer/dist/internal';

type TItem = {
    id: number | string;
};

export const updateItemById = <T extends TItem>(draft: WritableDraft<T[]>, updatedItem: T) => {
    const index = draft.findIndex(({ id }) => id === updatedItem.id);

    if (index === -1) return;
    draft[index] = updatedItem as Draft<T>;
};

export const hasParam = (valueToCheck: 'g' | 'b' | 'r' | 'gf' | 'gant', searchString?: string) => {
    const valuesObj = {
        g: 'g=on',
        b: 'b=on',
        r: 'r=on',
        gf: 'gf=on',
        gant: 'true',
    };
    return searchString?.includes(valuesObj[valueToCheck]);
};
