import { NumericFormatProps } from 'react-number-format'
import { Controller, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form'
import { COLORS } from '../../../WorkManagment/components/PercentStatusValue/PercentStatusValue.style'
import { StyledTooltip, StyledNumberFormat } from '../../../WorkManagment/components/components.styles'
import { StyledTextField } from './inputs.styles'
import { Box } from '@mui/material'
import { useState } from 'react'
import { getDefaultValidationRules } from '../../../WorkManagment/components/CellEditors/CellEditor/CellEditor.service'

interface IContrallableInput {
    fieldName: string
    onSave?: (value: number | undefined | null) => void
    validation?: RegisterOptions<FieldValues, string>
    defaultValue?: number | null
    NumericFormatProps?: NumericFormatProps
}

export function ControllableNumberInput(props: IContrallableInput) {
    const { fieldName, validation, onSave, defaultValue, NumericFormatProps } = props
    const [value, setValue] = useState<number | undefined>(undefined)
    const [isFocused, setIsFocused] = useState<boolean>(false)

    const methods = useFormContext()

    return (
        <Controller
            name={fieldName}
            control={methods.control}
            defaultValue={defaultValue}
            rules={{ ...getDefaultValidationRules('number'), ...validation }}
            render={({ field, formState }) => {
                const isInvalid = !!formState.errors[fieldName]
                const errorMessage = formState.errors[fieldName]?.message?.toString() || false

                return (
                    <StyledTooltip
                        open={isInvalid || isFocused}
                        title={errorMessage || 'Нажмите на Enter чтобы сохранить'}
                        color={isInvalid ? 'error' : 'primary'}
                    >
                        <Box>
                            <StyledNumberFormat
                                {...field}
                                onFocus={(e) => {
                                    e.target.select()
                                    methods.trigger(fieldName)
                                    setIsFocused((prev) => true)
                                }}
                                onBlur={(e) => {
                                    !isInvalid && onSave && onSave(value)
                                    setIsFocused((prev) => false)
                                }}
                                onKeyDown={(e) =>
                                    e.key === 'Enter' && !isInvalid && (e.target as HTMLInputElement).blur()
                                }
                                //@ts-ignore
                                customInput={StyledTextField}
                                defaultValue={formState.defaultValues && formState.defaultValues[fieldName]}
                                allowedDecimalSeparators={['.', ',']}
                                decimalSeparator=','
                                {...(isInvalid && {
                                    sx: {
                                        input: {
                                            color: COLORS.error.color,
                                            backgroundColor: COLORS.error.backgroundColor + '!important',
                                        },
                                    },
                                })}
                                color={isInvalid ? 'error' : 'primary'}
                                onValueChange={(values: { floatValue: number | undefined }) =>
                                    setValue((prevValue) => values.floatValue)
                                }
                                {...NumericFormatProps}
                            />
                        </Box>
                    </StyledTooltip>
                )
            }}
        />
    )
}
