import * as Yup from 'yup'

export const validationSchemaAgPeq = Yup.object({
    name: Yup.string().required('Введите титул').max(150, 'Не больше 150 символов'),
})

export const initialFormValuesAgPeq = {
    name: '',
}
export interface initialFormValuesAgPeqI {
    name: string
}
