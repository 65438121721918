import { TShiftErrorsData } from '@/types/error.types';

export const isShiftErrorsData = (data: any): data is TShiftErrorsData => {
    return (
        typeof data === 'object' &&
        data !== null &&
        typeof data.emptyHeaders === 'boolean' &&
        typeof data.invalidMainIdsOrBondDate === 'boolean' &&
        typeof data.recursiveDateShiftError === 'boolean' &&
        Array.isArray(data.factBlockers) &&
        Array.isArray(data.zeroFactBlockers) &&
        data.zeroFactBlockers.every(
            (blocker: any) =>
                typeof blocker.workId === 'number' &&
                Array.isArray(blocker.headers) &&
                blocker.headers.every(
                    (header: any) => typeof header.year === 'number' && typeof header.month === 'number'
                )
        ) &&
        typeof data.message === 'string'
    );
};
