import DatePicker, { registerLocale } from 'react-datepicker'
import { ru } from 'date-fns/locale'
import 'react-datepicker/dist/react-datepicker.css'
import { useAppDispatch, useTypedSelector } from '../../../store/store'
import { filtersSomSelector, setMaxDateDel, setMinDateDel } from '../../../store/slices/filtersSomSlice'
import '../DatePickerStyles.css'
import s from '../CheckboxPopoverSufficiency.module.scss'

registerLocale('ru', ru)

export default function FilterDatePickerEl() {
    const { dateDel } = useTypedSelector(filtersSomSelector)

    const dispatch = useAppDispatch()

    const onChange = (dates: (Date | null)[]) => {
        const [start, end] = dates
        dispatch(setMinDateDel(start))
        dispatch(setMaxDateDel(end))
    }

    return (
        <div className={s.date_picker_wrapper}>
            <DatePicker
                selected={dateDel.min}
                onChange={onChange}
                startDate={dateDel.min}
                endDate={dateDel.max}
                selectsRange
                inline
                locale="ru"
            />
        </div>
    )
}
