import { ArrowBack } from '@mui/icons-material'
import { Dialog, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FlexColumnWrapper } from '../../pages/NewExecutorView/components/components.styles'
import { StyledBtn } from '../../pages/WorkManagment/components/components.styles'
import { toggleDistributionProgress } from '../../store/slices/drawersSlice'
import { useAppDispatch } from '../../store/store'
import { UIUploadLoader } from '../ui/UIUploadLoader'
import { IDistributionProgressDialogProps } from './DistributionProgressDialog.def'

export const DistributionProgressDialog: React.FC<IDistributionProgressDialogProps> = ({ open, onClose }) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    return (
        <Dialog open={open} onClose={onClose}>
            <FlexColumnWrapper gap={2.5} width={346} p={3.75} alignItems={'center'}>
                <UIUploadLoader />
                <FlexColumnWrapper gap={1.25} textAlign={'center'}>
                    <Typography variant="h6" fontSize={16}>
                        Распределение объемов
                    </Typography>
                    <Typography variant="body2" color={'rgba(92, 110, 140, 1)'}>
                        В зависимости от количество работ процесс обработки может составить <br /> от 1 до 60 минут.
                    </Typography>
                </FlexColumnWrapper>
                <StyledBtn
                    variant="outlined"
                    fullWidth
                    sx={{ bgcolor: 'rgba(25, 118, 210, 0.08)' }}
                    startIcon={<ArrowBack />}
                    onClick={() => {
                        navigate('/')
                        onClose && onClose({}, 'backdropClick')
                        dispatch(
                            toggleDistributionProgress({
                                open: false,
                            })
                        )
                    }}
                >
                    Перейти к списку проектов
                </StyledBtn>
            </FlexColumnWrapper>
        </Dialog>
    )
}
