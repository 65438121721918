import { useCallback, useState } from 'react';

import { queryTermsType } from '@/api/filters/filters.api';

import { TSelectedColumn, setQueryTerm, setSelectedColumn } from '@/store/slices/filtersSlice';
import { useAppDispatch } from '@/store/store';

export const useToggleFilterDialog = () => {
    const [isOpenFilterDialog, setIsOpenFilterDialog] = useState(false);
    const dispatch = useAppDispatch();

    const openFilterDialog = useCallback((column: TSelectedColumn, queryTerm?: queryTermsType) => {
        setIsOpenFilterDialog(true);
        dispatch(setSelectedColumn(column));

        if (queryTerm) {
            dispatch(setQueryTerm(queryTerm));
        }
    }, []);

    const closeFilterDialog = useCallback(() => {
        setIsOpenFilterDialog(false);
        dispatch(setSelectedColumn(null));
    }, []);

    return {
        isOpenFilterDialog,
        openFilterDialog,
        closeFilterDialog,
    };
};
