import { ValueGetterParams } from 'ag-grid-community'
import { NumberCellRenderer } from '../WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer'
import {
    TActDataKeys,
    THeader,
    TKC6AColDefs,
    TKC6AWorkOrPosition,
    TKC6AWorkPosition,
    TTransformedKC6APosition,
} from './AgGridKsgKC6A.def'

export const transformWorkPositionToWork = (position: TKC6AWorkPosition): TTransformedKC6APosition => {
    return {
        ...position,
        isPosition: true,
    }
}

const getPeriodValue = (headerActId: number, key: TActDataKeys) => (params: ValueGetterParams<TKC6AWorkOrPosition>) => {
    if (!params.data?.actData) return null

    const actData = params.data?.actData.find((v) => v.actId === headerActId)

    if (!actData) return null
    return actData[key] ?? null
}

export const getHeaders = (headers: THeader[]): TKC6AColDefs => {
    return headers.map((header: THeader) => ({
        headerName: 'Закрыто',
        suppressMovable: true,
        colId: `closed_${header.actId}`,
        groupId: `closed_${header.actId}`,
        children: [
            {
                headerName: `${header.startDate} — ${header.endDate}`,
                colId: `period_${header.actId}`,
                suppressMovable: true,
                children: [
                    {
                        field: 'volume',
                        colId: `volume_${header.actId}`,
                        valueGetter: getPeriodValue(header.actId, 'volume'),
                        width: 120,
                        suppressMovable: true,
                        headerName: 'Объем',
                        editable: false,
                        cellRenderer: NumberCellRenderer,
                    },
                    {
                        field: 'cost',
                        colId: `cost_${header.actId}`,
                        valueGetter: getPeriodValue(header.actId, 'cost'),
                        width: 120,
                        suppressMovable: true,
                        headerName: 'Стоимость',
                        editable: false,
                        cellRenderer: NumberCellRenderer,
                    },
                ],
            },
        ],
    }))
}
