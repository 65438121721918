import styled from "styled-components";
import { Avatar } from "@mui/material";

type ManageUserAvatarProps = {
  isdisabled?: boolean;
};

export const ManageUserAvatar = styled(Avatar)<ManageUserAvatarProps>`
  width: 220px;
  height: 220px;
  cursor: ${(props) => (props.isdisabled ? "default" : "pointer")};
  background-color: ${(props) => props.theme.palette.bg.shades};

  .MuiSvgIcon-root {
    opacity: 0.42;
  }

  .MuiTypography-root,
  .MuiSvgIcon-root {
    color: ${(props) => props.theme.palette.secondary.gray};
  }
`;
