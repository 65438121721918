import { Close } from '@mui/icons-material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { TextField } from '@mui/material';
import { useState } from 'react';

import { FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';

export const useFilterSearch = () => {
    const [search, setSearch] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(() => e.target.value);
    const handleReset = () => setSearch(() => '');

    const SearchTextfieldComponent = (
        <TextField
            value={search}
            sx={{
                '.MuiOutlinedInput-root': {
                    pl: 1,
                    border: '2px solid rgba(92, 110, 140, 0.1)',
                    background: '#f6f7fb',
                    borderRadius: 2,
                },
                fieldset: {
                    border: 'none',
                },
                input: {
                    pl: 1,
                    color: '#7890B2',
                },
            }}
            size='small'
            InputProps={{
                startAdornment: (
                    <SearchOutlinedIcon
                        sx={{
                            color: '#7890B2',
                        }}
                    />
                ),
                endAdornment: (
                    <>
                        {search.length ? (
                            <FlexRowWrapper
                                alignItems={'center'}
                                onClick={handleReset}
                            >
                                <Close
                                    sx={{
                                        cursor: 'pointer',
                                        color: '#7890B2',
                                    }}
                                />
                            </FlexRowWrapper>
                        ) : null}
                    </>
                ),
            }}
            onChange={handleChange}
        />
    );

    return { SearchTextfieldComponent, search, onReset: handleReset };
};
