import { Box } from '@mui/material'
import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1 1 400px;
    /* gap: ${({theme}) => theme.spacing(1.5)}; */
`
export const WrapperCheckbox = styled.label`
    display: flex;
    cursor: pointer;
    /* justify-content: space-between; */
    align-items: flex-start;
    user-select: none;
    gap: ${({ theme }) => theme.spacing(1)};
    margin-bottom:  ${({ theme }) => theme.spacing(1.5)};;
`
export const FlexWrapper = styled.div<{ center: boolean }>`
    display: flex;
    align-items: center;
    ${({ center }) => {
        return center
            ? `
              justify-content: center;
            `
            : `
              gap: 12px;
              justify-content: space-between;
            `
    }}
`
export const CheckboxWrapper = styled(Box)`
  margin-left: auto;
`
