import { api } from '../api'
import {
    banUserInModuleRequest,
    CompanyUserAccessUpdateRequest,
    CreateFullUserRequest,
    CreateFullUserResponse,
    CreateUserRequest,
    CreateUserResponse,
    GetUserByIdRequest,
    GetUsersRequest,
    GetUsersResponse,
    InviteUserRequest,
    InviteUserResponse,
    PublicUserProfile,
    SetAvatarRequest,
    UserProfile,
} from './users.def'

export const usersApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUsers: build.query<GetUsersResponse, GetUsersRequest>({
            query: ({ limit, offset, companyId }) => ({
                url: `/company/${companyId}/users`,
                params: { limit, offset },
                method: 'GET',
            }),
            providesTags: ['Users'],
        }),
        getUserById: build.query<PublicUserProfile, GetUserByIdRequest>({
            query: ({ userId }) => ({
                url: `/profile/${userId}/get`,
                method: 'GET',
            }),
        }),
        createUser: build.mutation<CreateUserResponse, CreateUserRequest>({
            query: (body) => ({
                url: `/company/${body?.employment?.companyID}/admin/create-user`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Users'],
        }),
        inviteUser: build.mutation<InviteUserResponse, InviteUserRequest>({
            query: ({ userID, profile, employment }) => ({
                url: `/company/${employment?.companyID}/admin/${userID}/invite`,
                method: 'POST',
                body: { profile, employment },
            }),
        }),
        setUserAvatar: build.mutation<UserProfile, SetAvatarRequest>({
            query: ({ body, userID, companyID }) => {
                const { file } = body || {}

                if (file instanceof Blob) {
                    const formData = new FormData()
                    formData.append('file', file)

                    return {
                        url: `/company/${companyID}/admin/${userID}/set-avatar`,
                        method: 'POST',
                        body: formData,
                    }
                }
            },
            invalidatesTags: ['Users'],
        }),
        updateUserAccess: build.mutation<UserProfile, CompanyUserAccessUpdateRequest>({
            query: ({ body, userID, companyID }) => ({
                url: `/company/${companyID}/admin/${userID}/update-access`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Users'],
        }),

        createFullUser: build.mutation<any, CreateFullUserRequest>({
            queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
                const { dataForCreate, dataForSetAvatar, dataForSetAccess, companyID } = arg || {}

                const data = await baseQuery({
                    url: `/company/${dataForCreate?.employment?.companyID}/admin/create-user`,
                    method: 'POST',
                    body: dataForCreate,
                })

                if (data?.error) {
                    return { error: data.error }
                }

                const { success: newUser } = data.data as CreateUserResponse

                if (!!newUser) {
                    const { id: userID } = newUser || {}
                    const { file } = dataForSetAvatar || {}

                    if (file instanceof Blob) {
                        const formData = new FormData()
                        formData.append('file', file)
                        await baseQuery({
                            url: `/company/${companyID}/admin/${userID}/set-avatar`,
                            method: 'POST',
                            body: formData,
                        })
                    }

                    if (dataForSetAccess.newRole) {
                        await baseQuery({
                            url: `/company/${companyID}/admin/${userID}/update-access`,
                            method: 'POST',
                            body: dataForSetAccess,
                        })
                    }
                }
                return { data: data.data } as CreateFullUserResponse
            },
            invalidatesTags: ['Users'],
        }),
        banUserInModule: build.mutation<unknown, banUserInModuleRequest>({
            query: ({ companyID, userID }) => ({
                url: `/company/${companyID}/admin/${userID}/ban`,
                method: 'POST',
            }),
            invalidatesTags: ['Users'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetUsersQuery,
    useGetUserByIdQuery,
    useCreateUserMutation,
    useInviteUserMutation,
    useSetUserAvatarMutation,
    useUpdateUserAccessMutation,
    useCreateFullUserMutation,
    useBanUserInModuleMutation,
} = usersApi
