import { useEffect } from 'react'
import { useLazyGetCollectionOfFactOfActQuery } from '../../../api/works/works.api'
import { integrationStatusSelector } from '../../../store/slices/integrationStatusSlice'
import { useTypedSelector } from '../../../store/store'

export const useCollectionOfFactOfAct = () => {
    const integrationStatus = useTypedSelector(integrationStatusSelector)
    const currentAct = integrationStatus?.currentAct
    const [fetchData, response] = useLazyGetCollectionOfFactOfActQuery()

    const percentage = response?.data?.percentage

    useEffect(() => {
        if (!currentAct) return
        fetchData({ currentAct: currentAct! })
    }, [currentAct, fetchData])

    const refetch = () => {
        if (!currentAct) return
        fetchData({ currentAct: currentAct! })
    }

    return {
        data: response?.data,
        percentage: percentage,
        refetch: refetch,
    }
}
