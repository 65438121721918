import { MenuBook } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { IconButton, Menu } from '@mui/material';
import { format, parse } from 'date-fns';
import { MouseEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';

import { useGetModulesListQuery } from '@/api/importingData/importingData.api';
import { useDownloadExcelMutation } from '@/api/ksg/ksg.api';
import { useDownloadExcelEquipMutation } from '@/api/ksgEquip/ksgEquip.api';
import { useDownloadExcelPeopleMutation } from '@/api/ksgPeople/ksgPeople.api';
import { useGetTemplatesStatusQuery } from '@/api/templates/templates.api';

import { ConfigureAgGridColDefTemplate } from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate';
import RemainingItemsMenu from '@/components/RemainingItemsMenu/RemainingItemsMenu';
import TableTemplateMenu from '@/components/TableTemplates/TableTemplateMenu';

import { useToggleSearchParam } from '@/hooks/useToggleSearchParam';

import { CalendarTime } from '@/shared/SVG/Svg';
import { HtmlTooltip } from '@/shared/components/HtmlTooltip';
import { IntegrationWithOfferSearchParam } from '@/shared/constants/integration-with-offer';
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';
import { formDownloadLink } from '@/shared/utils';

import { drawersSelector, toggleAutoSendSnz, toggleNotificationsCenter } from '@/store/slices/drawersSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { projectsSelector } from '@/store/slices/projectsSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { pagesNames } from '@/index';

import s from '../TableLayout.module.scss';
import SyncIconEl from './SyncIconEl';
import { StyledIconBtn } from './styles';

const getExcludedColIds = (isOfferActive: boolean) => {
    if (isOfferActive) return ['monthlyCharts'];
    return ['lsr', 'monthlyCharts'];
};

export default function TopRightButtons() {
    const [anchorElTemplateTable, setAnchorElTemplateTable] = useState<null | HTMLElement>(null);
    const [anchorElRemaining, setAnchorElRemaining] = useState<null | HTMLElement>(null);
    const [hasSeenNotifications, setHasSeenNotifications] = useState(false);
    const [notificationsArray, setNotificationsArray] = useImmer<{ id: number; date: string }[]>(
        JSON.parse(localStorage.getItem('seenAlerts') || '[]')
    );
    const { AgGrid } = useTypedSelector(drawersSelector);
    const navigate = useNavigate();
    const { isActive: isOfferActive } = useToggleSearchParam(IntegrationWithOfferSearchParam);

    const { profile } = useTypedSelector(profileSelector);
    const { pageName } = useTypedSelector(projectsSelector);
    const isKC6APage = pageName === pagesNames.ksg_kc6a;
    const isKsgPage = pageName === pagesNames.ksg;

    const { projectId } = useParams();
    const dispatch = useAppDispatch();
    const location = useLocation();

    /**
     * Дабавил пропуск запроса в случае когда нет id проекта
     */
    const isTemplateCreated = useGetTemplatesStatusQuery(
        {
            projectId: Number(projectId),
        },
        {
            skip: !Number(projectId),
        }
    );
    const [exReq] = useDownloadExcelMutation();
    const [exEquipReq] = useDownloadExcelEquipMutation();
    const [exPeopleReq] = useDownloadExcelPeopleMutation();
    const modulesList = useGetModulesListQuery({
        id: Number(projectId),
    });

    useEffect(() => {
        localStorage.setItem('seenAlerts', JSON.stringify(notificationsArray));
    }, [notificationsArray]);

    const locationSplit = location.pathname.split('/');

    const changeAlertsDate = () => {
        const date = format(new Date(), 'dd.MM.yyyy');

        const changeIndex = notificationsArray.findIndex((item) => item.id === Number(projectId));

        if (!notificationsArray.length || changeIndex < 0) {
            setNotificationsArray((prev) => {
                prev.push({
                    id: Number(projectId),
                    date,
                });
            });
        } else {
            setNotificationsArray((prev) => {
                prev.splice(changeIndex, 1, {
                    id: Number(projectId),
                    date,
                });
            });
        }
    };

    useEffect(() => {
        const todayDate = new Date();
        const storageDate = notificationsArray.find((item) => {
            return item.id === Number(projectId);
        });

        if (storageDate?.date) {
            setHasSeenNotifications(
                parse(storageDate.date as string, 'dd.MM.yyyy', new Date()).setHours(0, 0, 0, 0) ===
                    todayDate.setHours(0, 0, 0, 0)
            );
        }
    }, [notificationsArray, projectId]);

    const locationLinkAndFuncSwitch = () => {
        const links = {
            workers: {
                func: () =>
                    exPeopleReq({ id: Number(projectId) })
                        .unwrap()
                        .then((res) => formDownloadLink(res, locationSplit, projectId)),
            },
            mim: {
                func: () =>
                    exEquipReq({ id: Number(projectId) })
                        .unwrap()
                        .then((res) => formDownloadLink(res, locationSplit, projectId)),
            },
        };
        return (
            links[locationSplit[2] as 'workers' | 'mim'] || {
                func: () =>
                    exReq({ id: Number(projectId) })
                        .unwrap()
                        .then((res) => formDownloadLink(res, locationSplit, projectId)),
            }
        );
    };

    return (
        <>
            <Menu
                id='basic-menu'
                anchorEl={anchorElTemplateTable}
                open={!!anchorElTemplateTable}
                onClose={() => setAnchorElTemplateTable(null)}
            >
                <TableTemplateMenu setAnchorElTemplateTable={setAnchorElTemplateTable} />
            </Menu>

            <Menu
                id='basic-menu'
                anchorEl={anchorElRemaining}
                open={!!anchorElRemaining}
                onClose={() => setAnchorElRemaining(null)}
            >
                <RemainingItemsMenu setAnchorElRemaining={setAnchorElRemaining} />
            </Menu>

            <div className={s.top_right}>
                {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'VIEW_BRIGADES') && !isKC6APage && (
                    <HtmlTooltip title='Справочник бригад'>
                        <StyledIconBtn onClick={(e) => navigate(`/brigades/${projectId}`)}>
                            <MenuBook />
                        </StyledIconBtn>
                    </HtmlTooltip>
                )}

                <ConfigureAgGridColDefTemplate
                    key={pageName}
                    type={pageName as pagesNames}
                    excludeColIds={getExcludedColIds(isOfferActive)}
                />

                {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'SEND_SNZ_AUTO') && !isKC6APage && (
                    <HtmlTooltip title='Отправить работы исполнителю для сбора факта'>
                        <IconButton
                            sx={{
                                backgroundColor: '#f6f7fb',
                                borderRadius: '6px',
                            }}
                            color='primary'
                            component='span'
                            onClick={() => dispatch(toggleAutoSendSnz())}
                        >
                            <CalendarTime />
                        </IconButton>
                    </HtmlTooltip>
                )}

                {modulesList.data?.modules.filter((f) => f !== 'PragmaOffer').length &&
                DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'DATA_IMPORT_FROM_EXTERNAL_MODULES') ? (
                    <SyncIconEl modulesList={modulesList.data?.modules} />
                ) : null}

                {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'NOTIFICATION_CENTER') && !isKC6APage && (
                    <HtmlTooltip title='Центр уведомлений'>
                        <span className={s.pos_rel}>
                            {!hasSeenNotifications && <div className={s.notification_icon_dot}></div>}
                            <IconButton
                                sx={{
                                    backgroundColor: !hasSeenNotifications ? '#0044b4' : '#f6f7fb',
                                    color: !hasSeenNotifications ? '#f6f7fb' : '#0044b4',
                                    borderRadius: '6px',
                                    ':hover': {
                                        backgroundColor: !hasSeenNotifications ? '#1d4ed8' : null,
                                    },
                                }}
                                color='primary'
                                component='span'
                                onClick={() => {
                                    changeAlertsDate();
                                    dispatch(toggleNotificationsCenter());
                                }}
                            >
                                <NotificationsIcon />
                            </IconButton>
                        </span>
                    </HtmlTooltip>
                )}

                {isKsgPage &&
                    (DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'SEND_SNZ_MANUALLY') ||
                        DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'SYNC_SETTINGS') ||
                        DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'SAVE_BASE_PLAN') ||
                        DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EIS_REPORT') ||
                        DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'DOWNLOAD_XLS') ||
                        DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'UPDATE_KSG')) && (
                        <HtmlTooltip title='Оставшиеся пункты меню'>
                            <IconButton
                                sx={{
                                    backgroundColor: '#f6f7fb',
                                    borderRadius: '6px',
                                }}
                                color='primary'
                                component='span'
                                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                                    setAnchorElRemaining(e.currentTarget);
                                }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </HtmlTooltip>
                    )}
            </div>
        </>
    );
}
