import styled from "styled-components";
import { TextField, Typography } from "@mui/material";

export const StyledTextFieldTitle = styled(Typography)`
  display: block;
  margin-bottom: 6px;
  font-weight: 600;
  text-align: left;
  color: ${(props) => props.theme.palette.secondary.gray};
  text-transform: none;
`;

export const StyledTextField = styled(TextField)`
  & .MuiFilledInput-root {
    border-radius: 8px;
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  & .MuiFilledInput-input {
    padding: 10px 16px;
    color: ${(props) => props.theme.palette.secondary.gray};
    text-overflow: ellipsis;
    border-radius: 8px;
  }
`;

export const ProjectFormTextField = styled(TextField)`
  & .MuiFilledInput-root {
    border-radius: 8px;
    background-color: ${(props) => props.theme.palette.bg.shades};
    border: 1px solid rgba(92, 110, 140, 0.1);
  }

  & .MuiFilledInput-input {
    padding: 8px 16px;
    font-weight: ${(props) => props.theme.typography.subtitle1.fontWeight};
    font-size: ${(props) => props.theme.typography.subtitle1.fontSize};
    line-height: ${(props) => props.theme.typography.subtitle1.lineHeight};
    letter-spacing: 0.15px;
    text-transform: ${(props) =>
      props.theme.typography.subtitle1.textTransform};
    color: ${(props) => props.theme.palette.text.dark};
    text-overflow: ellipsis;
    border-radius: 8px;

    &::placeholder {
      font-weight: ${(props) => props.theme.typography.body2.fontWeight};
      font-size: ${(props) => props.theme.typography.body2.fontSize};
      letter-spacing: 0.17px;
      text-transform: ${(props) => props.theme.typography.body2.textTransform};
      color: ${(props) => props.theme.palette.disabled};
      opacity: 1;
    }
  }

  & .MuiInputBase-multiline {
    padding: 0;
  }

  & .MuiInputBase-inputMultiline {
    font-weight: ${(props) => props.theme.typography.body2.fontWeight};
    font-size: ${(props) => props.theme.typography.body2.fontSize};
    line-height: ${(props) => props.theme.typography.body2.lineHeight};
    letter-spacing: 0.17px;
    text-transform: ${(props) => props.theme.typography.body2.textTransform};
  }
`;

export const DatePickerRenderInput = styled(TextField)`
  align-items: flex-end;
  width: 190px;

  & .MuiOutlinedInput-root {
    width: 130px;
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  & .MuiOutlinedInput-input {
    padding: 3px 8px;
    font-weight: ${(props) => props.theme.typography.subtitle2.fontWeight};
    font-size: ${(props) => props.theme.typography.subtitle2.fontSize};
    line-height: ${(props) => props.theme.typography.subtitle2.lineHeight};
    letter-spacing: 0.17px;
    text-transform: ${(props) =>
      props.theme.typography.subtitle2.textTransform};

    border-radius: 8px;

    &::placeholder {
      text-align: center;
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(92, 110, 140, 0.1);
  }

  & .MuiFormHelperText-root {
    margin-right: 0;
    text-align: right;
  }

  & .MuiIconButton-edgeEnd {
    padding: 0 4px;
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  & .MuiInputAdornment-root {
    margin-left: 0;
  }
`;

export const SmallFormTextField = styled(ProjectFormTextField)`
  align-items: flex-end;

  & .MuiFilledInput-root {
    max-width: 90px;
  }

  & .MuiFilledInput-input {
    padding: 2px 16px;
    font-weight: ${(props) => props.theme.typography.body2.fontWeight};
    font-size: ${(props) => props.theme.typography.body2.fontSize};
    line-height: ${(props) => props.theme.typography.body2.lineHeight};
    letter-spacing: 0.17px;
    text-transform: ${(props) => props.theme.typography.body2.textTransform};
    text-align: center;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  & .MuiFormHelperText-root {
    margin-right: 0;
    text-align: right;
  }
`;
