import * as Yup from 'yup'

export const validationSchema = Yup.object({
    title: Yup.string().required('Введите название').min(3, 'Не меньше 3 символов').max(30, 'Не больше 30 символов'),
    abbr: Yup.string()
        .nullable()
        .required('Введите аббревиатуру')
        .min(3, 'Не меньше 3 символов')
        .max(30, 'Не больше 30 символов'),
    description: Yup.string()
        .required('Заполните описание')
        .min(3, 'Не меньше 3 символов')
        .max(1000, 'Не больше 1000 символов'),
    status: Yup.string().required('Выберите статус'),
    timezone: Yup.number().nullable().required('Выберите часовой пояс'),
    dateStart: Yup.date()
        .nullable()
        .required('Выберите дату начала')
        .typeError('Неверный формат, введите дату')
        .min(new Date(2000, 0, 1), 'Не раньше 2000 года'),
    dateEnd: Yup.date()
        .nullable()
        .required('Выберите дату окончания')
        .typeError('Неверный формат, введите дату')
        .min(Yup.ref('dateStart'), 'Дата окончания не может быть раньше даты начала')
        .max(new Date(2050, 0, 1), 'Не позднее 2050 года'),
})

export interface ChartPayload {
    name: string
    stroke: string
    fillOpacity: number
    fill: string
    strokeWidth: number
    points: any[]
    dataKey: string
    color: string
    value: number
    payload: Payload
}

interface Payload {
    month: number
    fact: number
    plan: number
    basePlan: number
}

export const timezones = [
    { title: 'МСК-1. Калининград', offset: 2 },
    { title: 'МСК. Москва', offset: 3 },
    { title: 'МСК+1. Самара', offset: 4 },
    { title: 'МСК+2. Екатеринбург', offset: 5 },
    { title: 'МСК+3. Омск', offset: 6 },
    { title: 'МСК+4. Красноярск', offset: 7 },
    { title: 'МСК+5. Иркутск', offset: 8 },
    { title: 'МСК+6. Якутск', offset: 9 },
    { title: 'МСК+7. Владивосток', offset: 10 },
    { title: 'МСК+8. Магадан', offset: 11 },
    { title: 'МСК+9. Камчатка', offset: 12 },
]

export type projectsTerms = 'Все' | 'Активный' | 'Приостановлен' | 'Завершенный'
