import { useFormContext } from 'react-hook-form'
import { ValidationErrorMessage } from './ValidationErrorMessage'
import { ITextInputProps } from './ControlableInputs.def'
import { StyledTextField } from './ControlableInputs.styles'
import { DEFAULT_VALIDATION } from './ControlableInputs.service'

export function TextInput(props: ITextInputProps) {
    const { fieldName, validation } = props

    const formContext = useFormContext()

    const defaultValue = formContext?.formState?.defaultValues && formContext?.formState?.defaultValues[fieldName]

    return (
        <>
            <StyledTextField
                size="small"
                fullWidth
                error={!!formContext?.formState?.errors[fieldName]?.message}
                defaultValue={defaultValue || ''}
                {...formContext?.register(fieldName, { ...DEFAULT_VALIDATION.text, ...validation })}
                {...props}
            />
            <ValidationErrorMessage message={formContext?.formState?.errors[fieldName]?.message as string} />
        </>
    )
}
