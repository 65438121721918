import { req } from './api'
import { IRequestEstimateParams } from './api.types'

export class EstimateAPI {
    static baseURL: string = `/projects`

    static async getResources({ projectID, workID, pageType }: Partial<IRequestEstimateParams>) {
        return await req
            .get(
                this.baseURL +
                `/${projectID}/works/${workID}/cabinet/estimate/resources/${pageType}`
            )
            .then(({ data }) => {
                return data
            })
            .catch((e) => console.log(e))
    }

    static async getPositions({ projectID, workID }: Partial<IRequestEstimateParams>) {
        return await req
            .get(
                this.baseURL +
                `/${projectID}/works/${workID}/cabinet/estimate/positions/list`
            )
            .then(({ data }) => {
                return data
            })
            .catch((e) => console.log(e))
    }

    static async addNewItem({ estimateType, projectID, workID, body }: IRequestEstimateParams) {
        return await req
            .post(this.baseURL + `/${projectID}/works/${workID}/cabinet/estimate/${estimateType}/add`, body)
            .then(({ data }) => {
                return data
            })
            .catch((e) => console.log(e))
    }

    static async updateItem({ estimateType, projectID, workID, estimateItemID, body }: IRequestEstimateParams) {
        return await req
            .post(
                this.baseURL +
                `/${projectID}/works/${workID}/cabinet/estimate/${estimateType}/${estimateItemID}/update`,
                body
            )
            .then(({ data }) => {
                return data
            })
            .catch((e) => console.log(e))
    }

    static async deleteItem({ estimateType, projectID, workID, estimateItemID }: IRequestEstimateParams) {
        return await req
            .delete(
                this.baseURL + `/${projectID}/works/${workID}/cabinet/estimate/${estimateType}/${estimateItemID}/delete`
            )
            .then(({ data }) => {
                return data
            })
            .catch((e) => console.log(e))
    }
}
