import { ColDef, ColGroupDef } from 'ag-grid-community';

import { IAutocompleteOption } from '@/pages/WorkManagment/components/CellEditors/CellEditor/CellEditor.def';

export const isAutocompleteOption = (option: unknown): option is IAutocompleteOption => {
    if (typeof option !== 'object' || option === null) {
        return false;
    }

    const typedOption = option as Partial<IAutocompleteOption>;
    return Boolean(typedOption?.value && typedOption?.label);
};

export const isColGroupDef = (colDef: ColDef | ColGroupDef): colDef is ColGroupDef => 'children' in colDef;
