import { AgGridReact } from 'ag-grid-react';
import { useEffect } from 'react';

import { Nullable } from '@/shared/def';
import { setScrollParamsToLocalStorage } from '@/shared/utils';

import { pagesNames } from '..';
import { usePrevState } from './usePrevState';

type TUsePreserveScrollProps = {
    projectId: string;
    pageName: Nullable<pagesNames>;
    pathname: string;
    AgGrid: Nullable<AgGridReact>;
};

export const usePreserveScroll = ({ projectId, pageName, pathname, AgGrid }: TUsePreserveScrollProps) => {
    const prevProjectId = usePrevState(projectId);

    useEffect(() => {
        if (AgGrid) {
            return () => {
                const firstRenderedRow = AgGrid.api?.getFirstDisplayedRow();
                const lastRenderedRow = AgGrid.api?.getLastDisplayedRow();

                if (firstRenderedRow === 0 && lastRenderedRow === -1) return;

                AgGrid?.api?.hideOverlay();
                setScrollParamsToLocalStorage(pathname, firstRenderedRow, lastRenderedRow);
            };
        }
    }, [AgGrid, pageName, pathname]);

    useEffect(() => {
        return () => {
            if (prevProjectId !== projectId && prevProjectId) {
                localStorage.removeItem('scrollParams');
            }
        };
    }, [prevProjectId, projectId]);
};
