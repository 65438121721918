import { createApi } from '@reduxjs/toolkit/query/react';

import { IColumnTemplate } from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.def';

import { baseQueryWithReauth } from '../api';
import { getTemplatesListRes } from './templates.def';

export const templateApi = createApi({
    reducerPath: 'templateApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Templates'],
    endpoints: (build) => ({
        getTemplatesList: build.query<
            {
                data: IColumnTemplate[];
            },
            { projectId: string; type: string }
        >({
            query: ({ projectId, type }) => ({
                url: `/projects/${projectId}/patterns/list?type=${type}`,
                method: 'GET',
            }),
            providesTags: (result, error, arg) => {
                return [{ type: 'Templates', page: arg.type }];
            },
        }),
        addTemplate: build.mutation<IColumnTemplate, { projectId: string; type: string; body: any }>({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/patterns/add`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, arg) => {
                return [{ type: 'Templates', page: arg.type }];
            },
        }),
        updateTemplate: build.mutation<void, { projectId: string; type: string; templateId: number; body: any }>({
            query: ({ projectId, templateId, body }) => ({
                url: `/projects/${projectId}/patterns/${templateId}/update`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, arg) => {
                return [{ type: 'Templates', page: arg.type }];
            },
        }),
        deleteTemplate: build.mutation<getTemplatesListRes, { projectId: string; type: string; templateId: number }>({
            query: ({ projectId, templateId }) => ({
                url: `/projects/${projectId}/patterns/${templateId}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => {
                return [{ type: 'Templates', page: arg.type }];
            },
        }),
        setTemplate: build.mutation<getTemplatesListRes, { projectId: string; templateId: number }>({
            query: ({ projectId, templateId }) => ({
                url: `/projects/${projectId}/patterns/${templateId}/set`,
                method: 'POST',
            }),
        }),
        resetTemplate: build.mutation<getTemplatesListRes, { projectId: string; body: { type: string } }>({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/patterns/reset`,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useGetTemplatesListQuery,
    useAddTemplateMutation,
    useUpdateTemplateMutation,
    useDeleteTemplateMutation,
    useSetTemplateMutation,
    useResetTemplateMutation,
} = templateApi;
