import { Box, CircularProgress, Typography } from '@mui/material'
import styled from 'styled-components'

export const Circle = styled(Box)<{ backgroundColor?: string }>`
    border-radius: 50%;
    border: 1px solid #fff;
    width: 10px;
    height: 10px;
    background-color: ${({ backgroundColor }) => backgroundColor ?? '#6d9adc'};
`

export const StyledTypography = styled(Typography)`
    font-size: 14px;
    line-height: 130%;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.text.light};
`

export const StyledCircularProgress = styled(CircularProgress)<{ colorValue?: string }>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg) !important;
    color: ${({ colorValue }) => colorValue ?? '#ffffff'};
    transition: color 0.3s ease;
`

export const StyledContainer = styled(Box)<{ isOpen: boolean }>`
    position: absolute;
    top: 2px;
    right: 2px;
    border: 1px solid transparent;
    border-color: ${({ isOpen }) => (isOpen ? '#8cbbe8' : 'transparent')};
    background-color: ${({ isOpen }) => (isOpen ? '#1976d214' : 'transparent')};
    width: 32px;
    height: 32px;
    border-radius: 4px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
`
