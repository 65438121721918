import styled from 'styled-components'

export const Container = styled('div')`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    width: 100%;
    padding: 0 0.125rem;
`

export const Text = styled('p')`
    flex: 1;
    text-align: center;
`
