import { HtmlTooltip } from '@/shared/components/HtmlTooltip';

// TODO:
// 1. Переписать
// 2. Вынести в component/ui
export const HeaderNameEl = ({ width, name }: { width?: number; name: string }) => {
    return (
        <div
            style={{
                width: (width || 32) - 32,
            }}
            className='text_ellipsis'
        >
            <HtmlTooltip title={name}>
                <p>{name}</p>
            </HtmlTooltip>
        </div>
    );
};
