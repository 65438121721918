import { EstimatePositionsPageWrapper } from './EstimatePositionsPage.styles'
import { CardTitle, CardWrapper } from '../../../../components/components.styles'
import { StyledAgGrid } from '../../../../components/AgGridService/AgGrid.styles'
import { IEstimatePosition } from './EstimatePositionsPage.types'
import { useEffect, useState } from 'react'
import { TOTAL_ROW, estimatePositionsColDef } from './EstimatePositionsPage.colDef'
import {
    AG_GRID_ESTIMATE_PARAMS,
    disableAddAndDelete,
} from '../../../../components/AgGridService/AgGridColumnDef.service'
import { useParams } from 'react-router-dom'
import { EstimateAPI } from '../../../../api/EstimateAPI'
import { withRecalculatedTotalRow } from '../../../../../../shared/components/AgGrid/AgGrid.service'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../../../../../shared/rolePermissions'
import { profileSelector } from '../../../../../../store/slices/profileSlice'
import { useTypedSelector } from '../../../../../../store/store'

export function EstimatePositionsPage() {
    const [rowData, setRowData] = useState<IEstimatePosition[] | null>(null)
    const { profile } = useTypedSelector(profileSelector)
    const { projectId, workID } = useParams()

    useEffect(() => {
        EstimateAPI.getPositions({
            projectID: projectId,
            workID: workID,
        }).then(({ data }) => setRowData(() => data))
    }, [])

    return (
        <EstimatePositionsPageWrapper>
            <CardWrapper>
                <CardTitle size="small">Позиции</CardTitle>
                <StyledAgGrid
                    rowData={rowData}
                    columnDefs={estimatePositionsColDef}
                    context={{
                        estimateType: 'positions',
                        projectID: projectId,
                        workID: workID,
                    }}
                    {...AG_GRID_ESTIMATE_PARAMS()}
                    {...withRecalculatedTotalRow(
                        TOTAL_ROW,
                        [
                            {
                                field: 'costTotal',
                                totalFunc: 'sum',
                            },
                        ],
                        'name'
                    )}
                    {...((!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_WORK_MANAGMENT') ||
                        JSON.parse(localStorage.getItem('work')!)?.hasChildren) && {
                        suppressClickEdit: true,
                        ...disableAddAndDelete(true),
                    })}
                />
            </CardWrapper>
        </EstimatePositionsPageWrapper>
    )
}
