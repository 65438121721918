import { Box, FormControl, MenuItem, SelectChangeEvent } from '@mui/material'
import { Work, WorkOrWorkPosition } from '../../../../api/ksg/ksg.def'
import { IconArrowDown, IconArrowUp, TaskAutoModeIcon, TaskManualModeIcon, TaskModeSelect } from './TaskModeCell.styles'
import { useUpdateWorkModeMutation } from '../../../../api/ksg/ksg.api'
import { useSnackbar } from 'notistack'
import { onEditAgKsg } from '../../../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import { useAppDispatch } from '../../../../store/store'
import { ICellRendererParams } from 'ag-grid-community'

interface ITaskModeCellProps extends ICellRendererParams {
    data: Work
    projectId: string
    rowData: WorkOrWorkPosition[]
}

export const TaskModeCell: React.FC<ITaskModeCellProps> = ({ projectId, ...props }) => {
    const dispatch = useAppDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const [modeUpdateReq, modeUpdateRes] = useUpdateWorkModeMutation()

    const handleChange = async (event: SelectChangeEvent<unknown>) => {
        const newValue = event.target.value as string
        const manualPlanning = newValue === 'true'
        modeUpdateReq({
            projectId,
            workId: props.data.id,
            patch: {
                manualPlanning,
            },
        })
            .unwrap()
            .then((res) => {
                const updatedData = { ...props.data, manualPlanning }
                props.api.applyTransaction({ update: [updatedData] })
                dispatch(onEditAgKsg([updatedData]))
                enqueueSnackbar('Режим задачу изменен', {
                    variant: 'success',
                })
            })
            .catch((e) => {
                enqueueSnackbar(
                    'Не удалось перевести задачу в автоматический режим. Убедитесь, что факт не мешает связям.',
                    {
                        variant: 'error',
                    }
                )
                console.error(e)
            })
    }

    return (
        <FormControl fullWidth>
            <TaskModeSelect
                labelId="select-label"
                id="select"
                value={`${props.data.manualPlanning}`}
                onChange={handleChange}
                IconComponent={(props) => (
                    <Box
                        sx={{ transform: props.open ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}
                    >
                        {props.open ? <IconArrowUp {...props} /> : <IconArrowDown {...props} />}
                    </Box>
                )}
            >
                <MenuItem value={'false'}>
                    <TaskAutoModeIcon />
                </MenuItem>
                <MenuItem value={'true'}>
                    <TaskManualModeIcon />
                </MenuItem>
            </TaskModeSelect>
        </FormControl>
    )
}
