import { List } from '@mui/icons-material';
import { forwardRef } from 'react';

import { StyledTooltip } from '@/shared/components/StyledTooltip';

import { StyledButton } from './UIButton.styles';

interface IProps {
    onClick: () => void;
}

export const UIButton = forwardRef<HTMLButtonElement, IProps>(({ onClick }, ref) => {
    return (
        <StyledTooltip title='Шаблоны отображения таблицы'>
            <StyledButton
                ref={ref}
                color='primary'
                onClick={onClick}
            >
                <List />
            </StyledButton>
        </StyledTooltip>
    );
});
