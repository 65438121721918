import { ColDef, ColGroupDef } from 'ag-grid-community';

import { isColGroupDef } from '@/shared/guards/common.guards';

// TODO:
// 1. Переместить в shared/utils
export const extractColIds = (colDef: ColGroupDef | ColDef, acc: string[] = []) => {
    if (isColGroupDef(colDef)) {
        colDef.children.forEach((v) => extractColIds(v, acc));
        return acc;
    }

    colDef?.colId && acc.push(colDef.colId);
    return acc;
};
