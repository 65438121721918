import { TextField } from '@mui/material';

import { FormErrorAg } from '../AddLevel.service';
import s from '../AddLevelKsg.module.scss';

export default function WideTextLineEl({
    errorTitle,
    touched,
    title,
    required,
    ...props
}: {
    errorTitle?: string;
    touched?: boolean;
    title: string;
    required?: boolean;
}) {
    return (
        <div className={s.wide_input_line}>
            <p>
                {title}
                {required && (
                    <>
                        <span>*</span>
                    </>
                )}
                :
            </p>
            <div className='relative'>
                <TextField
                    InputLabelProps={{ shrink: false }}
                    placeholder='Введите текст'
                    fullWidth
                    sx={{
                        backgroundColor: '#F6F7FB',
                        '.MuiOutlinedInput-root': {
                            padding: '0.5rem',
                            fontSize: 14,
                            borderRadius: '6px',
                        },
                        '.MuiOutlinedInput-input::placeholder': {
                            color: 'rgba(43, 54, 72, 0.38)',
                        },
                        '.MuiOutlinedInput-notchedOutline': {
                            border: '1px solid rgba(43, 54, 72, 0.12)',
                            borderWidth: '1px !important',
                        },
                    }}
                    error={Boolean(touched) && Boolean(errorTitle?.length)}
                    {...props}
                    rows={2}
                    multiline
                />
                <FormErrorAg
                    isTouched={touched}
                    error={errorTitle}
                />
            </div>
        </div>
    );
}
