import s from '../Projects.module.scss'
import React from 'react'

export const Dot = ({ color, title }: { color: string; title: string }) => (
    <div className={s.dot_container}>
        <span
            className={s.dot}
            style={{
                backgroundColor: color,
            }}
        />
        <p>{title}</p>
    </div>
)
