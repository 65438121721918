import styled from 'styled-components'

export const ResetButton = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
`
