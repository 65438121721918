import { ICellRendererParams } from 'ag-grid-community'
import cn from 'classnames'
import { cpgViewEnum, cpgViewSelector } from '../../../store/slices/cpgViewSlice'
import { getSettings } from '../../../store/slices/settings/settingsViewAgGrid'
import { useTypedSelector } from '../../../store/store'
import '../AgGrid.scss'

export default function RowMonthHeaderRenderer({
    hasChildren,
    isGant = false,
    cellRendererParams,
    isMSG = false,
    isResourceTable = false,
    customContainerClassName = '',
}: {
    isGant?: boolean
    hasChildren?: boolean
    cellRendererParams: ICellRendererParams<any>
    isMSG?: boolean
    isResourceTable?: boolean
    customContainerClassName?: string
}) {
    const { cpgView } = useTypedSelector(cpgViewSelector)

    /* Получение данныс с стора о базовом плане и верификационном факте */
    const { basicPlan, verificationPlan, msgForecast } = useTypedSelector(getSettings)

    const isRoot = cellRendererParams.data?.level === 0
    const isVolumeFloatedToParent = cellRendererParams.data?.unit && cellRendererParams.data?.hasChildren
    const isChild = !cellRendererParams.data?.hasChildren && cellRendererParams.data?.level !== 0
    const background = {
        style: {
            backgroundColor: 'inherit',
        },
    }

    if (isGant) {
        return <div className="grid_center">Гант</div>
    }

    if (cpgView !== cpgViewEnum.blank && !isResourceTable) {
        return (
            <div className={cn('month_container', customContainerClassName)}>
                <div className="cell_body_plan not_month bb" {...(hasChildren && background)}>
                    ∑ опер. план
                </div>
                <div
                    className={`cell_body_fact ${isChild ? 'cell_body_fact--white' : ''} not_month bb`}
                    {...(hasChildren && background)}
                >
                    ∑ факт
                </div>
                {basicPlan && isMSG === false && !isResourceTable && (
                    <div className={`cell_body_plan not_month bb`} {...(hasChildren && background)}>
                        Базовый план
                    </div>
                )}
                {msgForecast && isMSG && (
                    <div className={`cell_body_plan not_month bb`} {...(hasChildren && background)}>
                        Прогноз
                    </div>
                )}
                {verificationPlan && (
                    <div className={`cell_body_forecast not_month bb`} {...(hasChildren && background)}>
                        Вер. факт
                    </div>
                )}
            </div>
        )
    }

    if (isRoot && !isGant) {
        return <div className="grid_center ksz">КС3</div>
    }

    if (cpgView === cpgViewEnum.blank) {
        if (isVolumeFloatedToParent || isChild) {
            return (
                <div className={cn('month_container', customContainerClassName)}>
                    <div className="cell_body_plan not_month bb" {...(hasChildren && background)}>
                        Опер. план
                    </div>
                    <div
                        className={`cell_body_fact ${isChild ? 'cell_body_fact--white' : ''}`}
                        {...(hasChildren && background)}
                    >
                        Факт
                    </div>
                    {basicPlan && isMSG === false && !isResourceTable && (
                        <div className={`cell_body_plan not_month bb`} {...(hasChildren && background)}>
                            Базовый план
                        </div>
                    )}
                    {msgForecast && isMSG && (
                        <div className={`cell_body_plan not_month bb`} {...(hasChildren && background)}>
                            Прогноз
                        </div>
                    )}
                    {verificationPlan && (
                        <div className={`cell_body_forecast not_month bb`} {...(hasChildren && background)}>
                            Вер. факт
                        </div>
                    )}
                </div>
            )
        }
    }

    return <div className={cn('month_container', customContainerClassName)} />
}
