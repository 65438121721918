import { Box, Typography } from "@mui/material";
import { FlexRowWrapper } from "../components.styles";
import { AlarmOn } from "@mui/icons-material";
import Countdown, { CountdownRendererFn } from "react-countdown";
import { theme } from "../../../../styles/theme";

interface ITimerProps {
    endTime?: Date
}

export function Timer({ endTime }: ITimerProps) {

    const stopTimerTime = endTime || new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)

    const renderer: CountdownRendererFn = ({ hours, minutes, seconds, completed }) => completed
        ? "Время вышло"
        : <Box>
            {hours < 10 ? "0" + hours : hours}:
            {minutes < 10 ? "0" + minutes : minutes}:
            {seconds < 10 ? "0" + seconds : seconds}
        </Box>

    return (
        <FlexRowWrapper gap={1}>
            <AlarmOn color={"warning"} fontSize="medium" />
            <Typography variant="button" fontWeight={"bold"} color={theme.palette.warning.main}>
                <Countdown date={stopTimerTime} renderer={renderer} />
            </Typography>
        </FlexRowWrapper>
    )
}