import PathSVG from '../../../../../shared/SVG/PathSVG'
import { FlexRowWrapper } from '../../../../NewExecutorView/components/components.styles'
import { IDepsTabIconComponentProps } from '../GantCell.def'

export function DepsTab({ isActive, depsCount, linkType }: IDepsTabIconComponentProps) {
    return (
        <FlexRowWrapper gap={0.5}>
            <PathSVG
                fontSize="small"
                isActive={isActive}
                {...(linkType === 'to' && { sx: { transform: 'rotate(180deg)', marginTop: -0.5 } })}
            />
            {depsCount}
        </FlexRowWrapper>
    )
}
