import { Typography } from '@mui/material';
import { ColGroupDef, ICellRendererParams } from 'ag-grid-community';
import { Location } from 'react-router-dom';

import { WorkMsg } from '@/api/msg/msg.def';

import { StyledCellWrapper } from '@/components/EditChartCell/EditChartCell.styles';

import { hasParam } from '@/shared/utils/common.utils';
import { applyStylesPercent } from '@/shared/utils/styles.utils';

import RowMonthHeaderRenderer from '../AgGrid/components/RowMonthHeaderRenderer';
import { getHeadersMonthsMsg } from '../AgGridMsg/AgGridMsg.service';
import { GantCell } from '../AgGridMsg/components/GantCell/GantCell';
import { COL_DEF_TEMPLATE } from '../WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { NumberCellRenderer } from '../WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer';
import { stylesLevel } from './MSGPrint.utils';

interface IGetMSGPrintColDefMSGArgs {
    workList: {
        data: WorkMsg[];
        total: number;
    } | null;
    location: Location;
}

export const MSGPrintColDef = ({ workList, location }: IGetMSGPrintColDefMSGArgs) => {
    return [
        {
            field: 'level',
            colId: 'level',
            headerName: 'Уровень',
            width: 130,
            spanHeaderHeight: true,
            cellRenderer: (params: ICellRendererParams<WorkMsg, any, any>) => {
                return (
                    params?.data?.level && (
                        <h5 className={stylesLevel(params?.data?.level)}>УР {params?.data?.level}</h5>
                    )
                );
            },
        },
        {
            field: 'numOrder',
            colId: 'numOrder',
            width: 100,
            spanHeaderHeight: true,
            headerName: '№',
        },
        {
            field: 'code',
            colId: 'code',
            width: 160,
            spanHeaderHeight: true,
            headerName: 'ID',
            cellRenderer: (data: ICellRendererParams<WorkMsg, any, any>) => {
                return (
                    <Typography
                        variant='subtitle2'
                        sx={{ color: (theme) => theme.palette.text.primary, fontWeight: 400 }}
                    >
                        {data.value}
                    </Typography>
                );
            },
        },
        {
            field: 'objTitle',
            colId: 'objTitle',
            width: 260,
            spanHeaderHeight: true,
            headerName: 'Титул',
        },
        {
            field: 'objName',
            colId: 'objName',
            width: 300,
            spanHeaderHeight: true,
            headerName: 'Объект',
        },
        {
            field: 'rdGroup',
            colId: 'rdGroup',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Раздел РД',
        },
        {
            field: 'rdCode',
            colId: 'rdCode',
            width: 160,
            spanHeaderHeight: true,
            headerName: 'Шифр РД',
        },
        {
            field: 'rdStatus',
            colId: 'rdStatus',
            width: 225,
            spanHeaderHeight: true,
            headerName: 'Статус выдачи РД',
        },
        {
            field: 'rdDateApproval',
            colId: 'rdDateApproval',
            width: 140,
            spanHeaderHeight: true,
            headerName: 'Дата согласования РД',
        },
        {
            field: 'rdVersion',
            colId: 'rdVersion',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Версия РД',
        },
        {
            field: 'rdDate',
            colId: 'rdDate',
            width: 140,

            spanHeaderHeight: true,
            headerName: 'Дата выдачи РД',
        },
        {
            field: 'typeOfWorks',
            colId: 'typeOfWorks',
            width: 150,
            headerName: 'Конструктив (вид работ)',
            spanHeaderHeight: true,
        },
        {
            field: 'workGroup',
            colId: 'workGroup',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Группа работ',
        },
        {
            field: 'contractorCompany',
            colId: 'contractorCompany',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Подрядчик',
        },
        {
            field: 'executorName',
            colId: 'executorName',
            width: 225,
            spanHeaderHeight: true,
            headerName: 'Исполнитель',
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('executorName'),
        },
        {
            colId: 'brigade',
            field: 'brigade',
            width: 225,
            spanHeaderHeight: true,
            headerName: 'Бригада',
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('brigade', 'name'),
        },
        {
            field: 'workName',
            colId: 'workName',
            width: 300,
            spanHeaderHeight: true,
            headerName: 'Наименование работы',
        },
        {
            field: 'unit',
            colId: 'unit',
            width: 130,
            spanHeaderHeight: true,
            headerName: 'Единица измерения',
            headerTooltip: 'Единица измерения',
        },
        {
            field: 'volumeTotal',
            colId: 'volumeTotal',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Общий объем на месяц',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
                supressTooltip: true,
            },
        },
        {
            field: 'volumeDoneFactKsg',
            colId: 'volumeDoneFactKsg',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Общий факт',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
                supressTooltip: true,
            },
        },
        {
            field: 'volumeTotalKsg',
            colId: 'volumeTotalKsg',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Общий объем',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
                supressTooltip: true,
            },
        },
        {
            headerName: 'Выполненный объём работ',
            colId: 'volumeDone',
            children: [
                {
                    field: 'volumeDonePlan',
                    colId: 'volumeDonePlan',
                    width: 100,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: {
                        supressTooltip: true,
                    },
                },
                {
                    field: 'volumeDoneForecast',
                    colId: 'volumeDoneForecast',
                    width: 100,
                    headerName: 'Прогноз',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: {
                        supressTooltip: true,
                    },
                },
                {
                    field: 'volumeDoneFact',
                    colId: 'volumeDoneFact',
                    width: 100,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: {
                        supressTooltip: true,
                    },
                },
            ],
        } as ColGroupDef<WorkMsg>,
        {
            headerName: 'Базовый план',
            colId: 'basePlanDates',
            children: [
                {
                    field: 'startDate',
                    colId: 'startDate',
                    width: 140,
                    spanHeaderHeight: true,
                    headerName: 'Дата начала',
                },
                {
                    field: 'endDate',
                    colId: 'endDate',
                    width: 140,
                    spanHeaderHeight: true,
                    headerName: 'Дата окончания',
                },
            ],
        } as ColGroupDef<WorkMsg>,
        {
            headerName: 'Оперативный план',
            colId: 'operPlanDates',
            children: [
                {
                    headerName: 'Дата начала',
                    field: 'operationalStartDate',
                    colId: 'operationalStartDate',
                    width: 140,
                },
                {
                    headerName: 'Дата окончания',
                    field: 'operationalEndDate',
                    colId: 'operationalEndDate',
                    width: 140,
                    cellRenderer: (params: ICellRendererParams) => {
                        return <>{params.data.operationalEndDate}</>;
                    },
                },
            ],
        } as ColGroupDef<WorkMsg>,
        {
            field: 'humanHourCost',
            colId: 'humanHourCost',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Трудозатраты на ед. изм., чел-час',
            headerTooltip: 'Трудозатраты на ед. изм., чел-час',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
                supressTooltip: true,
            },
        },
        {
            field: 'humanHoursTotal',
            colId: 'humanHoursTotal',
            width: 180,

            spanHeaderHeight: true,
            headerName: 'Трудозатраты общие, чел-час',
            headerTooltip: 'Трудозатраты общие, чел-час',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
                supressTooltip: true,
            },
        },
        {
            field: 'humanHoursProgressTZ',
            colId: 'humanHoursProgressTZ',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Прогресс ТЗ',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: (params: ICellRendererParams) => {
                return {
                    TypographyProps: { ...applyStylesPercent(params.value) },
                    NumericFormatProps: {
                        ...applyStylesPercent(params.value),
                        suffix: ' %',
                    },
                    supressTooltip: true,
                };
            },
        },
        {
            headerName: 'Прогресс трудозатрат нарастающим итогом, чел-час',
            colId: 'humanHoursProgress',
            children: [
                {
                    field: 'humanHoursProgressPlan',
                    colId: 'humanHoursProgressPlan',
                    width: 100,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: {
                        supressTooltip: true,
                    },
                },
                {
                    field: 'humanHoursProgressForecast',
                    colId: 'humanHoursProgressForecast',
                    width: 100,
                    headerName: 'Прогноз',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: {
                        supressTooltip: true,
                    },
                },
                {
                    field: 'humanHoursProgressFact',
                    colId: 'humanHoursProgressFact',
                    width: 100,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: {
                        supressTooltip: true,
                    },
                },
                {
                    field: 'humanHoursProgressPercent',
                    colId: 'humanHoursProgressPercent',
                    width: 130,
                    headerName: 'Процент выполнения',
                    headerTooltip: 'Процент выполнения',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: (params: ICellRendererParams) => {
                        return {
                            TypographyProps: { ...applyStylesPercent(params.value) },
                            NumericFormatProps: {
                                ...applyStylesPercent(params.value),
                                suffix: ' %',
                            },
                            supressTooltip: true,
                        };
                    },
                },
            ],
        } as ColGroupDef<WorkMsg>,
        {
            field: 'costER',
            colId: 'costER',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Стоимость ЕР (Дог.+ДР), руб.',
            headerTooltip: 'Стоимость ЕР (Дог.+ДР), руб.',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
                supressTooltip: true,
            },
        },
        {
            field: 'costTotal',
            colId: 'costTotal',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Общая стоимость (Дог.+ДР), рублей',
            headerTooltip: 'Общая стоимость (Дог.+ДР), рублей',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
                supressTooltip: true,
            },
        },
        {
            field: 'costTotalProgress',
            colId: 'costTotalProgress',
            width: 180,
            headerName: 'Общий прогресс по стоимости (Дог.+ДР)',
            headerTooltip: 'Общий прогресс по стоимости (Дог.+ДР)',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
            cellRendererParams: (params: ICellRendererParams) => {
                return {
                    TypographyProps: { ...applyStylesPercent(params.value) },
                    NumericFormatProps: {
                        ...applyStylesPercent(params.value),
                        suffix: ' %',
                    },
                    supressTooltip: true,
                };
            },
        },
        {
            headerName: 'Прогресс стоимости (Дог.+ДР) нарастающим итогом, руб',
            colId: 'costProgress',
            children: [
                {
                    field: 'costProgressPlan',
                    colId: 'costProgressPlan',
                    width: 180,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: {
                        supressTooltip: true,
                    },
                },
                {
                    field: 'costProgressFact',
                    colId: 'costProgressFact',
                    width: 180,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: {
                        supressTooltip: true,
                    },
                },
                {
                    field: 'costProgressPercent',
                    colId: 'costProgressPercent',
                    width: 180,
                    headerName: 'Процент текущего выполнения',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: (params: ICellRendererParams) => {
                        return {
                            TypographyProps: { ...applyStylesPercent(params.value) },
                            NumericFormatProps: {
                                ...applyStylesPercent(params.value),
                                suffix: ' %',
                            },
                            supressTooltip: true,
                        };
                    },
                },
            ],
        },
        {
            field: 'costERGK',
            colId: 'costERGK',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Стоимость ЕР (Договор), руб.',
            headerTooltip: 'Стоимость ЕР (Договор), руб.',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
                supressTooltip: true,
            },
        },
        {
            field: 'costTotalGK',
            colId: 'costTotalGK',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Общая стоимость (Договор), рублей',
            headerTooltip: 'Общая стоимость (Договор), рублей',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
                supressTooltip: true,
            },
        },
        {
            field: 'costTotalProgressGK',
            colId: 'costTotalProgressGK',
            width: 180,
            headerName: 'Общий прогресс по стоимости (Договор)',
            headerTooltip: 'Общий прогресс по стоимости (Договор)',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
            cellRendererParams: (params: ICellRendererParams) => {
                return {
                    TypographyProps: { ...applyStylesPercent(params.value) },
                    NumericFormatProps: {
                        ...applyStylesPercent(params.value),
                        suffix: ' %',
                    },
                    supressTooltip: true,
                };
            },
        },
        {
            headerName: 'Прогресс стоимости нарастающим итогом (Договор), руб',
            colId: 'costProgressGK',
            children: [
                {
                    field: 'costProgressPlanGK',
                    colId: 'costProgressPlanGK',
                    width: 180,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: {
                        supressTooltip: true,
                    },
                },
                {
                    field: 'costProgressFactGK',
                    colId: 'costProgressFactGK',
                    width: 180,
                    headerName: 'Факт',
                    сellRenderer: NumberCellRenderer,
                    cellRendererParams: {
                        supressTooltip: true,
                    },
                },
                {
                    field: 'costProgressPercentGK',
                    colId: 'costProgressPercentGK',
                    width: 180,
                    headerName: 'Процент текущего выполнения',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: (params: ICellRendererParams) => {
                        return {
                            TypographyProps: { ...applyStylesPercent(params.value) },
                            NumericFormatProps: {
                                ...applyStylesPercent(params.value),
                                suffix: ' %',
                            },
                            supressTooltip: true,
                        };
                    },
                },
            ],
        },
        {
            field: 'costERSub',
            colId: 'costERSub',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Стоимость ЕР (Расчет), руб.',
            headerTooltip: 'Стоимость ЕР (Расчет), руб.',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
                supressTooltip: true,
            },
        },
        {
            field: 'costTotalSub',
            colId: 'costTotalSub',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Общая стоимость (Расчет), рублей',
            headerTooltip: 'Общая стоимость (Расчет), рублей',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
                supressTooltip: true,
            },
        },
        {
            field: 'costTotalProgressSub',
            colId: 'costTotalProgressSub',
            width: 180,
            headerName: 'Общий прогресс по стоимости (Расчет)',
            headerTooltip: 'Общий прогресс по стоимости (Расчет)',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
            cellRendererParams: (params: ICellRendererParams) => {
                return {
                    TypographyProps: { ...applyStylesPercent(params.value) },
                    NumericFormatProps: {
                        ...applyStylesPercent(params.value),
                        suffix: ' %',
                    },
                    supressTooltip: true,
                };
            },
        },
        {
            headerName: 'Прогресс стоимости нарастающим итогом (Расчет), руб',
            colId: 'costProgressSub',
            children: [
                {
                    field: 'costProgressPlanSub',
                    colId: 'costProgressPlanSub',
                    width: 180,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: {
                        supressTooltip: true,
                    },
                },
                {
                    field: 'costProgressFactSub',
                    colId: 'costProgressFactSub',
                    width: 180,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: {
                        supressTooltip: true,
                    },
                },
                {
                    field: 'costProgressPercentSub',
                    colId: 'costProgressPercentSub',
                    width: 180,
                    headerName: 'Процент текущего выполнения',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: (params: ICellRendererParams) => {
                        return {
                            TypographyProps: { ...applyStylesPercent(params.value) },
                            NumericFormatProps: {
                                ...applyStylesPercent(params.value),
                                suffix: ' %',
                            },
                            supressTooltip: true,
                        };
                    },
                },
            ],
        },
        {
            field: 'costRemaining',
            colId: 'costRemaining',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Остаток стоимости, рублей',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
                supressTooltip: true,
            },
        },
        {
            field: 'verifiedFactTotal',
            colId: 'verifiedFactTotal',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Вер. факт нарастающим итогом',
            headerClass: 'ag-verFact',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
                supressTooltip: true,
            },
        },
        {
            field: '',
            width: 120,
            spanHeaderHeight: true,
            headerName: 'План/Факт',
            cellRenderer: (params: ICellRendererParams<WorkMsg, any, any>) => (
                <RowMonthHeaderRenderer
                    hasChildren={params.data?.hasChildren}
                    cellRendererParams={params}
                    isMSG={true}
                    isGant={hasParam('gant', location.search)}
                    customContainerClassName='month_container--msg-print'
                />
            ),
        },
        getHeadersMonthsMsg(workList?.data[0]?.dailyCharts, (cellRendererParams, chartIndex) => {
            if (!cellRendererParams.data || cellRendererParams.data.hasChildren) return <></>;
            return !window?.location?.search?.includes('gant') ? (
                <div className='month_container month_container--msg-print'>
                    <StyledCellWrapper isEditable={false}>
                        <NumberCellRenderer
                            suppressTooltip={true}
                            value={
                                cellRendererParams.api.getValue('dailyCharts', cellRendererParams.node)[chartIndex].plan
                            }
                        />
                    </StyledCellWrapper>
                    <StyledCellWrapper isEditable={false}>
                        <NumberCellRenderer
                            suppressTooltip={true}
                            value={
                                cellRendererParams.api.getValue('dailyCharts', cellRendererParams.node)[chartIndex].fact
                            }
                        />
                    </StyledCellWrapper>
                </div>
            ) : (
                <GantCell
                    supressEditRelationships
                    cellRendererParams={cellRendererParams}
                    chartIndex={chartIndex}
                />
            );
        }),
    ];
};
