import { Box } from "@mui/material";
import styled from "styled-components";
import { IPercentStatusValue } from "./PercentStatusValue.types";

export type TColors = "error" | "warning" | "default"

export const COLORS = {
    error: {
        color: "#F23C3C",
        backgroundColor: "rgba(242, 60, 60, 0.1)"
    },
    warning: {
        color: "#FE9B3F",
        backgroundColor: "rgba(254, 155, 63, 0.1)"
    },
    default: {
        color: "#5C87CE",
        backgroundColor: "rgba(92, 135, 206, 0.1)"
    }
}

export const PercentStatusValueWrapper = styled(Box)<Partial<IPercentStatusValue>>`
    padding: ${props => props.theme.spacing(0.5, 1)};
    width: 70px;
    ${props => ({
        ...COLORS[props.variant || 'default']
    })};
`