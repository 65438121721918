import { FaFilter } from '@react-icons/all-files/fa/FaFilter';

import { filtersNamesSmEnum, filterVarSom } from '@/pages/SufficiencyOfMaterials/SufficiencyOfMaterials.service';

import s from './CheckboxPopoverSufficiency.module.scss';

export default function CheckboxPopoverSufficiency({ name }: { name: filtersNamesSmEnum }) {
    return (
        <div className={s.main}>
            <div className={s.top}>
                <div className={s.filterTitle}>
                    <FaFilter color='#0044b4' />
                    <h4>{filterVarSom[name].title}</h4>
                </div>
            </div>

            <div className={name === 'dateDel' ? s.bottom_section : ''}>{filterVarSom[name].elem}</div>
            {filterVarSom[name].buttons}
        </div>
    );
}
