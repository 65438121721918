import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import { pingedTasksApi } from '@/api/pingedTasks/pingedTasks.api';

import { IActiveUploadTask, IDistributionTask, Nullable } from '@/shared/def';

import { RootState } from '../store';

type TRunningTask = 'main' | 'upload' | 'distribution' | 'none';

interface IInitialState {
    runningTask: TRunningTask;
    uploadTask: Nullable<IActiveUploadTask>;
    distributionTask: Nullable<IDistributionTask>;
}

const initialState: IInitialState = {
    runningTask: 'main',
    uploadTask: null,
    distributionTask: null,
};

const slice = createSlice({
    name: 'pingedTasks',
    initialState,
    reducers: {
        setUploadTask(state, action: PayloadAction<Nullable<IActiveUploadTask>>) {
            state.uploadTask = action.payload;

            if (action.payload) {
                state.runningTask = 'upload';
            } else {
                state.runningTask = 'main';
            }
        },
        setDistributionTask(state, action: PayloadAction<Nullable<IDistributionTask>>) {
            state.distributionTask = action.payload;

            if (action.payload) {
                state.runningTask = 'distribution';
            } else {
                state.runningTask = 'main';
            }
        },
        resetPingedTasks(state) {
            state.runningTask = 'main';
            state.uploadTask = null;
            state.distributionTask = null;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(pingedTasksApi.endpoints.getActiveTask.matchFulfilled, (state, action) => {
            if (action.payload.task) {
                state.runningTask = 'upload';
                state.uploadTask = action.payload.task;
            }

            if (action.payload.distributionTask) {
                state.runningTask = 'distribution';
                state.distributionTask = action.payload.distributionTask;
            }
        });
        builder.addMatcher(pingedTasksApi.endpoints.getUploadProgress.matchFulfilled, (state, action) => {
            if (isEqual(state.uploadTask, action.payload)) return;

            if (action.payload.result) {
                state.runningTask = 'main';
            }

            state.uploadTask = action.payload;
        });
        builder.addMatcher(pingedTasksApi.endpoints.getDistributeProgress.matchFulfilled, (state, action) => {
            if (isEqual(state.distributionTask, action.payload)) return;

            if (action.payload.result) {
                state.runningTask = 'main';
            }

            state.distributionTask = action.payload;
        });
    },
});

export const { setUploadTask, setDistributionTask, resetPingedTasks } = slice.actions;
export default slice.reducer;

export const runningTaskSelector = (state: RootState) => state.pingedTasks.runningTask;
export const uploadTaskSelector = (state: RootState) => state.pingedTasks.uploadTask;
export const distributionTaskSelector = (state: RootState) => state.pingedTasks.distributionTask;
