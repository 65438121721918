import { useMemo } from 'react';

import { filtersSelector } from '@/store/slices/filtersSlice';
import { useTypedSelector } from '@/store/store';

type TFilterTuple = [string, boolean];

// TODO:
// 1. Добавить в МСГ
const hasValues = (obj: object) => Object.values(obj).some((v) => v.length > 0);
const hasNonEmptyArray = (arr: any[]) => arr && arr.length > 0;

export const useGetAppliedFilters = () => {
    const { filters } = useTypedSelector(filtersSelector);

    const appliedFilters = useMemo(() => {
        const filterTuple: TFilterTuple[] = [
            ['workGroup', hasNonEmptyArray(filters.workGroupList) || filters.showEmptyWorkGroups],
            ['rdCode', hasNonEmptyArray(filters.rdCodes)],
            ['contractorCompany', hasNonEmptyArray(filters.contractors)],
            ['volumeTotal', filters.undistributedBaseVolume || filters.undistributedOperationalVolume],
            ['workName', hasValues(filters.workName)],
            ['objTitle', hasValues(filters.objTitle)],
            ['objName', hasValues(filters.objName)],
            ['rdStatus', hasValues(filters.rdStatus)],
        ];

        return filterTuple.filter(([_key, isApplied]) => isApplied).map(([key]) => key);
    }, [filters]);

    return appliedFilters;
};
