import { useCallback } from 'react';
import { useImmer } from 'use-immer';

import { useLatest } from '@/hooks/useLatest';

export type THiddenRow = {
    id: number;
    code: string;
};

export const useHiddenRows = () => {
    const [hiddenRows, setHiddenRows] = useImmer<THiddenRow[]>([]);

    const actualHiddenRows = useLatest(hiddenRows);
    const isRowHidden = (id: number) => {
        return Boolean(actualHiddenRows.current.find((item) => item.id === id));
    };
    const update = useCallback(
        (row: THiddenRow) => () => {
            setHiddenRows((draft) => {
                const index = draft.findIndex(({ id }) => id === row.id);
                if (index >= 0) {
                    draft.splice(index, 1);
                    return;
                }
                draft.push({ id: row?.id || 1, code: row?.code || '' });
            });
        },
        [setHiddenRows]
    );

    const setNewData = (data: THiddenRow[]) => {
        setHiddenRows(data);
    };

    const reset = () => {
        setHiddenRows([]);
    };

    return {
        hiddenRows,
        reset,
        isRowHidden,
        update,
        setNewData,
    };
};
