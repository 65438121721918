import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Button, Modal } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { GetRowIdParams, ICellRendererParams, IHeaderParams } from 'ag-grid-community'
import { HeaderSufficiency } from './components/HeaderSufficiency'
import { useParams } from 'react-router-dom'
import { useDownloadExcelMtoMutation, useGetWorksMtoMutation } from '../../api/works/works.api'
import { mtoItemI } from '../../api/works/works.def'
import CheckboxPopoverSufficiency from '../../components/CheckboxPopoverSufficiency/CheckboxPopoverSufficiency'
import s from './SufficiencyOfMaterials.module.scss'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Buffer } from 'buffer'
import { filtersNamesSmEnum } from './SufficiencyOfMaterials.service'
import { filtersSomSelector, setSomModal } from '../../store/slices/filtersSomSlice'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { format } from 'date-fns'
import { ExcelMtoIcon } from '../../shared/SVG/Svg'
import { modalStyles } from '../../shared/utils'
import WrapTextField from '../../shared/components/WrapTextField'

export default function SufficiencyOfMaterials() {
    const [name, setName] = useState<filtersNamesSmEnum>(filtersNamesSmEnum.nameMto)

    const { openSomModal, triggerSom, titlesArr, completion, delivered, dateDel } = useTypedSelector(filtersSomSelector)

    const gridRef = useRef<AgGridReact>(null)
    const { projectId, workId } = useParams()
    const dispatch = useAppDispatch()

    const [worksMtoReq, worksMtoRes] = useGetWorksMtoMutation()
    const [downloadExcelMto] = useDownloadExcelMtoMutation()

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation('mutations')

    const handleDownload = (file: string) => {
        const a = document.createElement('a')
        a.href = URL.createObjectURL(
            new Blob([Buffer.from(file, 'base64')], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
        )
        a.download = `mto_${projectId}_${workId}`
        a.click()
    }

    const downloadRequest = () => {
        downloadExcelMto({
            projectId,
            workId,
        })
            .unwrap()
            .then((res) => {
                handleDownload(res)
                enqueueSnackbar(t('download_mto'), {
                    variant: 'success',
                })
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                })
                console.error(e)
            })
    }

    useEffect(() => {
        worksMtoReq({
            projectId,
            workId,
            body: {
                completionPercent: {
                    min: completion.min ? Number(completion.min) : null,
                    max: completion.max ? Number(completion.max) : null,
                },
                dataPostavki: {
                    min: dateDel.min ? format(dateDel.min, 'dd.MM.yyyy') : null,
                    max: dateDel.max ? format(dateDel.max, 'dd.MM.yyyy') : null,
                },
                kolvoPostavlenoRd: {
                    min: delivered.min ? Number(delivered.min) : null,
                    max: delivered.max ? Number(delivered.max) : null,
                },
                naimenovanieMtrPoRd: titlesArr,
            },
        })
            .unwrap()
            .catch((e: any) => console.error(e))
    }, [projectId, workId, worksMtoReq, triggerSom])

    const getRowId = useCallback((params: GetRowIdParams) => {
        return params.data.id
    }, [])

    const columnDefs = [
        {
            field: 'id',
            width: 65,
            headerName: '№ п/п',
            cellRenderer: (data: ICellRendererParams<mtoItemI, any, any>) => {
                if (!data.data) return <></>
                return <div>{data.rowIndex + 1}</div>
            },
        },
        {
            field: 'pid',
            width: 160,
            headerName: 'PID',

            cellRenderer: (data: ICellRendererParams<mtoItemI, any, any>) => {
                if (!data.data || !data.column) return <></>
                return <WrapTextField actualWidth={data.column.getActualWidth()} value={data.value} />
            },
        },
        {
            field: 'titulRd',
            width: 160,
            headerName: 'Титул РД',

            cellRenderer: (data: ICellRendererParams<mtoItemI, any, any>) => {
                if (!data.data || !data.column) return <></>
                return <WrapTextField actualWidth={data.column.getActualWidth()} value={data.value} />
            },
        },
        {
            field: 'markaRd',
            width: 160,
            headerName: 'Марка РД',

            cellRenderer: (data: ICellRendererParams<mtoItemI, any, any>) => {
                if (!data.data || !data.column) return <></>
                return <WrapTextField actualWidth={data.column.getActualWidth()} value={data.value} />
            },
        },
        {
            field: 'shifrProekta',
            width: 160,
            headerName: 'Шифр проекта',

            cellRenderer: (data: ICellRendererParams<mtoItemI, any, any>) => {
                if (!data.data || !data.column) return <></>
                return <WrapTextField actualWidth={data.column.getActualWidth()} value={data.value} />
            },
        },
        {
            field: 'redakciyaSpecifikacii',
            width: 160,
            headerName: 'Номер редакции спецификации РД',

            cellRenderer: (data: ICellRendererParams<mtoItemI, any, any>) => {
                if (!data.data || !data.column) return <></>
                return <WrapTextField actualWidth={data.column.getActualWidth()} value={data.value} />
            },
        },
        {
            field: 'naimenovanieMtrPoRd',
            width: 160,
            headerName: 'Наименование МТР по РД',
            headerComponent: (data: IHeaderParams<mtoItemI, any>) => (
                <HeaderSufficiency
                    onFilterClick={() => {
                        dispatch(setSomModal(true))
                        setName(filtersNamesSmEnum.nameMto)
                    }}
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
            cellRenderer: (data: ICellRendererParams<mtoItemI, any, any>) => {
                if (!data.data || !data.column) return <></>
                return <WrapTextField actualWidth={data.column.getActualWidth()} value={data.value} />
            },
        },
        {
            field: 'markaTipGostOlMtr',
            width: 160,
            headerName: 'Марка/тип/ГОСТ/ОЛ МТР по РД',

            cellRenderer: (data: ICellRendererParams<mtoItemI, any, any>) => {
                if (!data.data || !data.column) return <></>
                return <WrapTextField actualWidth={data.column.getActualWidth()} value={data.value} />
            },
        },
        {
            field: 'edIzmPoRd',
            width: 160,
            headerName: 'Ед. изм. по РД',

            cellRenderer: (data: ICellRendererParams<mtoItemI, any, any>) => {
                if (!data.data || !data.column) return <></>
                return <WrapTextField actualWidth={data.column.getActualWidth()} value={data.value} />
            },
        },
        {
            field: 'dataVneseniyaPotrebnosti',
            width: 160,
            headerName: 'Дата внесения потребности',

            cellRenderer: (data: ICellRendererParams<mtoItemI, any, any>) => {
                if (!data.data || !data.column) return <></>
                return <WrapTextField actualWidth={data.column.getActualWidth()} value={data.value} />
            },
        },
        {
            field: 'kolvoVsegoPoRd',
            width: 160,
            headerName: 'Всего МТР (в ед. изм. по РД)',

            cellRenderer: (data: ICellRendererParams<mtoItemI, any, any>) => {
                if (!data.data || !data.column) return <></>
                return <WrapTextField actualWidth={data.column.getActualWidth()} value={data.value} />
            },
        },
        {
            field: 'direktivnayaDataPostavki',
            width: 160,
            headerName: 'Директивная дата поставки',
            cellRenderer: (data: ICellRendererParams<mtoItemI, any, any>) => {
                if (!data.data || !data.column) return <></>
                return <WrapTextField actualWidth={data.column.getActualWidth()} value={data.value} />
            },
        },
        {
            field: 'kolvoPostavlenoRd',
            width: 160,
            headerName: 'Поставлено МТР (в ед. изм. по РД)',
            headerComponent: (data: IHeaderParams<mtoItemI, any>) => (
                <HeaderSufficiency
                    onFilterClick={() => {
                        dispatch(setSomModal(true))
                        setName(filtersNamesSmEnum.delivered)
                    }}
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
            cellRenderer: (data: ICellRendererParams<mtoItemI, any, any>) => {
                if (!data.data || !data.column) return <></>
                return <WrapTextField actualWidth={data.column.getActualWidth()} value={data.value} />
            },
        },
        {
            field: 'dataPostavki',
            width: 160,
            headerName: 'Дата поставки',
            headerComponent: (data: IHeaderParams<mtoItemI, any>) => (
                <HeaderSufficiency
                    onFilterClick={() => {
                        dispatch(setSomModal(true))
                        setName(filtersNamesSmEnum.dateDel)
                    }}
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
            cellRenderer: (data: ICellRendererParams<mtoItemI, any, any>) => {
                if (!data.data || !data.column) return <></>
                return <WrapTextField actualWidth={data.column.getActualWidth()} value={data.value} />
            },
        },
        {
            field: 'orientirovochnayaDataPostavki',
            width: 160,
            headerName: 'Ориентировочная дата поставки',

            cellRenderer: (data: ICellRendererParams<mtoItemI, any, any>) => {
                if (!data.data || !data.column) return <></>
                return <WrapTextField actualWidth={data.column.getActualWidth()} value={data.value} />
            },
        },
        {
            field: 'completionPercent',
            width: 160,
            headerName: 'Обеспеченность в %',
            headerComponent: (data: IHeaderParams<mtoItemI, any>) => (
                <HeaderSufficiency
                    onFilterClick={() => {
                        dispatch(setSomModal(true))
                        setName(filtersNamesSmEnum.completion)
                    }}
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
            cellRenderer: (data: ICellRendererParams<mtoItemI, any, any>) => {
                if (!data.data || !data.column) return <></>
                return <WrapTextField actualWidth={data.column.getActualWidth()} value={data.value} />
            },
        },
        {
            field: 'completionFailDays',
            width: 160,
            headerName: 'Расчетный срыв поставки, дней',

            cellRenderer: (data: ICellRendererParams<mtoItemI, any, any>) => {
                if (!data.data || !data.column) return <></>
                return <WrapTextField actualWidth={data.column.getActualWidth()} value={data.value} />
            },
        },
    ]

    return (
        <div className={s.main}>
            <Modal open={openSomModal} onClose={() => dispatch(setSomModal(false))}>
                <Box sx={{ ...modalStyles, width: 350 }}>
                    <CheckboxPopoverSufficiency name={name} />
                </Box>
            </Modal>
            <div className={s.button_excel}>
                <Button onClick={downloadRequest} variant="outlined" endIcon={<ExcelMtoIcon />}>
                    Скачать Excel
                </Button>
            </div>
            <div
                style={{
                    overflow: 'auto',
                }}
            >
                <div className="ag-theme-alpine" style={{ height: '100%', width: 2640 }}>
                    <AgGridReact
                        ref={gridRef}
                        getRowId={getRowId}
                        rowHeight={64}
                        headerHeight={80}
                        rowData={worksMtoRes.data?.data}
                        columnDefs={columnDefs}
                        overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Загружается</span>'}
                        overlayNoRowsTemplate={'<span style="padding: 10px; border: 2px solid #444;">Нет данных</span>'}
                    ></AgGridReact>
                </div>
            </div>
        </div>
    )
}
