import { Typography } from '@mui/material';
import styled from 'styled-components';

export const Rect = styled('div')<{ border?: string; backgroundColor: string }>`
    border-radius: 16px;
    width: 32px;
    height: 16px;
    background-color: ${(props) => props.backgroundColor};
    border: ${(props) => props.border ?? 'none'};
`;

export const Text = styled(Typography)`
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #2b3648;
`;
