import { IconButton } from '@mui/material'
import { LockOpenOutlined, LockOutlined } from '@mui/icons-material'

export const OnClickLock = ({ onClick, clicked }: { onClick: (bool: boolean) => void; clicked: boolean }) => {
    const styles = {
        fill: '#0044b4',
    }
    return (
        <IconButton
            color="primary"
            component="span"
            onClick={() => {
                if (clicked) {
                    onClick(false)
                } else {
                    onClick(true)
                }
            }}
        >
            {clicked ? <LockOutlined sx={styles} /> : <LockOpenOutlined sx={styles} />}
        </IconButton>
    )
}
