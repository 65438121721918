import { useSearchParams } from 'react-router-dom'

export const useToggleSearchParam = (name: string, value = 'on') => {
    const [searchParams, setSearchParams] = useSearchParams()

    const isActive = searchParams.has(name)
    const handleToggle = () => {
        setSearchParams((searchParams) => {
            if (searchParams.has(name)) {
                searchParams.delete(name)
                return searchParams
            }

            searchParams.set(name, value)
            return searchParams
        })
    }

    return {
        searchParams,
        isActive,
        onToggle: handleToggle,
    }
}
