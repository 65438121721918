import s from '../../../pages/Projects/component/ProjectsDrawer.module.scss'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Button, Typography } from '@mui/material'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { Dispatch, SetStateAction } from 'react'
import { FlexColumnWrapper } from '../../../pages/NewExecutorView/components/components.styles'

export default function UploadAgain({
    length,
    setShowUploadAgain,
    setShowErrorsList,
    onUploadAgain,
    onCloseErrorList: onShowErrorList
}: {
    length: number | undefined
    setShowUploadAgain: Dispatch<SetStateAction<boolean>>
    setShowErrorsList: Dispatch<SetStateAction<boolean>>
    onUploadAgain?: () => void
    onCloseErrorList?: () => void
}) {
    return (
        <FlexColumnWrapper className={s.awd_loader_error} gap={1}>
            <WarningAmberIcon
                sx={{
                    color: '#f46b6b',
                    fontSize: '3rem',
                }}
            />
            <Typography variant='body2'>
                Произошла ошибка при загрузке файла! <br /> Всего строк с ошибками {length}
            </Typography>

            <Button onClick={() => onShowErrorList ? onShowErrorList() : setShowErrorsList(true)} color="error">
                посмотреть ошибки
            </Button>

            <Button
                onClick={() => onUploadAgain ? onUploadAgain() : setShowUploadAgain((prev) => false)}
                sx={{ backgroundColor: '#fff' }}
                startIcon={<AutorenewIcon />}
            >
                ЗАГРУЗИТЬ ЕЩЁ РАЗ
            </Button>
        </FlexColumnWrapper>
    )
}
