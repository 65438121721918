import { SvgIconProps } from '@mui/material'
import React from 'react'

const PlaneSVG: React.FC<SvgIconProps> = () => {
    return (
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.669837 7L7.6665 4L0.669837 1L0.666504 3.33333L5.6665 4L0.666504 4.66667L0.669837 7Z"
                fill="#2B3648"
            />
        </svg>
    )
}

export default PlaneSVG
