export const uploadSelectStyles = {
    backgroundColor: '#F6F7FB',
    width: '11rem',
    '.MuiSelect-select': {
        padding: '0.3rem',
        color: '#0044B4',
        fontSize: '14px',
        fontWeight: 500,
        textAlign: 'right',
    },
}

export const uploadVariants = ['Стандартный КСГ', '1С Шаблон', 'Интеграция с "Ценообразование"'] as const

export type uploadType = typeof uploadVariants[number]

/* Имена отправляемых свойств для стандартного КСГ при обновлении */
export const updateNamesBase: Array<keyof typeof initialsParams> = [
    'baseCalcByDate',
    'baseCalcByDateNewOnly',
    'factUpdateNewOnly',
    'factUpdateNot',
    'planCalcByDate',
    'planCalcByDateNewOnly',
]

/* Имена отправляемых свойств для 1С шаблона при обновлении */
export const updateNames1S: Array<keyof typeof initialsParams> = [
    'baseCalcByDate',
    'baseCalcByDateNewOnly',
    'factUpdateNewOnly',
    'factUpdateNot',
]

/* Имена отправляемых свойств для стандартного КСГ при загрузке */
export const uploadNamesBase: Array<keyof typeof initialsParams> = ['baseCalcByDate', 'factUpdateNot', 'planCalcByDate']

/* Имена отправляемых свойств для 1С шаблона при загрузке */
export const uploadNames1S: Array<keyof typeof initialsParams> = ['baseCalcByDate', 'factUpdateNot']

/* Подсказки для тултипа чекбоксов */
export const helpText = {
    baseCalcByDate: <p>Если параметр включен, базовый план пересчитывается для всех работ.</p>,
    baseCalcByDateNewOnly: (
        <p>
            По умолчанию с базовым планом никаких действий не производится.
            <br />
            Если параметр включен, базовый план пересчитывается для новых работ.
        </p>
    ),
    factUpdateNewOnly: (
        <p>
            По умолчанию извлекаются все данные из таймлайна для всех задач.
            <br />
            Если параметр включен, извлекается факт для новых работ.
        </p>
    ),
    factUpdateNot: <p>Если параметр включен, факт из таймлайна не будет извлечен.</p>,
    planCalcByDate: <p>Если параметр включен, то оперативный план в таймлайне пересчитывается для всех работ.</p>,
    planCalcByDateNewOnly: (
        <p>
            По умолчанию, используется оперативный план, взятый из таймлайна, чтобы сохранить текущую конфигурацию.
            <br />
            Если параметр включен, план будет пересчитываться только для новых задач, то есть для тех, у которых
            отсутствует уникальный идентификатор или не найдено соответствие в базе данных по ID.
        </p>
    ),
}

// ===========================================

/* Начальное состояние чекбоксов */
export const initialsParams = {
    baseCalcByDate: false,
    baseCalcByDateNewOnly: false,
    planCalcByDate: false,
    planCalcByDateNewOnly: false,
    factUpdateNewOnly: false,
    factUpdateNot: false,
    keepBaseDates: false,
    keepWorkGroup: false,
    keepMainGroup: false,
    keepCostDone: false
}

/* Интерфейс оболочки чекбоксов */
export interface ICheckParams {
    activeType: TTemplateType
    checkFn: (param: ICheckboxSettings) => void
    mode: 'upload' | 'update'
    state: typeof initialsParams
    disabled?: boolean
}

export type TWorkType = 'new' | 'old'

export type TTemplateType = '1C' | 'Standart'

export type TUploadType = 'upload' | 'update'

export type TCheckboxName =
    | 'baseCalcByDate'
    | 'baseCalcByDateNewOnly'
    | 'factUpdateNewOnly'
    | 'keepBaseDates'
    | 'keepWorkGroup'
    | 'keepMainGroup'
    | 'keepCostDone'
    | 'factUpdateNot'
    | 'planCalcByDate'
    | 'planCalcByDateNewOnly'

export type ICheckboxSettings = {
    name: TCheckboxName
    title: string
    templateTypes: TTemplateType[]
    uploadTypes: TUploadType[]
    helpText: React.ReactNode
    initialState: boolean
    workType: TWorkType
    contradactoryField?: TCheckboxName
}

export const checkboxSettings: ICheckboxSettings[] = [
    {
        name: 'baseCalcByDate',
        title: 'Пересчитать базовый план от дат',
        templateTypes: ['1C', 'Standart'],
        uploadTypes: ['upload', 'update'],
        helpText: <p>Если параметр включен, базовый план пересчитывается для всех работ.</p>,
        initialState: false,
        workType: 'old',
        contradactoryField: 'baseCalcByDateNewOnly',
    },
    {
        name: 'factUpdateNot',
        title: 'Не обновлять факт',
        templateTypes: ['1C', 'Standart'],
        uploadTypes: ['upload', 'update'],
        helpText: <p>Если параметр включен, факт из таймлайна не будет извлечен.</p>,
        initialState: false,
        workType: 'old',
        contradactoryField: 'factUpdateNewOnly',
    },
    {
        name: 'planCalcByDate',
        title: 'Пересчитать оперативный план от дат',
        templateTypes: ['1C', 'Standart'],
        uploadTypes: ['upload', 'update'],
        helpText: <p>Если параметр включен, то оперативный план в таймлайне пересчитывается для всех работ.</p>,
        initialState: false,
        workType: 'old',
        contradactoryField: 'planCalcByDateNewOnly',
    },
    {
        name: 'keepBaseDates',
        title: 'Сохранить базовый план',
        templateTypes: ['1C', 'Standart'],
        uploadTypes: ['update'],
        helpText: null,
        initialState: false,
        workType: 'old',
    },
    {
        name: 'keepWorkGroup',
        title: 'Сохранить группу работ',
        templateTypes: ['1C', 'Standart'],
        uploadTypes: ['update'],
        helpText: null,
        initialState: false,
        workType: 'old',
    },
    {
        name: 'keepMainGroup',
        title: 'Сохранить ключевой объем',
        templateTypes: ['1C', 'Standart'],
        uploadTypes: ['update'],
        helpText: null,
        initialState: false,
        workType: 'old',
    },
    {
        name: 'keepCostDone',
        title: 'Сохранить закрыто по КС2',
        templateTypes: ['1C', 'Standart'],
        uploadTypes: ['update'],
        helpText: null,
        initialState: false,
        workType: 'old',
    },
    {
        name: 'baseCalcByDateNewOnly',
        title: 'Пересчитать базовый план от дат',
        templateTypes: ['1C', 'Standart'],
        uploadTypes: ['update'],
        helpText: (
            <p>
                По умолчанию с базовым планом никаких действий не производится.
                <br />
                Если параметр включен, базовый план пересчитывается для новых работ.
            </p>
        ),
        initialState: false,
        workType: 'new',
        contradactoryField: 'baseCalcByDate',
    },
    {
        name: 'factUpdateNewOnly',
        title: 'Обновить факт',
        templateTypes: ['1C', 'Standart'],
        uploadTypes: ['update'],
        helpText: (
            <p>
                По умолчанию извлекаются все данные из таймлайна для всех задач.
                <br />
                Если параметр включен, извлекается факт для новых работ.
            </p>
        ),
        initialState: false,
        workType: 'new',
        contradactoryField: 'factUpdateNot',
    },
    {
        name: 'planCalcByDateNewOnly',
        title: 'Пересчитать оперативный план от дат',
        templateTypes: ['1C', 'Standart'],
        uploadTypes: ['update'],
        helpText: (
            <p>
                По умолчанию, используется оперативный план, взятый из таймлайна, чтобы сохранить текущую конфигурацию.
                <br />
                Если параметр включен, план будет пересчитываться только для новых задач, то есть для тех, у которых
                отсутствует уникальный идентификатор или не найдено соответствие в базе данных по ID.
            </p>
        ),
        initialState: false,
        workType: 'new',
        contradactoryField: 'planCalcByDate',
    },
]
