import { ColDef, ICellRendererParams, ICellEditorParams } from 'ag-grid-community'
import { Typography } from '@mui/material'
import { COL_DEF_TEMPLATE } from '../../WorkManagment/components/AgGridService/AgGridColumnDef.service'
import { NumberCellRenderer } from '../../WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer'
import { IWorkStaffResourceFactDataItem } from '../api/api.types'

export interface IWorkStaffResourceFactTableColDef extends ColDef {
    field?: keyof IWorkStaffResourceFactDataItem
    cellRenderer?: (params: ICellRendererParams<IWorkStaffResourceFactDataItem, number | string, any>) => React.ReactNode
    cellEditor?: ((params: ICellEditorParams<IWorkStaffResourceFactDataItem, number | string, any>) => React.ReactNode) | string
    children?: ColDef<IWorkStaffResourceFactDataItem>[]
}

export const SNZStaffTableColDef: IWorkStaffResourceFactTableColDef[] = [
    // {
    //     headerName: 'Тип',
    //     field: 'nameType',
    //     headerClass: 'ag-cell-left',
    //     cellClass: 'ag-cell-left',
    //     maxWidth: 200,
    // },
    {
        headerName: 'Наименование',
        field: 'nameProf',
        headerClass: 'ag-cell-left',
        cellClass: 'ag-cell-left',
        initialSort: 'asc',
    },
    {
        headerName: 'Факт',
        field: 'fact',
        maxWidth: 200,
        editable: true,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('fact'),
        cellRenderer: (params) => {
            return params.data?.fact == null ? (
                <Typography variant="tooltip" color={'primary'}>
                    Введите факт
                </Typography>
            ) : (
                <NumberCellRenderer {...params} />
            )
        },
    },
]
