import { useParams } from 'react-router-dom'
import { StyledAgGrid } from '../../../../../components/AgGridService/AgGrid.styles'
import {
    AG_GRID_DEFAULT_PARAMS,
    disableAddAndDelete,
} from '../../../../../components/AgGridService/AgGridColumnDef.service'
import { TOTAL_ESTIMATE_RESOURCE_ROW, estimateResourceColDef } from './EstimateResourceTable.colDef'
import { EstimateAPI } from '../../../../../api/EstimateAPI'
import { IEstimateResourcesTableProps } from './EstimateResourceTable.types'
import { IEstimateResource } from '../EstimateResourcesPage.types'
import { withRecalculatedTotalRow } from '../../../../../../../shared/components/AgGrid/AgGrid.service'
import { CellValueChangedEvent, GetRowIdParams } from 'ag-grid-community'
import { ITotalRowModel, ITotalRow } from '../../../../../../../shared/components/AgGrid/AgGrid.def'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../../../../../../shared/rolePermissions'
import { profileSelector } from '../../../../../../../store/slices/profileSlice'
import { useTypedSelector } from '../../../../../../../store/store'

export function EstimateResourceTable({ rowData }: IEstimateResourcesTableProps) {
    const { projectId, workID } = useParams()
    const { profile } = useTypedSelector(profileSelector)

    return (
        <StyledAgGrid
            rowData={rowData}
            columnDefs={estimateResourceColDef}
            context={{
                estimateType: 'resources',
                projectID: projectId,
                workID: workID,
            }}
            {...AG_GRID_DEFAULT_PARAMS()}
            groupHeaderHeight={30}
            headerHeight={30}
            getRowId={(params: GetRowIdParams) => params.data?.id || ''}
            onCellValueChanged={(event: CellValueChangedEvent) => {
                EstimateAPI.updateItem({
                    estimateType: event.context.estimateType,
                    projectID: event.context.projectID,
                    workID: event.context.workID,
                    estimateItemID: event.data.id,
                    body: { ...event.data, type: event.data.type?.label || event.data.type },
                })
            }}
            {...withRecalculatedTotalRow(
                TOTAL_ESTIMATE_RESOURCE_ROW as ITotalRow<IEstimateResource>,
                [
                    { field: 'costBaseTotal', totalFunc: 'sum' },
                    { field: 'costBaseTotalVolume', totalFunc: 'sum' },
                    { field: 'costCurrTotal', totalFunc: 'sum' },
                    { field: 'costCurrTotalVolume', totalFunc: 'sum' },
                ] as ITotalRowModel<IEstimateResource>[],
                'name'
            )}
            {...((!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_WORK_MANAGMENT') ||
                JSON.parse(localStorage.getItem('work')!)?.hasChildren) && {
                suppressClickEdit: true,
                ...disableAddAndDelete(true),
            })}
        />
    )
}

// !!! previos version with total data on estimete resources page

// const fieldsExcludedFromOverallRecalculate = ["name", "unit"] as IEstimateResourceColDef["field"][]

// .then(() => {
//     !fieldsExcludedFromOverallRecalculate.includes(event.colDef.field as IEstimateResourceColDef["field"]) &&
//     setOverallData(prevValue => {
//         if (prevValue) {
//             return recalculateOverallResources(
//                 event,
//                 prevValue,
//                 getResourceType(event.data),
//                 event.colDef.field as keyof IEstimateResourceOverall
//             )
//         }
//     })
// })
