import { Tooltip } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'
import { Work } from '../../../../api/ksg/ksg.def'
import { onEditAgKsg } from '../../../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import { useAppDispatch } from '../../../../store/store'
import { req } from '../../../WorkManagment/api/api'
import { IMassVolumeDistributionMenuProps, TMassVolumeDistributionType } from './MassVolumeDistributionMenu.def'
import {
    Container,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Title,
    TitleContainer,
} from './MassVolumeDistributionMenu.styles'

export const MassVolumeDistributionMenu: React.FC<IMassVolumeDistributionMenuProps> = ({
    workId,
    currentAct,
    preparedAct,
    anchor,
    onClose,
    refetchCollectionOfFactOfAct,
}) => {
    const dispatch = useAppDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { projectId } = useParams()

    const isDistributionButtonDisabled = Boolean(preparedAct) && !preparedAct?.toClose

    const handleMassVolumeDistribution = (type: TMassVolumeDistributionType) => async () => {
        try {
            const { data } = await req.post(`/projects/works/act/mass-execution-distribution`, {
                actId: currentAct,
                mainWorkId: workId,
                // percent: 0,
                projectId,
                type,
            })

            if (!data?.success) {
                enqueueSnackbar('Объем нельзя распределить, все данные были заполнены', {
                    variant: 'warning',
                })
                return
            }

            const updatedData = data.data as Work[]
            dispatch(onEditAgKsg(updatedData))
            enqueueSnackbar('Массовое распределение объемов прошло успешно', {
                variant: 'success',
            })
            refetchCollectionOfFactOfAct()
        } catch (error) {
            enqueueSnackbar('Возникла ошибка при массовом распределении объемов', {
                variant: 'error',
            })
        } finally {
            onClose()
        }
    }

    return (
        <Container anchorEl={anchor} open={Boolean(anchor)} onClose={onClose}>
            <TitleContainer>
                <Title>Массовое распределение объемов</Title>
            </TitleContainer>
            <List>
                <ListItem>
                    <ListItemButton onClick={handleMassVolumeDistribution('MAXVOLUME')}>
                        <ListItemText fontSize={8}> = max</ListItemText>
                        <ListItemText>максимально возможный объём</ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <Tooltip
                        placement="right"
                        title={
                            isDistributionButtonDisabled
                                ? 'Поле «Выполнено на конец отчётного периода» должно быть заполнено'
                                : ''
                        }
                    >
                        <ListItemButton
                            disabled={isDistributionButtonDisabled}
                            onClick={handleMassVolumeDistribution('DISTRIBUTION')}
                        >
                            <ListItemText fontSize={8}> = %%%</ListItemText>
                            <ListItemText>Распределить согласно основной работе</ListItemText>
                        </ListItemButton>
                    </Tooltip>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={handleMassVolumeDistribution('ZEROVOLUME')}>
                        <ListItemText fontSize={8}> = ZERO</ListItemText>
                        <ListItemText>Внести 0 вместо пустых значений</ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
        </Container>
    )
}
