import styled from "styled-components";
import { Stack } from "@mui/material";

export const UserManagementWrapper = styled(Stack)`
    margin-top: ${(props) => props.theme.spacing(1.25)};
    background-color: ${(props) => props.theme.palette.bg.white};
    border-radius: 8px;
    overflow: auto;
    height: calc(100vh - 90px);
    margin: 0 0.5rem;
`;
