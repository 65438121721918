import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

type minMaxType = {
    min: string
    max: string
}

interface initialStateI {
    titlesArr: string[]
    completion: minMaxType
    delivered: minMaxType
    dateDel: { min: Date | null; max: Date | null }
    openSomModal: boolean
    triggerSom: boolean
}

const minMaxDate = {
    min: null,
    max: null,
}

const minMax = {
    min: '',
    max: '',
}

const initialState: initialStateI = {
    titlesArr: [],
    completion: { ...minMax },
    delivered: { ...minMax },
    dateDel: { ...minMaxDate },
    openSomModal: false,
    triggerSom: false,
}

const slice = createSlice({
    name: 'filtersSomSlice',
    initialState,
    reducers: {
        setTextFiltersSom(state, action: PayloadAction<{ title: string; checked: boolean }>) {
            const { checked, title } = action.payload
            if (checked) {
                state.titlesArr.push(title)
            } else {
                const itemIndex = state.titlesArr.indexOf(title)
                if (itemIndex > -1) state.titlesArr.splice(itemIndex, 1)
            }
        },

        setMinCompletion(state, action: PayloadAction<string>) {
            state.completion.min = action.payload
        },

        setMaxCompletion(state, action: PayloadAction<string>) {
            state.completion.max = action.payload
        },

        setMinDelivered(state, action: PayloadAction<string>) {
            state.delivered.min = action.payload
        },

        setMaxDelivered(state, action: PayloadAction<string>) {
            state.delivered.max = action.payload
        },

        setMinDateDel(state, action: PayloadAction<Date | null>) {
            state.dateDel.min = action.payload
        },

        setMaxDateDel(state, action: PayloadAction<Date | null>) {
            state.dateDel.max = action.payload
        },

        resetTextFiltersSom(state) {
            state.titlesArr = []
        },

        resetCompletion(state) {
            state.completion = { ...minMax }
        },

        resetDelivered(state) {
            state.delivered = { ...minMax }
        },

        resetDateDel(state) {
            state.dateDel = { ...minMaxDate }
        },

        setSomModal(state, action: PayloadAction<boolean>) {
            state.openSomModal = action.payload
        },

        toggleTriggerSom(state) {
            state.triggerSom = !state.triggerSom
        },
    },
})

export const {
    setTextFiltersSom,
    setMinCompletion,
    setMaxCompletion,
    setMinDelivered,
    setMaxDelivered,
    setMinDateDel,
    setMaxDateDel,
    resetTextFiltersSom,
    resetCompletion,
    resetDelivered,
    resetDateDel,
    setSomModal,
    toggleTriggerSom,
} = slice.actions
export default slice.reducer

export const filtersSomSelector = (state: RootState) => state.filters_som
