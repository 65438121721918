import { OverflowableTypographyWithTooltip } from '../../../../components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip'
import { getColorByVolumeDoneDeviation } from '../../WorksSubPage/WorksSubPage.service'
import { IWorkFactData } from '../../api/api.types'
import { FlexRowWrapper, WorkDetailLabel, WorkDetailNumberFormat } from '../components.styles'

interface IWorkDetailsProps {
    work: IWorkFactData
}

export function WorkDetails({ work }: IWorkDetailsProps) {
    return (
        <FlexRowWrapper width={'auto'} gap={1} flexWrap={'wrap'}>
            <WorkDetailLabel>
                сег:
                <WorkDetailNumberFormat value={work?.dailyChart.plan || '-'} />
            </WorkDetailLabel>
            <WorkDetailLabel>
                на мес:
                <WorkDetailNumberFormat value={work?.planMonth || '-'} />
            </WorkDetailLabel>
            <WorkDetailLabel>
                прогноз:
                <WorkDetailNumberFormat value={work?.dailyChart.forecast || '-'} />
            </WorkDetailLabel>
            <FlexRowWrapper gap={0.25}>
                <WorkDetailLabel sx={{ display: 'inline-flex !important', minWidth: '60px' }}>ед. изм:</WorkDetailLabel>
                <OverflowableTypographyWithTooltip maxRows={1}>
                    <WorkDetailLabel>{((' ' + work?.unit) as string) || '-'}</WorkDetailLabel>
                </OverflowableTypographyWithTooltip>
            </FlexRowWrapper>
            <WorkDetailLabel>
                отклонение:
                <WorkDetailNumberFormat
                    colorValue={getColorByVolumeDoneDeviation(work?.volumeDoneDeviation)}
                    value={Math.abs(work?.volumeDoneDeviation!) ?? '-'}
                />
            </WorkDetailLabel>
        </FlexRowWrapper>
    )
}
