import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface initialStateI {
    total: number
    page: number
    rowsPerPage: string
}

const initialState: initialStateI = {
    total: 0,
    page: 1,
    rowsPerPage: '100',
}

const slice = createSlice({
    name: 'pagination',
    initialState,
    reducers: {
        setTotal(state, action: PayloadAction<number | undefined>) {
            if (action.payload) {
                state.total = action.payload
            } else {
                state.total = 0
            }
        },
        setPage(state, action: PayloadAction<number>) {
            state.page = action.payload
        },
        setRowsPerPage(state, action: PayloadAction<string>) {
            state.rowsPerPage = action.payload
        },
    },
})

export const { setTotal, setPage, setRowsPerPage } = slice.actions
export default slice.reducer

export const paginationSelector = (state: RootState) => state.pagination
