import { ICellRendererParams } from 'ag-grid-community';
import { useParams } from 'react-router-dom';

import { Work } from '@/api/ksg/ksg.def';

import { TAgGridContext } from '@/pages/KSGTable';

import { IActiveUploadTask, IDistributionTask, Nullable } from '@/shared/def';

import { setDistributionTask } from '@/store/slices/pingedTasksSlice';
import { useAppDispatch } from '@/store/store';

import { req } from '../../../WorkManagment/api/api';
import { NotificatorContainer } from '../NotificatorContainer/NotificatorContainer';
import { SpreadPlanBtn } from '../SpreadPlanBtn/SpreadPlanBtn';

export interface IProps extends ICellRendererParams<Work, any, TAgGridContext> {
    textValue: string;
    canSpread: boolean;
    isVisibleNotificator: boolean;
    notificatorTitle?: NonNullable<React.ReactNode>;
    notificator?: React.ReactNode;
    typeOfPlan: 'base' | 'operational';
}

type TAsyncSpreadPlanResponse = {
    existingDistributionTask: Nullable<IDistributionTask>;
    existingUploadTask: Nullable<IActiveUploadTask>;
    myDistributionTask: Nullable<IDistributionTask>;
};

export const DateCellRenderer: React.FC<IProps> = ({
    textValue,
    isVisibleNotificator,
    canSpread,
    typeOfPlan,
    notificatorTitle,
    notificator,
    ...params
}) => {
    const { projectId } = useParams();
    const data = params?.data;
    const dispatch = useAppDispatch();

    const isVisibleSpreadBtn = () => {
        if (!canSpread) return false;
        if (data?.hasChildren && params?.data?.level === 0) return true;
        if (data?.hasChildren === false) return true;
        return false;
    };

    const getUrlByTypeOfPlan = (typeOfPlan: 'base' | 'operational') => {
        const map = {
            base: 'base',
            operational: 'plan-fact',
        };

        return `/projects/${projectId}/works/ksg/distribute/${map[typeOfPlan]}`;
    };

    const spreadPlan = () => {
        const url = getUrlByTypeOfPlan(typeOfPlan);

        if (!url) return;

        req.post(url)
            .then(({ data }) => {
                const task = (data as TAsyncSpreadPlanResponse).myDistributionTask;

                dispatch(setDistributionTask(task));
            })
            .catch((e) => {});
    };

    const createAsyncSpreadPlan = () => {
        if (params?.data?.level === 0) {
            return spreadPlan;
        }

        return null;
    };

    return (
        <>
            <NotificatorContainer
                isVisible={isVisibleNotificator}
                title={notificatorTitle!}
                notificator={notificator}
            >
                {textValue}
            </NotificatorContainer>

            {isVisibleSpreadBtn() && (
                <SpreadPlanBtn
                    typeOfPlan={typeOfPlan}
                    cellRendererParams={params}
                    spreadPlanFunc={createAsyncSpreadPlan()}
                />
            )}
        </>
    );
};
