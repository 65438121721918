/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { queryTermsType } from '../../../../api/filters/filters.api'
import { Work } from '../../../../api/ksg/ksg.def'
import { deployLinks } from '../../../../shared/config/config'
import { IActiveUploadTaskShortly } from '../../../../shared/def'
import { axiosRefetchToken } from '../../../../shared/utils'
import { RootState } from '../../../store'
import { filtersKsgUnlimitedI, levelsUnlimitedI } from '../../filtersSlice'
export interface IGetParamsKSG {
    id: number
    limit?: number
    offset?: number
}
export const getWorksAgGrid = createAsyncThunk<
    { data: Work[]; total: number },
    IGetParamsKSG,
    {
        state: RootState
    }
>('ag_grid_ksg/worksList', async (paramsData, { getState, rejectWithValue }) => {
    try {
        const { id, ...params } = paramsData
        const { data } = await axios<{ data: Work[]; total: number; activeUpload: null | IActiveUploadTaskShortly }>({
            method: 'POST',
            url: deployLinks.server + `/projects/${id}/works/cpg-list-next-generation`,
            headers: {
                Authorization: `Bearer ${getState().auth.token}`,
            },
            params,
            data: { filter: getState().project.headerSearch.toLowerCase(), ...getState().filters.filters },
        })
        let returnPayload = { ...data }
        if (params.offset !== 0) {
            const stateData = getState().ag_grid_ksg.ksg.worksList
            returnPayload = {
                ...returnPayload,
                data: [...(stateData ? stateData.data : []), ...data.data],
            }
        }
        return returnPayload
    } catch (e: any) {
        console.error(e)
        axiosRefetchToken(e?.response?.status)
        return rejectWithValue(e)
    }
})

export const getOrphanFiltersPayload = (
    filters: filtersKsgUnlimitedI,
    filtersListUnlimited: Record<queryTermsType, levelsUnlimitedI>
) => {
    const keys: queryTermsType[] = [
        'objTitle',
        'workName',
        'rdStatus',
        'workGroup',
        'objName',
        'rdCode',
        'contractorCompany',
    ]

    const maxLevelByQueryTerm: Record<queryTermsType, string | null> = keys.reduce(
        (acc, key) => ({ ...acc, [key]: null }),
        {} as Record<queryTermsType, string | null>
    )

    keys.forEach((key) => {
        for (const [level, levelValue] of Object.entries(filters[key])) {
            if (levelValue.length > 0) {
                maxLevelByQueryTerm[key] = level
            }
        }
    })

    const orphanFiltersPayload = keys.reduce(
        (acc, key) => {
            if (!maxLevelByQueryTerm[key]) return acc

            let result = {
                ...acc,
            }
            const maxLevel = maxLevelByQueryTerm[key]

            for (const level of Object.keys(acc[key])) {
                if (Number(level) < Number(maxLevel)) {
                    result = {
                        ...result,
                        [key]: {
                            ...result[key],
                            [level]: [],
                        },
                    }
                }

                if (Number(level) > Number(maxLevel)) {
                    const newLevelValue = filtersListUnlimited[key][level as unknown as keyof levelsUnlimitedI]

                    result = {
                        ...result,
                        [key]: {
                            ...result[key],
                            [level]: newLevelValue,
                        },
                    }
                }
            }

            return result
        },
        { ...filters }
    )

    return orphanFiltersPayload
}

export const getWorksAgGridOrphan = createAsyncThunk<
    { data: Work[]; total: number },
    IGetParamsKSG,
    {
        state: RootState
    }
>('ag_grid_ksg/worksListOrphan', async (paramsData, { getState, rejectWithValue }) => {
    try {
        const { id, ...params } = paramsData

        const { filters, filtersListUnlimited } = getState().filters

        const { data } = await axios<{ data: Work[]; total: number; activeUpload: null | IActiveUploadTaskShortly }>({
            method: 'POST',
            url: deployLinks.server + `/projects/${id}/works/cpg-orphan`,
            headers: {
                Authorization: `Bearer ${getState().auth.token}`,
            },
            params,
            data: {
                filter: getState().project.headerSearch.toLowerCase(),
                ...getOrphanFiltersPayload(filters, filtersListUnlimited),
            },
        })
        let returnPayload = { ...data }
        if (params.offset !== 0) {
            const stateData = getState().ag_grid_ksg.ksg.worksList
            returnPayload = {
                ...returnPayload,
                data: [...(stateData ? stateData.data : []), ...data.data],
            }
        }
        return returnPayload
    } catch (e: any) {
        console.error(e)
        axiosRefetchToken(e?.response?.status)
        return rejectWithValue(e)
    }
})
