import { Button } from '@mui/material'
import s from '../NotificationsCenter.module.scss'

type TProps = {
    fontSize?: string
    border?: string
    padding?: string
    increaseVolume: () => void
    increasePeriod: () => void
    disabledVolume?: boolean
    disabledPeriod?: boolean
}

export const ButtonsNotificationCenter: React.FC<TProps> = ({
    fontSize = '13px',
    border = '1px solid #0044B4',
    padding = '6px',
    increaseVolume,
    increasePeriod,
    disabledVolume,
    disabledPeriod,
}) => {
    const sxStyles = {
        fontSize,
        border,
        color: '#0044B4',
        backgroundColor: '#edf4fb',
        padding,
        boxShadow: 'unset',
        '&:hover': {
            backgroundColor: '#dbeafe',
        },
    }

    return (
        <div className={s.notifications_buttons}>
            <Button disabled={disabledVolume} onClick={increaseVolume} sx={sxStyles} variant="contained">
                Увеличить выработку
            </Button>
            <div></div>
            <Button disabled={disabledPeriod} onClick={increasePeriod} sx={sxStyles} variant="contained">
                Увеличить срок
            </Button>
        </div>
    )
}
