/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { WorkMsg } from '../../../../api/msg/msg.def'
import { deployLinks } from '../../../../shared/config/config'
import { axiosRefetchToken } from '../../../../shared/utils'
import { RootState } from '../../../store'
import { getOrphanFiltersPayload } from '../ksgThunks'

export interface IGetParamsMSG {
    id: number
    limit?: number
    offset?: number
    year: any
    month: any
}
export const getWorksAgGrid = createAsyncThunk<
    { data: WorkMsg[]; total: number },
    IGetParamsMSG,
    {
        state: RootState
    }
>('ag_grid_msg/worksList', async (paramsData, { getState, rejectWithValue }) => {
    try {
        const { id, ...params } = paramsData
        const { data } = await axios<{ data: WorkMsg[]; total: number }>({
            method: 'POST',
            url: deployLinks.server + `/projects/${id}/works/msg-list-next-generation`,
            headers: {
                Authorization: `Bearer ${getState().auth.token}`,
            },
            params,
            data: { filter: getState().project.headerSearch.toLowerCase(), ...getState().filters.filters },
        })
        let returnPayload = { ...data }
        if (params.offset !== 0) {
            const stateData = getState().ag_grid_ksg.msg.worksList
            returnPayload = {
                ...returnPayload,
                data: [...(stateData ? stateData.data : []), ...data.data],
            }
        }
        return returnPayload
    } catch (e: any) {
        console.error(e)
        axiosRefetchToken(e?.response?.status)
        return rejectWithValue(e)
    }
})

export const getWorksAgGridOrphan = createAsyncThunk<
    { data: WorkMsg[]; total: number },
    IGetParamsMSG,
    {
        state: RootState
    }
>('ag_grid_msg/worksListOrphan', async (paramsData, { getState, rejectWithValue }) => {
    try {
        const { id, ...params } = paramsData

        const { filters, filtersListUnlimited } = getState().filters

        const { data } = await axios<{ data: WorkMsg[]; total: number }>({
            method: 'POST',
            url: deployLinks.server + `/projects/${id}/works/msg-orphan`,
            headers: {
                Authorization: `Bearer ${getState().auth.token}`,
            },
            params,
            data: {
                filter: getState().project.headerSearch.toLowerCase(),
                ...getOrphanFiltersPayload(filters, filtersListUnlimited),
            },
        })

        let returnPayload = { ...data }
        if (params.offset !== 0) {
            const stateData = getState().ag_grid_ksg.msg.worksList
            returnPayload = {
                ...returnPayload,
                data: [...(stateData ? stateData.data : []), ...data.data],
            }
        }
        return returnPayload
    } catch (e: any) {
        console.error(e)
        axiosRefetchToken(e?.response?.status)
        return rejectWithValue(e)
    }
})
