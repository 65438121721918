import styled from 'styled-components'
import { FlexRowWrapper } from '../../../pages/NewExecutorView/components/components.styles'

export const StyledRowWrapper = styled(FlexRowWrapper)`
    padding: 0;
    justify-content: center;
    align-items: center;
    text-align: left;
    width: 100%;
`
