import { Add, Autorenew, Edit } from '@mui/icons-material';
import { FormLabel, IconButton, Radio, Typography } from '@mui/material';
import { FC } from 'react';

import { IColumnTemplate } from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.def';
import { OverflowableTypographyWithTooltip } from '@/components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';

import { FlexColumnWrapper } from '@/pages/NewExecutorView/components/components.styles';

import { theme } from '@/styles/theme';

import { StyledMenu, StyledMenuItem } from './UIMenu.styles';

interface IProps {
    isOpen: boolean;
    anchorEl: HTMLButtonElement | null;
    templates: IColumnTemplate[];
    isDisabled: boolean;
    activeTemplate: IColumnTemplate | null;
    onAdd: () => void;
    onEdit: () => void;
    onClose: () => void;
    onReset: () => void;
    onSave: (template: IColumnTemplate, updateActiveTemplate?: boolean, showOverlay?: boolean) => void;
}

export const UIMenu: FC<IProps> = ({
    isOpen,
    anchorEl,
    templates,
    isDisabled,
    activeTemplate,
    onAdd,
    onEdit,
    onClose,
    onReset,
    onSave,
}) => {
    return (
        <StyledMenu
            anchorEl={anchorEl}
            open={isOpen}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <FlexColumnWrapper
                p={0}
                gap={0}
            >
                <StyledMenuItem
                    onClick={onReset}
                    sx={{ position: 'sticky' }}
                >
                    <Autorenew />
                    <Typography>Сбросить</Typography>
                </StyledMenuItem>
                <FlexColumnWrapper
                    p={0}
                    gap={0}
                    maxHeight={520}
                    sx={{ overflowY: 'auto', overflowX: 'hidden' }}
                >
                    {templates?.map((template) => {
                        return (
                            <FormLabel
                                key={template.id}
                                disabled={isDisabled}
                            >
                                <StyledMenuItem
                                    key={template.id}
                                    sx={{
                                        ...(template.id === activeTemplate?.id && {
                                            bgcolor: theme.palette.bg.shades,
                                        }),
                                    }}
                                    disableRipple
                                >
                                    <Radio
                                        checked={template.id === activeTemplate?.id}
                                        sx={{ p: 0, color: `${theme.palette.secondary.light} !important` }}
                                        // disableRipple
                                        onChange={(e, checked) => checked && onSave(template, true, true)}
                                        disabled={isDisabled}
                                    />
                                    <OverflowableTypographyWithTooltip
                                        TypographyProps={{
                                            sx: {
                                                display: 'block !important',
                                                background: 'none !important',
                                            },
                                        }}
                                        maxRows={1}
                                    >
                                        {template.name}
                                    </OverflowableTypographyWithTooltip>
                                    {!template.isCommon && template.id === activeTemplate?.id && (
                                        <IconButton
                                            sx={{ ml: 'auto' }}
                                            onClick={onEdit}
                                        >
                                            <Edit />
                                        </IconButton>
                                    )}
                                </StyledMenuItem>
                            </FormLabel>
                        );
                    })}
                </FlexColumnWrapper>
                <StyledMenuItem onClick={onAdd}>
                    <Add />
                    <Typography>Добавить</Typography>
                </StyledMenuItem>
            </FlexColumnWrapper>
        </StyledMenu>
    );
};
