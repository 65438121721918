import { api } from '../api'
import { LoginByToken, LoginResponse } from './auth.def'

export const authApi = api.injectEndpoints({
    endpoints: (build) => ({
        loginByToken: build.mutation<LoginResponse, LoginByToken>({
            query: ({ token }) => ({
                url: `/auth/from-core`,
                method: 'GET',
                params: { token },
            }),
            invalidatesTags: () => ['Profile'],
        }),
    }),
    overrideExisting: false,
})

export const { useLoginByTokenMutation } = authApi
