import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';

export const Buttons = ({
    onSubmit,
    submitText,
    cancelText,
    onClose,
    disabled,
    loading,
}: {
    onSubmit?: () => void;
    onClose: () => void;
    submitText: string;
    cancelText: string;
    disabled?: boolean;
    loading?: boolean;
}) => {
    const buttonStyles = {
        width: '10.75rem',
        height: '2.5rem',
        borderRadius: '6px',
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <LoadingButton
                loading={loading}
                disabled={disabled}
                sx={buttonStyles}
                onClick={onSubmit}
                variant='contained'
                color='success'
                type='submit'
            >
                {submitText}
            </LoadingButton>
            <Button
                sx={buttonStyles}
                onClick={onClose}
                variant='contained'
                color='primary'
            >
                {cancelText}
            </Button>
        </div>
    );
};
