import { Checkbox } from '@mui/material'
import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community'
import { IBond, IMSGGantBond } from '../../../../../../api/msg/msg.def'
import { IAddBondToDeleteListArgs, IMSGBondRowData } from './DeleteDepsMenu.def'

export function addBondToDeleteList({ params, checked }: IAddBondToDeleteListArgs) {
    const { linkType } = params.context as {
        linkType: 'from' | 'to'
    }
    params.context.setBond((prevState: IBond) => ({
        ...prevState,
        [linkType]: prevState?.[linkType]?.map((bond: IMSGGantBond) => {
            const { date: bondDate, workId: bondWorkId } = bond
            const { date, workId } = params.data || {}
            if (bondDate === date && bondWorkId === workId) {
                return {
                    ...bond,
                    checked: checked,
                }
            } else {
                return bond
            }
        }),
    }))
    // params.api.applyTransaction({
    //     update: [{ ...(params.data as IMSGGantBond), checked: checked }],
    // })
    // params.api.redrawRows({ rowNodes: [params.node] })
}

export const DeleteDepsTableColDef = [
    {
        headerName: '',
        field: 'checked',
        width: 40,
        flex: 0,
        cellRenderer: (params: ICellRendererParams<IMSGBondRowData>) => {
            return (
                <Checkbox
                    sx={{ p: 0 }}
                    disableRipple
                    checked={params.value}
                    size="small"
                    onChange={(e, checked) => {
                        addBondToDeleteList({
                            params: params,
                            checked: checked,
                        })
                    }}
                />
            )
        },
    },
    {
        headerName: 'Наименование работы',
        field: 'workName',
        flex: 1,
    },
    {
        headerName: 'Дата привязки',
        width: 90,
        flex: 0,
        valueGetter: (params) => {
            return params.data?.date
        },
    },
    {
        headerName: 'Тип связи',
        width: 110,
        valueGetter: (params) => {
            console.log(params.data?.side)
            if (params.data?.side === 'start') {
                if (params.context.side === "finish") {
                    return 'ФИНИШ - СТАРТ' 
                }
                return 'СТАРТ - СТАРТ'
            } else {
                return 'ФИНИШ - СТАРТ'
            }
        },
    },
] as (ColDef<IMSGBondRowData> | ColGroupDef<IMSGBondRowData>)[]
