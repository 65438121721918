import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { setGantLinkChoice } from '@/store/slices/msgGantSlice';
import { templatesSelector } from '@/store/slices/templatesSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

export const useUpdateGanttModeByCurrentTemplate = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { currentTemplate } = useTypedSelector(templatesSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!currentTemplate) return;
        if (currentTemplate.type !== 'msg') return;
        if (currentTemplate.isCommon) return;

        if (currentTemplate.isGanttOn) {
            if (searchParams.has('gant')) return;

            setSearchParams((searchParams) => {
                searchParams.append('gant', 'true');
                return searchParams;
            });
            return;
        }

        setSearchParams((searchParams) => {
            searchParams.delete('gant');
            return searchParams;
        });
        dispatch(setGantLinkChoice(null));
    }, [currentTemplate]);
};
