import React, { useCallback, useState } from "react";
import { FilterSelectProps } from "./FilterSelect.types";
import {
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 8;
export const SelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function FilterSelect({
  startIcon,
  items,
  value,
  defaultValue,
  onChange,
  ...props
}: FilterSelectProps) {
  const [open, setOpen] = useState(false);

  const handleChange = useCallback(
    (event: any) => {
      const value = event?.target?.value;
      onChange(typeof value === "string" ? value.split(",") : value);
    },
    [onChange],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return (
    <Stack maxWidth={220} justifyContent="center" {...props}>
      <Select
        sx={{
          "& .MuiSvgIcon-root": {
            fontSize: "16px",
            color: "#2B3648",
          },
          "& div": {
            paddingRight: 0.2,
          },
        }}
        variant="standard"
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={value}
        onChange={handleChange}
        displayEmpty
        disableUnderline
        IconComponent={KeyboardArrowDownSharpIcon}
        renderValue={(selected) => {
          return (
            <Stack direction="row" alignItems="center">
              <Typography pl={1} variant="body1">
                {startIcon}
              </Typography>
              <Stack ml={1}>{selected}</Stack>
            </Stack>
          );
        }}>
        {items?.map((item) => {
          const { value: name, title } = item;
          return (
            <MenuItem key={name} value={name}>
              <ListItemText primary={title} />
            </MenuItem>
          );
        })}
      </Select>
    </Stack>
  );
}

export default FilterSelect;
