import { CircularProgress, Stack } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useGetProjectByIdQuery } from '@/api/projects/projects.api';

interface IProps extends PropsWithChildren {}

export const ProjectGuardLayout: FC<IProps> = ({ children }) => {
    const { projectId } = useParams();

    const { isError, isLoading } = useGetProjectByIdQuery({ id: Number(projectId) });

    if (isLoading)
        return (
            <Stack
                justifyContent={'center'}
                alignItems={'center'}
            >
                <CircularProgress />
            </Stack>
        );
    if (isError) return <Navigate to={'/'} />;

    return <>{children}</>;
};
