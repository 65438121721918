import styled from 'styled-components'
import { Avatar, Card, Typography } from '@mui/material'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../../shared/rolePermissions'
import { PublicUserProfile } from '../../../api/users/users.def'

export const StyledAvatar = styled(Avatar)`
    width: 120px;
    height: 120px;
    border-radius: 4px;
`

interface StyledCardProps {
    profile: PublicUserProfile
    textcolor?: 'dark'
}

export const StyledCard = styled(Card)<StyledCardProps>`
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    min-height: 25rem;

    box-shadow: 0 5px 6px -3px rgba(74, 104, 176, 0.08), 0px 9px 12px 1px rgba(109, 154, 220, 0.08),
        0px 3px 16px 2px rgba(109, 154, 220, 0.08);

    &:hover {
        cursor: ${({ profile }) => DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_USERS')};
        box-shadow: ${(props) => props.theme.shadows[20]};
    }

    & .MuiCardHeader-root .MuiTypography-body2 {
        color: ${(props) => props.theme.palette.text.dark};
    }

    & .MuiCardContent-root .MuiTypography-body2 {
        color: ${(props) => (props.textcolor ? props.theme.palette.text.dark : props.theme.palette.secondary.gray)};
    }
`

export const CardSubtitle = styled(Typography)`
    font-weight: 700;
    font-size: ${(props) => props.theme.typography.tooltip.fontSize};
    line-height: ${(props) => props.theme.typography.tooltip.lineHeight};
    letter-spacing: ${(props) => props.theme.typography.tooltip.letterSpacing};
    color: ${(props) => props.theme.palette.secondary.gray};
`
