import { ArrowBack } from '@mui/icons-material'
import { ColDef, GridReadyEvent, ICellRendererParams, RowClassParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useMemo, useRef, useState } from 'react'
import { OverflowableTypographyWithTooltip } from '../../../../components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip'
import { UIFolder } from '../../../../components/ui/UIFolder'
import { Nullable } from '../../../../shared/def'
import { req } from '../../../WorkManagment/api/api'
import { StyledBtn } from '../../../WorkManagment/components/components.styles'
import { IWorkFactData } from '../../api/api.types'
import { FlexRowWrapper } from '../components.styles'
import { Container, DialogWindow, StyledAgGrid } from './RelatedWorksDialog.styles'

type TResponse = {
    workId: number
    parents: IParent[]
}

type TProps = {
    projectId: string
    selectedWork: Nullable<IWorkFactData>
    onWorkSelect: (work: Nullable<IWorkFactData>) => void
}

export interface IParent {
    id: number
    level: number
    workName: string
    objName: Nullable<string>
}

export interface IRowData extends IParent {
    isChild: boolean
}

const LevelCellRenderer: React.FC<ICellRendererParams<IRowData>> = (params) => {
    if (!params.data) return null

    const level = params.data.level
    const hasChildren = !params.data.isChild

    return <UIFolder level={level} hasChildren={hasChildren} />
}

const DefaultCellRenderer: React.FC<ICellRendererParams<IRowData>> = (params) => (
    <OverflowableTypographyWithTooltip maxRows={3}>{params.value}</OverflowableTypographyWithTooltip>
)

export const RelatedWorksDialog: React.FC<TProps> = ({ projectId, selectedWork, onWorkSelect }) => {
    const [parents, setParents] = useState<IParent[]>([])
    const gridRef = useRef<AgGridReact>(null)

    const isOpen = Boolean(selectedWork)

    const rowData = useMemo(() => {
        if (!selectedWork || !parents.length) return []

        const work = {
            workID: selectedWork?.workID,
            objName: selectedWork?.objName,
            workName: selectedWork?.workName,
            level: parents.length,
            isChild: true,
        } as unknown as IRowData
        return [...parents.map((v) => ({ ...v, isChild: false })), work] as IRowData[]
    }, [parents, selectedWork])

    const columnDefs = useMemo<ColDef<IRowData>[]>(() => {
        return [
            {
                field: 'level',
                headerName: 'Ур',
                width: 40,
                cellRenderer: LevelCellRenderer,
                autoHeight: true,
            },
            {
                field: 'objName',
                headerName: 'Объект',
                flex: 1,
                cellClass: 'ag-cell-align-left',
                autoHeight: true,
            },
            {
                field: 'workName',
                headerName: 'Наименование работы',
                flex: 1,
                cellClass: 'ag-cell-align-left',
                autoHeight: true,
            },
        ]
    }, [])

    const resetState = () => setParents([])

    const fetchParents = async () => {
        try {
            const data = await req.get(
                `/projects/${projectId}/works/msg/resources/executor/${selectedWork?.workID}/parents`
            )
            const { parents } = data.data as TResponse
            setParents(parents)
        } catch (error) {
            console.log('error', error)
        }
    }

    const handleClose = () => {
        onWorkSelect(null)
        setTimeout(resetState, 500)
    }

    const getRowClass = useCallback((params: RowClassParams<IRowData, any>) => {
        const level = params.data?.level as keyof typeof classesByLevel
        const classesByLevel = {
            0: 'level_zero',
            1: 'level_one',
            2: 'level_two',
            3: 'level_three',
            4: 'level_four',
            16: 'level_sixteen',
        }

        if (params.data?.isChild) return classesByLevel[16]

        return classesByLevel[level] ?? classesByLevel[4]
    }, [])

    const onGridReady = (params: GridReadyEvent<IRowData>) => {
        params.api.showLoadingOverlay()

        fetchParents().finally(() => {
            params.api.hideOverlay()
        })
    }

    return (
        <DialogWindow open={isOpen} onClose={handleClose}>
            <Container>
                <FlexRowWrapper width={'100%'} gap={0}>
                    <div className="ag-theme-alpine" style={{ flex: '1 1 0', height: '100%' }}>
                        <StyledAgGrid<any>
                            gridRef={gridRef}
                            rowData={rowData}
                            getRowClass={getRowClass}
                            columnDefs={columnDefs}
                            headerHeight={40}
                            rowHeight={36}
                            overlayLoadingTemplate="Получение данных..."
                            overlayNoRowsTemplate="Пока нет работ"
                            domLayout="autoHeight"
                            gridOptions={{
                                defaultColDef: {
                                    cellRenderer: DefaultCellRenderer,
                                },
                            }}
                            onGridReady={onGridReady}
                        />
                    </div>
                </FlexRowWrapper>
                <StyledBtn
                    variant="outlined"
                    sx={{
                        bgcolor: 'rgba(25, 118, 210, 0.08)',
                        marginTop: 'auto',
                        width: 'fit-content',
                        marginInline: 'auto',
                    }}
                    startIcon={<ArrowBack />}
                    onClick={handleClose}
                >
                    Вернуться к задачам
                </StyledBtn>
            </Container>
        </DialogWindow>
    )
}
