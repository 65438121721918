import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface initialStateI {
    titlesArr: string[]
}

const initialState: initialStateI = {
    titlesArr: [],
}

const slice = createSlice({
    name: 'filtersWorkersMim',
    initialState,
    reducers: {
        setFilterTitlesArr(state, action: PayloadAction<{ title: string; checked: boolean }>) {
            const { checked, title } = action.payload
            if (checked) {
                state.titlesArr.push(title)
            } else {
                const itemIndex = state.titlesArr.indexOf(title)
                if (itemIndex > -1) state.titlesArr.splice(itemIndex, 1)
            }
        },

        resetFilterTitlesArr(state) {
            state.titlesArr = []
        },
    },
})

export const { setFilterTitlesArr, resetFilterTitlesArr } = slice.actions
export default slice.reducer

export const filtersWorkersMimSelector = (state: RootState) => state.filtersWorkersMim
