import { CellClassParams, ColumnState, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ReactElement } from 'react';

import { IGetGantDependenciesRes, WorkMsg, dailyChart } from '@/api/msg/msg.def';

import {
    IColumnTemplate,
    TTemplateColumn,
} from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.def';
import { getFilteredColumnsState } from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.service';

import { monthsList } from '@/shared/constants/date';
import { IGetTargetRequestFuncArgs, ISubmitParams } from '@/shared/def';
import {
    capitalizeFirstLetter,
    getDayOfWeek,
    isCurrentDayAndMonth,
    onSubmitRejected,
    onSubmitSuccess,
} from '@/shared/utils';

import { onEditAgMSG } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { initMsgTemplateStateType } from '@/store/slices/msgTemplatesSlice';
import { store } from '@/store/store';

import { req } from '../WorkManagment/api/api';
import { createPathArrowV2, getGantDepId, getIndicatorCoords } from './components/GantCell/GantCell.service';

export const CANVAS_ID = 'gantRelationsCanvas';

const getChartCellClass = (isToday: boolean) => (params: CellClassParams) => {
    const level = params?.data?.level as keyof typeof classesByLevel;

    const classesByLevel = {
        0: 'current_month_level_zero',
        1: 'current_month_level_one',
        2: 'current_month_level_two',
        3: 'current_month_level_three',
        4: 'current_month_level_four',
        16: 'current_month_level_sixteen',
    };

    if (!params.data.hasChildren) return 'ag-cell-padding-none ag-cell-flex';

    const classByLevel = classesByLevel[level] ?? classesByLevel[4];

    return `ag-cell-padding-none ag-cell-flex ${isToday ? classByLevel : ''}`;
};

export const getHeadersMonthsMsg = (
    charts: dailyChart[] | undefined,
    callback: (data: ICellRendererParams<WorkMsg, any, any>, index: number, isToday: boolean) => ReactElement,
    isGantt?: boolean,
    columnWidths?: Map<string, number>
) => {
    const month = store.getState().monthMsg.month;
    const year = store.getState().monthMsg.year;
    const header = monthsList[month] + ', ' + year;

    return {
        headerName: header,
        suppressMovable: true,
        children: charts?.map((_, index) => {
            const day = index + 1;
            const dayOfWeek = getDayOfWeek(`${day}.${month + 1}.${year}`);
            const isToday = isCurrentDayAndMonth(day, month);
            const key = index === 0 ? 'dailyCharts' : `dailyCharts_${index}`;
            const width = isGantt ? 64 : columnWidths?.get(key) || 64;

            return {
                field: 'dailyCharts',
                width,
                resizable: !isGantt,
                headerName: day,
                headerComponent: () => (
                    <div
                        style={{
                            textAlign: 'center',
                            width: '100%',
                            borderRadius: '0.5rem',
                            padding: '0.5rem 0',
                        }}
                    >
                        {`${capitalizeFirstLetter(dayOfWeek)}, ${day}`}
                    </div>
                ),
                suppressMovable: true,
                cellRenderer: (data: ICellRendererParams<WorkMsg, any, any>) => callback(data, index, isToday),
                headerClass: () => {
                    if (isToday) return 'ag-header-current-chart';
                    if (dayOfWeek === 'вс' || dayOfWeek === 'сб') return 'ag-header-weekend-chart';

                    return '';
                },
                cellClass: getChartCellClass(isToday),
            };
        }),
    };
};

export function getTargetRequestByColDefFieldName({ params, projectID }: IGetTargetRequestFuncArgs) {
    switch (params.colDef.field) {
        case 'operationalStartDate':
        case 'operationalEndDate':
            return req.patch(`/projects/${projectID}/works/${params.data?.id}/update`, {
                [params.colDef.field as string]: params.newValue,
            });
        case 'executorName':
            return req.post(
                `/projects/${projectID}/works/msg/set-executor?year=${params.data.year}&month=${params.data.month}`,
                {
                    contractorCompany: params.newValue?.company || null,
                    executorId: params.newValue?.employeeID || null,
                    workID: params.data.id,
                }
            );
        case 'brigade':
            return req.post(
                `/projects/${projectID}/works/msg/set-brigade?year=${params.data.year}&month=${params.data.month}`,
                {
                    brigadeId: params.newValue?.value || null,
                    workID: params.data.id,
                }
            );
        default:
            return new Promise<any>((res, rej) => res({ data: { data: [] } }));
    }
}

export function updateRowDataMSG(data: WorkMsg[]) {
    store.dispatch(onEditAgMSG(data));
}

export function submitCellData(args: ISubmitParams) {
    const { params, projectID } = args;
    getTargetRequestByColDefFieldName({
        params: params,
        projectID: projectID as string,
    })
        .then(({ data }) => {
            const excludeRowUpdateFields = ['operationalStartDate', 'operationalEndDate'];
            // params.api.applyTransaction({ update: [data.data] })
            onSubmitSuccess(args);
            if (excludeRowUpdateFields.includes(params.colDef?.field as string)) {
                return;
            }
            updateRowDataMSG(data.data);
        })
        .catch((e) => {
            onSubmitRejected({
                error: e,
                ...args,
            });
        });
}

export function createGantCanvasV2(canvasID: string, width: number, height: number) {
    const viewPortNode = document.querySelector('.ag-center-cols-container');

    const canvasWrapper = document.createElement('div');
    canvasWrapper.setAttribute('id', canvasID + 'wrapper');
    canvasWrapper.style.position = 'absolute';
    canvasWrapper.style.right = '0px';
    canvasWrapper.style.top = '0px';
    canvasWrapper.style.pointerEvents = 'none';
    canvasWrapper.style.zIndex = '3';

    const canvasSVG = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    canvasSVG.setAttribute('id', canvasID);
    canvasSVG.setAttribute('width', width + '');
    canvasSVG.setAttribute('height', height.toString());
    canvasSVG.style.zIndex = '3';

    canvasWrapper.appendChild(canvasSVG);

    viewPortNode?.append(canvasWrapper);
}

export function drawDefaultArrowsFromServer(AgGrid: AgGridReact, deps: IGetGantDependenciesRes['data']) {
    deps.map((dep) => {
        dep.bonds.forEach((bond) => {
            const workFromID = bond.key.workId;
            const workFromRowIndex = AgGrid.api.getRowNode(workFromID.toString())?.rowIndex || 0;
            const workFromChartIndex = Number(bond.key.date.split('.')[0]) || 0 - 1;
            const workFromSide = bond.key.side;
            bond.to.map((workTo) => {
                const workToID = workTo.workId;
                const workToRowIndex = AgGrid.api.getRowNode(workToID.toString())?.rowIndex || 0;
                const workToChartIndex = Number(workTo.date.split('.')[0]) || 0 - 1;

                workFromRowIndex &&
                    workToRowIndex &&
                    createPathArrowV2({
                        pathID: getGantDepId({
                            workFromID: workFromID.toString(),
                            workToID: workTo?.workId?.toString() || '',
                            workFromDate: bond.key.date,
                            workToDate: workTo.date,
                        }),
                        from: getIndicatorCoords(workFromRowIndex, workFromChartIndex, workFromSide),
                        to: getIndicatorCoords(workToRowIndex, workToChartIndex, 'start'),
                        color: AgGrid.api?.getRowNode(workToID.toString())?.data?.brigade?.color,
                        canvasID: CANVAS_ID,
                    });
            });
        });
    });
}

type TGetGridColumnsAfterResizeProps = {
    isGantt: boolean;
    currentTemplate: IColumnTemplate;
    grid: AgGridReact;
};

export const getGridColumnsAfterResize = ({ isGantt, grid, currentTemplate }: TGetGridColumnsAfterResizeProps) => {
    const dailyCharts = [];

    if (isGantt && currentTemplate) {
        dailyCharts.push(...currentTemplate?.columns.filter((item) => item.name.startsWith('dailyCharts')));
    }

    const excludeColIds = isGantt ? ['dailyCharts'] : [];
    const columns = getFilteredColumnsState(grid, excludeColIds).map((columnState) => ({
        name: (columnState as ColumnState).colId,
        hide: (columnState as ColumnState).hide,
        pinned: (columnState as ColumnState).pinned,
        width: (columnState as ColumnState).width,
    })) as TTemplateColumn[];

    return [...columns, ...dailyCharts];
};

export const daysInMonth = (month: number, year: number) => {
    return new Date(year, month, 0).getDate();
};

export const getRowWidthMsg = (length: number | undefined, msgTObj: Omit<initMsgTemplateStateType, 'name'>) => {
    if (!length) return;
    const width = () => {
        let count = 5740;

        if (!msgTObj.level.show) {
            count -= 130;
        }
        if (!msgTObj.code.show) {
            count -= 160;
        }
        if (!msgTObj.objTitle.show) {
            count -= 225;
        }
        if (!msgTObj.rdDateApproval.show) {
            count -= 200;
        }
        if (!msgTObj.objName.show) {
            count -= 300;
        }
        if (!msgTObj.executorName.show) {
            count -= 225;
        }
        if (!msgTObj.workName.show) {
            count -= 300;
        }
        if (!msgTObj.unit.show) {
            count -= 130;
        }
        if (!msgTObj.volumeDone.show) {
            count -= 300;
        }
        if (!msgTObj.humanHoursProgress.show) {
            count -= 430;
        }
        if (!msgTObj.rdCode.show) {
            count -= 180;
        }
        if (!msgTObj.rdStatus.show) {
            count -= 180;
        }
        if (!msgTObj.rdDate.show) {
            count -= 180;
        }
        if (!msgTObj.rdGroup.show) {
            count -= 180;
        }
        if (!msgTObj.rdVersion.show) {
            count -= 180;
        }
        if (!msgTObj.workGroup.show) {
            count -= 180;
        }
        if (!msgTObj.contractorCompany.show) {
            count -= 180;
        }
        if (!msgTObj.startDate.show) {
            count -= 180;
        }
        if (!msgTObj.endDate.show) {
            count -= 180;
        }
        if (!msgTObj.humanHourCost.show) {
            count -= 180;
        }
        if (!msgTObj.humanHoursTotal.show) {
            count -= 180;
        }
        if (!msgTObj.humanHoursProgressTZ.show) {
            count -= 180;
        }
        if (!msgTObj.volumeTotal.show) {
            count -= 180;
        }
        if (!msgTObj.costER.show) {
            count -= 180;
        }
        if (!msgTObj.costTotal.show) {
            count -= 180;
        }
        if (!msgTObj.costRemaining.show) {
            count -= 180;
        }

        return count;
    };
    return length * 120 + width();
};
