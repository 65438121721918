import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { IGantLinkActiveChoiseProps } from '../../layouts/TableLayout/components/GantLinkActiveChoise/GantLinkActiveChoise.def'
import { WritableDraft } from 'immer/dist/internal'

interface initialStateI {
    activeGantChoice: IGantLinkActiveChoiseProps | null
}

const initialState: initialStateI = {
    activeGantChoice: null,
}

const msgGantSlice = createSlice({
    name: 'msgGant',
    initialState,
    reducers: {
        setGantLinkChoice(state, action: PayloadAction<initialStateI['activeGantChoice']>) {
            //@ts-ignore
            state.activeGantChoice = {...action.payload}
        },
    },
})

export const { setGantLinkChoice } = msgGantSlice.actions
export default msgGantSlice.reducer

export const msgGantSelector = (state: RootState) => state.msgGant
