import React from 'react'
import { ksgPeopleChart } from '../../../api/ksgPeople/ksgPeople.def'
import { EditChartCell } from '../../../components/EditChartCell/EditChartCell'
import { ICellRendererParams } from 'ag-grid-community'

export default function ZeroLevelWrapper({
    isZero,
    value,
    monthRowsHeadersCell,
    chart,
    children,
    cellRendererParams,
    chartIndex = 0,
}: {
    isZero: boolean
    value?: (string | number | React.ReactElement) | undefined
    monthRowsHeadersCell?: boolean
    chart?: ksgPeopleChart
    children: React.ReactNode
    cellRendererParams: ICellRendererParams
    chartIndex?: number
}) {
    if (!isZero) return <>{children}</>

    if (monthRowsHeadersCell) {
        return (
            <div
                style={{
                    gridTemplateRows: 'repeat(5, 1fr)',
                    height: 199,
                }}
                className="month_container"
            >
                <div className="cell_body_plan border_none bb">План</div>
                <div className="cell_body_fact border_none bb">Факт</div>
                <div className="cell_body_plan border_none bb">Норматив</div>
                <div className="cell_body_fact border_none bb">Отклонение</div>
                <div className="cell_body_plan border_none">Выработка в рублях</div>
            </div>
        )
    }

    if (chart) {
        return (
            <div
                style={{
                    gridTemplateRows: 'repeat(5, 1fr)',
                    height: 199,
                }}
                className="month_container"
            >
                <EditChartCell
                    defaultValue={chart.plan}
                    isEditable={false}
                    cellRendererParams={cellRendererParams}
                    chartValueKey={'plan'}
                    chartIndex={chartIndex}
                />
                <EditChartCell
                    defaultValue={chart.fact}
                    isEditable={false}
                    cellRendererParams={cellRendererParams}
                    chartValueKey={'fact'}
                    chartIndex={chartIndex}
                />
                <EditChartCell
                    defaultValue={chart.norm}
                    isEditable={false}
                    cellRendererParams={cellRendererParams}
                    chartValueKey={'norm'}
                    chartIndex={chartIndex}
                />
                <EditChartCell
                    defaultValue={chart.deviation}
                    isEditable={false}
                    cellRendererParams={cellRendererParams}
                    chartValueKey={'deviation'}
                    chartIndex={chartIndex}
                />
                <EditChartCell
                    defaultValue={chart.output}
                    isEditable={false}
                    cellRendererParams={cellRendererParams}
                    chartValueKey={'output'}
                    chartIndex={chartIndex}
                />
                {/* <div className="cell_body_plan border_none bb">{chart.plan}</div>
                <div className="cell_body_fact border_none bb">{chart.fact}</div>
                <div className="cell_body_plan border_none bb">{chart.norm}</div>
                <div className="cell_body_fact border_none bb">{chart.deviation}</div>
                <div className="cell_body_plan border_none">{chart.output}</div>  */}
            </div>
        )
    }
    return <div>{value}</div>
}
