import { FaFilter } from '@react-icons/all-files/fa/FaFilter';

import g from '@/shared/stylesheets/TableGrid.module.scss';

import { filtersWorkersMimSelector } from '@/store/slices/filtersWorkersMimSlice';
import { useTypedSelector } from '@/store/store';

export const HeaderSufficiency = ({
    width,
    name,
    onFilterClick,
}: {
    width?: number;
    name: string;
    onFilterClick: () => void;
}) => {
    const { titlesArr } = useTypedSelector(filtersWorkersMimSelector);

    return (
        <div
            style={{
                width: (width || 32) - 32,
            }}
        >
            <p
                style={{
                    fontSize: '14px',
                    maxWidth: 120,
                }}
            >
                {name}
            </p>
            <span
                onClick={() => {
                    onFilterClick();
                }}
                className={g.abs_right_center + ' pointer'}
            >
                <FaFilter color={titlesArr.length ? '#6FCCBC' : '#fff'} />
            </span>
        </div>
    );
};
