import styled from 'styled-components'
import { FlexRowWrapper } from '../../../NewExecutorView/components/components.styles'

export const ActiveFilterNotificator = styled(FlexRowWrapper)`
    background: ${({ theme }) => theme.palette.error.main};
    border: ${({ theme }) => `2px solid ${theme.palette.secondary.gray}`};
    border-radius: 100%;
    width: 12px;
    height: 12px;
    justify-content: center;
    top: 0;
    right: 0;
    position: absolute;
`
