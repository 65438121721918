import { IconButton, Menu, MenuItem } from '@mui/material'
import { setSyncModuleData, toggleSyncModule } from '../../../store/slices/drawersSlice'
import { SvgCloudDone } from '../../../shared/SVG/Svg'
import { useAppDispatch } from '../../../store/store'
import { useGetStatusImportMutation } from '../../../api/importingData/importingData.api'
import { useParams } from 'react-router-dom'
import React, { MouseEvent, useState } from 'react'
import { pickSyncModuleType } from '../../../api/importingData/importingData.def'
import { useSnackbar } from 'notistack'
import { HtmlTooltip } from '../../../shared/components/HtmlTooltip'
import { modulesNames } from '../../../components/Synchronize/Synchronize.service'

export default function SyncIconEl({ modulesList }: { modulesList?: pickSyncModuleType[] }) {
    const [openPopoverLinks, setOpenPopoverLinks] = useState<HTMLButtonElement | null>(null)

    const { projectId } = useParams()

    const [statusImportReq] = useGetStatusImportMutation()

    const dispatch = useAppDispatch()

    const { enqueueSnackbar } = useSnackbar()

    const onClickMenuItem = (moduleName: pickSyncModuleType) => {
        setOpenPopoverLinks(null)
        dispatch(toggleSyncModule())

        statusImportReq({
            id: Number(projectId),
            body: {
                module: moduleName,
            },
        })
            .unwrap()
            .then((res) => {
                dispatch(
                    setSyncModuleData({
                        module: res.module,
                        moduleProjectName: res.moduleProjectName,
                        lastTime: res.lastImportTime,
                    })
                )
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка, доп информация в консоли', {
                    variant: 'error',
                })
                console.error(e)
            })
    }

    return (
        <>
            <Menu
                open={!!openPopoverLinks}
                anchorEl={openPopoverLinks}
                onClose={() => setOpenPopoverLinks(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                {modulesList
                    ?.filter((f) => f !== 'PragmaOffer')
                    .map((v) => (
                        <MenuItem onClick={() => onClickMenuItem(v)} key={v}>
                            {modulesNames[v]}
                        </MenuItem>
                    ))}
            </Menu>
            <HtmlTooltip title="Импорт данных в проект из внешнних модулей">
                <IconButton
                    sx={{
                        backgroundColor: '#f6f7fb',
                        borderRadius: '6px',
                    }}
                    color="primary"
                    component="span"
                    onClick={(event: MouseEvent<HTMLButtonElement>) => setOpenPopoverLinks(event.currentTarget)}
                >
                    <SvgCloudDone />
                </IconButton>
            </HtmlTooltip>
        </>
    )
}
