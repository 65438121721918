import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { AccessTitle } from "./styles";
import { theme } from "../../../styles/theme";
import { UserAccessesProps } from "./UserAccesses.types";

function UserAccesses({ access }: UserAccessesProps) {
  if (!access.allProjects && !access.projects.length) {
    return <Typography variant="body2">Нет проектов</Typography>;
  }

  return (
    <Stack spacing={2}>
      {access.allProjects ? (
        <Box>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box>
              <DescriptionOutlinedIcon
                style={{ fontSize: 20, color: theme.palette.primary.main }}
              />
            </Box>
            <AccessTitle variant="subtitle2">Все проекты и объекты</AccessTitle>
          </Stack>
        </Box>
      ) : (
        access.projects.slice(0, 1).map((project) => (
          <Box key={project.id}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box>
                <DescriptionOutlinedIcon
                  style={{ fontSize: 20, color: theme.palette.primary.main }}
                />
              </Box>
              <AccessTitle variant="subtitle2">{project.name}</AccessTitle>
            </Stack>
          </Box>
        ))
      )}
    </Stack>
  );
}

export default UserAccesses;
