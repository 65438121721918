import isNaN from 'lodash/isNaN'
import toNumber from 'lodash/toNumber'
import { NumericFormat } from 'react-number-format'
import { StyledNumber } from './UIColoredPercentage.styles'
import { getType } from './UIColoredPercentage.utils'

interface IProps {
    value: unknown
}

export const UIColoredPercentage: React.FC<IProps> = ({ value }) => {
    const percent = toNumber(value)

    if (isNaN(percent)) return null

    const type = getType(percent)

    return (
        <NumericFormat
            value={percent}
            displayType="text"
            allowedDecimalSeparators={[',', '.']}
            thousandSeparator={' '}
            decimalSeparator=","
            decimalScale={2}
            renderText={(value) => <StyledNumber type={type}>{value}%</StyledNumber>}
        />
    )
}
