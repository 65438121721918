export type TType = 'less_50' | '50_90' | '90_plus'

export const getType = (value: number) => {
    if (value === null) return 'less_50'
    if (value < 50) return 'less_50'
    if (value < 90) return '50_90'
    return '90_plus'
}

export const getColorsByType = (type: TType) => {
    const map = {
        less_50: {
            backgroundColor: '#ffb1b1',
            color: '#fff',
        },
        '50_90': {
            backgroundColor: '#fef08a',
            color: '#988d22',
        },
        '90_plus': {
            backgroundColor: '#8dd4c8',
            color: '#fff',
        },
    }

    return map[type]
}
