import { IconButton } from '@mui/material';
import { AiOutlineHome } from '@react-icons/all-files/ai/AiOutlineHome';
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

export default function IconButtonEl() {
    const navigate = useNavigate();

    return (
        <IconButton
            color='primary'
            component='button'
            sx={{
                margin: '0 0 0.2rem 0.1rem',
                paddingLeft: 0,
            }}
            disableRipple
            disableTouchRipple
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                navigate('/');
            }}
        >
            <AiOutlineHome
                color='#0044b4'
                fontSize='1.5rem'
            />
        </IconButton>
    );
}
