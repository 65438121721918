import { AgGridReact } from 'ag-grid-react'
import styled from 'styled-components'
import FilterIcon from '../../../../assets/FilterIcon.svg'
import FilterIconOutlined from '../../../../assets/FilterIconOutlined.svg'

export const StyledAgGrid = styled(AgGridReact)`
    .ag-overlay-loading-wrapper {
        background-color: white;
    }
    .ag-popup-child {
        box-shadow: ${({ theme }) => `2px 2px 10px 1px ${theme.palette.bg.gray}`};
        border-radius: ${({ theme }) => `${theme.spacing(0.5)}`};
    }

    .ag-icon-filter {
        background: ${() => `transparent url(${FilterIcon}) center/contain
            no-repeat;`};
        color: transparent;
        font-size: 24px;
        display: none;
    }

    .ag-header-cell-filtered {
        .ag-header-cell-menu-button {
            background: ${() => `transparent url(${FilterIcon}) center/contain no-repeat`};
        }
    }

    .ag-header-cell-menu-button {
        background: ${() => `transparent url(${FilterIconOutlined}) center/contain
            no-repeat;`};
        color: transparent;
        font-size: 24px;
        display: block !important;
        opacity: 1 !important;
        cursor: pointer;
    }

    .ag-root-wrapper {
        width: 100% !important;
    }
    .ag-header {
        height: auto;
    }
    .ag-header-container,
    .ag-header-row-column {
        background-color: #f8f9fb;
        color: #5c6e8c;
        font-weight: 500;
        font-size: 14px;
    }
    .ag-header-cell {
        padding: ${(props) => props.theme.spacing(0, 1)};
    }
    .ag-row {
        /* border-bottom: 0.5px solid #dadee5; */
        font-size: 14px;
    }
    .ag-row-total {
        border: none;
    }
    .ag-row-total .ag-cell {
        background-color: #f8f9fb;
        font-weight: 500;
        cursor: default !important;
    }
    .ag-cell {
        /* padding: ${(props) => props.theme.spacing(1, 1)}; */
        padding: 0;
    }

    .ag-cell-value {
        padding: 8px;
    }

    .ag-cell-editable:hover {
    }
    .ag-row:not(.ag-row-total) .ag-cell {
        outline: ${(props) => `1px solid ${props.theme.palette.bg.gray}`};
    }

    .ag-cell-px {
        padding-inline: ${(props) => props.theme.spacing(1)};
    }

    .ag-row:not(.ag-row-total) .ag-cell-editable:hover {
        cursor: pointer;
        box-shadow: ${({ theme }) => `inset 0px -1px 0px 0px #1976d2`};
    }

    .ag-cell input {
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .ag-cell-wrapper {
        width: inherit;
    }

    .ag-input-field-input {
        text-align: center;
    }
    .ag-cell-not-inline-editing {
        background: white;
    }
    .ag-cell-inline-editing {
        overflow: inherit;
    }
    .ag-cell-focus.ag-cell-inline-editing {
        box-shadow: inset 0px -2px 0px 0px #1976d2 !important;
    }
    .ag-cell-left,
    .ag-cell-left * {
        justify-content: flex-start !important;
        text-align: left !important;
    }
    .ag-cell-no-padding {
        padding: 0;
        .ag-cell-value {
            padding: 0;
        }
    }

    .ag-cell-right,
    .ag-cell-right * {
        justify-content: flex-end !important;
        text-align: right !important;
    }
    .ag-cell-row-group .ag-cell {
        /* background: ${(props) => props.theme.palette.bg.shades}; */
        font-size: 16px;
        color: ${(props) => props.theme.palette.primary.main};
    }

    .ag-total-row-cell {
        pointer-events: none;
        display: none;
    }
    .ag-pinned-left-cols-container > .ag-row > .ag-cell {
        border-right: ${(props) => `1px solid ${props.theme.palette.bg.gray}`};
    }

    .ag-row .ag-cell-no-borders {
        box-shadow: none !important;
        border: none !important;
        outline: none !important;
    }

    .ag-cell-first-column {
        padding-left: 40px;
    }
`
