import { Box, Typography, TypographyProps } from '@mui/material';
import styled from 'styled-components';

export const FolderContainer = styled(Box)`
    position: relative;
    width: 21px;
`;

interface IFolderLabelProps extends TypographyProps {
    colorValue?: string;
}

export const FolderLabel = styled(Typography).withConfig({
    shouldForwardProp: (prop) => prop !== ('colorValue' as string),
})<IFolderLabelProps>`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
    font-size: 8px;
    line-height: 150%;
    text-align: center;
    color: ${({ colorValue }) => colorValue ?? '#fff'};
`;
