import { isNumber } from 'lodash';
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Nullable } from '@/shared/def';

import { isRefreshCostGridSelector } from '@/store/slices/workManagementSlice';
import { useTypedSelector } from '@/store/store';

import { req } from '../../api/api';
import { OverflowableTypography, StyledTooltip } from '../components.styles';
import {
    CellContainer,
    CellValue,
    Container,
    Currency,
    GridContainer,
    Label,
    LabelCell,
    RowContainer,
} from './CostGrid.styles';
import { IAnalytics, ICostGridProps, IResponse, IUICellProps, IUICostGridProps } from './CostGrid.types';
import { getTooltipTitle, isElementOverflown } from './CostGrid.utils';

const Cell: FC<IUICellProps> = ({ value }) => {
    const ref = useRef<HTMLElement | null>(null);
    const [isOverflown, setIsOverflown] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (value === null) return;
        if (!ref.current) return;

        setIsOverflown(() => isElementOverflown(ref.current!));
    }, [ref.current, value]);

    return (
        <CellContainer>
            {isNumber(value) ? (
                <>
                    <StyledTooltip
                        title={getTooltipTitle(value, isOverflown)}
                        placement='top-start'
                    >
                        <OverflowableTypography
                            ref={ref}
                            rows={1}
                            sx={{
                                textAlign: 'right',
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '140%',
                                color: '#0e0f11',
                                display: 'block !important',
                            }}
                        >
                            <CellValue value={value} />
                        </OverflowableTypography>
                    </StyledTooltip>
                    <Currency> руб.</Currency>
                </>
            ) : (
                '—'
            )}
        </CellContainer>
    );
};

type TRowProps = {
    label: string;
    value: Nullable<number>;
};

const Row: FC<TRowProps> = ({ value, label }) => {
    const ref = useRef<HTMLDivElement | null>(null);

    // TODO:
    // 1. Доделать
    // useLayoutEffect(() => {
    //     if (!ref.current) return;

    //     console.log('RowC useLayoutEffect');

    //     const rect = ref.current.getBoundingClientRect();
    //     console.log('rect', rect);
    //     const ch = ref.current.children;
    //     const ch2 = ch[1].childNodes;
    //     console.log('ch', ch);
    //     console.log('ch2', ch2);
    // }, [ref]);

    return (
        <RowContainer ref={ref}>
            <LabelCell>{label}</LabelCell>
            <Cell value={value} />
        </RowContainer>
    );
};

export const UICostGrid: FC<IUICostGridProps> = ({
    costPlan,
    primeCostProgressPlan,
    primeCostPlan,
    costFact,
    primeCostProgressFact,
    primeCostFact,
}) => {
    return (
        <GridContainer>
            <Row
                label='Стоимость договор, план:'
                value={costPlan}
            />
            <Row
                label='Стоимость договор, факт:'
                value={costFact}
            />
            <Row
                label='Себестоимость(% прогресса), план:'
                value={primeCostProgressPlan}
            />
            <Row
                label='Себестоимость(% прогресса), факт:'
                value={primeCostProgressFact}
            />
            <Row
                label='Себестоимость, план:'
                value={primeCostPlan}
            />
            <Row
                label='Себестоимость, факт:'
                value={primeCostFact}
            />
        </GridContainer>
    );
};

export const CostGrid: FC<ICostGridProps> = ({ projectId, workId }) => {
    const isRefreshCostGrid = useTypedSelector(isRefreshCostGridSelector);

    const [data, setData] = useState<IAnalytics>({
        costPlan: null,
        costFact: null,
        primeCostProgressPlan: null,
        primeCostProgressFact: null,
        primeCostPlan: null,
        primeCostFact: null,
    });

    useEffect(() => {
        fetchData();
    }, [isRefreshCostGrid]);

    const fetchData = () => {
        req.get(`/projects/${projectId}/cabinet/resources/${workId}/info`).then(({ data: responseData }) => {
            const data = responseData as IResponse;
            setData(() => data.analytics);
        });
    };

    return (
        <Container>
            <Label>Экономика:</Label>
            <UICostGrid {...data} />
        </Container>
    );
};
