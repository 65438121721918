import { req } from "./api"
import { IProff, IProffCollectionReqParams, IProffCollectionResponse } from "./api.types"

export class CollectionsAPI {
    static baseURL: string = `/collections/staff/professions`

    static async getProffList(params: IProffCollectionReqParams): Promise<IProffCollectionResponse> {
        return await req.get(this.baseURL + `/list`, params)
            .then(({ data }) => {
                return data
            })
            .catch(e => console.log(e))
    }

    static async addNewProff (profName: string): Promise<IProff> {
        return await req.post(this.baseURL + `/add`, {name: profName})
            .then(({ data }) => {
                return data
            })
            .catch(e => console.log(e))
    }
}