import { Stack } from '@mui/material'
import styled from 'styled-components'
import { TType, getColorsByType } from './UIColoredPercentage.utils'

export const StyledNumber = styled(Stack).attrs({
    direction: 'row',
    alignItems: 'center',
    justifyContent: 'center',
})<{ type: TType }>`
    border-radius: 32px;
    padding: 4px 8px;
    width: 60px;
    height: 20px;
    background-color: ${({ type }) => getColorsByType(type).backgroundColor};
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
    color: ${({ type }) => getColorsByType(type).color};
`
