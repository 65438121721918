import { Box } from '@mui/material'
import styled from 'styled-components'
import { FlexRowWrapper } from '../../../NewExecutorView/components/components.styles'
import { IIndicatorProps, IStyledFilledBarProps } from './GantCell.def'

const DEFAULT_STYLES = {
    borderRadius: 24,
    indicatorColor: 'rgba(164, 164, 164, 1)',
    borderColor: 'rgba(164, 164, 164, 0.5)',
    innerMarginBarMargin: 14,
}

export const StyledFilledBar = styled(FlexRowWrapper)<IStyledFilledBarProps>`
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${({ variant, bgcolor }) =>
        variant === 'outer'
            ? { outline: `2px solid ${DEFAULT_STYLES.borderColor}` }
            : { filter: 'brightness(110%) contrast(105%)', outline: `1px solid ${DEFAULT_STYLES.borderColor}` }};
    ${({ isChosen, bgcolor }) =>
        isChosen && {
            background:
                'repeating-linear-gradient(-40deg, rgba(255,255,255,0.2) 0, rgba(255,255,255,0.2) 2px, transparent 2px, transparent 4px)',
            backgroundColor: bgcolor as string,
        }};
    height: ${({ size, cellHeight }) => {
        const k = {
            small: 2.5,
            medium: 2,
            large: 1.5,
        }

        return Math.floor(cellHeight! / k[size as keyof typeof k]) + 'px'
    }};
    ${({ placement, cellWidth, variant }) => {
        const innerBarMargin = variant === 'inner' ? DEFAULT_STYLES.innerMarginBarMargin + 'px' : '0px'
        switch (placement) {
            case 'start':
                return {
                    borderRadius: `${DEFAULT_STYLES.borderRadius}px 0 0 ${DEFAULT_STYLES.borderRadius}px`,
                    width: `calc(${cellWidth}px - 40px - ${innerBarMargin})`,
                    right: 0,
                }
            case 'center':
                return {
                    borderRadius: `${DEFAULT_STYLES.borderRadius}px`,
                    width: `calc(${cellWidth}px - 68px - ${innerBarMargin})`,
                }
            case 'end':
                return {
                    borderRadius: `0 ${DEFAULT_STYLES.borderRadius}px ${DEFAULT_STYLES.borderRadius}px 0`,
                    width: `calc(${cellWidth}px - 40px - ${innerBarMargin})`,
                    left: 0,
                }
            case 'fullWidth':
                return {
                    width: `${cellWidth}px`,
                    left: 0,
                }
        }
    }};
`

StyledFilledBar.defaultProps = {
    cellWidth: 64,
    cellHeight: 40,
}

export const Indicator = styled(Box)<IIndicatorProps>`
    display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
    border-radius: 1px;
    position: absolute;
    z-index: 3;
    transform: rotate(45deg);
    width: 11px;
    height: 11px;
    filter: saturate(200%) brightness(75%) contrast(120%);
    ${({ side }) => {
        const _side = side === 'start' ? 'left' : 'right'
        return {
            [_side]: '24px',
        }
    }};
    cursor: pointer;
`
