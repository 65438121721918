import * as yup from 'yup'
import 'yup-phone-lite'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'

export const validationPhoneSchema = yup.string().phone()

export function useValidatePhone() {
    const { t } = useTranslation()

    return useCallback(
        (value: string) => (validationPhoneSchema.isValidSync(value) ? '' : t('common:errors.auth.invalid_phone')),
        [t]
    )
}
