import g from '@/shared/stylesheets/TableGrid.module.scss';

export function stylesLevel(lev: number | null) {
    const stylesByLevel = {
        0: g.level0,
        1: g.level1,
        2: g.level2,
        3: g.level3,
        4: g.level4,
        5: g.level5,
        6: g.level6,
        7: g.level7,
    };
    return stylesByLevel[lev as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7];
}
