import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface IInitialState {
    isRefreshCostGrid: boolean;
}

const initialState: IInitialState = {
    isRefreshCostGrid: false,
};

const slice = createSlice({
    name: 'workManagement',
    initialState,
    reducers: {
        setRefreshCostGrid: (state) => {
            state.isRefreshCostGrid = !state.isRefreshCostGrid;
        },
    },
});

export const { setRefreshCostGrid } = slice.actions;
export default slice.reducer;

export const isRefreshCostGridSelector = (state: RootState) => state.workManagement.isRefreshCostGrid;
