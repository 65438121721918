import cloneDeep from 'lodash/cloneDeep'
import { INotification } from '../../api/notificationsCenter/notificationsCenter.def'

export const LIMIT = 20

type TAlertIds = {
    volumeIncreaseIds: number[]
    periodIncreaseIds: number[]
}

const defaultAlertIds: TAlertIds = { volumeIncreaseIds: [], periodIncreaseIds: [] }

export const getAlertIds = (notifications: INotification[] | undefined): TAlertIds => {
    if (!notifications) return cloneDeep(defaultAlertIds)

    return notifications.reduce((acc: TAlertIds, item) => {
        if (item.availableInc.volume) {
            acc.volumeIncreaseIds.push(item.alertId)
        }
        if (item.availableInc.period) {
            acc.periodIncreaseIds.push(item.alertId)
        }
        return acc
    }, cloneDeep(defaultAlertIds))
}

export const calculateOffset = (page: number) => (page - 1) * LIMIT

export const hasNotificationsByTotal = (total: number | undefined) => (total ? total > 0 : false)

export const calculatePaginationCount = (total: number | undefined) => Number(Math.ceil((total || 10) / 20).toFixed(0))
