import React, { Fragment } from 'react'
import { Box, Stack } from '@mui/material'
import Button from '../Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import { theme } from '../../../../styles/theme'
import { UserTopBarProps } from './UserTopBar.types'
import { useTypedSelector } from '../../../../store/store'
import { profileSelector } from '../../../../store/slices/profileSlice'

function UserTopBar({ onReturnClick, onDeleteClick, isEditUser }: UserTopBarProps) {
    const { profile } = useTypedSelector(profileSelector)
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: 3.75, pt: 3.75, pb: 2.5 }}>
            <Box>
                <Button
                    variant="text"
                    style={{ color: theme.palette.text.dark }}
                    startIcon={<ArrowBackIcon />}
                    onClick={onReturnClick}
                >
                    Назад
                </Button>
            </Box>
            {isEditUser ? (
                <Fragment>
                    {profile.role === 'admin' ? (
                        <Box>
                            <Button icon={true} color="error" onClick={onDeleteClick}>
                                <DeleteIcon />
                            </Button>
                        </Box>
                    ) : (
                        <span></span>
                    )}
                </Fragment>
            ) : null}
        </Stack>
    )
}

export default UserTopBar
