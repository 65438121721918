import StackedBarChartIcon from '@mui/icons-material/StackedBarChart'
import { Button } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

import { useUpdateCurrentTemplate } from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.model'

import { useToggleSearchParam } from '@/hooks/useToggleSearchParam'

import { HtmlTooltip } from '@/shared/components/HtmlTooltip'
import { GanttChartSearchParams } from '@/shared/constants/gantt'
import { IntegrationWithOfferSearchParam } from '@/shared/constants/integration-with-offer'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions'

import { profileSelector } from '@/store/slices/profileSlice'
import { templatesSelector } from '@/store/slices/templatesSlice'
import { useTypedSelector } from '@/store/store'

import { GanttChartsModeIconButton } from './styles'

export type GanttChartsMode = 'on' | 'off'

export const getGanttChartsMode = (searchParams: URLSearchParams): GanttChartsMode =>
    searchParams.has('g') || searchParams.has('gf') ? 'on' : 'off'

const DATA_BY_MODE = {
    on: {
        tooltipTitle: 'Выйти из режима "Диаграмма Ганта"'
    },
    off: {
        tooltipTitle: 'Включить режим "Диаграмма Ганта"'
    }
}

export default function GanttLinks() {
    const { isActive: isIntegrationOfferActive } = useToggleSearchParam(IntegrationWithOfferSearchParam)
    const { update: updateCurrentTemplate } = useUpdateCurrentTemplate()

    const [searchParams, setSearchParams] = useSearchParams()
    const { profile } = useTypedSelector(profileSelector)
    const { currentTemplate } = useTypedSelector(templatesSelector)

    const ganttChartsMode = getGanttChartsMode(searchParams)

    const isVisibleGanttButton =
        DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'VIEW_GANT_SMR') && !isIntegrationOfferActive

    const data = DATA_BY_MODE[ganttChartsMode] ?? DATA_BY_MODE['off']
    const factButtonVariant = searchParams.has(GanttChartSearchParams.Fact) ? 'contained' : 'outlined'
    const handleGanttChartsModeToggle = () => {
        setSearchParams(searchParams => {
            if (getGanttChartsMode(searchParams) === 'on') {
                Object.values(GanttChartSearchParams).forEach(p => searchParams.delete(p))
                return searchParams
            }
            searchParams.append(GanttChartSearchParams.Plan, 'on')
            return searchParams
        })

        updateCurrentTemplate({
            isGanttOn: !currentTemplate?.isGanttOn
        })
    }

    const handleFactClick = () => {
        setSearchParams(searchParams => {
            if (searchParams.has(GanttChartSearchParams.Fact)) {
                searchParams.delete(GanttChartSearchParams.Fact)
                return searchParams
            }

            searchParams.set(GanttChartSearchParams.Fact, 'on')
            return searchParams
        })
    }

    return (
        <>
            {ganttChartsMode === 'on' && (
                <Button
                    onClick={handleFactClick}
                    variant={factButtonVariant}
                >
                    ФАКТ
                </Button>
            )}
            {isVisibleGanttButton && (
                <HtmlTooltip title={data.tooltipTitle}>
                    <GanttChartsModeIconButton
                        color='primary'
                        onClick={handleGanttChartsModeToggle}
                        mode={ganttChartsMode}
                    >
                        <StackedBarChartIcon />
                    </GanttChartsModeIconButton>
                </HtmlTooltip>
            )}
        </>
    )
}
