import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useSetTagMutation } from '@/api/ksg/ksg.api';

import { useLatest } from '@/hooks/useLatest';

import { onCheckTagKsgAg } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { useAppDispatch } from '@/store/store';

export const useToggleTag = () => {
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');
    const dispatch = useAppDispatch();
    const projectId = useLatest(params.projectId!);
    const [setTagReq] = useSetTagMutation();

    const handleToggleTag = useCallback((bool: boolean, workId: number) => {
        dispatch(
            onCheckTagKsgAg({
                bool,
                workId,
            })
        );

        if (!workId) return;

        setTagReq({
            id: Number(projectId.current),
            workId: workId,
            body: {
                tag: bool,
            },
        })
            .unwrap()
            .then(() => {
                enqueueSnackbar(t('tag_set'), {
                    variant: 'success',
                });
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка, для дополнительной информации откройте консоль', {
                    variant: 'error',
                });
                console.error(e);
            });
    }, []);

    return handleToggleTag;
};
