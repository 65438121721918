import React from 'react'
import { Stack, Typography } from '@mui/material'
import { CardSubtitle } from '../UserCard/styles'
import { StyledCardContent } from './styles'
import UserAccesses from '../UserAccesses/UserAccesses'
import { UserCardContentProps } from './UserCardContent.types'

function UserCardContent({ data }: UserCardContentProps) {
    const { access, company } = data
    const { userCompanyName, userPosition } = company

    return (
        <StyledCardContent>
            <Stack spacing={1.5}>
                <Stack spacing={0.5}>
                    <CardSubtitle>Компания:</CardSubtitle>
                    <Typography variant="body2">{userCompanyName}</Typography>
                </Stack>

                <Stack spacing={0.5}>
                    <CardSubtitle>Должность:</CardSubtitle>
                    <Typography variant="body2">{userPosition}</Typography>
                </Stack>

                <Stack spacing={0.5}>
                    <CardSubtitle>Доступы:</CardSubtitle>
                    <UserAccesses access={access} />
                </Stack>
            </Stack>
        </StyledCardContent>
    )
}

export default UserCardContent
