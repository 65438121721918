import s from '../../pages/SufficiencyOfMaterials/SufficiencyOfMaterials.module.scss'
import { HtmlTooltip } from './HtmlTooltip'
import { Typography } from '@mui/material'
import React from 'react'

export default function WrapTextField({ value, actualWidth }: { value: string; actualWidth: number }) {
    return (
        <div className={s.px}>
            <HtmlTooltip title={value && value.length > 12 ? value : ''}>
                <Typography width={actualWidth - 16} color="#2b3648" fontSize={14} noWrap>
                    {value}
                </Typography>
            </HtmlTooltip>
        </div>
    )
}
