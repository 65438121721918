import { Typography } from '@mui/material';
import { ColDef, ICellRendererParams, IHeaderParams } from 'ag-grid-community';

import { EditChartCell } from '../../../../components/EditChartCell/EditChartCell';
import { OverflowableTypographyWithTooltip } from '../../../../components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../../../shared/rolePermissions';
import { FlexRowWrapper } from '../../../NewExecutorView/components/components.styles';
import { NumberCellRenderer } from '../../components/CellRenderers/NumberCellRenderer/NumberCellRenderer';
import { IMobilizationScheduleRow } from './MobilizationSchedulePage.def';
import {
    MONTH_ORDER_TO_MONTH_NAME,
    getConvertedAverage,
    getConvertedChartData,
    onSubmitNewFact,
} from './MobilizationSchedulePage.service';
import { MobilizationChartCell } from './MobilizationSchedulePage.styles';

export const StaticMobilizationTableColDef = [
    {
        headerName: '',
        minWidth: 40,
        maxWidth: 40,
        pinned: 'left',
        cellRenderer: (params: any) => {
            return params?.data?.id !== 'totalRow' && params?.rowIndex + 1;
        },
    },
    {
        headerName: 'Наименование',
        field: 'name',
        minWidth: 200,
        cellClass: 'ag-cell-left',
        headerClass: 'ag-cell-left',
        pinned: 'left',
        cellRenderer: (params: ICellRendererParams) => (
            <OverflowableTypographyWithTooltip maxRows={2}>{params.value}</OverflowableTypographyWithTooltip>
        ),
    },
    {
        headerName: '',
        cellClass: 'ag-cell-no-padding',
        minWidth: 80,
        maxWidth: 100,
        cellRenderer: (params: ICellRendererParams) => {
            return (
                <MobilizationChartCell>
                    <Typography variant='body2'>План</Typography>
                    <Typography variant='body2'>Факт</Typography>
                </MobilizationChartCell>
            );
        },
        pinned: 'left',
    },
    {
        headerName: 'Среднее',
        field: 'average.fact',
        cellClass: 'ag-cell-no-padding',
        minWidth: 80,
        maxWidth: 100,
        cellRenderer: (params: ICellRendererParams) => {
            const { plan, fact } = getConvertedAverage(params);
            return (
                <MobilizationChartCell>
                    <NumberCellRenderer value={plan} />
                    <NumberCellRenderer value={fact} />
                </MobilizationChartCell>
            );
        },
        pinned: 'left',
    },
];

export function getDinamicColDef(rowData: IMobilizationScheduleRow[] | null, searchParams: URLSearchParams): ColDef[] {
    if (!rowData) {
        return [];
    }
    return (
        rowData[0]?.chart?.map((chart, index) => {
            return {
                headerComponent: (params: IHeaderParams) => {
                    return (
                        <FlexRowWrapper
                            justifyContent={'center'}
                            width={'100%'}
                        >
                            {searchParams.get('type') === 'MONTHLY'
                                ? MONTH_ORDER_TO_MONTH_NAME[
                                      chart.header as unknown as keyof typeof MONTH_ORDER_TO_MONTH_NAME
                                  ].shortName
                                : chart.header}
                        </FlexRowWrapper>
                    );
                },
                spanHeaderHeight: true,
                minWidth: 78,
                maxWidth: 120,
                cellClass: 'ag-cell-no-padding',
                cellRenderer: (params: ICellRendererParams) => {
                    const { plan, fact } = getConvertedChartData(params, index);

                    return (
                        <MobilizationChartCell key={index}>
                            <EditChartCell
                                cellRendererParams={params}
                                chartValueKey={'plan'}
                                chartIndex={index}
                                isEditable={false}
                                defaultValue={plan}
                            />
                            <EditChartCell
                                cellRendererParams={params}
                                chartValueKey={'fact'}
                                chartIndex={index}
                                isEditable={
                                    params.context.searchParams.get('type') === 'DAILY' &&
                                    DOES_ROLE_HAS_ACCESS_TO_FEATURE(
                                        params?.context?.profile?.role,
                                        'EDIT_WORK_MANAGMENT'
                                    ) &&
                                    params.context?.work?.hasChildren === false
                                }
                                defaultValue={fact}
                                onSubmitData={onSubmitNewFact(params.context.refreshCostGrid)}
                                {...(params.context.searchParams.get('subType') === 'shift' && {
                                    convertInputValue: (value) => (value ? value * 10 : value),
                                })}
                            />
                        </MobilizationChartCell>
                    );
                },
            };
        }) || []
    );
}
