import { Typography } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import { IStatValueProps } from './StatValue.types'
import { DEFAULT_PROPS } from './StatValue.service'

export function StatValue({ value, NumericFormatProps , TypographyProps }: IStatValueProps) {
    return (
        <NumericFormat
            value={value}
            renderText={(value) => <Typography {...TypographyProps}>{value}</Typography>}
            {...DEFAULT_PROPS}
            {...NumericFormatProps }
        />
    )
}
