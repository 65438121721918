import { Dialog, LinearProgress } from '@mui/material';
import { ColumnResizedEvent, GetRowIdParams, ICellRendererParams, RowClassParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { Updater } from 'use-immer';

import { useGetBrigadesQuery, useGetExecutorsQuery } from '@/api/company/company.api';
import { queryTermsType } from '@/api/filters/filters.api';
import { useGetHeadersCPGQuery } from '@/api/ksg/ksg.api';
import { WorkMsg } from '@/api/msg/msg.def';

import {
    useGetCurrentTemplateColumnWidths,
    useUpdateCurrentTemplate,
} from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.model';
import { OverflowableTypographyWithTooltip } from '@/components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';
import ShowMsgDialog from '@/components/ShowMsgDialog/ShowMsgDialog';

import { usePagination } from '@/hooks/useVerticalPagination';

import { req } from '@/pages/WorkManagment/api/api';
import { AG_GRID_DEFAULT_PARAMS_KSG } from '@/pages/WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { LoadingOverlay } from '@/pages/WorkManagment/components/AgGridService/components/LoadingOverlay';

import { HEADER_ROW_HEIGHT } from '@/shared/constants/ag-grid';
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';
import { hasParam } from '@/shared/utils/common.utils';
import { getPaginationInitialParams } from '@/shared/utils/works.utils';

import { agGridListMode, agGridMsgSelector } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { IGetParamsMSG, getWorksAgGrid, getWorksAgGridOrphan } from '@/store/slices/agGridKsgMsgSlices/msgThunks';
import { authSelector } from '@/store/slices/authSlice';
import { setAgGrid } from '@/store/slices/drawersSlice';
import { TSelectedColumn, filtersSelector, setQueryTerm, setSelectedColumn } from '@/store/slices/filtersSlice';
import { monthMsgSelector } from '@/store/slices/monthMsgSlice';
import { msgTemplatesSelector } from '@/store/slices/msgTemplatesSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { projectsSelector } from '@/store/slices/projectsSlice';
import { templatesSelector } from '@/store/slices/templatesSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { TAgGridContext, getMSGColDefs } from './AgGridMsg.colDef';
import { useGetAppliedFilters, useGetMinMaxLevels, useRowHeight } from './AgGridMsg.model';
import './AgGridMsg.scss';
import {
    CANVAS_ID,
    createGantCanvasV2,
    daysInMonth,
    drawDefaultArrowsFromServer,
    getGridColumnsAfterResize,
    getRowWidthMsg,
    submitCellData,
} from './AgGridMsg.service';
import { FilterDialogs } from './components/MsgFilterDialogs';

const LIMIT = 500;

export default function AgGridMsg() {
    const [isLoading, setIsLoading] = useState(true);

    // const [hiddenRowsIds, setHiddenRowsIds] = useImmer<{ id: number; code: string }[]>([])
    const { hiddenRowsIds, setHiddenRowsIds } = useOutletContext<{
        hiddenRowsIds: {
            id: number;
            code: string;
        }[];
        setHiddenRowsIds: Updater<
            {
                id: number;
                code: string;
            }[]
        >;
    }>();
    const [triggerRerender, setTriggerRerender] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [listModeOn, setListModeOn] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { templateMsgState } = useTypedSelector(msgTemplatesSelector);
    const { month, year } = useTypedSelector(monthMsgSelector);
    const { token } = useTypedSelector(authSelector);
    const { filters } = useTypedSelector(filtersSelector);
    const { profile } = useTypedSelector(profileSelector);
    const { headerSearch } = useTypedSelector(projectsSelector);
    const { worksList } = useTypedSelector(agGridMsgSelector);
    const listMode = useTypedSelector(agGridListMode);
    const { t } = useTranslation('chartKeys');
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const { projectId } = useParams();

    const gridRef = useRef<AgGridReact>(null);
    const { getRowHeight } = useRowHeight(gridRef.current);

    const filtersJsonLength = JSON.stringify(filters).length;

    const { minLevelByListMode } = useGetMinMaxLevels(worksList?.data, listMode);
    const appliedFilters = useGetAppliedFilters();

    const isGantt = Boolean(hasParam('gant', location.search));

    const { update: updateCurrentTemplate } = useUpdateCurrentTemplate();
    const { columnWidths } = useGetCurrentTemplateColumnWidths();
    const { currentTemplate } = useTypedSelector(templatesSelector);

    const openFilterModal = (column: TSelectedColumn, queryTerm?: queryTermsType) => {
        setOpenModal(true);
        dispatch(setSelectedColumn(column));

        if (queryTerm) {
            dispatch(setQueryTerm(queryTerm));
        }
    };

    const closeFilterModal = () => {
        setOpenModal(false);
        dispatch(setSelectedColumn(null));
    };

    const context = useMemo(() => {
        return {
            projectId: projectId!,
            appliedFilters,
            openFilterModal: openFilterModal,
        } as TAgGridContext;
    }, [appliedFilters, projectId]);

    useLayoutEffect(() => {
        if (!worksList) return;
        if (!minLevelByListMode) {
            setHiddenRowsIds([]);
            return;
        }

        setHiddenRowsIds(
            worksList.data.filter((v) => v.level === minLevelByListMode).map((v) => ({ id: v.id, code: v.code }))
        );
    }, [worksList, minLevelByListMode]);

    if (gridRef?.current) {
        dispatch(setAgGrid(gridRef?.current));
    }

    useEffect(() => {
        return () => {
            dispatch(setAgGrid(null));
        };
    }, [location.pathname, projectId]);

    const getExecutors = useGetExecutorsQuery(
        {
            companyID: profile?.company?.companyID,
        },
        {
            skip: !profile?.company?.companyID,
        }
    );

    const getBrigades = useGetBrigadesQuery({
        projectID: projectId,
    });

    const headersCPG = useGetHeadersCPGQuery({
        id: Number(projectId),
    });

    const isCreatedMsg = useMemo(() => {
        return headersCPG.data?.allHeaders.filter((header) => {
            if (header.isCreatedMSG) {
                return header.month === month + 1 && header.year === year;
            }
        });
    }, [headersCPG.data?.allHeaders, month]);

    const getRowClass = useCallback((params: RowClassParams<WorkMsg, any>) => {
        const level = params.data?.level as keyof typeof classesByLevel;
        const classesByLevel = {
            0: 'level_zero',
            1: 'level_one',
            2: 'level_two',
            3: 'level_three',
            4: 'level_four',
            16: 'level_sixteen',
        };

        if (!params.data?.hasChildren) return classesByLevel[16];

        return classesByLevel[level] ?? classesByLevel[4];
    }, []);

    const handleColumnResized = (event: ColumnResizedEvent) => {
        if (event.source !== 'api' && event.source !== 'uiColumnDragged') {
            return;
        }
        if (event.finished && gridRef.current) {
            updateCurrentTemplate({
                columns: getGridColumnsAfterResize({
                    isGantt,
                    grid: gridRef.current,
                    currentTemplate: currentTemplate!,
                }),
            });
        }
    };

    const getRowId = useCallback((params: GetRowIdParams) => {
        return params.data.id;
    }, []);

    const filteredWorks = useMemo(() => {
        const filtArr = worksList?.data.reduce((acc: WorkMsg[], prev) => {
            const idx = hiddenRowsIds.every((filItem) => {
                if (prev.code === filItem.code) {
                    return true;
                }

                return !filItem.code.split('-').every((v) => prev.code.split('-').includes(v));
            });
            return idx ? [...acc, prev] : acc;
        }, []);
        if (!filtArr?.length || !worksList?.data.length) {
            gridRef.current?.api?.showNoRowsOverlay();
        }
        return filtArr?.length ? { total: filtArr.length || 1, data: filtArr } : worksList;
    }, [hiddenRowsIds, worksList]);

    // PAGINATION
    /**
     * устанавливается нужный экшен по условию, для диспатча события внутри useVerticalPagination
     */
    const getData = useCallback(
        (params: IGetParamsMSG) => {
            const sendingFunc = listMode ? getWorksAgGridOrphan : getWorksAgGrid;
            return sendingFunc(params);
        },
        [listMode]
    );

    /**
     * общее количество записей в БД
     */
    const [totalRows, setTotalRows] = useState(0);

    /**
     * Эффект следит за изменением projectId
     * и используя Api AgGrid управляет оверлэем таблицы
     * если workList не null отрисовывает таблицу,
     * в ином случае показывает что идет загрузка
     */
    useEffect(() => {
        if (worksList) {
            setTotalRows(worksList.total);
            gridRef.current?.api?.hideOverlay();
        } else {
            // gridRef.current?.api?.showLoadingOverlay()
        }
    }, [worksList]);
    /**
     *  добавил в массив resetToInitialDeps зависимость от projectId
     *  чтобы при смене проекта был перезапрос за данными
     */

    const scrollParams = JSON.parse(localStorage.getItem('scrollParams')!);
    const lastRenderedRow = scrollParams && scrollParams[location.pathname]?.lastRenderedRow;

    const isAllDataFetched = worksList?.total === worksList?.data?.length;

    usePagination<IGetParamsMSG>({
        initialParams: {
            ...getPaginationInitialParams(lastRenderedRow, projectId!, LIMIT),
            year: year,
            month: month + 1,
        },
        sendFn: getData,
        thenFn: () => {
            setIsLoading(false);
        },
        catchFn: () => {
            setIsLoading(false);
        },
        totalCount: totalRows,
        requiredDeps: [projectId, totalRows !== null, token],
        resetToInitialDeps: [
            filters,
            listMode,
            projectId,
            month,
            headerSearch,
            projectId,
            filtersJsonLength,
            token,
            triggerRerender,
            listModeOn,
        ],
        isAllDataFetched,
    });

    const getChartViewportDimensions = useCallback(
        (rowHeight: number, colWidth: number, AgGrid: AgGridReact | null) => {
            let width = 0;
            let height = (filteredWorks?.data.length || 0) * rowHeight;
            if (AgGrid) {
                AgGrid.columnApi?.getColumnState().map((columnState) => {
                    if (columnState.colId.includes('dailyCharts')) {
                        width += columnState?.width || colWidth;
                    }
                });
            }

            return {
                chartsViewportWidth: width,
                chartsViewportHeight: height,
            };
        },
        [filteredWorks?.data.length]
    );

    const drawInitialArrows = useCallback(() => {
        const { chartsViewportWidth, chartsViewportHeight } = getChartViewportDimensions(80, 120, gridRef.current);
        req.post(`/projects/${projectId}/dependencies/works/msg/info`, {
            month: month + 1,
            year: year,
        })
            .then(({ data }) => {
                if (gridRef.current) {
                    document.getElementById(CANVAS_ID + 'wrapper')?.remove();
                    createGantCanvasV2(CANVAS_ID, chartsViewportWidth, chartsViewportHeight);
                    drawDefaultArrowsFromServer(gridRef.current, data?.data);
                }
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', { variant: 'error' });
            });
    }, [getChartViewportDimensions, projectId, month, year, enqueueSnackbar]);

    useEffect(() => {
        if (searchParams.get('gant') && !document.getElementById(CANVAS_ID + 'wrapper')) {
            drawInitialArrows();
        }
        if (!searchParams.get('gant')) {
            document.getElementById(CANVAS_ID + 'wrapper')?.remove();
        }
    }, [searchParams]);

    if (isCreatedMsg?.length === 0 && DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_MSG_SMR')) {
        return (
            <Dialog
                open={true}
                onClose={() => {}}
            >
                <ShowMsgDialog setTriggerRerender={setTriggerRerender} />
            </Dialog>
        );
    }
    if (!filteredWorks) {
        return (
            <LinearProgress
                sx={{
                    width: getRowWidthMsg(daysInMonth(month, new Date().getFullYear()), templateMsgState) || 500,
                }}
            />
        );
    }

    return (
        <>
            <LoadingOverlay
                key='overlay'
                open={isLoading}
                transitionDuration={0}
                sx={{ zIndex: 999 }}
            />

            <FilterDialogs
                projectId={projectId!}
                isOpen={openModal}
                onClose={closeFilterModal}
            />

            <div className='msg'>
                <div
                    className='ag-theme-alpine'
                    style={{
                        height: '100%',
                        width: '100%',
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    {filteredWorks.data && (
                        <AgGridReact
                            ref={gridRef}
                            getRowId={getRowId}
                            getRowClass={getRowClass}
                            rowData={filteredWorks.data}
                            getRowHeight={getRowHeight}
                            columnDefs={getMSGColDefs({
                                hiddenRowsIds,
                                setHiddenRowsIds,
                                location,
                                getExecutors,
                                getBrigades,
                                gridRef,
                                worksList,
                                profile,
                                month,
                                year,
                                enqueueSnackbar,
                                columnWidths,
                            })}
                            {...AG_GRID_DEFAULT_PARAMS_KSG(location)}
                            gridOptions={{
                                ...AG_GRID_DEFAULT_PARAMS_KSG(location).gridOptions,
                                defaultColDef: {
                                    ...AG_GRID_DEFAULT_PARAMS_KSG(location).gridOptions?.defaultColDef,
                                    cellRenderer: (params: ICellRendererParams) => (
                                        <OverflowableTypographyWithTooltip maxRows={2}>
                                            {params.value}
                                        </OverflowableTypographyWithTooltip>
                                    ),
                                    resizable: true,
                                },
                                stopEditingWhenCellsLoseFocus: true,
                            }}
                            onCellValueChanged={(params) => {
                                submitCellData({
                                    params: params,
                                    projectID: projectId as string,
                                    enqueueSnackbar: enqueueSnackbar,
                                    translate: t,
                                });
                                params.api.setSuppressModelUpdateAfterUpdateTransaction(true);
                            }}
                            onRowDataUpdated={(params) => {
                                searchParams.get('gant') && drawInitialArrows();
                            }}
                            {...(!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_WORK_MSG') && {
                                suppressClickEdit: true,
                            })}
                            headerHeight={HEADER_ROW_HEIGHT}
                            groupHeaderHeight={HEADER_ROW_HEIGHT}
                            context={context}
                            onColumnResized={handleColumnResized}
                            // TODO:
                            // 1. В дальнейшем нужно добавить. Проблема что при включении, в cellEditor с date нельзя открыть календарь
                            // stopEditingWhenCellsLoseFocus
                        />
                    )}
                </div>
            </div>
        </>
    );
}
