import { LinearProgress } from "@mui/material";
import styled from "styled-components";
import { IProgressBarProps } from "./ProgressBar.types";

export const ProgressBarComponent = styled(LinearProgress)<IProgressBarProps>`
    width: 100%;
    &.MuiLinearProgress-root {
        display: block !important;
        height: 24px;
        border-radius: ${props => props.theme.spacing(0.5)};
        border: 1px solid rgba(203, 211, 244, 0.5);
        background-color: rgba(248, 249, 251, 1);
    };
    &.MuiLinearProgress-root::before {
        content: "";
        height: 100%;
        width: 4px;
        background: rgba(248, 249, 251, 1);
        z-index: 2;
        position: absolute;
    };
    &.MuiLinearProgress-root::after {
        content: "";
        height: 100%;
        width: 4px;
        right: 0;
        background: rgba(248, 249, 251, 1);
        z-index: 2;
        position: absolute;
    };
    & .MuiLinearProgress-bar {
        margin: ${props => props.theme.spacing(0.5)};
        border-radius: ${props => props.theme.spacing(0.5)};
        background-color: rgba(0, 68, 180, 0.7) !important;
    }
    & .MuiLinearProgress-bar::before {
        content: "${({ currentValue }) => currentValue} / ";
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        bottom: 0;
        right: 0;
        padding-top: 1px;
        padding-right: ${props => props.theme.spacing(0.5)};
        font-size: 12px;
        color: white;
        font-weight: 500;
    }
    & .MuiLinearProgress-bar::after {
        content: "${({ targetValue }) => targetValue}";
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        bottom: 0;
        right: ${({ targetValue }) => -(targetValue.toString().length * 9) + 'px'};
        padding-top: 1px;
        padding-right: ${props => props.theme.spacing(0.5)};
        font-size: 12px;
        color: rgba(0, 68, 180, 0.7);
        font-weight: 500;
    }
    ${props => {
        const visiblePartWidth = (props.value || 0) * 3
        return (props.currentValue >= props.targetValue || (props.value && props.value >= 80)) && ({
            "& .MuiLinearProgress-bar::before": {
                content: `"${props.currentValue} / ${props.targetValue}"`,
                right: visiblePartWidth / 2 - (props.currentValue + " / " + props.targetValue).length * 3
            },
            "& .MuiLinearProgress-bar::after": {
                display: "none",
            }
        })
    }}
    ${props => {
        return (props.value || 0) <= 30 && ({

            "& .MuiLinearProgress-bar::after": {
                content: `"${props.currentValue} / ${props.targetValue}"`,
                right: -((props.currentValue + " / " + props.targetValue).length * 7) + 'px'
                // right: "-20px"
                // right: visiblePartWidth / 2 - (props.currentValue + " / " + props.targetValue).length * 3
            },
            "& .MuiLinearProgress-bar::before": {
                display: "none",
            }
        })
    }}
`