import React from 'react'
import { useParams } from 'react-router-dom'
import { pagesNames } from '../../../index'
import { projectsSelector } from '../../../store/slices/projectsSlice'
import { useTypedSelector } from '../../../store/store'
import { BreadCrumbTitle } from './BreadCrumbTitle'
import ButtonTitleEl from './ButtonTitleEl'
import IconButtonEl from './IconButtonEl'
import { MyBreadCrumbs } from './MyBreadCrumbs'

export default function BreadcrumbsEl({
    projectTitle,
    setOpenPopoverLinks,
}: {
    projectTitle: string
    setOpenPopoverLinks: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>
}) {
    const { pageName } = useTypedSelector(projectsSelector)
    const { projectId } = useParams()

    const routeTitles: Partial<Record<pagesNames, React.ReactElement>> = {
        projects: (
            <MyBreadCrumbs>
                <BreadCrumbTitle>Все проекты</BreadCrumbTitle>
            </MyBreadCrumbs>
        ),
        projects_charts: (
            <MyBreadCrumbs>
                <BreadCrumbTitle>Все проекты</BreadCrumbTitle>
            </MyBreadCrumbs>
        ),
        users: (
            <MyBreadCrumbs>
                <BreadCrumbTitle>Пользователи</BreadCrumbTitle>
            </MyBreadCrumbs>
        ),
        users_add: (
            <>
                <IconButtonEl />
                <MyBreadCrumbs>
                    <BreadCrumbTitle>Пользователи</BreadCrumbTitle>
                    <BreadCrumbTitle>Управление пользователями</BreadCrumbTitle>
                </MyBreadCrumbs>
            </>
        ),
        users_edit: (
            <>
                <IconButtonEl />
                <MyBreadCrumbs>
                    <BreadCrumbTitle>Пользователи</BreadCrumbTitle>
                    <BreadCrumbTitle>Управление пользователями</BreadCrumbTitle>
                </MyBreadCrumbs>
            </>
        ),
        ksg: (
            <ButtonTitleEl
                titles={[{ title: 'КСГ' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        ksg_people: (
            <ButtonTitleEl
                titles={[{ title: 'КСГ' }, { title: 'Персонал' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        ksg_equip: (
            <ButtonTitleEl
                titles={[{ title: 'КСГ' }, { title: 'Механизмы' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        ksg_kc6a: (
            <ButtonTitleEl
                titles={[{ title: 'КСГ' }, { title: 'KC-6A' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        msg: (
            <ButtonTitleEl
                titles={[{ title: 'МСГ' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        msg_people: (
            <ButtonTitleEl
                titles={[{ title: 'МСГ' }, { title: 'Персонал' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        msg_equip: (
            <ButtonTitleEl
                titles={[{ title: 'МСГ' }, { title: 'Механизмы' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        executor: <></>,
        executor_people: <></>,
        executor_equip: <></>,
        ag_grid: <></>,
        ag_grid_msg: <></>,
        ag_grid_ksg_people: <></>,
        ag_grid_ksg_equip: <></>,
        ag_grid_temp: <></>,
        mto: (
            <ButtonTitleEl
                titles={[{ title: 'КСГ' }, { title: 'МТО' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        workManagement: (
            <ButtonTitleEl
                titles={[{ title: 'Кабинет управления работой' }]}
                projectTitle={projectTitle}
                isProjectTitleNav
            />
        ),
        brigades: (
            <ButtonTitleEl titles={[{ title: 'Справочник бригад' }]} projectTitle={projectTitle} isProjectTitleNav />
        ),
        addBrigade: (
            <ButtonTitleEl
                titles={[
                    { title: 'Справочник бригад', link: `/brigades/${projectId}` },
                    { title: 'Добавление бригады' },
                ]}
                projectTitle={projectTitle}
                isProjectTitleNav
            />
        ),
        editBrigade: (
            <ButtonTitleEl
                titles={[
                    { title: 'Справочник бригад', link: `/brigades/${projectId}` },
                    { title: 'Добавление бригады' },
                ]}
                projectTitle={projectTitle}
                isProjectTitleNav
            />
        ),
    }

    return routeTitles[pageName || pagesNames.projects] || <></>
}
