import { Backdrop, BackdropProps, Box } from '@mui/material';

import { UIEllipsisLoader } from '../../../../../components/ui/UIEllipsisLoader';
import { theme } from '../../../../../styles/theme';

export function LoadingOverlay(props: BackdropProps) {
    return (
        <Backdrop
            {...props}
            sx={{
                zIndex: 99,
                height: 'calc(100% - 175px)',
                bottom: 0,
                top: 'auto',
                right: theme.spacing(1),
                left: theme.spacing(1.5),
                background: 'white',
                borderRadius: 1,
                p: 2,
                ...props.sx,
            }}
        >
            <Box>{props.children || <UIEllipsisLoader>Загрузка</UIEllipsisLoader>}</Box>
        </Backdrop>
    );
}
