const KSG_DEFAULT_TEMPLATE_NAME = 'Диспетчер'
const MSG_DEFAULT_TEMPLATE_NAME = 'Диспетчер'

export const DEFAULT_TEMPLATE_NAME = {
    ksg: KSG_DEFAULT_TEMPLATE_NAME,
    msg: MSG_DEFAULT_TEMPLATE_NAME
}

export const DEFAULT_TEMPLATES_BY_TYPE = {
    ksg: [
        {
            name: 'Диспетчер',
            type: 'ksg',
            isCommon: true,
            version: 2,
            rowHeight: null,
            columns: [
                {
                    pinned: 'left',
                    name: 'level',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'numOrder',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'code',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'tag',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'mainGroup',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'objTitle',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'objName',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdGroup',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdCode',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdStatus',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdDateApproval',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdVersion',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdDate',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'typeOfWorks',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'workGroup',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'groupOfWorks',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'contractorCompany',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'workName',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'unit',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'volumeTotal',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'volumeDonePlan',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'volumeDoneFact',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'startDate',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'endDate',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'operationalStartDate',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'operationalEndDate',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHourCost',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursTotal',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressTZ',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressPlan',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressFact',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressPercent',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'machineHourCost',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'machineHoursTotal',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costER',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotal',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalProgress',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPlan',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressFact',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPercent',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costRemaining',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costDone',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costNZP',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costERGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalProgressGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPlanGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressFactGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPercentGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costNZPGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costERSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalProgressSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPlanSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressFactSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPercentSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costNZPSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'prStart',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'prEnd',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'prProvision',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'workPeriod',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'verifiedFactTotal',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'workBonds',
                    hide: true,
                    order: null,
                    width: null
                }
            ]
        },
        {
            name: 'Планировщик',
            type: 'ksg',
            isCommon: true,
            version: 3,
            rowHeight: null,
            columns: [
                {
                    pinned: null,
                    name: 'mode',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'level',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'numOrder',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'code',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'tag',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'mainGroup',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'objTitle',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'objName',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdGroup',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdCode',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdStatus',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdDateApproval',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdVersion',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdDate',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'typeOfWorks',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'workGroup',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'groupOfWorks',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'contractorCompany',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'workName',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'unit',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'volumeTotal',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'volumeDonePlan',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'volumeDoneFact',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'startDate',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'endDate',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'operationalStartDate',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'operationalEndDate',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHourCost',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursTotal',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressTZ',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressPlan',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressFact',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressPercent',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'machineHourCost',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'machineHoursTotal',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costER',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotal',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalProgress',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPlan',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressFact',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPercent',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costRemaining',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costDone',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costNZP',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costERGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalProgressGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPlanGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressFactGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPercentGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costNZPGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costERSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalProgressSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPlanSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressFactSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPercentSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costNZPSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'prStart',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'prEnd',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'prProvision',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'workPeriod',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'verifiedFactTotal',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'workBonds',
                    hide: false,
                    order: null,
                    width: null
                }
            ]
        }
    ],
    msg: [
        {
            name: 'Диспетчер',
            type: 'msg',
            isCommon: true,
            version: 2,
            rowHeight: null,
            columns: [
                {
                    pinned: 'left',
                    name: 'level',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'numOrder',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'code',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'objTitle',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'objName',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdGroup',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdCode',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdStatus',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdDateApproval',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdVersion',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdDate',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'typeOfWorks',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'workGroup',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'contractorCompany',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'executorName',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'brigade',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'workName',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'unit',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'volumeTotal',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'volumeDoneFactKsg',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'volumeTotalKsg',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'volumeDonePlan',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'volumeDoneForecast',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'volumeDoneFact',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'startDate',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'endDate',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'operationalStartDate',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'operationalEndDate',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHourCost',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursTotal',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressTZ',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressPlan',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressForecast',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressFact',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressPercent',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costER',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotal',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalProgress',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPlan',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressFact',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPercent',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costERGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalProgressGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPlanGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressFactGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPercentGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costERSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalProgressSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPlanSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressFactSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPercentSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costRemaining',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'verifiedFactTotal',
                    hide: true,
                    order: null,
                    width: null
                }
            ]
        },
        {
            name: 'Планировщик',
            type: 'msg',
            isCommon: true,
            version: 2,
            rowHeight: null,
            columns: [
                {
                    pinned: 'left',
                    name: 'level',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'numOrder',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'code',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'objTitle',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'objName',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdGroup',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdCode',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdStatus',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdDateApproval',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdVersion',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'rdDate',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'typeOfWorks',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'workGroup',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'contractorCompany',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'executorName',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'brigade',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'workName',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'unit',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'volumeTotal',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'volumeDoneFactKsg',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'volumeTotalKsg',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'volumeDonePlan',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'volumeDoneForecast',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'volumeDoneFact',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'startDate',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'endDate',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'operationalStartDate',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: 'left',
                    name: 'operationalEndDate',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHourCost',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursTotal',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressTZ',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressPlan',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressForecast',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressFact',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'humanHoursProgressPercent',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costER',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotal',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalProgress',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPlan',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressFact',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPercent',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costERGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalProgressGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPlanGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressFactGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPercentGK',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costERSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costTotalProgressSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPlanSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressFactSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costProgressPercentSub',
                    hide: true,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'costRemaining',
                    hide: false,
                    order: null,
                    width: null
                },
                {
                    pinned: null,
                    name: 'verifiedFactTotal',
                    hide: true,
                    order: null,
                    width: null
                }
            ]
        }
    ]
}
