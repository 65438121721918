import s from '../Synchronize/Synchronize.module.scss'
import React, { ReactElement, useCallback, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import FormControl from '@mui/material/FormControl'
import { Button } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import {
    drawersSelector,
    setSyncModuleData,
    toggleSynchronize,
    toggleSyncModule,
} from '../../store/slices/drawersSlice'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { useStartImportSynchronizeMutation } from '../../api/importingData/importingData.api'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'
import { pickSyncModuleType } from '../../api/importingData/importingData.def'
import CircularProgress from '@mui/material/CircularProgress'
import { getDateTimeString } from '../../shared/utils'
import { ButtonsAndWarningEl } from '../../shared/components/ButtonsAndWarning/ButtonsAndWarning'
import ErrorsList from '../../shared/components/ErrorsList/ErrorsList'
import { modulesNames } from '../Synchronize/Synchronize.service'

export type errorsType =
    | 'cross_module.module_not_binded'
    | 'cross_module.bind_not_found'
    | 'cross_module.module_unavailable'
type importResErrorsType = 'updated' | 'unchanged' | 'unmatched' | 'uninvolved'

export type errorSyncType = {
    data?: errorsType
    error?: string
    originalStatus?: 400 | 403
    status?: string
} | null

export default function SyncDone() {
    const [error, setError] = useState<errorSyncType>(null)
    const [showErrorsList, setShowErrorsList] = useState(false)
    const [errorScreenVariant, setErrorScreenVariant] = useState<importResErrorsType>('updated')

    const { syncModuleData } = useTypedSelector(drawersSelector)

    const [startImportSyncReq, startImportSyncRes] = useStartImportSynchronizeMutation()

    const { projectId } = useParams()
    const dispatch = useAppDispatch()

    const { enqueueSnackbar } = useSnackbar()

    const errorsText = {
        'cross_module.bind_not_found': `Привязаный проект не найден в модуле`,
        'cross_module.module_unavailable': `Модуль временно недоступен, пожалуйста повторите загрузку позже`,
        'cross_module.module_not_binded': 'Нет привязанного модуля',
    }

    const handleChange = (event: SelectChangeEvent) => {
        const module = event.target.value as pickSyncModuleType
        if (!syncModuleData) return
        dispatch(setSyncModuleData({ ...syncModuleData, module }))
    }

    const onSubmit = async () => {
        try {
            const result = await startImportSyncReq({
                projectId: Number(projectId),
                body: {
                    module: syncModuleData?.module || 'PragmaDesign',
                },
            }).unwrap()

            if (result.success) {
                enqueueSnackbar(result.description, {
                    variant: 'success',
                })
            }
        } catch (e: any) {
            const err: errorSyncType = e
            setError(err)
            enqueueSnackbar(errorsText[e.data as errorsType], {
                variant: 'error',
            })
        }
    }

    // const onSubmitChoose = () => {
    //     setShowAgain(true)
    // }

    const handleClickErrorsDone = (variant: importResErrorsType) => {
        setShowErrorsList(true)
        setErrorScreenVariant(variant)
    }
    /**
     * Обернул в useCallback для того чтобы отслеживать какой модуль синхронизируется
     * если это Procurement то не нужно выводить ссылку на просмотр обновлений
     */
    const linkOrTextDoneEl = useCallback((variant: importResErrorsType, num: number | undefined, text: string) => {
        return num ? (
            <span className={syncModuleData?.module !== 'PragmaProcurement' ? s.link_style : ''}
                  onClick={() => syncModuleData?.module !== 'PragmaProcurement' && handleClickErrorsDone(variant)}>
                {`${text} ${num}${variant !== 'uninvolved' ? ', ' : ''}`}
            </span>
        ) : (
            `${text} ${num}${variant !== 'uninvolved' ? ', ' : ''}`
        )
    }, [syncModuleData])

    const errorsScreenVariantsObj: Record<importResErrorsType, ReactElement> = {
        updated: (
            <ErrorsList
                setShowErrorsList={setShowErrorsList}
                errorMessage='Данные работ по шифру обновлены.'
                errorsImportObj={startImportSyncRes.data?.updatedList}
                title='Обновлено'
            />
        ),
        unchanged: (
            <ErrorsList
                setShowErrorsList={setShowErrorsList}
                errorMessage='Данные работ актуальны на момент интеграции.'
                errorsImportObj={startImportSyncRes.data?.unchangedList}
                title='Без изменений'
            />
        ),
        unmatched: (
            <ErrorsList
                setShowErrorsList={setShowErrorsList}
                errorMessage='Отсутствует в проекте Design.'
                errorsImportObj={startImportSyncRes.data?.unmatchedList}
                title='Нет совпадений'
            />
        ),
        uninvolved: (
            <ErrorsList
                setShowErrorsList={setShowErrorsList}
                errorMessage='Работ с данным шифром не найдено в проекте RM.'
                errorsImportArr={startImportSyncRes.data?.uninvolvedList}
                title='Шифров не задействовано'
            />
        ),
    }

    const doneEl = (
        <>
            <div className={s.done_icon_container}>
                <CheckIcon
                    sx={{
                        fill: '#6FCCBC',
                        width: '3rem',
                        height: '100%',
                    }}
                />
            </div>
            <p>
                Синхронизация проекта прошла успешно!{' '}
                {linkOrTextDoneEl('updated', startImportSyncRes.data?.updated, 'Обновлены шифры РД:')}
                {syncModuleData?.module !== 'PragmaProcurement'
                    ? linkOrTextDoneEl('unchanged', startImportSyncRes.data?.unchanged, 'без изменений:')
                    : ''}
                {syncModuleData?.module !== 'PragmaProcurement'
                    ? linkOrTextDoneEl('unmatched', startImportSyncRes.data?.unmatched, 'без совпадений:')
                    : ''}
                {syncModuleData?.module !== 'PragmaProcurement'
                    ? linkOrTextDoneEl('uninvolved', startImportSyncRes.data?.uninvolved, 'шифров не задействовано:')
                    : ''}
            </p>
        </>
    )

    const errorEl = (
        <>
            <div className={s.done_icon_container}>
                <WarningAmberIcon
                    sx={{
                        fill: '#F46B6B',
                        width: '3rem',
                        height: '100%',
                    }}
                />
            </div>
            <p>
                Ошибка!
                <br />
                {errorsText[error?.data as errorsType]}
            </p>
        </>
    )

    const buttons = {
        getClose: (
            <ButtonsAndWarningEl
                onClose={() => dispatch(toggleSyncModule())}
                onSubmit={onSubmit}
                submitText='получить'
                cancelText='закрыть'
            />
        ),
        done: (
            <Button
                disabled={startImportSyncRes.isLoading}
                variant='contained'
                color='success'
                onClick={() => dispatch(toggleSyncModule())}
            >
                Готово
            </Button>
        ),
        // chooseClose: (
        //     <ButtonsAndWarningEl
        //         onClose={() => dispatch(toggleSynchronize())}
        //         onSubmit={onSubmitChoose}
        //         submitText="выбрать проект"
        //         cancelText="закрыть"
        //     />
        // ),
    }

    const renderBottomEl = () => {
        if (error) {
            if (error.data === 'cross_module.module_not_binded') {
                return (
                    <>
                        <div className={s.percent_container}>{errorEl}</div>
                        {buttons['done']}
                    </>
                )
            }

            if (error.data === 'cross_module.bind_not_found') {
                return (
                    <>
                        <div className={s.percent_container}>{errorEl}</div>
                        {buttons['done']}
                    </>
                )
            }

            if (error.data === 'cross_module.module_unavailable') {
                return (
                    <>
                        <div className={s.percent_container}>{errorEl}</div>
                        <Button
                            disabled={startImportSyncRes.isLoading}
                            variant='contained'
                            color='primary'
                            onClick={() => dispatch(toggleSynchronize())}
                        >
                            Закрыть
                        </Button>
                    </>
                )
            }
        }

        if (startImportSyncRes.isLoading) {
            return (
                <div className={s.progress_center}>
                    <CircularProgress />
                </div>
            )
        }

        if (startImportSyncRes.isSuccess) {
            return (
                <>
                    <div className={s.percent_container}>{doneEl}</div>
                    {buttons['done']}
                </>
            )
        }

        return buttons['getClose']
    }

    if (showErrorsList) {
        return errorsScreenVariantsObj[errorScreenVariant]
    }

    return (
        <>
            <h4 className={s.sync_header}>Синхронизовать данные</h4>

            <section className={s.synchronize_main_second}>
                <div className={s.desc_top}>
                    <h4>ВНИМАНИЕ!</h4>

                    <p>
                        При синхронизации данных на дату, для которой существует другой отчет, он будет полностью
                        перезаписан данными из нового файла.
                    </p>
                </div>

                <p className={s.date_text}>
                    Последняя синхронизация: {getDateTimeString(syncModuleData?.lastTime || null)}
                </p>

                <FormControl fullWidth>
                    <Select
                        disabled
                        sx={{
                            '.MuiSelect-select': {
                                padding: '5px 14px',
                                fontSize: 14,
                                fontWeight: 500,
                                backgroundColor: '#F6F7FB',
                            },
                            '.MuiInputBase-input': {
                                paddingRight: '1rem !important',
                            },
                            '.MuiSvgIcon-root': {
                                display: 'none',
                            },
                        }}
                        value={syncModuleData?.module || ''}
                        onChange={handleChange}
                    >
                        <MenuItem value={syncModuleData?.module}>
                            {modulesNames[syncModuleData?.module || 'PragmaDesign']}
                        </MenuItem>
                    </Select>
                </FormControl>

                <div className={s.project_line_sync}>
                    <div className={s.project_line_left}>
                        <DescriptionOutlinedIcon
                            sx={{
                                width: '1.1rem',
                                height: '1.1rem',
                            }}
                        />
                        <p>Проект:</p>
                    </div>
                    <h4>{syncModuleData?.moduleProjectName || ''}</h4>
                </div>

                {renderBottomEl()}
            </section>
        </>
    )
}
