import { Menu, MenuItem } from '@mui/material';
import styled from 'styled-components';

export const StyledMenu = styled(Menu)`
    .MuiPaper-root {
        padding: ${({ theme }) => theme.spacing(0)};
        width: 300px;
        max-height: 760px;
    }
    .MuiList-root {
        padding: 0;
    }
`;

export const StyledMenuItem = styled(MenuItem)`
    display: flex;
    gap: ${({ theme }) => theme.spacing(1)};
    color: ${({ theme }) => theme.palette.secondary.light};
    p {
        padding: ${({ theme }) => theme.spacing(1)} 0;
    }
`;
