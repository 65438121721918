import { Box, Typography } from '@mui/material';
import { CellValueChangedEvent, ColDef, ColGroupDef, GetRowIdParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';

import { setWorkManagmetResourceGridApi } from '@/store/slices/drawersSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { setRefreshCostGrid } from '@/store/slices/workManagementSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { FlexRowWrapper } from '../../../../NewExecutorView/components/components.styles';
import { CollectionsAPI } from '../../../api/CollectionsAPI';
import { ResourceAPI } from '../../../api/ResourcesAPI';
import { TResourceType } from '../../../api/api.types';
import { StyledAgGrid } from '../../../components/AgGridService/AgGrid.styles';
import {
    AG_GRID_RESOURCES_PARAMS,
    disableAddAndDelete,
} from '../../../components/AgGridService/AgGridColumnDef.service';
import { IAutocompleteOption } from '../../../components/CellEditors/CellEditor/CellEditor.def';
import { CardTitle, CardWrapper } from '../../../components/components.styles';
import { StyledStatProgress } from '../ResourcesPage.styles';
import { IOverallValues, IResource, IStaffResource } from '../ResoursePage.types';
import { PARAMS } from './ResourceCard.service';
import { IResourceCard } from './ResourceCard.types';

export function ResourceCard({
    resourceType,
    triggerRefresh,
    AgGridProps,
    calculateTotalPlan,
    estimateTotal,
}: IResourceCard) {
    const { projectId, workID } = useParams();
    const [rowData, setRowData] = useState<IStaffResource[] | null>(null);
    const { t } = useTranslation('resource_types');
    const { profile } = useTypedSelector(profileSelector);
    const [proffList, setProffList] = useState<IAutocompleteOption[]>([]);
    const [refreshProffList, setRefreshProffList] = useState<boolean>(false);
    const [overall, setOverall] = useState<IOverallValues | null>(null);
    const [TZMOverall, setTZMOverall] = useState<IOverallValues | null>(null);
    const dispatch = useAppDispatch();
    const gridRef = useRef<AgGridReact>(null);
    const [isRefreshEstimateTotal, setIsRefreshEstimateTotal] = useState<boolean>(false);

    useEffect(() => {
        ResourceAPI.getList({
            resourceType: resourceType,
            projectID: projectId,
            workID: workID,
        }).then(({ data, overall }) => {
            setRowData((prevValue) => data);
            setOverall((prevState) => (resourceType == 'mim' ? overall.mimOverall : overall));
            setTZMOverall((prevValue) => overall.tzmOverall);
            setIsRefreshEstimateTotal((prev) => !prev);
        });
    }, [triggerRefresh]);

    useEffect(() => {
        if (estimateTotal === null || estimateTotal === undefined) return;

        setOverall((prevState) => ({
            ...(prevState as IOverallValues),
            estimateValue: estimateTotal,
        }));
    }, [estimateTotal, isRefreshEstimateTotal]);

    useEffect(() => {
        gridRef &&
            dispatch(
                setWorkManagmetResourceGridApi({
                    [resourceType]: gridRef.current,
                })
            );
        return;
    }, []);

    useEffect(() => {
        resourceType === 'staff' &&
            CollectionsAPI.getProffList({
                limit: 9999,
                offset: 0,
                filter: '',
            }).then(({ data }) => {
                setProffList((prevState) =>
                    data.map((proff) => ({
                        label: proff.name,
                        value: proff.id.toString(),
                    }))
                );
            });
    }, [refreshProffList]);

    function getTotals(params: CellValueChangedEvent) {
        getTotalPlan(params);
        getTotalRatio(params);
        resourceType === 'mim' && getTZMTotalPlan(params);
    }

    function getTotalPlan(params: CellValueChangedEvent) {
        setOverall((prevValue) => ({
            ...prevValue!,
            totalValue: calculateTotalPlan(params),
        }));
    }

    function getTotalRatio(params: CellValueChangedEvent) {
        let totalRequired = 0;
        let totalAvailable = 0;
        params.api.forEachNode((node) => {
            totalRequired += node.data.required;
            totalAvailable += node.data.available;
        });

        setOverall((prevValue) => ({
            ...prevValue!,
            totalRatio: (totalAvailable / totalRequired || 0) * 100,
        }));
    }

    const refreshCostGrid = () => {
        dispatch(setRefreshCostGrid());
    };

    function getTZMTotalPlan(params: CellValueChangedEvent) {
        let TZMTotal = 0;
        params.api.forEachNode((node) => {
            TZMTotal += node.data.tzmIncluded && node.data.plan;
            return;
        });
        setTZMOverall((prevValue) => ({
            ...prevValue!,
            totalValue: TZMTotal,
        }));
    }

    function getTargetColDef(resourceType: TResourceType) {
        return PARAMS[resourceType].colDef;
    }

    return (
        <CardWrapper gridColumn={resourceType == 'mto' ? '1/3' : 'auto'}>
            <Box
                display='flex'
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <CardTitle size='small'>{t(resourceType)}</CardTitle>

                <Box
                    display={'flex'}
                    alignItems={'center'}
                    gap={1}
                >
                    <StyledStatProgress
                        NumericFormatProps={{
                            suffix: PARAMS[resourceType].suffix,
                        }}
                        currentValue={{
                            value: overall?.totalValue,
                            TypographyProps: {
                                color: (overall?.totalValue || 0) > (overall?.estimateValue || 0) ? 'error' : 'primary',
                            },
                        }}
                        totalValue={{
                            value: overall?.estimateValue ?? 0,
                        }}
                    />
                    {/* <InfoOutlined
                        fontSize="small"
                        color={"secondary"}
                        sx={{
                            cursor: "pointer"
                        }}
                    /> */}
                </Box>

                {/* <PercentStatusValue
                    value={totalRatio}
                    variant={getStatus(totalRatio || 0)}
                /> */}
            </Box>
            <StyledAgGrid
                ref={gridRef}
                rowData={rowData}
                columnDefs={getTargetColDef(resourceType) as ColDef[] | ColGroupDef[]}
                context={{
                    projectID: projectId,
                    workID: workID,
                    resourceType: resourceType,
                    proffList: proffList,
                    setRefreshProffList: setRefreshProffList,
                    setProffList: setProffList,
                    getTotals: getTotals,
                    profile: profile,
                    refreshCostGrid: refreshCostGrid,
                }}
                {...AG_GRID_RESOURCES_PARAMS(getTotals)}
                onRowDataUpdated={(event) => {
                    if (event.type == 'rowDataUpdated') {
                        getTotals(event as any);
                    }
                }}
                getRowId={(params: GetRowIdParams<any, IResource>) => params.data?.id}
                animateRows
                {...((!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_WORK_MANAGMENT') ||
                    JSON.parse(localStorage.getItem('work')!)?.hasChildren) && {
                    suppressClickEdit: true,
                    ...disableAddAndDelete(true),
                })}
                {...AgGridProps}
            />
            {resourceType === 'mim' && (
                <FlexRowWrapper
                    justifyContent={'flex-end'}
                    sx={{ transform: 'scale(0.9)' }}
                >
                    <Typography variant='body2'>В т.ч. ТЗМ:</Typography>
                    <StyledStatProgress
                        NumericFormatProps={{
                            suffix: 'ч.ч.',
                        }}
                        currentValue={{
                            value: TZMOverall?.totalValue,
                            TypographyProps: {
                                color:
                                    (TZMOverall?.totalValue || 0) > (TZMOverall?.estimateValue || 0)
                                        ? 'error'
                                        : 'primary',
                            },
                        }}
                        totalValue={{
                            value: TZMOverall?.estimateValue || 0,
                        }}
                    />
                </FlexRowWrapper>
            )}
        </CardWrapper>
    );
}
