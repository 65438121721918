import { Fragment, useCallback, useEffect } from 'react'
import { toggleCreateMsgTemplate } from '../../store/slices/drawersSlice'
import { fixShow } from '../../store/slices/cpgViewSlice'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { bodyTemplateMsg } from './TableTemplate.service'
import { useAddTemplateMsgMutation } from '../../api/templates/templates.api'
import SharedTemplateDrawer from './components/SharedTemplateDrawer'
import s from './Templates.module.scss'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
    applyTemplateMsg,
    checkTemplateMsgItemView,
    initMsgTemplateStateType,
    msgTemplatesSelector,
    refreshTemplateMsgView, setTemplateMsgState, setTemplateMsgStateView,
} from '../../store/slices/msgTemplatesSlice'
import { setTemplateName, sharedTemplatesSelector } from '../../store/slices/templatesSharedSlice'
import { applyTemplateKsg } from '../../store/slices/ksgTemplatesSlice'
import { useSetPatternKsgMutation, useSetPatternMsgMutation } from '../../api/templatesGetSet/templatesGetSet.api'

export default function CreateMsgTemplate() {
    const { templateName } = useTypedSelector(sharedTemplatesSelector)
    const { templateMsgState,templateMsgStateView } = useTypedSelector(msgTemplatesSelector)

    const [addTemplateReq] = useAddTemplateMsgMutation()
    const [setPatternReq] = useSetPatternMsgMutation()

    const dispatch = useAppDispatch()

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation('mutations')

    const { projectId } = useParams()

    const handleChange = (checked: boolean, term: keyof Omit<initMsgTemplateStateType, 'name'>, type: fixShow) => {
        dispatch(checkTemplateMsgItemView(term, checked, type))
    }

    useEffect(() => {
        dispatch(refreshTemplateMsgView())
    }, [dispatch])

    const sendAddTemplate = useCallback(()=> {
        addTemplateReq({
            id: Number(projectId),
            body: {...bodyTemplateMsg(templateMsgStateView),name: templateName,},
        })
            .unwrap()
            .then((response) => {
                dispatch(applyTemplateMsg())
                setPatternReq({ projectId: Number(projectId), patId: response.id })
                    .then(() => {
                        enqueueSnackbar(t('template_added'), {
                            variant: 'success',
                        })
                    })
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                })
                console.error(e)
            })
    },[addTemplateReq, projectId, templateMsgStateView, templateName])

    useEffect(() => {
        dispatch(setTemplateName(''))
    }, [dispatch])

    return (
        <Fragment>
            <div className={s.header}>
                <h4>Создать шаблон таблицы МСГ</h4>
            </div>
            <div className={s.container_create}>
                <SharedTemplateDrawer
                    onSave={() => {
                        sendAddTemplate()
                        dispatch(toggleCreateMsgTemplate())
                    }}
                    onCancel={() => {
                        dispatch(toggleCreateMsgTemplate())
                    }}
                    onCheckItemMsg={(checked, term, type) => {
                        handleChange(checked, term, type)
                    }}
                />
            </div>
        </Fragment>
    )
}
