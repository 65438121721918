import { Box, Typography } from '@mui/material';
import { ColumnState } from 'ag-grid-community';
import { useSnackbar } from 'notistack';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigation, useParams } from 'react-router-dom';

import { useToggleSearchParam } from '@/hooks/useToggleSearchParam';

import { req } from '@/pages/WorkManagment/api/api';
import { LoadingOverlay } from '@/pages/WorkManagment/components/AgGridService/components/LoadingOverlay';

import { IntegrationWithOfferSearchParam } from '@/shared/constants/integration-with-offer';
import { Nullable } from '@/shared/def';

import { drawersSelector } from '@/store/slices/drawersSlice';
import { integrationStatusSelector } from '@/store/slices/integrationStatusSlice';
import { setCurrentTemplate, templatesSelector } from '@/store/slices/templatesSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { DEFAULT_TEMPLATE_NAME } from './ConfigureAgGridColDefTemplate.config';
import {
    IColumnTemplate,
    IConfigureTemplateListBtnProps,
    TPatternsListType,
} from './ConfigureAgGridColDefTemplate.def';
import { useUpdateDefaultTemplates } from './ConfigureAgGridColDefTemplate.model';
import {
    addDefaultTemplates,
    getCurrentColDefsState,
    getFilteredColumnsState,
    getSortedTemplates,
    hasDefaultTemplates,
    isPageWithDefaultTemplates,
} from './ConfigureAgGridColDefTemplate.service';
import { UIAddEditDrawer } from './ui/UIAddEditDrawer';
import { UIButton } from './ui/UIButton';
import { UICancelConfirmDialog } from './ui/UICancelConfirmDialog';
import { UIDeleteConfirmDialog } from './ui/UIDeleteConfirmDialog';
import { UIMenu } from './ui/UIMenu';

/**
 * Создает меню управления шаблонами колонок AgGrid на основе gridApi.
 * Генерирует доступные к управлению колонки на основании colDefs и colId.
 * Если у колонки в columnDefs отсутствует colId - в шаблон она не попадет
 */

export type TMode = 'add' | 'edit';

export function ConfigureAgGridColDefTemplate({ type, excludeColIds }: IConfigureTemplateListBtnProps) {
    const { projectId } = useParams();
    const dispatch = useAppDispatch();
    const { isActive } = useToggleSearchParam(IntegrationWithOfferSearchParam);
    const integrationStatus = useTypedSelector(integrationStatusSelector);
    const isOfferActive = integrationStatus?.isImportFromOffer && isActive;
    const { enqueueSnackbar } = useSnackbar();
    const { AgGrid } = useTypedSelector(drawersSelector);
    const [openTemplateListMenu, setOpenTemplateListMenu] = useState<boolean>(false);
    const [openTemplateSettingsDrawer, setOpenTemplateSettingsDrawer] = useState<boolean>(false);
    const [templateList, setTemplatesList] = useState<IColumnTemplate[]>([]);
    const [refreshTemplateList, setRefreshTemplateList] = useState<boolean>(false);
    const [mode, setMode] = useState<Nullable<TMode>>(null);
    const [activeTemplate, setActiveTemplate] = useState<IColumnTemplate | null>(null);
    const [isConfirmCancelDialogOpen, setIsConfirmCancelDialogOpen] = useState<boolean>(false);
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState<boolean>(false);
    const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
    const { isRefreshList, currentTemplate } = useTypedSelector(templatesSelector);
    const [activeTemplateOverlay, setActiveTemplateOverlay] = useState({
        shown: true,
        message: 'Получаем данные...',
    });
    const navigation = useNavigation();
    const isPageLoading = navigation.state === 'loading';

    const sortedTemplates = getSortedTemplates(templateList, type as TPatternsListType);

    const btnRef = useRef() as RefObject<HTMLButtonElement>;

    const { control, getValues, formState, resetField, setValue } = useForm({
        mode: 'all',
        defaultValues: {
            name: '',
        },
    });

    const refreshTemplates = () => setRefreshTemplateList((prevState) => !prevState);

    useUpdateDefaultTemplates({
        type,
        templateList,
        isPageLoading,
        activeTemplate,
        updateActiveTemplate: (updatedActiveTemplate: IColumnTemplate) => {
            setActiveTemplate(updatedActiveTemplate);
            applyTemplateToAgGrid(updatedActiveTemplate);
        },
        updateTemplateList: (updatedList: IColumnTemplate[]) => {
            setTemplatesList((prev) => {
                const ids = updatedList.map((v) => v.id);
                return [...updatedList, ...prev.filter((v) => !ids.includes(v.id))];
            });
        },
    });

    useEffect(() => {
        refreshTemplates();
    }, [isRefreshList]);

    useEffect(() => {
        req.get(`/projects/${projectId}/patterns/list?type=${type}`)
            .then(async ({ data }) => {
                const templates = data.data as IColumnTemplate[];
                const activeTemplateFromData = data.data.find((template: IColumnTemplate) => template.isActive) ?? null;

                const shouldAddDefaultTemplates =
                    !isPageLoading &&
                    isPageWithDefaultTemplates(type) &&
                    !hasDefaultTemplates(type as TPatternsListType, templates);

                if (shouldAddDefaultTemplates) {
                    await addDefaultTemplates({
                        type: type as TPatternsListType,
                        projectId: projectId!,
                        hasActiveTemplate: Boolean(activeTemplateFromData),
                        refreshTemplates,
                        enqueueSnackbar,
                    });
                }

                const shouldSetDefaultTemplate =
                    !isPageLoading && isPageWithDefaultTemplates(type) && !activeTemplateFromData;

                if (shouldSetDefaultTemplate) {
                    const activeTemplate = data.data.find(
                        (template: IColumnTemplate) =>
                            template?.name === DEFAULT_TEMPLATE_NAME[type as TPatternsListType]
                    );
                    await req.post(`/projects/${projectId}/patterns/${activeTemplate?.id}/set`);
                    refreshTemplates();
                }

                if (activeTemplateFromData?.id !== activeTemplate?.id) {
                    setActiveTemplateOverlay((prevState) => ({
                        ...prevState,
                        message: 'Применяем настройки шаблона...',
                    }));
                    setActiveTemplate(() => activeTemplateFromData);
                    dispatch(setCurrentTemplate(activeTemplateFromData));
                }
                setTemplatesList(() => templates);
            })
            .catch((e) => {
                enqueueSnackbar('Не удалось загрузить список доступных шаблонов', {
                    variant: 'error',
                });
            });
    }, [refreshTemplateList, projectId, type]);

    useEffect(() => {
        if (!isOfferActive) return;
        if (!activeTemplate) return;

        setTimeout(() => applyTemplateToAgGrid(activeTemplate));
    }, [isOfferActive]);

    useEffect(() => {
        if (!!activeTemplate && AgGrid) {
            saveChosenActiveTemplateToServer(activeTemplate.id);
            setTimeout(() => applyTemplateToAgGrid(activeTemplate));
        }
        activeTemplate
            ? localStorage.setItem(
                  'activeTemplate',
                  JSON.stringify(getServerTemplateConvertedIntoAgGridColState(activeTemplate))
              )
            : localStorage.removeItem('activeTemplate');
    }, [activeTemplate, AgGrid, projectId, type]);

    useEffect(() => {
        activeTemplateOverlay.shown &&
            setTimeout(() => {
                resetOverlay();
            }, 1000);
    }, [activeTemplateOverlay]);

    useEffect(() => {
        setOpenTemplateSettingsDrawer(!!mode);
        mode === 'edit' && setValue('name', activeTemplate?.name || '', { shouldValidate: true });
        if (mode === null) {
            setIsConfirmCancelDialogOpen(() => false);
            setIsConfirmDeleteDialogOpen(() => false);
            setIsFormChanged(() => false);
            resetField('name');
        }
    }, [mode]);

    const applyTemplateToAgGrid = useCallback(
        (activeTemplate: IColumnTemplate) => {
            AgGrid?.columnApi?.applyColumnState({
                state: getServerTemplateConvertedIntoAgGridColState(activeTemplate) as ColumnState[],
            });
        },
        [AgGrid]
    );

    function saveChosenActiveTemplateToServer(templateID: number) {
        req.post(`/projects/${projectId}/patterns/${templateID}/set`).catch((e) => {
            enqueueSnackbar('Не удалось сохранить выбранный шаблон в качестве активного', { variant: 'error' });
        });
    }

    function resetOverlay() {
        setActiveTemplateOverlay(() => ({
            shown: false,
            message: '',
        }));
    }

    const createSubmitPayloadByMode = (mode: TMode) => {
        if (mode === 'add') {
            return {
                name: getValues('name'),
                type: type,
                isCommon: false,
                rowHeight: null,
                columns: getFilteredColumnsState(AgGrid).map((columnState) => ({
                    name: (columnState as ColumnState).colId,
                    pinned: (columnState as ColumnState).pinned,
                    hide: (columnState as ColumnState).hide,
                    width: (columnState as ColumnState).width,
                })),
                ganttSeparatorPosition: null,
                isActive: true,
                isGanttOn: false,
                isOwner: true,
                version: null,
            };
        }

        return {
            ...currentTemplate,
            name: getValues('name'),
            columns: getFilteredColumnsState(AgGrid).map((columnState) => ({
                name: (columnState as ColumnState).colId,
                pinned: (columnState as ColumnState).pinned,
                hide: (columnState as ColumnState).hide,
                width: (columnState as ColumnState).width,
            })),
        };
    };

    const getSubmitRequestInfoByMode = (mode: TMode) => {
        if (mode === 'add') {
            return {
                URL: `/projects/${projectId}/patterns/add`,
                successMessage: ' добавлен',
            };
        }

        return {
            URL: `/projects/${projectId}/patterns/${activeTemplate?.id}/update`,
            successMessage: ' изменен',
        };
    };

    function onSubmit() {
        const { URL, successMessage } = getSubmitRequestInfoByMode(mode!);
        const payload = createSubmitPayloadByMode(mode!);

        formState.isValid &&
            URL &&
            req
                .post(URL, payload)
                .then(({ data }) => {
                    if (mode === 'add') {
                        saveActiveTemplate(data);
                        refreshTemplates();
                        resetField('name');
                    }
                    if (mode === 'edit') {
                        setTemplatesList((prevState) =>
                            prevState.map((template) => {
                                if (activeTemplate && template.id === activeTemplate?.id) {
                                    return {
                                        ...template,
                                        columns: getFilteredColumnsState(AgGrid).map((columnState) => ({
                                            name: (columnState as ColumnState).colId,
                                            pinned: (columnState as ColumnState).pinned,
                                            hide: (columnState as ColumnState).hide,
                                            width: (columnState as ColumnState).width,
                                        })),
                                        name: getValues('name'),
                                    } as IColumnTemplate;
                                }
                                return template;
                            })
                        );
                        activeTemplate &&
                            setActiveTemplate((prevState) => {
                                const activeTemplate = {
                                    ...prevState,
                                    ...payload,
                                } as IColumnTemplate;
                                return activeTemplate;
                            });
                        dispatch(setCurrentTemplate(payload as IColumnTemplate));
                    }

                    enqueueSnackbar(successMessage, { variant: 'success' });
                    setOpenTemplateSettingsDrawer(() => false);
                    setTimeout(() => setMode(() => null), 200);
                })
                .catch((e) => {
                    enqueueSnackbar('Ошибка', { variant: 'error' });
                });
    }

    function getServerTemplateConvertedIntoAgGridColState(template: IColumnTemplate) {
        return (
            template?.columns.map((column) => ({
                colId: column.name,
                hide: column.hide,
                pinned: column.pinned,
            })) || []
        );
    }

    function saveActiveTemplate(template: IColumnTemplate, updateActiveTemplate?: boolean, showOverlay?: boolean) {
        if (showOverlay) {
            setActiveTemplateOverlay((prevState) => ({
                ...prevState,
                shown: true,
                message: 'Применяем настройки шаблона...',
            }));
        }

        updateActiveTemplate && setActiveTemplate(() => template);
        dispatch(setCurrentTemplate(template));
        req.post(`/projects/${projectId}/patterns/${template.id}/set`).catch((e) => {
            enqueueSnackbar('Ошибка', { variant: 'error' });
        });
    }

    const resetTemplate = async () => {
        if (['ksg', 'msg'].includes(type)) {
            const activeTemplate =
                templateList.find(
                    (template: IColumnTemplate) => template?.name === DEFAULT_TEMPLATE_NAME[type as TPatternsListType]
                ) ?? null;
            await req.post(`/projects/${projectId}/patterns/${activeTemplate?.id}/set`);
            setActiveTemplate(() => activeTemplate);
            return;
        }

        activeTemplate &&
            req
                .post(`/projects/${projectId}/patterns/reset`, {
                    type: type,
                })
                .then(() => {
                    enqueueSnackbar('Шаблон успешно сброшен', { variant: 'success' });
                    setActiveTemplate(null);
                    dispatch(setCurrentTemplate(null));

                    AgGrid?.columnApi.resetColumnState();
                })
                .catch((e) => {
                    enqueueSnackbar('Ошибка', { variant: 'error' });
                });
    };

    function cancel() {
        setOpenTemplateSettingsDrawer(() => false);
        setTimeout(() => {
            setMode(null);
            activeTemplate
                ? AgGrid?.columnApi.applyColumnState({
                      state: getServerTemplateConvertedIntoAgGridColState(activeTemplate) as ColumnState[],
                  })
                : AgGrid?.columnApi.resetColumnState();
        });
    }

    function deleteTemplate() {
        activeTemplate &&
            req
                .delete(`/projects/${projectId}/patterns/${activeTemplate.id}/delete`)
                .then(() => {
                    setOpenTemplateSettingsDrawer(() => false);
                    setOpenTemplateListMenu(() => false);
                    setMode(null);
                    enqueueSnackbar('Шаблон успешно удален', { variant: 'success' });
                    AgGrid?.columnApi.resetColumnState();
                    refreshTemplates();
                    resetField('name');
                })
                .catch((e) => {
                    enqueueSnackbar('Ошибка', { variant: 'error' });
                });
    }

    function leaveWithConfirm() {
        if (isFormChanged) {
            setIsConfirmCancelDialogOpen(() => true);
        } else {
            cancel();
        }
    }

    const tableColumns = getCurrentColDefsState(AgGrid, excludeColIds) ?? [];

    return (
        <Box>
            <LoadingOverlay
                key='overlay'
                open={activeTemplateOverlay.shown}
                transitionDuration={0}
                sx={{ zIndex: 999 }}
            >
                <Typography>{activeTemplateOverlay.message}</Typography>
            </LoadingOverlay>

            {/* Кнопка "Шаблоны отображения таблицы" */}
            <UIButton
                ref={btnRef}
                onClick={() => {
                    setOpenTemplateListMenu((prevState) => !prevState);
                }}
            />

            {/* Главное меню */}
            <UIMenu
                isOpen={openTemplateListMenu}
                anchorEl={btnRef?.current}
                templates={sortedTemplates}
                isDisabled={activeTemplateOverlay.shown}
                activeTemplate={activeTemplate}
                onAdd={() => setMode(() => 'add')}
                onEdit={() => setMode(() => 'edit')}
                onClose={() => setOpenTemplateListMenu(() => false)}
                onReset={resetTemplate}
                onSave={saveActiveTemplate}
            />

            {/* Драйвер для создания шаблона таблицы */}
            <UIAddEditDrawer
                mode={mode}
                isOpen={openTemplateSettingsDrawer}
                isSaveButtonDisabled={!formState.isValid}
                control={control}
                columns={tableColumns}
                agGrid={AgGrid}
                onIsFormChanged={setIsFormChanged}
                onClose={leaveWithConfirm}
                onSave={onSubmit}
                onDelete={deleteTemplate}
            />

            {/* Модалка для подтверждения отмены настройки шаблона */}
            <UICancelConfirmDialog
                isOpen={isConfirmCancelDialogOpen}
                onYesAction={cancel}
                onNoAction={() => setIsConfirmCancelDialogOpen(() => false)}
            />

            {/* Модалка для подтверждения удаления шаблона */}
            <UIDeleteConfirmDialog
                isOpen={isConfirmDeleteDialogOpen}
                onYesAction={deleteTemplate}
                onNoAction={() => setIsConfirmDeleteDialogOpen(() => false)}
            />
        </Box>
    );
}
