import { FC } from 'react';

import { ConfirmDialog } from '@/pages/WorkManagment/components/ConfirmDialog/ConfirmDialog';

interface IProps {
    isOpen: boolean;
    onYesAction: () => void;
    onNoAction: () => void;
}

export const UIDeleteConfirmDialog: FC<IProps> = ({ isOpen, onYesAction, onNoAction }) => {
    return (
        <ConfirmDialog
            open={isOpen}
            title={'Вы уверены, что хотите удалить шаблон'}
            onYes={{
                action: onYesAction,
                btnText: 'Удалить',
            }}
            onNo={{
                action: onNoAction,
            }}
            DialogProps={{ sx: { zIndex: 9999999 } }}
        />
    );
};
