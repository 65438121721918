import { Autocomplete, FilterOptionsState, Switch, TextField, Typography, createFilterOptions } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parse } from 'date-fns';
import { ru } from 'date-fns/locale';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { PROJECT_END_DATE_MAX, PROJECT_START_DATE_MIN } from '@/shared/constants/project';

import { theme } from '@/styles/theme';

import { COLORS } from '../../PercentStatusValue/PercentStatusValue.style';
import { ControllerInputWrapper, EditorTextField, StyledNumberFormat, StyledTooltip } from '../../components.styles';
import { getDefaultValidationRules, getTooltipProps } from '../CellEditor/CellEditor.service';
import { IAutocompleteOption, ICustomCellEditorParams } from './CellEditor.def';

export const CellEditor = forwardRef(
    (
        {
            dataType = 'number',
            options = [],
            validation,
            createFilterOptionsConfig,
            getInfoMessage,
            ...rest
        }: ICustomCellEditorParams,
        ref
    ) => {
        const [value, setValue] = useState<number | string | boolean | IAutocompleteOption>(rest.value);
        const [rowsCount, setRowsCount] = useState(0);
        const {
            control,
            formState,
            setValue: setFormValue,
            trigger,
        } = useForm({
            mode: 'all',
            reValidateMode: 'onChange',
        });

        useEffect(() => {
            rest?.eGridCell?.getElementsByTagName('input')[0]?.select();
            rest?.eGridCell?.getElementsByTagName('input')[0]?.focus();
            rest?.eGridCell?.getElementsByTagName('textarea')[0]?.select();
            rest?.eGridCell?.getElementsByTagName('textarea')[0]?.focus();
        }, []);

        useEffect(() => {
            dataType == 'date' && rest?.eGridCell?.getElementsByTagName('input')[0]?.focus();
        }, [dataType, formState.isValid, rest?.eGridCell]);

        /* Component Editor Lifecycle methods */
        useImperativeHandle(ref, () => {
            return {
                focusIn() {
                    return true;
                },
                // the final value to send to the grid, on completion of editing
                getValue() {
                    if (dataType == 'autocomplete') {
                        if (value == null) {
                            return null;
                        }
                        if (typeof value == 'object') {
                            return {
                                ...(value as IAutocompleteOption),
                                label: (value as IAutocompleteOption)?.label?.split('Добавить ').pop() || '',
                            };
                        }
                        return (value as string).split('Добавить ').pop() || '';
                    }
                    return value;
                },
                // Cancel all changes, if input is invalid
                isCancelAfterEnd() {
                    return !formState.isValid;
                },
            };
        });

        let defaultValidationSchema: ICustomCellEditorParams['validation'] = getDefaultValidationRules(dataType);

        const filter = createFilterOptions<IAutocompleteOption>(createFilterOptionsConfig);

        return (
            <Controller
                name={'input'}
                control={control}
                defaultValue={dataType === 'date' ? value && parse(value as string, 'dd.MM.yyyy', new Date()) : value}
                rules={{
                    ...defaultValidationSchema,
                    ...validation,
                    validate: {
                        ...defaultValidationSchema?.validate,
                        ...validation?.validate,
                    },
                }}
                render={({ field, formState, fieldState }) => {
                    const isInvalid = !!formState.errors['input'];
                    const errorMessage =
                        (formState.errors['input']?.message && formState.errors['input']?.message.toString()) || '';
                    const infoMessage = getInfoMessage && getInfoMessage(value);

                    return (
                        <StyledTooltip
                            placement='right'
                            PopperProps={{
                                disablePortal: false,
                            }}
                            {...getTooltipProps(errorMessage, infoMessage)}
                        >
                            <ControllerInputWrapper
                                error={isInvalid}
                                onClick={(e) => e.preventDefault()}
                            >
                                {dataType == 'number' && (
                                    <StyledNumberFormat
                                        defaultValue={rest.value}
                                        // value={field.value}
                                        allowedDecimalSeparators={['.', ',']}
                                        decimalSeparator=','
                                        onFocus={(e: { target: { select: () => void } }) => e.target.select()}
                                        onValueChange={(values: { floatValue: any }) => {
                                            setValue((prevValue) => values.floatValue);
                                            field.onChange(values.floatValue);
                                        }}
                                        valueIsNumericString
                                        {...(isInvalid && {
                                            style: {
                                                color: COLORS.error.color,
                                            },
                                        })}
                                        thousandSeparator={' '}
                                        {...rest.NumericFormatProps}
                                    />
                                )}
                                {dataType == 'text' && (
                                    <EditorTextField
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue((prevValue) => e.target.value);
                                        }}
                                        fullWidth
                                        {...rest.TextFieldProps}
                                    />
                                )}
                                {dataType == 'textarea' && (
                                    <EditorTextField
                                        defaultValue={rest.value}
                                        ref={field.ref}
                                        name={field.name}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            trigger(field.name);
                                            setValue((prevValue) => e.target.value);
                                        }}
                                        fullWidth
                                        {...rest.TextFieldProps}
                                    />
                                )}
                                {dataType == 'date' && (
                                    <LocalizationProvider
                                        adapterLocale={ru}
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <DesktopDatePicker
                                            {...field}
                                            inputFormat='dd.MM.yyyy'
                                            mask={'__.__.____'}
                                            onChange={(date, string) => {
                                                field.onChange(date);
                                                setValue(string || '');
                                                setFormValue(
                                                    'input',
                                                    string ? parse(string || '', 'dd.MM.yyyy', new Date()) : date,
                                                    {
                                                        shouldValidate: true,
                                                    }
                                                );
                                            }}
                                            minDate={PROJECT_START_DATE_MIN}
                                            maxDate={PROJECT_END_DATE_MAX}
                                            onAccept={(date: any) => {
                                                setValue(() => format(date, 'dd.MM.yyyy'));
                                                field.onChange(date);
                                                setFormValue('input', date, { shouldValidate: true });
                                                setTimeout(() => {
                                                    !formState.errors?.input && rest.api.stopEditing();
                                                });
                                            }}
                                            closeOnSelect
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        {...params}
                                                        onClick={(e) => {
                                                            e.currentTarget.classList.contains('MuiFormControl-root') &&
                                                                e.preventDefault();
                                                        }}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: 'дд.мм.гггг',
                                                            style: {
                                                                textAlign: 'left',
                                                            },
                                                        }}
                                                        sx={{
                                                            '.MuiOutlinedInput-root': {
                                                                fontSize: '14px',
                                                            },
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                display: 'none',
                                                            },
                                                            input: {
                                                                paddingLeft: 0,
                                                                paddingRight: 0,
                                                                textAlign: 'left',
                                                            },
                                                            ...rest.TextFieldProps?.sx,
                                                        }}
                                                        {...rest.TextFieldProps}
                                                    />
                                                );
                                            }}
                                        />
                                    </LocalizationProvider>
                                )}
                                {dataType == 'autocomplete' && (
                                    //@ts-ignore
                                    <Autocomplete
                                        renderInput={(autocompleteParams) => (
                                            <EditorTextField
                                                {...field}
                                                {...autocompleteParams}
                                                value={value}
                                                placeholder='Введите или выберите значение'
                                            />
                                        )}
                                        onChange={(e, newValue) => {
                                            field.onChange(e);
                                            setValue(() => newValue as IAutocompleteOption);
                                        }}
                                        disableClearable={true}
                                        defaultValue={!value ? null : (value as string)}
                                        fullWidth
                                        options={options}
                                        noOptionsText='Нет значений'
                                        renderOption={(autocompleteProps, option) => (
                                            <Typography
                                                variant='body2'
                                                color={theme.palette.text.primary}
                                                {...autocompleteProps}
                                            >
                                                {option.label}
                                            </Typography>
                                        )}
                                        {...rest.AutocompleteProps}
                                        {...(rest.withNewOptionCreation && {
                                            freeSolo: true,
                                            selectOnFocus: true,
                                            clearOnBlur: true,
                                            forcePopupIcon: true,
                                            filterOptions: (
                                                options: any[],
                                                params: FilterOptionsState<IAutocompleteOption>
                                            ) => {
                                                const filtered = filter(options, params);

                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some(
                                                    (option) => inputValue === option.label
                                                );
                                                if (inputValue !== '' && !isExisting && !errorMessage) {
                                                    filtered.push({
                                                        value: inputValue,
                                                        label: `Добавить ${inputValue}`,
                                                    });
                                                }

                                                return filtered;
                                            },
                                            getOptionLabel: (option: IAutocompleteOption) => {
                                                return option.label?.split('Добавить ').pop() || option;
                                            },
                                        })}
                                    />
                                )}
                                {dataType === 'switch' && (
                                    <Switch
                                        {...field}
                                        // disabled={!rest.colDef.editable}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue((prevValue) => e.target.checked);
                                        }}
                                    />
                                )}
                            </ControllerInputWrapper>
                        </StyledTooltip>
                    );
                }}
            />
        );
    }
);
