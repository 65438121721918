import React, { useState } from 'react'
import { Button, Grid, Stack, TextField } from '@mui/material'

export default function SelectDaysEl({ setDaysCb }: { setDaysCb: (start: string, end: string) => void }) {
    const [start, setStart] = useState('1')
    const [end, setEnd] = useState('31')

    return (
        <Grid
            sx={{
                padding: '1.5rem',
            }}
            container
            direction="column"
            gap={'1rem'}
        >
            <Stack direction="row" spacing={2}>
                <TextField
                    value={start}
                    onChange={(e) => setStart(e.target.value)}
                    id="День начала"
                    label="День начала"
                    variant="outlined"
                />
                <TextField
                    value={end}
                    onChange={(e) => setEnd(e.target.value)}
                    id="День окончания"
                    label="День окончания"
                    variant="outlined"
                />
            </Stack>
            <Button
                onClick={() => {
                    setDaysCb(start, end)
                }}
                variant="outlined"
            >
                Подтвердить
            </Button>
        </Grid>
    )
}
