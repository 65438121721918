import { FaFilter } from '@react-icons/all-files/fa/FaFilter';

import { Button, Container, Text } from './UIHeaderFilter.styles';

interface IUIHeaderFilterProps {
    width?: number;
    name: string;
    isApplied: boolean;
    onClick: () => void;
    containerPadding?: string;
}

export const UIHeaderFilter: React.FC<IUIHeaderFilterProps> = ({
    width,
    name,
    containerPadding,
    isApplied,
    onClick,
}) => {
    const color = isApplied ? '#6FCCBC' : '#fff';

    return (
        <Container
            width={(width || 32) - 32}
            padding={containerPadding}
        >
            <Text>{name}</Text>
            <Button onClick={onClick}>
                <FaFilter
                    fontSize={12}
                    fill={color}
                />
            </Button>
        </Container>
    );
};
