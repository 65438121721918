import AutorenewIcon from '@mui/icons-material/Autorenew'
import { Button, CircularProgress, LinearProgress, Pagination } from '@mui/material'
import { useNotificationsCenter } from './NotificationsCenter.model'
import s from './NotificationsCenter.module.scss'
import { ButtonsNotificationCenter } from './components/ButtonsNotificationsCenter'
import { Notification } from './components/Notification'

export const NotificationsCenter = () => {
    const {
        notifications,
        pagination,
        hasNotifications,
        loading,

        handleVolumeIncrease,
        handleVolumeIncreaseAll,
        handlePeriodIncrease,
        handlePeriodIncreaseAll,
        handleNotificationsLook,
    } = useNotificationsCenter()

    const renderData = () => {
        if (loading.circularProgress) {
            return (
                <div className={s.loading_wrapper}>
                    <CircularProgress />
                </div>
            )
        }

        if (!hasNotifications) {
            return (
                <div className={s.no_data}>
                    <p>Нет новых уведомлений</p>
                </div>
            )
        }

        return (
            <div className={s.main}>
                {notifications?.map((notification) => (
                    <Notification
                        key={notification.alertId}
                        notification={notification}
                        isVolumeButtonDisabled={loading.increaseVolume}
                        isPeriodButtonDisabled={loading.increasePeriod}
                        increaseVolume={handleVolumeIncrease([notification.alertId])}
                        increasePeriod={handlePeriodIncrease([notification.alertId])}
                    />
                ))}
            </div>
        )
    }

    return (
        <>
            {loading.linearProgress && (
                <div className={s.progress_abs}>
                    <LinearProgress />
                </div>
            )}
            <div className={s.header}>
                <div></div>
                <p>Центр уведомлений</p>

                <Button
                    disabled={loading.lookNotifications}
                    size="small"
                    onClick={handleNotificationsLook}
                    variant="text"
                    startIcon={<AutorenewIcon />}
                    sx={{
                        textTransform: 'capitalize',
                        '.MuiButton-startIcon': {
                            marginRight: '0.25rem',
                        },
                    }}
                >
                    Обновить
                </Button>
            </div>
            {hasNotifications && (
                <div className={s.pagination_container}>
                    <Pagination {...pagination} />
                </div>
            )}
            {renderData()}

            {hasNotifications && (
                <div className={s.buttons_bottom_container}>
                    <p>Выбрать для всех:</p>
                    <ButtonsNotificationCenter
                        disabledVolume={loading.increaseVolumeAll}
                        disabledPeriod={loading.increasePeriodAll}
                        increaseVolume={handleVolumeIncreaseAll}
                        increasePeriod={handlePeriodIncreaseAll}
                        fontSize="14px"
                        padding="7px"
                    />
                </div>
            )}
        </>
    )
}
