import { Button, FormControl, FormControlLabel, FormGroup, Switch } from '@mui/material'
import { switchesArr, switchesArrMsg } from '../TableTemplate.service'
import { Fragment, useEffect, useState } from 'react'
import { useAppDispatch, useTypedSelector } from '../../../store/store'
import s from '../Templates.module.scss'
import { DebounceInput } from 'react-debounce-input'
import { drawersSelector } from '../../../store/slices/drawersSlice'
import { initMsgTemplateStateType, msgTemplatesSelector } from '../../../store/slices/msgTemplatesSlice'
import { initCpgTemplateStateType, ksgTemplatesSelector } from '../../../store/slices/ksgTemplatesSlice'
import { setTemplateName, sharedTemplatesSelector } from '../../../store/slices/templatesSharedSlice'
import { fixShow } from '../../../store/slices/cpgViewSlice'
import { OnClickLock } from './OnClickLock'

export default function SharedTemplateDrawer({
    onSave,
    onCancel,
    onCheckItemKsg,
    onCheckItemMsg,
}: {
    onSave: () => void
    onCancel: () => void
    onCheckItemKsg?: (checked: boolean, term: keyof Omit<initCpgTemplateStateType, 'name'>, type: fixShow) => void
    onCheckItemMsg?: (checked: boolean, term: keyof Omit<initMsgTemplateStateType, 'name'>, type: fixShow) => void
}) {
    const [error, setError] = useState('')
    const [touched, setTouched] = useState(false)

    const { templateName, templatesKsgNamesList, templatesMsgNamesList } = useTypedSelector(sharedTemplatesSelector)
    const { templateCpgStateView: templateCpgState } = useTypedSelector(ksgTemplatesSelector)
    const { templateMsgStateView: templateMsgState } = useTypedSelector(msgTemplatesSelector)
    const { createCpgTemplate, createMsgTemplate } = useTypedSelector(drawersSelector)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!templateName.length) {
            return setError('Введите название')
        }

        if (templateName.length < 3) {
            return setError('Не меньше 3 символов')
        }

        if (templateName.length > 50) {
            return setError('Не больше 50 символов')
        }

        if (createCpgTemplate && templatesKsgNamesList.includes(templateName.toLowerCase())) {
            return setError('Название не может повторяться')
        }

        if (createMsgTemplate && templatesMsgNamesList.includes(templateName.toLowerCase())) {
            return setError('Название не может повторяться')
        }

        return setError('')
    }, [templateName])
    const changesField = [
        'volumeDonePlan','volumeDoneFact','volumeDoneForecast',
        'humanHoursProgressFact','humanHoursProgressPlan','humanHoursProgressPercent',
        'costProgressPlan','costProgressFact','costProgressPercent',
        'humanHoursProgressPlan','humanHoursProgressFact','humanHoursProgressForecast','humanHoursProgressPercent',
        'startDate', 'endDate',
        'operationalStartDate', 'operationalEndDate'
    ]

    return (
        <Fragment>
            <div className={s.input}>
                <DebounceInput
                    value={templateName}
                    placeholder="Название шаблона таблицы"
                    debounceTimeout={300}
                    onChange={(e) => dispatch(setTemplateName(e.target.value))}
                    required
                    onBlur={() => setTouched(true)}
                    min={3}
                    max={50}
                />
                {!!error.length && touched && <div className={s.error}>{error}</div>}
            </div>

            <div className={s.list}>
                <FormControl component="fieldset" variant="standard">
                    {onCheckItemKsg ? (
                        <FormGroup>
                            {switchesArr.map((v) => {
                                const padding = changesField.includes(v.term)
                                return (
                                    <div className={s[`list_item${padding?'_p':''}`]} key={v.term}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={
                                                        templateCpgState[
                                                            v.term as keyof Omit<initCpgTemplateStateType, 'name'>
                                                        ].show
                                                    }
                                                    onChange={(e) => {
                                                        if (!onCheckItemKsg) return
                                                        onCheckItemKsg(
                                                            e.target.checked,
                                                            v.term as keyof Omit<initCpgTemplateStateType, 'name'>,
                                                            'show'
                                                        )
                                                    }}
                                                    name={v.term}
                                                />
                                            }
                                            label={v.title}
                                        />
                                        {!padding &&
                                            <OnClickLock
                                                clicked={
                                                    templateCpgState[v.term as keyof Omit<initCpgTemplateStateType, 'name'>]
                                                        .fix
                                                }
                                                onClick={(bool) => {
                                                    if (!onCheckItemKsg) return
                                                    // console.log(bool,v.term as keyof Omit<initCpgTemplateStateType, 'name'>)
                                                    onCheckItemKsg(
                                                        bool,
                                                        v.term as keyof Omit<initCpgTemplateStateType, 'name'>,
                                                        'fix'
                                                    )
                                                }}
                                            />
                                        }
                                    </div>
                                )
                            })}
                        </FormGroup>
                    ) : (
                        <FormGroup>
                            {switchesArrMsg.map((v) => {
                                const padding = changesField.includes(v.term)
                                return (
                                    <div className={s[`list_item${padding?'_p':''}`]} key={v.term}>
                                        <FormControlLabel
                                            key={v.term}
                                            control={
                                                <Switch
                                                    checked={
                                                        templateMsgState[
                                                            v.term as keyof Omit<initMsgTemplateStateType, 'name'>
                                                        ].show
                                                    }
                                                    onChange={(e) => {
                                                        if (!onCheckItemMsg) return
                                                        onCheckItemMsg(
                                                            e.target.checked,
                                                            v.term as keyof Omit<initMsgTemplateStateType, 'name'>,
                                                            'show'
                                                        )
                                                    }}
                                                    name={v.term}
                                                />
                                            }
                                            label={v.title}
                                        />
                                        {!padding && (
                                            <OnClickLock
                                                clicked={
                                                    templateMsgState[v.term as keyof Omit<initMsgTemplateStateType, 'name'>]
                                                        .fix
                                                }
                                                onClick={(bool) => {
                                                    if (!onCheckItemMsg) return
                                                    onCheckItemMsg(
                                                        bool,
                                                        v.term as keyof Omit<initMsgTemplateStateType, 'name'>,
                                                        'fix'
                                                    )
                                                }}
                                            />
                                        )}
                                    </div>
                                )
                            })}
                        </FormGroup>
                    )}
                </FormControl>
            </div>

            <div className={s.buttons}>
                <Button
                    disabled={!!error.length && touched}
                    onClick={() => {
                        if (error.length) {
                            setTouched(true)
                        } else {
                            onSave()
                        }
                    }}
                    sx={{
                        backgroundColor: '#2E7D32',
                        width: '11rem',
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: '#3a993e',
                        },
                    }}
                    variant="contained"
                    color="inherit"
                >
                    Cохранить
                </Button>
                <Button onClick={onCancel} sx={{ backgroundColor: '#0044B4', width: '11rem' }} variant="contained">
                    Oтменить
                </Button>
            </div>
        </Fragment>
    )
}
