import { Button } from '@mui/material';
import { IoMdTrash } from '@react-icons/all-files/io/IoMdTrash';
import { useSnackbar } from 'notistack';
import { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useDeleteTemplateMutation, useUpdateTemplateMutation } from '@/api/templates/templates.api';

import ConfirmationModal from '@/shared/components/ConfirmationModal/ConfirmationModal';

import { fixShow } from '@/store/slices/cpgViewSlice';
import { toggleChangeCpgTemplate } from '@/store/slices/drawersSlice';
import {
    applyTemplateKsg,
    checkTemplateCpgItemView,
    initCpgTemplateStateType,
    ksgTemplatesSelector,
    refreshTemplateCpg,
} from '@/store/slices/ksgTemplatesSlice';
import { sharedTemplatesSelector } from '@/store/slices/templatesSharedSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { bodyTemplateKsg } from './TableTemplate.service';
import s from './Templates.module.scss';
import SharedTemplateDrawer from './components/SharedTemplateDrawer';

export default function ChangeKsgTemplate() {
    const [warningModal, setWarningModal] = useState(false);

    const { templateId, templateName } = useTypedSelector(sharedTemplatesSelector);
    const { templateCpgStateView, templateCpgState } = useTypedSelector(ksgTemplatesSelector);

    const dispatch = useAppDispatch();

    const [changeTemplateReq] = useUpdateTemplateMutation();
    const [deleteTemplateReq] = useDeleteTemplateMutation();

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');

    const { projectId } = useParams();

    const handleChange = (checked: boolean, term: keyof Omit<initCpgTemplateStateType, 'name'>, type: fixShow) => {
        dispatch(checkTemplateCpgItemView(term, checked, type));
    };

    function sendChangeTemplate() {
        dispatch(applyTemplateKsg());
        changeTemplateReq({
            id: Number(projectId),
            temId: templateId,
            body: { ...bodyTemplateKsg(templateCpgStateView), name: templateName },
        })
            .unwrap()
            .then(() => {
                enqueueSnackbar(t('template_changed'), {
                    variant: 'success',
                });
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                });
                console.error(e);
            });
    }

    const deleteTemplate = useCallback(() => {
        return deleteTemplateReq({
            id: Number(projectId),
            temId: templateId,
        })
            .unwrap()
            .then(() => {
                enqueueSnackbar(t('template_deleted'), {
                    variant: 'success',
                });
                if (templateCpgState.name === templateName) {
                    dispatch(refreshTemplateCpg());
                }
                dispatch(toggleChangeCpgTemplate());
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                });
                console.error(e);
            });
    }, [projectId, templateId, templateCpgState, templateName]);

    return (
        <Fragment>
            <div className={s.header}>
                <h4>Изменить шаблон таблицы</h4>
            </div>
            <div className={s.container_change}>
                <SharedTemplateDrawer
                    onSave={() => {
                        sendChangeTemplate();
                        dispatch(toggleChangeCpgTemplate());
                    }}
                    onCancel={() => {
                        dispatch(toggleChangeCpgTemplate());
                    }}
                    onCheckItemKsg={(checked, term, type) => {
                        // console.log(checked,term,type)
                        handleChange(checked, term, type);
                    }}
                />
                <Button
                    fullWidth
                    sx={{
                        color: '#f46b6b',
                    }}
                    color='warning'
                    startIcon={<IoMdTrash />}
                    onClick={() => setWarningModal(true)}
                >
                    Удалить шаблон
                </Button>
            </div>

            <ConfirmationModal
                openState={warningModal}
                onCancel={() => setWarningModal(false)}
                onConfirm={deleteTemplate}
                title='Вы уверены что хотите удалить шаблон?'
                content={`После удаления шаблона ${templateName} эти данные будут безвозвратно утеряны.`}
            />
        </Fragment>
    );
}
