import { Checkbox } from '@mui/material'
import React from 'react'

import { useSetKsgPeopleIsMainMutation } from '../../../api/ksgPeople/ksgPeople.api'
import { useTypedSelector } from '../../../store/store'
import { profileSelector } from '../../../store/slices/profileSlice'
import { ICellRendererParams } from 'ag-grid-community'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../../shared/rolePermissions'

export default function CheckboxEl({
    cellRendererParams,
    projectId,
}: {
    cellRendererParams: ICellRendererParams
    projectId?: string
}) {
    const [setMainReq] = useSetKsgPeopleIsMainMutation()
    const { profile } = useTypedSelector(profileSelector)

    const handleSetMain = (isMain: boolean) => {
        setMainReq({
            id: Number(projectId),
            staffId: cellRendererParams.data.id,
            body: {
                isMain: isMain,
            },
        })
            .unwrap()
            .then(() =>
                cellRendererParams.api.applyTransactionAsync({
                    update: [
                        { ...cellRendererParams.api.getRowNode(cellRendererParams.node.data.id)?.data, isMain: isMain },
                    ],
                })
            )
    }

    return (
        <Checkbox
            checked={cellRendererParams?.data?.isMain}
            disabled={profile.role === 'client'}
            onChange={(e) => {
                DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_KSG_STAFF_ROW') && handleSetMain(e.target.checked)
            }}
            sx={{
                color: '#0044b4',
                '&.Mui-checked': {
                    color: '#0044b4',
                },
            }}
        />
    )
}
