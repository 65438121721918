import { Fragment, useCallback, useEffect } from 'react'
import { useAddTemplateMutation } from '../../api/templates/templates.api'
import { toggleCreateCpgTemplate } from '../../store/slices/drawersSlice'
import { fixShow } from '../../store/slices/cpgViewSlice'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { bodyTemplateKsg } from './TableTemplate.service'
import SharedTemplateDrawer from './components/SharedTemplateDrawer'
import s from './Templates.module.scss'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
    applyTemplateKsg,
    checkTemplateCpgItemView,
    initCpgTemplateStateType,
    ksgTemplatesSelector,
    refreshTemplateCpgView,
} from '../../store/slices/ksgTemplatesSlice'
import { setTemplateName, sharedTemplatesSelector } from '../../store/slices/templatesSharedSlice'
import { useSetPatternKsgMutation } from '../../api/templatesGetSet/templatesGetSet.api'

export default function CreateKsgTemplate() {
    const { templateName } = useTypedSelector(sharedTemplatesSelector)
    const { templateCpgState, templateCpgStateView } = useTypedSelector(ksgTemplatesSelector)

    const [addTemplateReq] = useAddTemplateMutation()
    const [setPatternReq] = useSetPatternKsgMutation()

    const dispatch = useAppDispatch()

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation('mutations')

    const { projectId } = useParams()

    const handleChange = (checked: boolean, term: keyof Omit<initCpgTemplateStateType, 'name'>, type: fixShow) => {

        dispatch(checkTemplateCpgItemView(term, checked, type))
    }

    useEffect(() => {
        dispatch(refreshTemplateCpgView())
    }, [dispatch])

    const sendAddTemplate = useCallback(() => {
        addTemplateReq({
            id: Number(projectId),
            body: { ...bodyTemplateKsg(templateCpgStateView), name: templateName },
        })
            .unwrap()
            .then((response) => {
                dispatch(applyTemplateKsg())
                setPatternReq({ projectId: Number(projectId), patId: response.id })
                    .then(() => {
                        enqueueSnackbar(t('template_added'), {
                            variant: 'success',
                        })
                    })
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                })
                console.error(e)
            })
    }, [addTemplateReq, projectId, templateCpgStateView, templateName])

    useEffect(() => {
        dispatch(setTemplateName(''))
    }, [dispatch])
    // console.log('templateCpgStateView create>>>',templateCpgStateView)
    // console.log('templateCpgState create>>>',templateCpgState)
    return (
        <Fragment>
            <div className={s.header}>
                <h4>Создать шаблон таблицы</h4>
            </div>
            <div className={s.container_create}>
                <SharedTemplateDrawer
                    onSave={() => {
                        sendAddTemplate()
                        dispatch(toggleCreateCpgTemplate())
                    }}
                    onCancel={() => {
                        dispatch(toggleCreateCpgTemplate())
                    }}
                    onCheckItemKsg={(boolean, term, type) => {
                        handleChange(boolean, term, type)
                    }}
                />
            </div>
        </Fragment>
    )
}
