import { ArrowDropDown } from '@mui/icons-material';
import { Breadcrumbs, Button } from '@mui/material';
import React, { MouseEvent } from 'react';
import { Link, useParams } from 'react-router-dom';

import { FlexRowWrapper } from '../../../pages/NewExecutorView/components/components.styles';
import { BreadCrumbTitle } from './BreadCrumbTitle';
import IconButtonEl from './IconButtonEl';

interface IBreadCrumbTitle {
    title: string;
    link?: string;
}

export default function ButtonTitleEl({
    titles,
    isProjectTitleNav,
    setOpenPopoverLinks,
    projectTitle,
}: {
    titles: IBreadCrumbTitle[];
    isProjectTitleNav?: boolean;
    setOpenPopoverLinks?: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
    projectTitle: string;
}) {
    const { projectId } = useParams();
    return (
        <FlexRowWrapper sx={{ gap: '0 !important' }}>
            <IconButtonEl />

            <FlexRowWrapper sx={{ gap: '0 !important' }}>
                <Breadcrumbs
                    sx={{
                        '.MuiBreadcrumbs-ol': {
                            display: 'flex !important',
                            flexWrap: 'noWrap',
                            justifyContent: 'flex-start',
                        },
                        color: '#2B3648',
                    }}
                >
                    <Button
                        onClick={(event: MouseEvent<HTMLButtonElement>) => {
                            setOpenPopoverLinks && setOpenPopoverLinks(event.currentTarget);
                        }}
                        sx={{
                            color: '#2B3648',
                            textTransform: 'capitalize',
                            fontSize: 18,
                            fontWeight: 600,
                        }}
                        endIcon={setOpenPopoverLinks ? <ArrowDropDown /> : false}
                    >
                        {isProjectTitleNav ? (
                            <Link to={`/ksg/${projectId}`}>
                                <BreadCrumbTitle>{projectTitle}</BreadCrumbTitle>
                            </Link>
                        ) : (
                            <BreadCrumbTitle>{projectTitle}</BreadCrumbTitle>
                        )}
                    </Button>
                    {titles?.map((title) => {
                        return title.link ? (
                            <Link
                                key={title.link}
                                to={title.link}
                            >
                                <BreadCrumbTitle>{title.title}</BreadCrumbTitle>
                            </Link>
                        ) : (
                            <BreadCrumbTitle key={title.title}>{title.title}</BreadCrumbTitle>
                        );
                    })}
                </Breadcrumbs>
            </FlexRowWrapper>
        </FlexRowWrapper>
    );
}
