import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { CgDanger } from '@react-icons/all-files/cg/CgDanger';
import { Dispatch, SetStateAction } from 'react';

import s from '@/pages/Projects/component/ProjectsDrawer.module.scss';

import { uploadSelectStyles, uploadType, uploadVariants } from '../UploadDrawer.service';

export default function DescriptionAndSelectUploadDrawer({
    uploadType,
    setUploadType,
}: {
    uploadType?: uploadType;
    setUploadType?: Dispatch<SetStateAction<uploadType>>;
}) {
    return (
        <>
            <div className={s.awd_description}>
                <p>Вы можете добавить отчет с помощью excel документа с уже подготовленными данными </p>

                <h4>ВНИМАНИЕ!</h4>

                <p>
                    При загрузке отчета на дату, для которой существует другой отчет, он будет полностью перезаписан
                    данными из нового файла.
                </p>
            </div>

            {setUploadType && uploadType ? (
                <div className={s.select}>
                    <div className={s.select_left}>
                        <CgDanger
                            color='#5c6e8c'
                            fontSize={16}
                        />
                        <p
                            style={{
                                fontSize: '14px',
                            }}
                        >
                            Тип шаблона:
                        </p>
                    </div>
                    <FormControl
                        sx={{
                            minWidth: '11rem',
                        }}
                    >
                        <Select
                            sx={uploadSelectStyles}
                            onChange={(e: SelectChangeEvent) => {
                                setUploadType && setUploadType(e.target.value as uploadType);
                            }}
                            value={uploadType}
                        >
                            {uploadVariants.map((v) => (
                                <MenuItem
                                    key={v}
                                    value={v}
                                >
                                    {v}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
}
