import { Nullable } from '@/shared/def';
import { isFloat } from '@/shared/utils';

const format = (value: number, minimumFractionDigits = 6) =>
    value.toLocaleString('ru-RU', {
        minimumFractionDigits,
    });

const withLabel = (value: string) => `${value} руб.`;

export const getTooltipTitle = (value: Nullable<number>, isOverflow?: boolean) => {
    if (!value) return '';
    if (!isFloat(value)) return isOverflow ? withLabel(format(value, 0)) : '';

    return withLabel(format(value));
};

export const isElementOverflown = (element: HTMLElement) =>
    element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight;
