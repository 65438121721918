import i18n from 'i18next'
import ru from './ru.json'

i18n.init({
    interpolation: { escapeValue: false },
    lng: 'ru',
    resources: {
        ru,
    },
    keySeparator: '.',
})

export { i18n }
