import { useState } from 'react'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import { Button } from '@mui/material'

export const ButtonsAndWarningEl = ({
    onSubmit,
    submitText,
    cancelText,
    onClose,
    disabled,
}: {
    onSubmit?: () => void
    onClose: () => void
    submitText: string
    cancelText: string
    disabled?: boolean
}) => {
    const [warningModal, setWarningModal] = useState(false)

    const buttonStyles = {
        width: '10.75rem',
        height: '2.5rem',
        borderRadius: '6px',
    }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <ConfirmationModal
                openState={warningModal}
                onCancel={() => setWarningModal(false)}
                onConfirm={onClose}
                title="Подтвердить закрытие формы?"
                content="Форма будет закрыта, а все введенные данные безвозвратно утеряны."
            />
            <Button
                disabled={disabled}
                sx={buttonStyles}
                onClick={onSubmit}
                variant="contained"
                color="success"
                type="submit"
            >
                {submitText}
            </Button>
            <Button sx={buttonStyles} onClick={() => setWarningModal(true)} variant="contained" color="primary">
                {cancelText}
            </Button>
        </div>
    )
}
