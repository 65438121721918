import { ICellRendererParams } from 'ag-grid-community'
import { IWorkFactData } from '../../../api/api.types'
import { FlexRowWrapper } from '../../../components/components.styles'
import { TContext } from '../../WorksTable.colDef'
import { Icon, InfoButton, Text } from './WorkNameCellRenderer.styles'

type TInfoProps = {
    positionType?: 'absolute' | 'relative'
    onClick: () => void
}

export const Info: React.FC<TInfoProps> = ({ positionType, onClick }) => {
    return (
        <InfoButton positionType={positionType} onClick={onClick}>
            <Icon />
        </InfoButton>
    )
}

export const WorkNameCellRenderer = (params: ICellRendererParams<IWorkFactData, number | string, TContext>) => {
    const onWorkSelect = params.context.onWorkSelect

    const onClick = () => onWorkSelect(params.data ?? null)

    return (
        <FlexRowWrapper sx={{ justifyContent: 'space-between' }}>
            <Text sx={{ flex: 1 }}>{params.value}</Text>
            <Info onClick={onClick} />
        </FlexRowWrapper>
    )
}
