import { ColGroupDef, EditableCallbackParams, ICellRendererParams, IHeaderParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ProviderContext } from 'notistack';
import { RefObject } from 'react';
import { Link, Location } from 'react-router-dom';
import { Updater } from 'use-immer';

import { queryTermsType } from '@/api/filters/filters.api';
import { WorkMsg } from '@/api/msg/msg.def';
import { PublicUserProfile } from '@/api/users/users.def';

import { OverflowableTypographyWithTooltip } from '@/components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';
import { UIHeaderFilter } from '@/components/ui/UIHeaderFilter';

import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';
import { areAllValuesNull } from '@/shared/utils';
import { hasParam } from '@/shared/utils/common.utils';

import { TSelectedColumn } from '@/store/slices/filtersSlice';

import { SpreadPlanBtn } from '../AgGrid/components/SpreadPlanBtn/SpreadPlanBtn';
import { VolumeTotalNotificator } from '../AgGrid/components/VolumeTotalNotificator/VolumeTotalNotificator';
import { COL_DEF_TEMPLATE } from '../WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { NumberCellRenderer } from '../WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer';
import { PercentageCellRenderer } from '../WorkManagment/components/CellRenderers/PercentageCellRenderer';
import { getHeadersMonthsMsg } from './AgGridMsg.service';
import LevelRendererAgGridMsg from './components/LevelRendererAgGridMsg';
import MonthRendererMsg from './components/MonthRendererMSG/MonthRendererMsg';
import RowMonthHeaderRendererMsg from './components/RowMonthHeaderRendererMsg';

export type TAgGridContext = {
    projectId: string;
    appliedFilters: string[];
    openFilterModal: (column: TSelectedColumn, queryTerm?: queryTermsType) => void;
};

interface IGetColDefMSGArgs {
    hiddenRowsIds: {
        id: number;
        code: string;
    }[];
    setHiddenRowsIds: Updater<
        {
            id: number;
            code: string;
        }[]
    >;
    location: Location;
    getExecutors: any;
    getBrigades: any;
    gridRef: RefObject<AgGridReact<any>>;
    worksList: {
        data: WorkMsg[];
        total: number;
    } | null;
    profile: PublicUserProfile;
    month: number;
    year: number;
    columnWidths: Map<string, number>;
    enqueueSnackbar: ProviderContext['enqueueSnackbar'];
}

export const getMSGColDefs = ({
    hiddenRowsIds,
    setHiddenRowsIds,
    location,
    getExecutors,
    getBrigades,
    gridRef,
    worksList,
    profile,
    month,
    year,
    enqueueSnackbar,
    columnWidths,
}: IGetColDefMSGArgs) => {
    return [
        {
            field: 'level',
            colId: 'level',
            headerName: 'Уровень',
            width: columnWidths.get('level') || 72,
            spanHeaderHeight: true,
            cellRenderer: (data: ICellRendererParams<WorkMsg, any, any>) => {
                if (!data.data) return <></>;

                return (
                    <LevelRendererAgGridMsg
                        hasChildren={data.data.hasChildren}
                        id={data.data.id}
                        code={data.data.code}
                        level={data.data.level}
                        hiddenRowsIds={hiddenRowsIds}
                        setHiddenRowsIds={setHiddenRowsIds}
                    />
                );
            },
        },
        {
            field: 'numOrder',
            colId: 'numOrder',
            width: columnWidths.get('numOrder') || 50,
            spanHeaderHeight: true,
            headerName: '№',
        },
        {
            field: 'code',
            colId: 'code',
            width: columnWidths.get('code') || 90,
            spanHeaderHeight: true,
            headerName: 'ID',
            cellRenderer: (params: ICellRendererParams<WorkMsg, any, TAgGridContext>) => {
                return (
                    <Link
                        to={`/workManagment/${params.context.projectId}/${params.data?.id}/estimate/resources`}
                        state={{ prevPath: location.pathname }}
                    >
                        <OverflowableTypographyWithTooltip
                            maxRows={3}
                            TypographyProps={{ sx: { px: 1, cursor: 'pointer' } }}
                        >
                            {params.data?.id}
                        </OverflowableTypographyWithTooltip>
                    </Link>
                );
            },
        },
        {
            field: 'objTitle',
            colId: 'objTitle',
            width: columnWidths.get('objTitle') || 256,
            spanHeaderHeight: true,
            headerName: 'Титул',
            headerComponent: (params: IHeaderParams<WorkMsg, TAgGridContext>) => (
                <UIHeaderFilter
                    name={params.displayName}
                    width={params.column.getActualWidth()}
                    isApplied={params.context.appliedFilters.includes('objTitle')}
                    onClick={() => params.context.openFilterModal('objTitle', 'objTitle')}
                    containerPadding={'0 6px 0 0'}
                />
            ),
            cellClass: 'ag-cell-align-left',
        },
        {
            field: 'objName',
            colId: 'objName',
            width: columnWidths.get('objName') || 256,
            spanHeaderHeight: true,
            headerName: 'Объект',
            headerComponent: (params: IHeaderParams<WorkMsg, TAgGridContext>) => (
                <UIHeaderFilter
                    name={params.displayName}
                    width={params.column.getActualWidth()}
                    isApplied={params.context.appliedFilters.includes('objName')}
                    onClick={() => params.context.openFilterModal('objName', 'objName')}
                    containerPadding={'0 6px 0 0'}
                />
            ),
            cellClass: 'ag-cell-align-left',
        },
        {
            field: 'rdGroup',
            colId: 'rdGroup',
            width: columnWidths.get('rdGroup') || 90,
            spanHeaderHeight: true,
            headerName: 'Раздел РД',
        },
        {
            field: 'rdCode',
            colId: 'rdCode',
            width: columnWidths.get('rdCode') || 90,
            spanHeaderHeight: true,
            headerName: 'Шифр РД',
            headerComponent: (params: IHeaderParams<WorkMsg, TAgGridContext>) => (
                <UIHeaderFilter
                    name={params.displayName}
                    isApplied={params.context.appliedFilters.includes('rdCode')}
                    onClick={() => params.context.openFilterModal('rdCode')}
                />
            ),
        },
        {
            field: 'rdStatus',
            colId: 'rdStatus',
            width: columnWidths.get('rdStatus') || 188,
            spanHeaderHeight: true,
            headerName: 'Статус выдачи РД',
            headerComponent: (params: IHeaderParams<WorkMsg, TAgGridContext>) => (
                <UIHeaderFilter
                    name={params.displayName}
                    width={params.column.getActualWidth()}
                    isApplied={params.context.appliedFilters.includes('rdStatus')}
                    onClick={() => params.context.openFilterModal('rdStatus', 'rdStatus')}
                    containerPadding={'0 6px 0 0'}
                />
            ),
            cellClass: 'ag-cell-align-left',
        },
        {
            field: 'rdDateApproval',
            colId: 'rdDateApproval',
            width: columnWidths.get('rdDateApproval') || 140,
            spanHeaderHeight: true,
            headerName: 'Дата согласования РД',
        },
        {
            field: 'rdVersion',
            colId: 'rdVersion',
            width: columnWidths.get('rdVersion') || 90,
            spanHeaderHeight: true,
            headerName: 'Версия РД',
        },
        {
            field: 'rdDate',
            colId: 'rdDate',
            width: columnWidths.get('rdDate') || 140,
            spanHeaderHeight: true,
            headerName: 'Дата выдачи РД',
        },
        {
            field: 'typeOfWorks',
            colId: 'typeOfWorks',
            width: columnWidths.get('typeOfWorks') || 140,
            headerName: 'Конструктив (вид работ)',
            spanHeaderHeight: true,
            cellClass: 'ag-cell-align-left',
        },
        {
            field: 'workGroup',
            colId: 'workGroup',
            width: columnWidths.get('workGroup') || 256,
            spanHeaderHeight: true,
            headerName: 'Группа работ',
            headerComponent: (params: IHeaderParams<WorkMsg, TAgGridContext>) => (
                <UIHeaderFilter
                    name={params.displayName}
                    isApplied={params.context.appliedFilters.includes('workGroup')}
                    onClick={() => params.context.openFilterModal('workGroup')}
                />
            ),
            cellClass: 'ag-cell-align-left',
        },
        {
            field: 'contractorCompany',
            colId: 'contractorCompany',
            width: columnWidths.get('contractorCompany') || 256,
            spanHeaderHeight: true,
            headerName: 'Подрядчик',
            headerComponent: (params: IHeaderParams<WorkMsg, TAgGridContext>) => (
                <UIHeaderFilter
                    name={params.displayName}
                    isApplied={params.context.appliedFilters.includes('contractorCompany')}
                    onClick={() => params.context.openFilterModal('contractorCompany')}
                />
            ),
            cellClass: 'ag-cell-align-left',
        },
        {
            field: 'executorName',
            colId: 'executorName',
            width: columnWidths.get('executorName') || 256,
            spanHeaderHeight: true,
            headerName: 'Исполнитель',
            editable: (params: EditableCallbackParams) => !params.data?.hasChildren,
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.CUSTOM_AUTOCOMPLETE('ag-cell-align-left'),
            cellEditorParams: {
                dataType: 'autocomplete',
                options: getExecutors.data?.data.map((executor: any) => ({
                    label: executor.name,
                    value: executor.employeeID,
                    ...executor,
                })),
                AutocompleteProps: {
                    disableClearable: false,
                },
            },
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('executorName'),
        },
        {
            colId: 'brigade',
            field: 'brigade',
            width: columnWidths.get('brigade') || 256,
            spanHeaderHeight: true,
            headerName: 'Бригада',
            headerComponent: (params: IHeaderParams<WorkMsg, TAgGridContext>) => (
                <UIHeaderFilter
                    name={params.displayName}
                    isApplied={params.context.appliedFilters.includes('brigade')}
                    onClick={() => params.context.openFilterModal('brigade')}
                />
            ),
            editable: (params: EditableCallbackParams) => !params.data?.hasChildren,
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.CUSTOM_AUTOCOMPLETE('ag-cell-align-left'),
            cellEditorParams: {
                dataType: 'autocomplete',
                options: getBrigades.data?.data.map((brigade: any) => ({
                    label: brigade.name,
                    value: brigade.id,
                    ...brigade,
                })),
                AutocompleteProps: {
                    disableClearable: false,
                },
            },
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('brigade', 'name'),
        },
        {
            field: 'workName',
            colId: 'workName',
            width: columnWidths.get('workName') || 256,
            spanHeaderHeight: true,
            headerName: 'Наименование работы',
            // TODO:
            // 1. Вынести все headerComponent с фильтрами в отдельный компонент
            headerComponent: (params: IHeaderParams<WorkMsg, TAgGridContext>) => (
                <UIHeaderFilter
                    name={params.displayName}
                    width={params.column.getActualWidth()}
                    isApplied={params.context.appliedFilters.includes('workName')}
                    onClick={() => params.context.openFilterModal('workName', 'workName')}
                    containerPadding={'0 6px 0 0'}
                />
            ),
            cellClass: 'ag-cell-align-left',
        },
        {
            field: 'unit',
            colId: 'unit',
            width: columnWidths.get('unit') || 90,
            spanHeaderHeight: true,
            headerName: 'Ед. измерения',
            headerTooltip: 'Единица измерения',
        },
        {
            field: 'volumeTotal',
            colId: 'volumeTotal',
            width: columnWidths.get('volumeTotal') || 124,
            spanHeaderHeight: true,
            headerName: 'Общий объем на месяц',
            cellRenderer: (params: ICellRendererParams<WorkMsg>) => {
                return (
                    <>
                        {params.data?.volumeDistributeIndicator &&
                            !areAllValuesNull(params.data.volumeDistributeIndicator) && (
                                <VolumeTotalNotificator
                                    data={params.data?.volumeDistributeIndicator}
                                    options={{
                                        volumePlanLabel: 'ОПМ:',
                                    }}
                                    hideFields={['volumeBasePlan']}
                                />
                            )}
                        <NumberCellRenderer {...params} />
                    </>
                );
            },
        },
        {
            field: 'volumeDoneFactKsg',
            colId: 'volumeDoneFactKsg',
            width: columnWidths.get('volumeDoneFactKsg') || 124,
            spanHeaderHeight: true,
            headerName: 'Общий факт',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'volumeTotalKsg',
            colId: 'volumeTotalKsg',
            width: columnWidths.get('volumeTotalKsg') || 124,
            spanHeaderHeight: true,
            headerName: 'Общий объем',
            cellRenderer: NumberCellRenderer,
        },
        {
            headerName: 'Выполненный объём работ',
            colId: 'volumeDone',
            children: [
                {
                    field: 'volumeDonePlan',
                    colId: 'volumeDonePlan',
                    width: columnWidths.get('volumeDonePlan') || 85,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'volumeDoneForecast',
                    colId: 'volumeDoneForecast',
                    width: columnWidths.get('volumeDoneForecast') || 85,
                    headerName: 'Прогноз',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'volumeDoneFact',
                    colId: 'volumeDoneFact',
                    width: columnWidths.get('volumeDoneFact') || 85,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                },
            ],
        } as ColGroupDef<WorkMsg>,
        {
            headerName: 'Базовый план',
            colId: 'basePlanDates',
            children: [
                {
                    field: 'startDate',
                    colId: 'startDate',
                    width: columnWidths.get('startDate') || 82,
                    spanHeaderHeight: true,
                    headerName: 'Начало',
                },
                {
                    field: 'endDate',
                    colId: 'endDate',
                    width: columnWidths.get('endDate') || 82,
                    spanHeaderHeight: true,
                    headerName: 'Окончание',
                },
            ],
        } as ColGroupDef<WorkMsg>,
        {
            headerName: 'Оперативный план',
            colId: 'operPlanDates',
            children: [
                {
                    field: 'operationalStartDate',
                    colId: 'operationalStartDate',
                    width: columnWidths.get('operationalStartDate') || 82,
                    editable: (params) => !params.data?.hasChildren,
                    spanHeaderHeight: true,
                    headerName: 'Начало',
                    ...COL_DEF_TEMPLATE.EDITABLE_CELLS.DATE({
                        field: 'operationalStartDate',
                        compareWithEndDate: 'operationalEndDate',
                        gridApi: gridRef?.current?.api,
                        cellClass: 'ag-cell-date',
                    }),
                },
                {
                    field: 'operationalEndDate',
                    colId: 'operationalEndDate',
                    width: columnWidths.get('operationalEndDate') || 82,
                    editable: (params) => !params.data?.hasChildren,
                    spanHeaderHeight: true,
                    headerName: 'Окончание',
                    ...COL_DEF_TEMPLATE.EDITABLE_CELLS.DATE({
                        field: 'operationalEndDate',
                        compareWithStartDate: 'operationalStartDate',
                        gridApi: gridRef?.current?.api,
                        cellClass: 'ag-cell-padding-none ag-cell-flex ag-cell-date',
                    }),
                    cellRenderer: (params: ICellRendererParams) => {
                        return (
                            <>
                                <div className='operational-end-date-text'>{params.data?.operationalEndDate}</div>
                                {params.data?.hasChildren && params?.data?.level === 0 && (
                                    <SpreadPlanBtn
                                        typeOfPlan='operational'
                                        typeOfReport='msg'
                                        cellRendererParams={params}
                                    />
                                )}
                                {params.data?.hasChildren === false &&
                                    DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'SPREAD_OPER_PLAN') && (
                                        <SpreadPlanBtn
                                            typeOfPlan='operational'
                                            typeOfReport='msg'
                                            cellRendererParams={params}
                                        />
                                    )}
                            </>
                        );
                    },
                },
            ],
        } as ColGroupDef<WorkMsg>,
        {
            field: 'humanHourCost',
            colId: 'humanHourCost',
            width: columnWidths.get('humanHourCost') || 112,
            spanHeaderHeight: true,
            headerName: 'Трудозатраты на ед. изм., чел-час',
            headerTooltip: 'Трудозатраты на ед. изм., чел-час',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'humanHoursTotal',
            colId: 'humanHoursTotal',
            width: columnWidths.get('humanHoursTotal') || 112,
            spanHeaderHeight: true,
            headerName: 'Трудозатраты общие, чел-час',
            headerTooltip: 'Трудозатраты общие, чел-час',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'humanHoursProgressTZ',
            colId: 'humanHoursProgressTZ',
            width: columnWidths.get('humanHoursProgressTZ') || 90,
            spanHeaderHeight: true,
            headerName: 'Прогресс ТЗ',
            cellRenderer: PercentageCellRenderer,
        },
        {
            headerName: 'Прогресс трудозатрат нарастающим итогом, чел-час',
            colId: 'humanHoursProgress',
            children: [
                {
                    field: 'humanHoursProgressPlan',
                    colId: 'humanHoursProgressPlan',
                    width: columnWidths.get('humanHoursProgressPlan') || 87,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'humanHoursProgressForecast',
                    colId: 'humanHoursProgressForecast',
                    width: columnWidths.get('humanHoursProgressForecast') || 87,
                    headerName: 'Прогноз',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'humanHoursProgressFact',
                    colId: 'humanHoursProgressFact',
                    width: columnWidths.get('humanHoursProgressFact') || 87,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'humanHoursProgressPercent',
                    colId: 'humanHoursProgressPercent',
                    width: columnWidths.get('humanHoursProgressPercent') || 90,
                    headerName: '% выполнения',
                    headerTooltip: 'Процент выполнения',
                    cellRenderer: PercentageCellRenderer,
                },
            ],
        } as ColGroupDef<WorkMsg>,
        {
            field: 'costER',
            colId: 'costER',
            width: columnWidths.get('costER') || 156,
            spanHeaderHeight: true,
            headerName: 'Стоимость ЕР (Дог.+ДР), руб.',
            headerTooltip: 'Стоимость ЕР (Дог.+ДР), руб.',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotal',
            colId: 'costTotal',
            width: columnWidths.get('costTotal') || 156,
            spanHeaderHeight: true,
            headerName: 'Общая стоимость (Дог.+ДР), рублей',
            headerTooltip: 'Общая стоимость (Дог.+ДР), рублей',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotalProgress',
            colId: 'costTotalProgress',
            width: columnWidths.get('costTotalProgress') || 148,
            headerName: 'Общий прогресс по стоимости (Дог.+ДР)',
            headerTooltip: 'Общий прогресс по стоимости (Дог.+ДР)',
            spanHeaderHeight: true,
            cellRenderer: PercentageCellRenderer,
        },
        {
            headerName: 'Прогресс стоимости (Дог.+ДР) нарастающим итогом, руб',
            colId: 'costProgress',
            children: [
                {
                    field: 'costProgressPlan',
                    colId: 'costProgressPlan',
                    width: columnWidths.get('costProgressPlan') || 126,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressFact',
                    colId: 'costProgressFact',
                    width: columnWidths.get('costProgressFact') || 126,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressPercent',
                    colId: 'costProgressPercent',
                    width: columnWidths.get('costProgressPercent') || 148,
                    headerName: '% текущего выполнения',
                    cellRenderer: PercentageCellRenderer,
                },
            ],
        },
        {
            field: 'costERGK',
            colId: 'costERGK',
            width: columnWidths.get('costERGK') || 156,
            spanHeaderHeight: true,
            headerName: 'Стоимость ЕР (Договор), руб.',
            headerTooltip: 'Стоимость ЕР (Договор), руб.',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotalGK',
            colId: 'costTotalGK',
            width: columnWidths.get('costTotalGK') || 156,
            spanHeaderHeight: true,
            headerName: 'Общая стоимость (Договор), рублей',
            headerTooltip: 'Общая стоимость (Договор), рублей',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotalProgressGK',
            colId: 'costTotalProgressGK',
            width: columnWidths.get('costTotalProgressGK') || 156,
            headerName: 'Общий прогресс по стоимости (Договор)',
            headerTooltip: 'Общий прогресс по стоимости (Договор)',
            spanHeaderHeight: true,
            cellRenderer: PercentageCellRenderer,
        },
        {
            headerName: 'Прогресс стоимости нарастающим итогом (Договор), руб',
            colId: 'costProgressGK',
            children: [
                {
                    field: 'costProgressPlanGK',
                    colId: 'costProgressPlanGK',
                    width: columnWidths.get('costProgressPlanGK') || 126,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressFactGK',
                    colId: 'costProgressFactGK',
                    width: columnWidths.get('costProgressFactGK') || 126,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressPercentGK',
                    colId: 'costProgressPercentGK',
                    width: columnWidths.get('costProgressPercentGK') || 148,
                    headerName: '% текущего выполнения',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: PercentageCellRenderer,
                },
            ],
        },
        {
            field: 'costERSub',
            colId: 'costERSub',
            width: columnWidths.get('costERSub') || 156,
            spanHeaderHeight: true,
            headerName: 'Стоимость ЕР (Расчет), руб.',
            headerTooltip: 'Стоимость ЕР (Расчет), руб.',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotalSub',
            colId: 'costTotalSub',
            width: columnWidths.get('costTotalSub') || 156,
            spanHeaderHeight: true,
            headerName: 'Общая стоимость (Расчет), рублей',
            headerTooltip: 'Общая стоимость (Расчет), рублей',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotalProgressSub',
            colId: 'costTotalProgressSub',
            width: columnWidths.get('costTotalProgressSub') || 156,
            headerName: 'Общий прогресс по стоимости (Расчет)',
            headerTooltip: 'Общий прогресс по стоимости (Расчет)',
            spanHeaderHeight: true,
            cellRenderer: PercentageCellRenderer,
        },
        {
            headerName: 'Прогресс стоимости нарастающим итогом (Расчет), руб',
            colId: 'costProgressSub',
            children: [
                {
                    field: 'costProgressPlanSub',
                    colId: 'costProgressPlanSub',
                    width: columnWidths.get('costProgressPlanSub') || 126,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressFactSub',
                    colId: 'costProgressFactSub',
                    width: columnWidths.get('costProgressFactSub') || 126,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressPercentSub',
                    colId: 'costProgressPercentSub',
                    width: columnWidths.get('costProgressPercentSub') || 148,
                    headerName: '% текущего выполнения',
                    cellRenderer: PercentageCellRenderer,
                },
            ],
        },
        {
            field: 'costRemaining',
            colId: 'costRemaining',
            width: columnWidths.get('costRemaining') || 156,
            spanHeaderHeight: true,
            headerName: 'Остаток стоимости, рублей',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'verifiedFactTotal',
            colId: 'verifiedFactTotal',
            width: columnWidths.get('verifiedFactTotal') || 136,
            spanHeaderHeight: true,
            headerName: 'Вер. факт нарастающим итогом',
            headerClass: 'ag-verFact',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'planFact',
            width: columnWidths.get('planFact') || 96,
            spanHeaderHeight: true,
            headerName: 'План/Факт',
            cellRenderer: (params: ICellRendererParams<WorkMsg, any, any>) => (
                <RowMonthHeaderRendererMsg
                    cellRendererParams={params}
                    isGantt={hasParam('gant', location.search)}
                />
            ),
            cellClass: 'ag-cell-padding-none ag-cell-flex',
        },
        getHeadersMonthsMsg(
            worksList?.data[0]?.dailyCharts,
            (data, index, isToday) => {
                if (!data.data) return <></>;

                return (
                    <MonthRendererMsg
                        cellRendererParams={data}
                        chartIndex={index}
                        isToday={isToday}
                    />
                );
            },
            hasParam('gant', location.search),
            columnWidths
        ),
    ];
};
