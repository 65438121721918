const donePercentSameStyles: React.CSSProperties = {
    borderRadius: '32px',
    padding: '4px 13px',
    fontSize: '10px !important',
    fontWeight: 600,
    textTransform: 'uppercase',
    minWidth: 60,
};
const donePercentLess50 = {
    ...donePercentSameStyles,
    color: '#fff',
    backgroundColor: '#ffb1b1 !important',
};
const donePercent5090 = {
    ...donePercentSameStyles,
    color: '#988d22',
    backgroundColor: '#fef08a !important',
};
const donePercent90Plus = {
    ...donePercentSameStyles,
    color: '#fff',
    backgroundColor: '#8dd4c8 !important',
};

export function applyStylesPercent(percent: number | null) {
    if (percent === null) return donePercentLess50;
    if (percent < 50) return donePercentLess50;
    if (percent < 90) return donePercent5090;
    return donePercent90Plus;
}
