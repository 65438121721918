import { DeleteOutline } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import { useState } from "react";
import { ConfirmDialog } from "../../ConfirmDialog/ConfirmDialog";
import { removeRow } from "../../../subPages/Resources/ResourcePage.service";

interface IDeleteRowRendererProps  {
    params: ICellRendererParams,
    deleteCallBack: (params: ICellRendererParams) => void
}

export function DeleteRowRenderer(props: IDeleteRowRendererProps) {

    const {deleteCallBack, params} = props

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

    return (
        <>
            <ConfirmDialog
                open={isDialogOpen}
                title={'Вы уверены, что хотите удалить строку?'}
                message="Данные будут удалены безвозвратно"
                onYes={{
                    action: (e) => deleteCallBack(params),
                    btnText: 'Удалить'
                }}
                onNo={{
                    action: (e) => setIsDialogOpen(prevValue => !prevValue)
                }}
            />
            <IconButton
                onClick={(e) => setIsDialogOpen(prevValue => !prevValue)}
                disableRipple
                sx={{
                    p: 0
                }}
            >
                <DeleteOutline />
            </IconButton>
        </>
    )

}