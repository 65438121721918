import styled from 'styled-components'
import { FlexColumnWrapper } from '../NewExecutorView/components/components.styles'
import { PageWrapper } from '../WorkManagment/components/components.styles'
import { Box } from '@mui/material'

export const StyledCardWrapper = styled(Box)`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(455px, 1fr));
    gap: ${({ theme }) => theme.spacing(2)};
`
export const StyledCard = styled(FlexColumnWrapper)`
    padding: ${({ theme }) => theme.spacing(2.5)};
    gap: ${({ theme }) => theme.spacing(2)};
    background: white;
    border-radius: ${({ theme }) => theme.spacing(1.25)};
    cursor: pointer;
`
export const AddBrigadePageWrapper = styled(PageWrapper)`
    padding-top: ${({ theme }) => theme.spacing(1)} !important;
    grid-template-columns: 456px 1fr 1fr;
    grid-template-rows: 40px 1fr;
`
