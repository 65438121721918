import { Dialog, Stack, Typography } from '@mui/material';
import styled from 'styled-components';

export const DialogWindow = styled(Dialog)`
    .MuiDialog-container {
        .MuiPaper-root {
            border: 1px solid #fff;
            margin: 0;
            box-shadow: 0 24px 48px 0 rgba(16, 24, 40, 0.14), 0 -8px 24px 0 rgba(16, 24, 40, 0.08);
            background: #fff;
            border-radius: 8px;
            padding: 30px;
            width: 400px;
            height: 240px;
        }
    }
`;

export const Container = styled(Stack).attrs({
    direction: 'column',
    width: '100%',
    gap: 2.5,
})``;

export const Title = styled(Typography).attrs({
    variant: 'h3',
})`
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #0044b4;
`;

export const Text = styled(Typography).attrs({})`
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #2b3648;
`;
