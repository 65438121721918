import React from "react";
import { ColoredTitleProps } from "./ColoredTitle.types";
import { StyledBox, StyledTypography } from "./styles";

function ColoredTitle({ body, color }: ColoredTitleProps) {
  return (
    <StyledBox bgColor={color}>
      <StyledTypography variant="tooltip">{body}</StyledTypography>
    </StyledBox>
  );
}

export default ColoredTitle;
