import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CriticalPathSVG: React.FC<SvgIconProps & { isActive?: boolean }> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                {/* <mask
                    id="mask0_1357_24500"
                    // style="mask-type:luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                > */}
                <path
                    d='M18.5 16L21 18.5L18.5 21'
                    stroke={props.isActive ? 'white' : '#0044B4'}
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M3 5C3.53043 5 4.03914 4.78929 4.41421 4.41421C4.78929 4.03914 5 3.53043 5 3C5 2.46957 4.78929 1.96086 4.41421 1.58579C4.03914 1.21071 3.53043 1 3 1C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3C1 3.53043 1.21071 4.03914 1.58579 4.41421C1.96086 4.78929 2.46957 5 3 5Z'
                    fill={props.isActive ? 'white' : '#0044B4'}
                    stroke={props.isActive ? 'white' : '#0044B4'}
                    strokeWidth='2'
                    strokeLinejoin='round'
                />
                <path
                    d='M5 3H9C9.53043 3 10.0391 3.21071 10.4142 3.58579C10.7893 3.96086 11 4.46957 11 5V16.5C11 17.0304 11.2107 17.5391 11.5858 17.9142C11.9609 18.2893 12.4696 18.5 13 18.5H21'
                    stroke={props.isActive ? 'white' : '#0044B4'}
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                {/* </mask> */}
                {/* <g mask="url(#mask0_1357_24500)">
                    <path d="M-1 -1H23V23H-1V-1Z" fill={props.isActive ? 'white' : '#0044B4'} />
                </g> */}
            </svg>
        </SvgIcon>
    );
};

export default CriticalPathSVG;
