import { GridApi } from 'ag-grid-community';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useDeleteWorkMutation } from '@/api/works/works.api';

import { useLatest } from '@/hooks/useLatest';

import { onDeleteRowKsgAg } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { useAppDispatch } from '@/store/store';

export const useDeleteRow = (gridApi?: GridApi | undefined) => {
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');
    const [deleteReq] = useDeleteWorkMutation();
    const dispatch = useAppDispatch();

    const projectId = useLatest(params.projectId!);
    const gridApiRef = useLatest(gridApi);

    const handleDeleteRow = useCallback((workId: number) => {
        deleteReq({
            id: Number(projectId.current),
            workId,
        })
            .unwrap()
            .then((res) => {
                enqueueSnackbar(t('work_deleted'), {
                    variant: 'success',
                });

                dispatch(
                    onDeleteRowKsgAg({
                        id: workId,
                        works: res.data,
                    })
                );
                gridApiRef?.current && gridApiRef.current.applyTransaction({ update: res.data });
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                });
            });
    }, []);

    return handleDeleteRow;
};
