import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { ICellRendererParams } from 'ag-grid-community'
import { useState } from 'react'

export function RowGroupExpandRenderer(params: ICellRendererParams) {
    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    const children = params?.data?.children?.map((child: any) => ({
        ...child,
        isChild: true,
    }))

    return (
        (params?.data?.children?.length > 0 &&
            (!isExpanded ? (
                <ExpandMore
                    sx={{ cursor: 'pointer' }}
                    onClick={(e) => {
                        setIsExpanded(true)
                        params.api.applyTransactionAsync({
                            add: children,
                            addIndex: params?.rowIndex + 1,
                        })
                    }}
                />
            ) : (
                <ExpandLess
                    sx={{ cursor: 'pointer' }}
                    onClick={(e) => {
                        setIsExpanded(false)
                        params.api.applyTransactionAsync({
                            remove: children,
                        })
                    }}
                />
            ))) ||
        null
    )
}
