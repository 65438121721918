import { AiOutlinePlus } from '@react-icons/all-files/ai/AiOutlinePlus';
import { useDispatch } from 'react-redux';

import { usePermissionsByRole } from '@/hooks/usePermissionsByRole';

import EmptyPlaceholder from '@/shared/components/EmptyPlaceholder/EmptyPlaceholder';

import { toggleAddProject } from '@/store/slices/drawersSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { useTypedSelector } from '@/store/store';

export const ProjectsEmptyPlaceholder = () => {
    const dispatch = useDispatch();
    const { profile } = useTypedSelector(profileSelector);
    const { doesNotHaveFeatureAccess } = usePermissionsByRole();

    if (doesNotHaveFeatureAccess('CREATE_PROJECT')) {
        return <EmptyPlaceholder text={`Здравствуйте ${profile.firstName}, у вас еще нет проектов для отображения.`} />;
    }

    return (
        <EmptyPlaceholder
            text={`Здравствуйте ${profile.firstName}, у вас еще нет проектов для отображения.
                    Давайте создадим ваш первый проект.`}
            onClick={() => dispatch(toggleAddProject())}
            icon={<AiOutlinePlus />}
            buttonText={
                profile?.role === 'executor' || profile?.role === 'none' || profile.role === 'client'
                    ? undefined
                    : 'добавить проект'
            }
        />
    );
};
