import { BoxProps } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import styled from 'styled-components'
import { FlexColumnWrapper } from '../../pages/NewExecutorView/components/components.styles'

interface IStyledCellWrapperProps extends BoxProps {
    isEditable: boolean
    isActive?: boolean
}

export const StyledCellWrapper = styled(FlexColumnWrapper)<IStyledCellWrapperProps>`
    justify-content: center;
    overflow: inherit;
    position: relative;
    background-color: white;

    :nth-of-type(odd) {
        background-color: ${({ isActive }) => (isActive ? 'rgba(141, 212, 200, 0.4)' : 'rgba(141, 212, 200, 0.15)')};
    }
    :nth-last-of-type(1) {
        background-color: ${({ isActive }) => (isActive ? '#f6f7fb' : '#ffffff')};
    }
    :not(:last-of-type) {
        box-shadow: inset 0px -1px rgb(221, 226, 234);
    }
    :hover {
        box-shadow: ${({ isEditable }) => isEditable && 'inset 0px -2px #6d9adc !important'};
        cursor: ${({ isEditable }) => isEditable && 'pointer'};
    }
    :last-of-type:hover {
        box-shadow: ${({ isEditable }) => isEditable && 'inset 0px -2.5px #6d9adc !important'};
    }
`

export const StyledNumericFormat = styled(NumericFormat)`
    border: none;
    font-size: 14px;
    width: 100%;
    text-align: center;
    background: transparent;
`
