import { api } from '../api'
import { getHeadersRes } from './ksgKC6A.def'

export const ksgKC6AApi = api.injectEndpoints({
    endpoints: (build) => ({
        getHeadersKC6A: build.query<getHeadersRes, { projectId: number }>({
            query: ({ projectId }) => ({
                url: `/projects/${projectId}/works/get-kc-6a/headers`,
                method: 'GET',
            }),
            async onQueryStarted(arg, api) {
                try {
                    const data = await api.queryFulfilled

                    // api.dispatch(setMiddleVisible(data.data.isCreatedKSG))
                } catch {}
            },
            // providesTags: ['Headers'],
        }),
    }),
    overrideExisting: false,
})

export const { useGetHeadersKC6AQuery } = ksgKC6AApi
