import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { IAfterGuiAttachedParams, IDoesFilterPassParams } from 'ag-grid-community';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { FlexColumnWrapper } from '../../../NewExecutorView/components/components.styles';
import { ICustomAgGridFilterParams } from './ColumnFilter.def';

export const ColumnFilter = forwardRef(({ options, ...rest }: ICustomAgGridFilterParams, ref) => {
    const [chosenValues, setChosenValues] = useState<string[]>(options);

    useImperativeHandle(ref, () => {
        return {
            doesFilterPass(params: IDoesFilterPassParams) {
                return chosenValues?.includes(params.data[rest?.column?.getColDef()?.field || '']);
            },

            isFilterActive() {
                return chosenValues?.length !== options?.length;
            },
            afterGuiDetached(params: IAfterGuiAttachedParams) {
                rest.api.refreshHeader();
            },
            onAnyFilterChanged() {},

            getModel() {},

            setModel() {},
        };
    });

    useEffect(() => {
        rest.filterChangedCallback();
    }, [chosenValues]);

    return (
        <FlexColumnWrapper
            gap={1}
            sx={{
                background: 'white',
                p: 2,
            }}
        >
            {options?.length > 0 && (
                <>
                    <FormControlLabel
                        checked={chosenValues?.length === options?.length}
                        control={
                            <Checkbox
                                size='small'
                                indeterminate={chosenValues?.length !== options?.length && chosenValues?.length !== 0}
                            />
                        }
                        label={<Typography variant='body2'>Выбрать все</Typography>}
                        onChange={(e, checked) => setChosenValues((prevValue) => (checked ? options : []))}
                    />
                    {options.map((option) => {
                        return (
                            <FormControlLabel
                                key={option}
                                checked={chosenValues.includes(option)}
                                control={<Checkbox size='small' />}
                                label={<Typography variant='body2'>{option}</Typography>}
                                onChange={(e, checked) =>
                                    setChosenValues((prevValue) =>
                                        checked ? [...prevValue, option] : prevValue.filter((value) => value !== option)
                                    )
                                }
                            />
                        );
                    })}
                </>
            )}
        </FlexColumnWrapper>
    );
});
