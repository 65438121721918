import { initMsgTemplateStateType } from '../../store/slices/msgTemplatesSlice'
import { initCpgTemplateStateType } from '../../store/slices/ksgTemplatesSlice'

export const switchesArrMsg = [
    {
        title: 'Уровень',
        term: 'level',
    },
    {
        title: 'ID',
        term: 'code',
    },
    {
        title: 'Титул',
        term: 'objTitle',
    },
    {
        title: '№',
        term: 'numOrder',
    },
    {
        title: 'Объект',
        term: 'objName',
    },
    {
        title: 'Раздел РД',
        term: 'rdGroup',
    },
    {
        title: 'Шифр РД',
        term: 'rdCode',
    },
    {
        title: 'Статус выдачи РД',
        term: 'rdStatus',
    },
    {
        title: 'Дата согласования РД',
        term: 'rdDateApproval',
    },
    {
        title: 'Версия РД',
        term: 'rdVersion',
    },
    {
        title: 'Дата выдачи РД',
        term: 'rdDate',
    },
    {
        title: 'Группа работ',
        term: 'workGroup',
    },
    {
        title: 'Подрядчик',
        term: 'contractorCompany',
    },
    {
        title: 'Исполнитель',
        term: 'executorName',
    },
    {
        title: 'Наименование работы',
        term: 'workName',
    },
    {
        title: 'Единица измерения',
        term: 'unit',
    },
    {
        title: 'Общий объем на месяц',
        term: 'volumeTotal',
    },
    {
        title: 'Общий факт',
        term: 'volumeDoneFactKsg',
    },
    {
        title: 'Общий объем',
        term: 'volumeTotalKsg',
    },
    {
        title: 'Выполненный объём работ',
        term: 'volumeDone',
    },

    {
        title: 'План',
        term: 'volumeDonePlan',
    },
    {
        title: 'Прогноз',
        term: 'volumeDoneForecast',
    },
    {
        title: 'Факт',
        term: 'volumeDoneFact',
    },

    {
        title: 'Базовый план',
        term: 'basePlan',
    },
    {
        title: 'Дата начала',
        term: 'startDate',
    },
    {
        title: 'Дата окончания',
        term: 'endDate',
    },
    {
        title: 'Оперативный план',
        term: 'operPlan',
    },
    {
        title: 'Дата начала',
        term: 'operationalStartDate',
    },
    {
        title: 'Дата окончания',
        term: 'operationalEndDate',
    },
    {
        title: 'Трудозатраты на ед. изм',
        term: 'humanHourCost',
    },
    {
        title: 'Трудозатраты общие',
        term: 'humanHoursTotal',
    },
    {
        title: 'Прогресс ТЗ',
        term: 'humanHoursProgressTZ',
    },

    {
        title: 'Трудозатраты общие нарастающим итогом',
        term: 'humanHoursProgress',
    },
    {
        title: 'План',
        term: 'humanHoursProgressPlan',
    },
    {
        title: 'Прогноз',
        term: 'humanHoursProgressForecast',
    },
    {
        title: 'Факт',
        term: 'humanHoursProgressFact',
    },
    {
        title: 'Процент выполнения',
        term: 'humanHoursProgressPercent',
    },

    {
        title: 'Стоимость ЕР (Дог.+ДР), руб.',
        term: 'costER',
    },
    {
        title: 'Общая стоимость',
        term: 'costTotal',
    },

    {
        title: 'Остаток стоимости',
        term: 'costRemaining',
    },
]

export const switchesArr = [
    {
        title: 'Уровень',
        term: 'level',
    },
    {
        title: '№',
        term: 'numOrder',
    },
    {
        title: 'ID',
        term: 'code',
    },
    {
        title: 'Прочие работы',
        term: 'tag',
    },
    {
        title: 'Титул',
        term: 'objTitle',
    },
    {
        title: 'Объект',
        term: 'objName',
    },
    {
        title: 'Раздел РД',
        term: 'rdGroup',
    },
    {
        title: 'Шифр РД',
        term: 'rdCode',
    },
    {
        title: 'Статус выдачи РД',
        term: 'rdStatus',
    },
    {
        title: 'Дата согласования РД',
        term: 'rdDateApproval',
    },
    {
        title: 'Версия РД',
        term: 'rdVersion',
    },
    {
        title: 'Дата выдачи РД',
        term: 'rdDate',
    },
    {
        title: 'Группа работ',
        term: 'workGroup',
    },
    {
        title: 'Подрядчик',
        term: 'contractorCompany',
    },
    {
        title: 'Наименование работы',
        term: 'workName',
    },
    {
        title: 'Единица измерения',
        term: 'unit',
    },
    {
        title: 'Общий объем',
        term: 'volumeTotal',
    },
    {
        title: 'Выполненный объём работ',
        term: 'volumeDone',
    },
    {
        title: 'План',
        term: 'volumeDonePlan',
    },
    {
        title: 'Факт',
        term: 'volumeDoneFact',
    },
    {
        title: 'Базовый план',
        term: 'basePlan',
    },
    {
        title: 'Дата начала',
        term: 'startDate',
    },
    {
        title: 'Дата окончания',
        term: 'endDate',
    },
    {
        title: 'Оперативный план',
        term: 'operPlan',
    },
    {
        title: 'Дата начала',
        term: 'operationalStartDate',
    },
    {
        title: 'Дата окончания',
        term: 'operationalEndDate',
    },

    {
        title: 'Трудозатраты на ед. изм',
        term: 'humanHourCost',
    },
    {
        title: 'Трудозатраты общие',
        term: 'humanHoursTotal',
    },
    {
        title: 'Прогресс ТЗ',
        term: 'humanHoursProgressTZ',
    },

    {
        title: 'Прогресс ТЗ нар. итогом',
        term: 'humanHours',
    },
    {
        title: 'План',
        term: 'humanHoursProgressPlan',
    },
    {
        title: 'Факт',
        term: 'humanHoursProgressFact',
    },
    {
        title: 'Процент выполнения',
        term: 'humanHoursProgressPercent',
    },

    {
        title: 'Затраты машин и мех. на ед.',
        term: 'machineHourCost',
    },
    {
        title: 'Затраты механизмов',
        term: 'machineHoursTotal',
    },
    {
        title: 'Стоимость ЕР (Дог.+ДР), руб.',
        term: 'costER',
    },
    {
        title: 'Общая стоимость',
        term: 'costTotal',
    },
    {
        title: 'Общий прогресс по стоимости',
        term: 'costTotalProgress',
    },
    {
        title: 'Прогресс стоимости нар. итогом',
        term: 'costProgress',
    },

    {
        title: 'План',
        term: 'costProgressPlan',
    },
    {
        title: 'Факт',
        term: 'costProgressFact',
    },
    {
        title: 'Процент текущего выполнения',
        term: 'costProgressPercent',
    },

    {
        title: 'Остаток стоимости',
        term: 'costRemaining',
    },
    {
        title: 'Закрыто по КС2',
        term: 'costDone',
    },
    {
        title: 'НЗП',
        term: 'costNZP',
    },
    {
        title: 'Ориентировочная дата начала поставки',
        term: 'prStart',
    },
    {
        title: 'Ориентировочная дата завершения поставки',
        term: 'prEnd',
    },
    {
        title: 'Обеспеченность в %',
        term: 'prProvision',
    },
    {
        title: 'Период работ',
        term: 'workPeriod',
    },
]

export const bodyTemplateKsg = (terms: Omit<initCpgTemplateStateType, 'name'>) => {
    return {
        numOrder: terms.numOrder,
        level: terms.level,
        code: terms.code,
        contractorCompany: terms.contractorCompany,
        costDone: terms.costDone,
        costER: terms.costER,
        costNZP: terms.costNZP,
        costProgressFact: terms.costProgressFact,
        costProgressPercent: terms.costProgressPercent,
        costProgressPlan: terms.costProgressPlan,
        costRemaining: terms.costRemaining,
        costTotal: terms.costTotal,
        costTotalProgress: terms.costTotalProgress,
        endDate: terms.endDate,
        humanHourCost: terms.humanHourCost,
        humanHours: terms.humanHours,
        humanHoursProgressFact: terms.humanHoursProgressFact,
        humanHoursProgressPercent: terms.humanHoursProgressPercent,
        humanHoursProgressPlan: terms.humanHoursProgressPlan,
        humanHoursProgressTZ: terms.humanHoursProgressTZ,
        humanHoursTotal: terms.humanHoursTotal,
        machineHourCost: terms.machineHourCost,
        machineHoursTotal: terms.machineHoursTotal,
        objName: terms.objName,
        objTitle: terms.objTitle,
        rdCode: terms.rdCode,
        rdDate: terms.rdDate,
        rdDateApproval: terms.rdDateApproval,
        rdGroup: terms.rdGroup,
        rdVersion: terms.rdVersion,
        rdStatus: terms.rdStatus,
        startDate: terms.startDate,
        unit: terms.unit,
        volumeDoneFact: terms.volumeDoneFact,
        volumeDonePlan: terms.volumeDonePlan,
        volumeTotal: terms.volumeTotal,
        workGroup: terms.workGroup,
        workName: terms.workName,
        workPeriod: terms.workPeriod,
        tag: terms.tag,
        prStart: terms.prStart,
        prEnd: terms.prEnd,
        prProvision: terms.prProvision,
        operationalStartDate: terms.operationalStartDate,
        operationalEndDate: terms.operationalEndDate,
    }
}

export const bodyTemplateMsg = (terms: Omit<initMsgTemplateStateType, 'name'>) => {
    return {
        level: terms.level,
        code: terms.code,
        contractorCompany: terms.contractorCompany,
        costER: terms.costER,
        costProgressFact: terms.code,
        costProgressForecast: terms.code,
        costProgressPercent: terms.code,
        costProgressPlan: terms.code,
        costRemaining: terms.costRemaining,
        costTotal: terms.costTotal,
        costTotalProgress: terms.code,
        endDate: terms.endDate,
        executor: terms.executorName,
        humanHourCost: terms.humanHourCost,
        humanHoursProgressFact: terms.humanHoursProgressFact,
        humanHoursProgressForecast: terms.humanHoursProgressForecast,
        humanHoursProgressPercent: terms.humanHoursProgressPercent,
        humanHoursProgressPlan: terms.humanHoursProgressPlan,
        humanHoursProgressTZ: terms.humanHoursProgressTZ,
        humanHoursTotal: terms.humanHoursTotal,
        machineHourCost: terms.code,
        machineHoursTotal: terms.code,
        numOrder: terms.numOrder,
        objName: terms.objName,
        objTitle: terms.objTitle,
        rdCode: terms.rdCode,
        rdDate: terms.rdDate,
        rdDateApproval: terms.rdDateApproval,
        rdGroup: terms.rdGroup,
        rdVersion: terms.rdVersion,
        rdStatus: terms.rdStatus,
        startDate: terms.startDate,
        unit: terms.unit,
        volumeDoneForecast: terms.volumeDoneForecast,
        volumeDonePlan: terms.volumeDonePlan,
        volumeDoneFact: terms.volumeDoneFact,
        volumeDoneFactKsg: terms.volumeDoneFactKsg,
        volumeTotalKsg: terms.volumeTotalKsg,
        volumeTotal: terms.volumeTotal,
        workGroup: terms.workGroup,
        workPeriod: terms.workGroup,
        workName: terms.workName,
        operationalStartDate: terms.operationalStartDate,
        operationalEndDate: terms.operationalEndDate,
    }
}

export const templateMenuItemS = {
    paddingRight: '0.5rem',
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'end',
    gridTemplateColumns: 'auto auto 1fr',
    '.MuiTypography-root': {
        width: '12rem',
    },
}
