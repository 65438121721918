import styles from '../Projects.module.scss';
import { Dot } from '../component/Dot';

export const ChartsLegend = () => {
    return (
        <div className={styles.dots}>
            <Dot
                color='#FFB1B1'
                title='факт'
            />
            <Dot
                color='#6D9ADC'
                title='план'
            />
            <Dot
                color='#d4dce7'
                title='базовый план'
            />
        </div>
    );
};
