import { CircularProgress, Stack } from '@mui/material';
import { Fragment, useEffect } from 'react';
import { Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useSpinDelay } from 'spin-delay';

import { useLoginByTokenMutation } from '@/api/auth/auth.api';
import { profileApi } from '@/api/profile/profile.api';

import Header from '@/components/Header/Header';

import { usePreserveScroll } from '@/hooks/usePreserveScroll';

import { PageNotFound } from '@/pages/PageNotFound/PageNotFound';

import NoAccessIcon from '@/shared/SVG/NoAccessIcon';
import s from '@/shared/components/EmptyPlaceholder/EmptyPlaceholder.module.scss';
import pic from '@/shared/components/EmptyPlaceholder/backgroundStart.png';
import { currentCompanyName, deployLinks } from '@/shared/config/config';
import { DOES_ROLE_HAS_ACCESS_TO_PAGE } from '@/shared/rolePermissions';

import { changeListMode, clearWorks } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { isAuthSelector } from '@/store/slices/authSlice';
import { drawersSelector } from '@/store/slices/drawersSlice';
import { refreshFilters } from '@/store/slices/filtersSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { projectsSelector } from '@/store/slices/projectsSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

export const RootLayout = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const isAuth = useTypedSelector(isAuthSelector);

    const [loginByToken] = useLoginByTokenMutation();
    const dispatch = useAppDispatch();
    const { profile } = useTypedSelector(profileSelector);

    const { projectId } = useParams();
    const { pageName } = useTypedSelector(projectsSelector);
    const location = useLocation();

    const isLoading = useSpinDelay(!(pageName && profile.role), { delay: 0, minDuration: 1000 });
    const { AgGrid } = useTypedSelector(drawersSelector);

    /**
     * Эффект следит за сменой проекта
     * при смене очищаются workList
     * и очищаются примененные фильтры
     */
    useEffect(() => {
        if (/^\/ksg|msg.+/.test(location.pathname)) {
            dispatch(refreshFilters());
            dispatch(clearWorks());
            dispatch(changeListMode(false));
        }
        // }
        //eslint-disable-next-line
    }, [projectId, pageName]);
    const companyParam = currentCompanyName ? `&company=${currentCompanyName}` : '';

    useEffect(() => {
        if (!isAuth || searchParams.has('token')) {
            if (searchParams.has('token')) {
                loginByToken({ token: searchParams.get('token')! })
                    .then(() => {
                        dispatch(profileApi.endpoints.getProfile.initiate());
                        searchParams.delete('token');
                        setSearchParams(searchParams);
                    })
                    .catch((err) => console.error(err));
            } else {
                window.location.replace(`${deployLinks.core}?source=resource_managment${companyParam}`);
            }
        } else {
            dispatch(profileApi.endpoints.getProfile.initiate());
        }
    }, [companyParam, dispatch, isAuth, loginByToken, searchParams, setSearchParams]);

    usePreserveScroll({
        projectId: projectId!,
        AgGrid,
        pageName,
        pathname: location.pathname,
    });

    const renderContent = () => {
        if (isLoading) {
            return (
                <Stack
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <CircularProgress />
                </Stack>
            );
        }

        if (profile.role === 'none') {
            return (
                <PageNotFound
                    hideNavBtn
                    IconComponent={<NoAccessIcon />}
                >
                    Вам еще не выдали соответствующую роль или доступ к проекту
                </PageNotFound>
            );
        }

        if (!DOES_ROLE_HAS_ACCESS_TO_PAGE(profile.role, pageName!)) {
            return <PageNotFound />;
        }

        return <Outlet />;
    };

    if (!isAuth)
        return (
            <div className={s.container}>
                <div className={s.content}>Ждём</div>
                <img
                    src={pic}
                    alt=''
                />
            </div>
        );

    return (
        <Fragment>
            <Header />
            {renderContent()}
        </Fragment>
    );
};
