import { DOES_ROLE_HAS_ACCESS_TO_FEATURE, TFeatureName } from '@/shared/rolePermissions';

import { profileRoleSelector } from '@/store/slices/profileSlice';
import { useTypedSelector } from '@/store/store';

export type TFeatureGuard = (feature: TFeatureName) => boolean;

export const usePermissionsByRole = () => {
    const role = useTypedSelector(profileRoleSelector);

    const hasFeatureAccess: TFeatureGuard = (feature) => DOES_ROLE_HAS_ACCESS_TO_FEATURE(role, feature);
    const doesNotHaveFeatureAccess: TFeatureGuard = (feature) => !DOES_ROLE_HAS_ACCESS_TO_FEATURE(role, feature);

    return {
        role,
        hasFeatureAccess,
        doesNotHaveFeatureAccess,
    };
};
