import { Typography } from "@mui/material";
import { StatValue } from "../StatValue/StatVallue";
import { StatProgressWrapper } from "./StatProgress.styles";
import { IStatProgressProps } from "./StatProgress.types";

export function StatProgress({
    variant = "row",
    separator = "из",
    currentValue,
    totalValue,
    NumericFormatProps 
}: IStatProgressProps) {
    return (
        <StatProgressWrapper
            variant={variant}
        >
            <StatValue
                value={currentValue.value}
                TypographyProps={currentValue.TypographyProps}
                NumericFormatProps ={{
                    ...currentValue.NumericFormatProps ,
                    ...NumericFormatProps 
                }}
            />
            <Typography
                display="flex"
                alignItems="flex-end"
            >
                <Typography {...totalValue.TypographyProps}>
                    &nbsp;{separator}&nbsp;
                </Typography>
                <StatValue
                    value={totalValue.value}
                    TypographyProps={totalValue.TypographyProps}
                    NumericFormatProps ={{
                        ...totalValue.NumericFormatProps ,
                        ...NumericFormatProps 
                    }}
                />
            </Typography>
        </StatProgressWrapper>
    )
}