import { Box, Modal } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import CheckboxPopover from '@/components/CheckboxPopover/CheckboxPopover';
import { FilterDialog } from '@/components/FilterDialog/FilterDialog';

import { modalStyles } from '@/shared/utils';

import { selectedColumnSelector, setContractors, setRdCodes, setWorkGroup } from '@/store/slices/filtersSlice';
import { useTypedSelector } from '@/store/store';

import { getDialogKey, useBrigadesFilterDialogLogic, useFilterDialogLogic } from './MsgFilterDialogs.model';

interface IProps {
    projectId: string;
    isOpen: boolean;
    onClose: () => void;
}

export const WorkGroupFilterDialog: FC<IProps> = ({ projectId, isOpen, onClose }) => {
    const { data, isLoading, handleReset, handleApply, checkboxes } = useFilterDialogLogic(
        projectId,
        'group',
        'workGroupList'
    );

    return (
        <FilterDialog
            data={data}
            isLoading={isLoading}
            name='Группа работ'
            HeaderCheckboxes={checkboxes}
            onFilterReset={handleReset}
            onFilterApply={(options) => handleApply(options, setWorkGroup)}
            open={isOpen}
            onClose={onClose}
        />
    );
};

export const RdGroupFilterDialog: FC<IProps> = ({ projectId, isOpen, onClose }) => {
    const { data, isLoading, handleReset, handleApply, checkboxes } = useFilterDialogLogic(projectId, 'rd', 'rdCodes');

    return (
        <FilterDialog
            data={data}
            isLoading={isLoading}
            name='Шифр РД'
            HeaderCheckboxes={checkboxes}
            onFilterReset={handleReset}
            onFilterApply={(options) => handleApply(options, setRdCodes)}
            open={isOpen}
            onClose={onClose}
        />
    );
};

export const ContractorCompanyFilterDialog: FC<IProps> = ({ projectId, isOpen, onClose }) => {
    const { data, isLoading, handleReset, handleApply, checkboxes } = useFilterDialogLogic(
        projectId,
        'contractor',
        'contractors'
    );

    return (
        <FilterDialog
            data={data}
            isLoading={isLoading}
            name='Подрядчик'
            HeaderCheckboxes={checkboxes}
            onFilterReset={handleReset}
            onFilterApply={(options) => handleApply(options, setContractors)}
            open={isOpen}
            onClose={onClose}
        />
    );
};

export const BrigadesFilterDialog: FC<IProps> = ({ projectId, isOpen, onClose }) => {
    const { data, isLoading, handleReset, handleApply } = useBrigadesFilterDialogLogic(projectId);

    return (
        <FilterDialog
            data={data}
            isLoading={isLoading}
            name='Бригады'
            onFilterReset={handleReset}
            onFilterApply={handleApply}
            open={isOpen}
            onClose={onClose}
        />
    );
};

interface IContainerProps extends PropsWithChildren {
    isOpen: boolean;
    onClose: () => void;
}

const Container: FC<IContainerProps> = ({ isOpen, onClose, children }) => {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
        >
            <Box
                sx={{
                    ...modalStyles,
                    width: 373,
                }}
            >
                {children}
            </Box>
        </Modal>
    );
};

type TCommonProps = {
    projectId: string;
    isOpen: boolean;
    onClose: () => void;
};

export const Common: React.FC<TCommonProps> = ({ projectId, isOpen, onClose }) => {
    const selectedColumn = useTypedSelector(selectedColumnSelector);

    return {
        workGroup: (
            <WorkGroupFilterDialog
                projectId={projectId}
                isOpen={isOpen}
                onClose={onClose}
            />
        ),
        rdCode: (
            <RdGroupFilterDialog
                projectId={projectId}
                isOpen={isOpen}
                onClose={onClose}
            />
        ),
        contractorCompany: (
            <ContractorCompanyFilterDialog
                projectId={projectId}
                isOpen={isOpen}
                onClose={onClose}
            />
        ),
        brigade: (
            <BrigadesFilterDialog
                projectId={projectId}
                isOpen={isOpen}
                onClose={onClose}
            />
        ),
        default: (
            <Container
                isOpen={isOpen}
                onClose={onClose}
            >
                <CheckboxPopover msg />
            </Container>
        ),
    }[getDialogKey(selectedColumn)];
};
