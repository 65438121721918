import { Box, IconButton, IconButtonProps } from '@mui/material'
import styled from 'styled-components'
import '../../../AgGrid/AgGrid.scss'

export const StyledContainer = styled(Box)`
    position: absolute;
    top: 2px;
    right: 7px;
`

export const StyledIconBtn = styled(IconButton)<IconButtonProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4cf0c;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    padding: 0;
    transition: filter 0.3s ease;
    filter: opacity(100%);

    &:hover {
        background-color: #f4cf0c;
        filter: opacity(70%);
    }
`
