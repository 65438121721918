import { useTranslation } from 'react-i18next'
import React from 'react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Box, IconButton } from '@mui/material'
import s from './ErrorsList.module.scss'
import CancelIcon from '@mui/icons-material/Cancel'
import { HtmlTooltip } from '../HtmlTooltip'
import { DrawerTitle } from '../../../components/UpdateKSGDrawer/UpdateKSGDrawer.styles'

interface ErrorsListProps {
    setShowErrorsList: React.Dispatch<React.SetStateAction<boolean>>
    errors?: Error[] | undefined | null
    errorsImportObj?: { code: string; count: number }[] | undefined
    errorsImportArr?: string[] | undefined
    errorMessage?: string
    title?: string
}

interface Error {
    type: string
    row: number | null
    col: number | null
    cell?: number | null
    message: string | null
}

export default function ErrorsList(props: ErrorsListProps) {
    const { setShowErrorsList, errors, errorsImportObj, errorsImportArr, errorMessage, title } = props
    const { t } = useTranslation('uploadErrors')
    const contentEl = () => {
        switch (true) {
            case !!errorsImportObj:
                return errorsImportObj?.map((v) => (
                    <li key={v.code}>
                        <div className={s.errors_type}>
                            <div className={s.message_between}>
                                <h5>{v.code}</h5>
                            </div>
                            <p className={s.errors_message}>{errorMessage}</p>
                            <p>Работ: {v.count}</p>
                        </div>
                    </li>
                ))

            case !!errorsImportArr:
                return errorsImportArr?.map((v) => (
                    <li key={v}>
                        <div className={s.errors_type}>
                            <h5>{v}</h5>
                            <p className={s.errors_message}>{errorMessage}</p>
                        </div>
                    </li>
                ))

            default:
                return errors?.map((v, index) => (
                    <li key={index} className={s.errors_item}>
                        <div className={s.errors_type}>
                            <div>
                                <WarningAmberIcon
                                    sx={{
                                        color: '#f46b6b',
                                        fontSize: '1.2rem',
                                    }}
                                />
                                <h5>{t(v.type)}</h5>
                            </div>
                            <p>Строка {v.row} </p>
                        </div>
                        <p className={s.errors_message}>Запись: {v.message}</p>
                    </li>
                ))
        }
    }

    return (
        <Box width="400px" overflow={"hidden"}>
            {/* <h4 className={s.sync_header}> */}
            <DrawerTitle
                position={"sticky"}
                zIndex={2}
            >
                {title}
                <HtmlTooltip title="Закрыть и вернуться на предыдущий экран"
                    style={{
                        position: "absolute",
                        bottom: "calc(100% / 2 - 20px)",
                        right: 20,
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <IconButton color="primary" component="span" onClick={() => setShowErrorsList(false)}>
                        <CancelIcon />
                    </IconButton>
                </HtmlTooltip>
            </DrawerTitle>

            <ul
                // style={{ width: errorMessage ? 380 : undefined }}
                className={s.errors_list}>
                {contentEl()}
            </ul>
        </Box>
    )
}
