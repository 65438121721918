import React, { useCallback } from 'react'
import { FormFieldProps } from './FieldForm.types'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { TextField } from '@mui/material'
import {
    DatePickerRenderInput,
    ProjectFormTextField,
    SmallFormTextField,
    StyledTextField,
    StyledTextFieldTitle,
} from './styles'
import { StyledSelect } from '../UserRoleSelection/styles'
import { DesktopDatePicker } from '@mui/x-date-pickers'

function FieldForm({
    version,
    title,
    maxLength,
    fieldProps,
    onBlur,
    dataValue,
    onDataChange,
    children,
    ...props
}: FormFieldProps) {
    const { t } = useTranslation()
    const [{ onBlur: onFieldBlur, ...field }, { touched, error }] = useField({
        name: props.name,
        ...fieldProps,
    })

    const handleBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            onFieldBlur?.(e)
            onBlur?.(e)
        },
        [onBlur, onFieldBlur]
    )

    const isError = touched && !!error

    switch (version) {
        case 'profile':
            return (
                <label style={{ textAlign: 'left' }}>
                    <StyledTextFieldTitle mb={0.75} variant="tooltip" pl={2}>
                        {title}
                    </StyledTextFieldTitle>
                    <StyledTextField
                        {...field}
                        InputProps={{ disableUnderline: true }}
                        fullWidth
                        variant="filled"
                        size="small"
                        helperText={isError ? t(error as string, { maxLength }) : undefined}
                        onBlur={handleBlur}
                        error={isError}
                        {...props}
                    />
                </label>
            )

        case 'project':
        case 'rd':
        case 'objects':
            return (
                <ProjectFormTextField
                    {...field}
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                    variant="filled"
                    size="small"
                    helperText={isError ? t(error as string, { maxLength }) : undefined}
                    onBlur={handleBlur}
                    error={isError}
                    {...props}
                />
            )

        case 'date':
            return (
                <DesktopDatePicker
                    mask="__.__.____"
                    value={dataValue}
                    onChange={onDataChange!}
                    disableOpenPicker
                    renderInput={(params) => (
                        <DatePickerRenderInput
                            helperText={isError ? t(error as string, { maxLength }) : undefined}
                            {...props}
                            {...params}
                            error={isError}
                            inputProps={{
                                ...params.inputProps,
                                placeholder: 'дд.мм.год',
                            }}
                        />
                    )}
                />
            )

        case 'select':
            return (
                <StyledSelect
                    select
                    {...field}
                    InputProps={{ disableUnderline: true }}
                    helperText={isError ? t(error as string, { maxLength }) : undefined}
                    onBlur={handleBlur}
                    error={isError}
                    {...props}
                >
                    {children}
                </StyledSelect>
            )

        case 'small':
            return (
                <SmallFormTextField
                    {...field}
                    InputProps={{ disableUnderline: true }}
                    fullWidth
                    variant="filled"
                    size="small"
                    helperText={isError ? t(error as string, { maxLength }) : undefined}
                    onBlur={handleBlur}
                    error={isError}
                    {...props}
                />
            )

        default:
            return (
                <TextField
                    {...field}
                    onBlur={handleBlur}
                    error={isError}
                    helperText={isError ? t(error as string) : undefined}
                    {...props}
                />
            )
    }
}

export default FieldForm
