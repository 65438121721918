import { TabContext, TabList } from '@mui/lab';
import { Tab } from '@mui/material';
import { memo } from 'react';

import { projectsTerms } from '../Projects.service';

export const Tabs = memo(
    ({
        tab,
        onChange,
        isEmptyTab,
    }: {
        tab: projectsTerms;
        onChange: (event: React.SyntheticEvent, newValue: projectsTerms) => void;
        isEmptyTab: (status: projectsTerms) => boolean;
    }) => {
        return (
            <TabContext value={tab}>
                <TabList
                    sx={{
                        '.MuiButtonBase-root': {
                            fontWeight: 600,
                            fontSize: 14,
                        },
                    }}
                    onChange={onChange}
                    aria-label='lab API tabs example'
                >
                    <Tab
                        label='Все проекты'
                        value='Все'
                    />
                    <Tab
                        label='Активные'
                        disabled={isEmptyTab('Активный')}
                        value='Активный'
                    />
                    <Tab
                        label='Приостановленные'
                        disabled={isEmptyTab('Приостановлен')}
                        value='Приостановлен'
                    />
                    <Tab
                        label='Завершенные'
                        disabled={isEmptyTab('Завершенный')}
                        value='Завершенный'
                    />
                </TabList>
            </TabContext>
        );
    }
);
