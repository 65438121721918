import { Typography } from '@mui/material'
import { IValidationErrorMessage } from './ControlableInputs.def'

export function ValidationErrorMessage(props: IValidationErrorMessage) {
    const { message } = props

    return message ? (
        <Typography color="error" fontSize={'12px'} textAlign={'right'} position={"absolute"} right={0} bottom={-16}>
            {message}
        </Typography>
    ) : null
}
