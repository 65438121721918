import styled from 'styled-components'
import { MenuItem, TextField } from '@mui/material'

export const StyledSelect = styled(TextField)`
  background-color: ${props => props.theme.palette.bg.shades};
  
  & .MuiSelect-select {
    padding: 10px 16px;
    text-align: left;
  }
  
  & .MuiSvgIcon-root {
    right: 14px;
    color: ${props => props.theme.palette.text.dark};
    font-size: 16px;
  }
  
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(92, 110, 140, 0.3);
    border-radius: 6px;
  }
`

export const StyledSelectMenuItem = styled(MenuItem)`
  max-width: 276px;
  
  &:hover {
    color: ${props => props.theme.palette.primary.main};
    background-color: ${props => props.theme.palette.bg.shades};
  }
  
  &.Mui-focusVisible {
    background-color: ${props => props.theme.palette.bg.gray};
  }
`

export const DisabledSelectMenuItem = styled(StyledSelectMenuItem)`
  &.Mui-disabled {
    background-color: ${props => props.theme.palette.bg.white};
  }
`