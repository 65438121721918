import { Box, Select } from '@mui/material';
import styled from 'styled-components';

import { FlexRowWrapper } from '../../../NewExecutorView/components/components.styles';
import { WorkManagementSubPageWrapper } from '../../components/components.styles';

export const MobilizationPageWrapper = styled(WorkManagementSubPageWrapper)`
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    height: calc(100vh - 140px);
    min-width: 100%;
`;

export const StyledSelect = styled(Select)`
    position: relative;
    .MuiSelect-select {
        padding: 0;
        color: ${({ theme }) => theme.palette.primary.main};
        width: fit-content;
    }
    fieldset {
        outline: none;
        border: none !important;
        width: auto;
    }
    svg {
        color: ${({ theme }) => theme.palette.primary.main};
    }
`;
export const MobilizationChartCell = styled(Box)`
    gap: 0 !important;
    display: grid;
    grid-auto-rows: 1fr;
    width: 100%;
    p,
    div {
        background: white;
    }
    p {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        align-self: center;
        /* line-height: 30px; */
        /* padding: 8px; */
    }
    p:first-of-type {
        padding-bottom: 4px;
    }
    p:last-of-type {
        padding-top: 4px;
    }
    div:not(:last-of-type) {
        border-bottom: 1px rgb(221, 226, 234) solid !important;
    }
    p:not(:last-of-type) {
        box-shadow: inset 0px -1px rgb(221, 226, 234) !important;
    }
`;

export const StyledControlPeriodSelect = styled(StyledSelect)`
    .MuiSelect-select {
        padding: ${({ theme }) => theme.spacing('3.5px', 2)};
        background: rgba(25, 118, 210, 0.08);
        font-size: 12;
    }
`;

StyledControlPeriodSelect.defaultProps = {
    MenuProps: {
        PaperProps: {
            sx: {
                maxHeight: '250px !important',
            },
        },
    },
};

export const StyledNoRowsOverlayComponent = styled(FlexRowWrapper)`
    height: 100%;
    width: 100%;
    background-color: white;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
`;
