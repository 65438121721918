import { Box, BoxProps } from "@mui/material";
import styled from "styled-components";
import { COLORS, TColors } from "../PercentStatusValue/PercentStatusValue.style";

interface IRdStatusBarProps extends Omit<BoxProps, 'color'> {
    color: TColors
}

export const RDStatusWrapper = styled(Box) <IRdStatusBarProps>`
    display: flex;
    gap: ${props => props.theme.spacing(1)};
    align-items: center;
    * {
        color: ${props => COLORS[props.color as TColors].color};
    }
`

export const RDStatusBar = styled(Box) <IRdStatusBarProps>`
    width: 22px;
    height: 12px;
    background-color: ${props => COLORS[props.color as TColors].color};
    border-radius: ${props => props.theme.spacing(0.25)};
`