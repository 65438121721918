import { Tab, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { WorkWithResourcesAPI } from '../api/WorkWithResourcesAPI'
import { IWorkMimFactDataItem, IWorkStaffResourceFactDataItem, IWorkFactData } from '../api/api.types'
import { StyledSNZAgGrid } from '../AgGridService/AgGrid.styles'
import { TabsContained } from '../../WorkManagment/components/components.styles'
import { FlexColumnWrapper, FlexRowWrapper, StyledControlPanel, StyledWorkWithResourceControlPanel } from '../components/components.styles'
import { AG_GRID_DEFAULT_PARAMS } from '../../WorkManagment/components/AgGridService/AgGridColumnDef.service'
import { SNZMimTableColDef } from './WorkMimResourceFactTable.colDef'
import { CellValueChangedEvent } from 'ag-grid-community'
import { theme } from '../../../styles/theme'
import { ControllableNumberInput } from '../components/inputs/ControllableNumberInput'
import { FormProvider, useForm } from 'react-hook-form'
import { SwitchListTypeTabs } from '../components/SwitchListTypeTabs/SwitchListTypeTabs'
import { SNZStaffTableColDef } from './WorkStaffResourceFactTable.ColDef'
import { switchToMobileView } from '../components/components.service'

interface IResourceList {
    mim: IWorkMimFactDataItem[] | null
    staff: IWorkStaffResourceFactDataItem[] | null
}

export function WorkResourcesListPage() {
    const { projectID, workID } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [waitList, setWaitList] = useState<IResourceList>({
        mim: null,
        staff: null,
    })
    const [doneList, setDoneList] = useState<IResourceList>({
        mim: null,
        staff: null,
    })
    const [work, setWork] = useState<IWorkFactData>()

    const methods = useForm({
        mode: 'all',
        defaultValues: {
            volumeFact: work?.dailyChart.fact,
        },
    })

    useEffect(() => {
        WorkWithResourcesAPI.getWorkWithResourcesByID({
            projectID: projectID || '',
            workID: workID,
        }).then(({ staff, mim, work }) => {
            setWaitList((prevValue) => ({
                ...prevValue,
                staff: staff.waitList,
                mim: mim.waitList,
            }))
            setDoneList((prevValue) => ({
                ...prevValue,
                staff: staff.doneList,
                mim: mim.doneList,
            }))
            setWork(work)
            methods.reset({
                volumeFact: work?.dailyChart.fact,
            })
        })
    }, [])

    function moveRowFromWaitToDoneList(params: CellValueChangedEvent, resourceType: keyof IResourceList) {
        setWaitList((prevValue) => ({
            ...prevValue,
            [resourceType]: (prevValue[resourceType] as any).filter(
                (listItem: IWorkMimFactDataItem | IWorkStaffResourceFactDataItem) => {
                    return params.data.id != listItem.id
                }
            ),
        }))
        setDoneList((prevValue) => ({
            ...prevValue,
            [resourceType]: [...(prevValue[resourceType] || []), params.data],
        }))
    }

    function moveRowFromDoneToWaitList(params: CellValueChangedEvent, resourceType: keyof IResourceList) {
        setDoneList((prevValue) => ({
            ...prevValue,
            [resourceType]: (prevValue[resourceType] as any).filter(
                (listItem: IWorkMimFactDataItem | IWorkStaffResourceFactDataItem) => {
                    return params.data.id != listItem.id
                }
            ),
        }))
        setTimeout(() =>
            setWaitList((prevValue) => ({
                ...prevValue,
                [resourceType]: [...(prevValue[resourceType] || []), { ...params.data, fact: params.oldValue }],
            }))
        )
    }

    function saveResourceFact(params: CellValueChangedEvent, withMoveToDoneList: boolean) {
        withMoveToDoneList && moveRowFromWaitToDoneList(params, searchParams.get('resourceType') as keyof IResourceList)
        WorkWithResourcesAPI.updateItemFact({
            projectID: projectID || '',
            itemType: searchParams.get('resourceType') as keyof IResourceList,
            body: {
                fact: params.newValue,
                id: params.data.id,
            },
        }).catch((e) => moveRowFromDoneToWaitList(params, searchParams.get('resourceType') as keyof IResourceList))
    }

    function saveVolumeFact(value: number | null | undefined) {
        WorkWithResourcesAPI.updateItemFact({
            projectID: projectID || '',
            itemType: 'work',
            body: {
                fact: value || null,
                id: workID || '',
            },
        })
    }

    return (
        <FlexColumnWrapper width={'100%'} height={'100%'} gap={2} >
            <StyledWorkWithResourceControlPanel flexWrap={"wrap"}>
                <TabsContained
                    value={searchParams.get('resourceType')}
                    onChange={(e, newValue) =>
                        setSearchParams((params) => {
                            params.set('resourceType', newValue)
                            return params
                        })
                    }
                >
                    <Tab label={'Персонал'} value="staff" />
                    <Tab label={'МиМ'} value="mim" />
                </TabsContained>

                <SwitchListTypeTabs />

                <FlexRowWrapper ml={'auto'} width={'auto'}>
                    <Typography color={'secondary'} variant="body2">
                        м3 сегодня: {work?.volumeDoneFact}
                    </Typography>
                    <Typography color={'secondary'} variant="body2">
                        на месяц: {work?.planMonth}
                    </Typography>
                    <Typography color={'secondary'} variant="body2">
                        прогноз: {work?.planMonth}
                    </Typography>
                    <FlexRowWrapper>
                        <Typography color={theme.palette.text.primary} variant="button">
                            Объем работ:
                        </Typography>

                        <FormProvider {...methods}>
                            <ControllableNumberInput
                                defaultValue={work?.dailyChart.fact}
                                fieldName="volumeFact"
                                onSave={saveVolumeFact}
                                NumericFormatProps ={{
                                    placeholder: "Введите факт"
                                }}
                            />
                        </FormProvider>
                    </FlexRowWrapper>
                </FlexRowWrapper>
            </StyledWorkWithResourceControlPanel>
            <StyledSNZAgGrid
                rowData={
                    searchParams.get('listType') == 'waitList'
                        ? waitList[(searchParams.get('resourceType') || 'staff') as keyof IResourceList]
                        : doneList[(searchParams.get('resourceType') || 'staff') as keyof IResourceList]
                }
                columnDefs={searchParams.get('resourceType') == 'staff' ? SNZStaffTableColDef : SNZMimTableColDef}
                {...AG_GRID_DEFAULT_PARAMS()}
                onRowDataUpdated={(event) => {
                    event.api.sizeColumnsToFit()
                }}
                onCellValueChanged={(params: CellValueChangedEvent) =>
                    saveResourceFact(params, searchParams.get('listType') == 'waitList')
                }
                overlayNoRowsTemplate="Пока нет ресурсов"
                onGridReady={(params) => switchToMobileView(params, ["nameType"])}
                onGridSizeChanged={(params) => switchToMobileView(params, ["nameType"])}
            />
        </FlexColumnWrapper>
    )
}
