import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';

import { Work, WorkOrWorkPosition } from '@/api/ksg/ksg.def';

import { VolumeTotalNotificator } from '@/pages/AgGrid/components/VolumeTotalNotificator/VolumeTotalNotificator';
import { NumberCellRenderer } from '@/pages/WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer';

import { isWorkPosition } from '@/shared/guards/works.guards';
import { areAllValuesNull } from '@/shared/utils';

const BaseCellRenderer: FC<ICellRendererParams<Work>> = (props) => {
    const data = props.data as Work;

    const isVisibleNotificator = data.volumeDistributeIndicator && !areAllValuesNull(data.volumeDistributeIndicator);
    const isVisibleValue = data.volumeTotal || !data?.hasChildren;

    return (
        <>
            {isVisibleNotificator && <VolumeTotalNotificator data={data?.volumeDistributeIndicator!} />}
            {isVisibleValue && <NumberCellRenderer {...props} />}
        </>
    );
};

export const VolumeTotalCellRenderer: FC<ICellRendererParams<WorkOrWorkPosition>> = (props) => {
    if (!props.data) return null;
    if (isWorkPosition(props.data)) return <NumberCellRenderer {...props} />;

    return <BaseCellRenderer {...(props as ICellRendererParams<Work>)} />;
};
