import { triggerMsgByBasePlanChange } from '../../store/slices/basePlan'
import { setTotal } from '../../store/slices/paginationSlice'
import { store } from '../../store/store'
import { api } from '../api'
import {
    getInitMSGReq,
    getMSGReq,
    getMSGTreeReq,
    GetMSGTreeRes,
    IGetGantDependenciesReq,
    IGetGantDependenciesRes,
    ISendEmailOnMSGFactDifferenceReq,
    ISpreadPlanReq,
    ISpreadPlanRes,
    MSGTree,
    setMSGExecutorReq,
    setMSGExecutorRes,
    uploadMonthsReq,
    uploadMonthsRes,
} from './msg.def'

export const msgApi = api.injectEndpoints({
    endpoints: (build) => ({
        getMSG: build.query<GetMSGTreeRes, getMSGTreeReq>({
            query: ({ id, year, month, limit, offset, filter }) => ({
                url: `/projects/${id}/works/msg-tree`,
                params: { year, month, limit, offset, filter },
                method: 'GET',
            }),
            async onQueryStarted(arg, api) {
                const data = await api.queryFulfilled

                api.dispatch(setTotal(data.data.data.length ? data.data.total : 1))
            },
            providesTags: ['MSG'],
        }),

        downloadMSG: build.mutation<string, getMSGReq>({
            query: ({ id, year, month }) => ({
                url: `/projects/${id}/works/export-msg`,
                params: { year, month },
                method: 'POST',
            }),
        }),

        initMSG: build.mutation<GetMSGTreeRes, getInitMSGReq>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/works/msg-init`,
                body,
                method: 'POST',
            }),
            invalidatesTags: ['MSG', 'Headers'],
        }),

        setMSGExecutor: build.mutation<setMSGExecutorRes, setMSGExecutorReq>({
            query: ({ id, year, month, body }) => ({
                url: `/projects/${id}/works/msg/set-executor`,
                params: { year, month },
                body,
                method: 'POST',
            }),
            async onQueryStarted({ id, year, month, body, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(
                        msgApi.util.updateQueryData(
                            'getMSG',
                            {
                                id,
                                year,
                                month,
                                limit: +store.getState().pagination.rowsPerPage,
                                offset:
                                    (store.getState().pagination.page - 1) * +store.getState().pagination.rowsPerPage,
                                filter: store.getState().project.headerSearch,
                            },
                            (draft) => {
                                const updateWork = (work: MSGTree) => {
                                    if (work.payload.id === body.workID) {
                                        work.payload.contractorCompany = body.contractorCompany || ''
                                    }
                                    if (work.payload.hasChildren) {
                                        work.children.forEach((child) => {
                                            updateWork(child)
                                        })
                                    }
                                }
                                draft.data.forEach((work) => {
                                    updateWork(work)
                                })
                            }
                        )
                    )
                } catch {}
            },
        }),

        uploadMonths: build.mutation<uploadMonthsRes, uploadMonthsReq>({
            query: ({ id, year, month, body, method }) => ({
                url: `/projects/${id}/works/msg/update-charts`,
                params: { year, month },
                body,
                method: method,
            }),
            async onQueryStarted({ id, year, month, body, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedStatus } = await queryFulfilled
                    dispatch(
                        msgApi.util.updateQueryData(
                            'getMSG',
                            {
                                id,
                                year,
                                month,
                                limit: +store.getState().pagination.rowsPerPage,
                                offset:
                                    (store.getState().pagination.page - 1) * +store.getState().pagination.rowsPerPage,
                                filter: store.getState().project.headerSearch,
                            },
                            (draft) => {
                                const updateWork = (work: MSGTree) => {
                                    if (work.payload.id === updatedStatus.data[0].id) {
                                        work.payload = updatedStatus.data[0]
                                    }
                                    if (work.payload.hasChildren) {
                                        work.children.forEach((child) => {
                                            updateWork(child)
                                        })
                                    }
                                }
                                draft.data.forEach((work) => {
                                    updateWork(work)
                                })
                            }
                        )
                    )
                } catch {}
            },
        }),

        lockMsg: build.mutation<unknown, { projectId: number; year: number; month: number }>({
            query: ({ projectId, year, month }) => ({
                url: `/projects/${projectId}/works/msg/lock`,
                params: { year, month },
                method: 'POST',
            }),
            async onQueryStarted(arg, api) {
                try {
                    await api.queryFulfilled
                    api.dispatch(triggerMsgByBasePlanChange())
                } catch {}
            },
        }),
        spreadPlan: build.mutation<ISpreadPlanRes, ISpreadPlanReq>({
            query: ({ workID, projectID, body }) => ({
                url: `/projects/${projectID}/works/${workID}/distribute/ksg/plan`,
                method: 'POST',
                body: body,
            }),
        }),
        getGantDependencies: build.query<IGetGantDependenciesRes, IGetGantDependenciesReq>({
            query: ({ projectID, month, year }) => ({
                url: `/projects/${projectID}/dependencies/works/msg/info`,
                method: 'POST',
                body: {
                    month: month,
                    year: year,
                },
            }),
        }),
        sendEmailOnMSGFactDifference: build.mutation<void, ISendEmailOnMSGFactDifferenceReq>({
            query: ({ projectID, workID, executorId, year, month, day }) => ({
                url: `/projects/${projectID}/works/send-info-alert-difference?workID=${workID}&executorId=${executorId}&year=${year}&month=${month}&day=${day}`,
                method: 'POST',
            }),
        }),
    }),
    overrideExisting: false,
})

export const {
    useLockMsgMutation,
    useInitMSGMutation,
    useSetMSGExecutorMutation,
    useUploadMonthsMutation,
    useDownloadMSGMutation,
    useSpreadPlanMutation,
    useGetGantDependenciesQuery,
    useSendEmailOnMSGFactDifferenceMutation,
} = msgApi
