import { Dialog, Stack } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import styled from 'styled-components'

export const DialogWindow = styled(Dialog)`
    .MuiDialog-container {
        padding-inline: 10px;

        .MuiPaper-root {
            border: 1px solid #fff;
            padding: 30px;
            margin: 0;
            border-radius: 16px;
            padding: 30px;
            max-width: 612px;
            width: 100%;
            min-height: 268px;
            display: flex;
            flex-direction: column;

            @media screen and (max-width: 600px) {
                padding: 25px 20px;
            }
        }
    }
`

export const Container = styled(Stack).attrs({
    direction: 'column',
})`
    flex: 1;
    gap: 20px;
`

export const StyledAgGrid = styled(AgGridReact)`
    --ag-border-color: #5c6e8c !important;
    --ag-header-cell-hover-background-color: #6e86ab !important;
    --ag-cell-horizontal-border: solid rgba(0, 0, 0, 0.1);
    --ag-header-column-separator-display: block;
    --ag-header-column-separator-color: #6e86ab !important;
    --ag-header-column-separator-width: 1px;
    --ag-row-border-color: rgba(0, 0, 0, 0.1);

    .ag-root-wrapper {
        border-radius: 0.5rem 0.5rem 0 0;
    }

    .ag-header-cell-label {
        justify-content: center;
    }

    .ag-theme-alpine .ag-header-group-cell {
        background-color: $grayb2;
        padding: 0 0.125rem;
        text-align: center;

        * {
            font-size: 14px;
            text-align: center;
            color: #fff;
            font-weight: 500;
        }
    }

    .ag-header-container {
        white-space: normal;
        text-align: center;
    }

    .ag-theme-alpine .ag-cell-label-container {
        padding: 0;
    }

    .ag-header-cell {
        background-color: $grayb2;
        padding: 0;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #fff;
        padding: 0 0.125rem;
    }

    .level_zero {
        background-color: #b2cfff;
        font-weight: 400;
        color: #2b3648;
    }

    .level_one {
        background-color: #b2cfff;
    }

    .level_two {
        background-color: #c5dbff;
    }

    .level_three {
        background-color: #dae8ff;
    }

    .level_four {
        background-color: #ecf3ff;
    }

    .level_sixteen {
        background-color: #fff;
    }

    .ag-cell {
        padding: 0.5rem;
    }

    .ag-cell-value {
        line-height: 140%;
    }

    .ag-cell-align-left {
        justify-content: flex-start;
        text-align: left;
    }

    .ag-cell-padding-none {
        padding: 0;
    }

    .ag-cell-flex {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ag-header-row {
        background-color: #7890b2;
    }

    .ag-header-group-cell,
    .ag-header-cell {
        background-color: #7890b2;
        border-right: 1px solid #5c6e8c;
    }

    .ag-header-row:not(:first-child) .ag-header-cell:not(.ag-header-span-height.ag-header-span-total),
    .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
        border-top: 1px solid #5c6e8c;
    }

    .ag-header-cell::after,
    .ag-header-group-cell::after {
        display: none;
    }

    .ag-theme-alpine .ag-row {
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #2b3648;
        text-align: left;
    }
`
