import { Checkbox } from '@mui/material'
import styled from 'styled-components'

export const StyledCheckbox = styled(Checkbox)`
    color: #0044b4;
    padding: 4px;

    & .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
    }

    &.Mui-checked {
        color: #0044b4;
    }
`
