import { Stack, Typography } from '@mui/material'
import styled from 'styled-components'

interface IWorkPositionLevelRendererProps {}

const Container = styled(Stack).attrs({
    alignItems: 'center',
    justifyContent: 'center',
})`
    border: 0.5px solid #fe9b3f;
    border-radius: 4px;
    padding: 4px;
    width: 30px;
    height: 20px;
    background: rgba(254, 155, 63, 0.2);
`

const Text = styled(Typography)`
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #fe9b3f;
`
export const WorkPositionLevelRenderer: React.FC<IWorkPositionLevelRendererProps> = () => {
    return (
        <Container>
            <Text>Поз</Text>
        </Container>
    )
}
