import React from 'react'
import { Container, Loader } from './UIUploadLoader.styles'

interface IProps {}

export const UIUploadLoader: React.FC<IProps> = () => {
    return (
        <Container>
            <Loader />
        </Container>
    )
}
