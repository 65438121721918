import { NextArrow, PrevArrow } from './MonthSwitcher.styles';

export const ARROW_ICONS = {
    PREV: <PrevArrow />,
    NEXT: <NextArrow />,
};

export const MONTH_STEP = 1;
export const BOUNDARY_MONTH_STEP = 2;

