import { CircularProgress, Stack } from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import { useGetUsersQuery } from '../../api/users/users.api'
import AdminLegend from './AdminLegend/AdminLegend'
import UserCard from './UserCard/UserCard'
import { TabData } from './components/Tabs/Tabs.types'
import { Limit } from './global'
import { UserRoleByTabLabel, UserRoleTabLabels, userTabNamesArray } from './user'
import { NUMBER_OF_ROWS_PER_USERS_PAGE } from './utils/constants'

import { profileSelector } from '../../store/slices/profileSlice'
import { useTypedSelector } from '../../store/store'
import { useDataFilter, useSearchValue, useUserNavigation } from './Users.model'

function Users() {
    const [currentTab, setCurrentTab] = useState<UserRoleTabLabels>('Все пользователи')
    const tabNames: UserRoleTabLabels[] = userTabNamesArray

    const searchValue = useSearchValue()

    const { profile } = useTypedSelector(profileSelector)

    const [limitValue, setLimitValue] = useState<Limit['value']>(NUMBER_OF_ROWS_PER_USERS_PAGE[0].value)
    const [page, setPage] = useState(1)

    const {
        data: userResponse,
        isLoading,
        isFetching,
    } = useGetUsersQuery({
        companyId: profile?.company?.companyID,
    })
    const userData = useMemo(() => userResponse?.data ?? [], [userResponse?.data])

    const { pagedUserData, countPagination } = useDataFilter({
        userData,
        currentTab,
        searchValue,
        limitValue,
        page,
    })

    const { handleUserCardClick, handleUserAdd } = useUserNavigation(profile.role)

    const tabsData: TabData<UserRoleTabLabels>[] = useMemo(() => {
        return tabNames.map((tabName) => {
            const disabled =
                tabName === 'Все пользователи'
                    ? false
                    : !userData?.some((user) => user.role === UserRoleByTabLabel[tabName])

            return {
                body: tabName,
                disabled,
            }
        })
    }, [tabNames, userData])

    const handleTabChange = useCallback((_: React.SyntheticEvent, tabValue: UserRoleTabLabels) => {
        setCurrentTab(tabValue)
        setPage(1)
    }, [])

    const handleChangeLimit = useCallback(
        (limit: number) => {
            setLimitValue(Number(limit))
            setPage(1)
        },
        [setLimitValue, setPage]
    )
    const handleChangePage = useCallback((page: number) => setPage(page), [setPage])

    return (
        <Stack
            sx={{
                display: 'grid',
                gridTemplateRows: { xs: '1fr calc(100vh - 175px)', lg: '1fr calc(100vh - 120px)' },
            }}
        >
            <AdminLegend<UserRoleTabLabels>
                currentTab={currentTab}
                pagination
                tabsData={tabsData}
                onTabChange={handleTabChange}
                onAddClick={handleUserAdd}
                countPagination={countPagination}
                limit={limitValue}
                onChangeLimit={handleChangeLimit}
                onChangePage={handleChangePage}
                page={page}
                numberRows={NUMBER_OF_ROWS_PER_USERS_PAGE}
            />
            {isLoading || isFetching ? (
                <Stack flex={1} alignItems="center" justifyContent="center" height="100%">
                    <CircularProgress />
                </Stack>
            ) : (
                <div className="user_cards">
                    {pagedUserData.map((user) => (
                        <div key={user.id}>
                            <UserCard data={user} onClick={handleUserCardClick} />
                        </div>
                    ))}
                </div>
            )}
        </Stack>
    )
}

export default Users
