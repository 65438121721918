import { useMonthSwitcher } from './MonthSwitcher.model';
import { Container, Month } from './MonthSwitcher.styles';

interface IProps {}

export const MonthSwitcher: React.FC<IProps> = () => {
    const { hasHeaders, arrow, monthName } = useMonthSwitcher();

    if (!hasHeaders) return null;

    return (
        <Container>
            {arrow.prev}
            <Month>{monthName}</Month>
            {arrow.next}
        </Container>
    );
};
