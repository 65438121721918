import { Box, Typography, TypographyProps } from '@mui/material'
import { NumericFormatProps } from 'react-number-format'
import styled from 'styled-components'
import { COLORS } from '../../WorkManagment/components/PercentStatusValue/PercentStatusValue.style'
import { StyledNumberFormat, TabsContainedStyle } from '../../WorkManagment/components/components.styles'
import { BREAKPOINTS } from './components.service'
import { IFactSendStatusBar } from './components.types'

export const FactSendStatusBar = styled(Typography)<IFactSendStatusBar>`
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 0.46px;
    font-weight: 500;
    padding: ${(props) => props.theme.spacing(1.25)};
    width: 100%;
    ${({ isWaiting: factAlreadySent }) => {
        if (factAlreadySent) {
            return {
                backgroundColor: '#FFFAF3',
                color: COLORS.warning.color,
            }
        }
        return {
            backgroundColor: '#ECF5FF',
            color: COLORS.default.color,
        }
    }}
`

export const FlexColumnWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing(props.gap == undefined ? 2 : Number(props.gap))};
`

export const FlexRowWrapper = styled(Box)`
    display: flex;
    flex-direction: row;
    gap: ${(props) => props.theme.spacing(props.gap == undefined ? 2 : Number(props.gap))};
    align-items: center;
`

export const CompanyName = styled(FlexRowWrapper)`
    ${(props) => {
        return {
            ...(props.theme.typography.body1 as any),
        }
    }};
    width: 100%;
    justify-content: center;
    padding: ${(props) => props.theme.spacing(1)};
    text-transform: inherit;
    font-weight: 500;
    color: ${(props) => props.theme.palette.text.primary};
    background: ${(props) => props.theme.palette.bg.shades};
`

export const OverflowableTypography = styled(Typography)<TypographyProps & { rows?: number }>`
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => props.rows || 1};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const WorkDetailNumberFormat = styled(StyledNumberFormat).attrs({
    displayType: 'text',
    decimalScale: 2,
})<NumericFormatProps & { colorValue?: string | null }>`
    margin-left: ${(props) => props.theme.spacing(0.5)};
    color: ${(props) => props.colorValue ?? 'inherit'};
`

export const WorkDetailLabel = styled(OverflowableTypography).attrs({
    color: 'secondary',
    fontSize: 14,
    variant: 'body2',
})`
    gap: ${(props) => props.theme.spacing(1)};
`

export const StyledHeader = styled(FlexRowWrapper)`
    padding: ${(props) => props.theme.spacing(1.25)};
    padding-bottom: 0;
    justify-content: space-between;
    @media screen and (max-width: ${BREAKPOINTS.LAYOUT}px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 0;
        & > div:first-child div {
            display: none;
        }
        div:nth-of-type(1) {
            grid-column: 1/3;
            grid-row: 2/3;
        }
        div:nth-of-type(2) {
            grid-column: 1/2;
            grid-row: 1/2;
        }
        div:nth-of-type(3) {
            grid-column: 2/3;
            grid-row: 1/2;
        }
    }
`

export const StyledControlPanel = styled(FlexRowWrapper)`
    flex-wrap: wrap;
    width: 100%;
    @media screen and (max-width: ${BREAKPOINTS.LAYOUT}px) {
        .MuiTabs-root {
            width: 100%;
            ${TabsContainedStyle};
        }

        .MuiButtonBase-root {
            flex: 1 1 50%;
        }
    }
`

export const StyledWorkWithResourceControlPanel = styled(StyledControlPanel)`
    @media screen and (max-width: ${BREAKPOINTS.LAYOUT}px) {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 1;
        div:nth-of-type(3) {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 25px;
            gap: 0;
        }
        div:nth-of-type(3) > p {
            font-size: 10px;
            white-space: nowrap;
        }
        div:nth-of-type(3) div {
            grid-row: 1/2;
            grid-column: 1/4;
            flex: 1 0 0;
        }
        div:nth-of-type(3) > * input {
            width: 100%;
            padding: 8px !important;
        }
    }
`
