import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { FormControl, IconButton, MenuItem, Popover } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Buffer } from 'buffer';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
    useCreateReportMutation,
    useDownloadReportEisMutation,
    useGetEisMonthsQuery,
    useGetEisReportsQuery,
    useRepeatEisMutation,
    useSetStatusEisMutation,
} from '@/api/eis/eis.api';

import { monthsList } from '@/shared/constants/date';

import { toggleEisReports } from '@/store/slices/drawersSlice';
import { useAppDispatch } from '@/store/store';

import s from './EisReportsDrawer.module.scss';
import { formControlStyles } from './EisReportsDrawer.service';
import EisLineEl from './components/EisLineEl';
import SelectDaysEl from './components/SelectDaysEl';

// keys
// download_eis
export default function EisReportsDrawer() {
    const [year, setYear] = useState<string>(String(new Date().getFullYear()));
    const [selectedMonth, setSelectedMonth] = useState({
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
    });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { projectId } = useParams();

    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');

    const eisReports = useGetEisReportsQuery({
        id: Number(projectId),
        year: Number(year),
        month: selectedMonth.month + 1,
    });
    const getMonthsQuery = useGetEisMonthsQuery({
        id: Number(projectId),
    });

    const [downloadReportReq] = useDownloadReportEisMutation();
    const [setStatusEisReq] = useSetStatusEisMutation();
    const [repeatEisReq] = useRepeatEisMutation();
    const [createReportReq] = useCreateReportMutation();

    const error = (e?: any) => {
        enqueueSnackbar('Ошибка, для дополнительной информации откройте консоль', {
            variant: 'error',
        });
        console.error(e);
    };

    const success = (key: string) => {
        enqueueSnackbar(t(key), {
            variant: 'success',
        });
    };

    const handleChange = (event: SelectChangeEvent) => {
        setYear(event.target.value);
    };
    const handleDownload = (file: string, name: string) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(
            new Blob([Buffer.from(file, 'base64')], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
        );
        a.download = name.replace(/[.]/g, '');
        a.click();
    };

    const downloadRequest = (id: number, name: string) => {
        downloadReportReq({
            id: Number(projectId),
            reportId: id,
        })
            .unwrap()
            .then((res) => {
                handleDownload(res, name);
                success('download_eis');
            })
            .catch(error);
    };

    const handleSetStatus = (id: number, isActive: boolean) => {
        setStatusEisReq({
            id: Number(projectId),
            reportId: id,
            body: {
                status: isActive ? 'Актуальный' : 'Не актуальный',
            },
        })
            .unwrap()
            .then(() => {
                success('set_status_eis');
            })
            .catch(error);
    };

    const handleRepeatEis = (id: number) => {
        repeatEisReq({
            id: Number(projectId),
            reportId: id,
        })
            .unwrap()
            .then(() => {
                success('repeat_eis');
            })
            .catch(error);
    };

    const handleCreateReport = (start: string, end: string) => {
        setAnchorEl(null);
        createReportReq({
            id: Number(projectId),
            body: {
                dayEnd: Number(end),
                dayStart: Number(start),
                month: selectedMonth.month + 1,
                year: Number(year),
            },
        });
    };

    const months:
        | {
              months: {
                  exists: boolean;
                  month: number;
              }[];
          }
        | undefined = getMonthsQuery.data?.data.filter((v) => v.year === Number(year))[0];

    return (
        <>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <SelectDaysEl setDaysCb={handleCreateReport} />
            </Popover>

            <div className={s.header}>
                <div className={s.top_icon}>
                    <IconButton onClick={() => dispatch(toggleEisReports())}>
                        <KeyboardDoubleArrowRightIcon sx={{ fontSize: '2.2rem', color: '#0044b4' }} />
                    </IconButton>
                </div>
                <h4>Отчеты для ЕИС</h4>
                <div className={s.right_top_icon}>
                    <IconButton
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                    >
                        <CalendarTodayIcon />
                    </IconButton>
                </div>
            </div>
            <div className={s.main}>
                <div className={s.left}>
                    <FormControl
                        variant='standard'
                        sx={formControlStyles}
                    >
                        <Select
                            sx={{
                                fontSize: '13px',
                                color: '#0044b4',
                            }}
                            value={year}
                            onChange={handleChange}
                        >
                            {getMonthsQuery.data?.data.map((v) => (
                                <MenuItem
                                    key={v.year}
                                    value={v.year}
                                >
                                    {v.year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className={s.months_container}>
                        {months?.months.map((month) => (
                            <div
                                onClick={() =>
                                    (month.exists || new Date().getMonth() === month.month - 1) &&
                                    setSelectedMonth({ year: Number(year), month: month.month - 1 })
                                }
                                className={
                                    s.default_month_container +
                                    ` ${
                                        !month.exists && new Date().getMonth() !== month.month - 1 ? s.future_month : ''
                                    }` +
                                    ` ${
                                        selectedMonth.month === month.month - 1 && selectedMonth.year === Number(year)
                                            ? s.active_month
                                            : ''
                                    }`
                                }
                                key={month.month}
                            >
                                {monthsList[month.month - 1]}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={s.right}>
                    {eisReports.data && eisReports.data.data.length ? (
                        eisReports.data.data.map((report) => (
                            <EisLineEl
                                key={report.reportId}
                                report={report}
                                repeat={report.original}
                                repeatCb={handleRepeatEis}
                                setStatusCb={(id, isActive) => handleSetStatus(id, isActive)}
                                downloadCb={(id) => downloadRequest(id, report.name)}
                            />
                        ))
                    ) : (
                        <div className={s.placeholder}>
                            <p>Пока нет готовых отчетов</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
