import { TypographyProps } from '@mui/material'
import { FlexColumnWrapper } from '../../pages/NewExecutorView/components/components.styles'
import { OverflowableTypographyWithTooltip } from '../OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip'
import { WorkManagmentResourceBlock } from './WorkManagmentRecourceCell.style'

export function WorkManagmentRecourceCell(props: TypographyProps) {
    return (
        <FlexColumnWrapper gap={1}>
            <OverflowableTypographyWithTooltip maxRows={2} TypographyProps={{ variant: 'body2' }}>
                {props.children as string}
            </OverflowableTypographyWithTooltip>
            <WorkManagmentResourceBlock>Кабинет работ</WorkManagmentResourceBlock>
        </FlexColumnWrapper>
    )
}
