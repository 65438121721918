export type TTabData = {
    label: string
    value: TTabValues
    disabled: boolean
}

export type TTabValues = 'kc_6a' | 'to_closing'

export const tabsData: TTabData[] = [
    {
        label: 'к закрытию',
        value: 'to_closing',
        disabled: false,
    },
    {
        label: 'кс-6а',
        value: 'kc_6a',
        disabled: true,
    },
]
