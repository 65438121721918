import { Box, IconButton, ListItemIcon, MenuItem, Typography } from '@mui/material'
import { Add, Autorenew, Edit } from '@mui/icons-material'
import { SvgVerticalLinesTableTemplate } from '../../../shared/SVG/Svg'
import { templateBody, templateMsgBody } from '../../../api/templates/templates.def'
import { useAppDispatch, useTypedSelector } from '../../../store/store'
import { templateMenuItemS } from '../TableTemplate.service'
import { setTemplateId, setTemplateName } from '../../../store/slices/templatesSharedSlice'
import { ksgTemplatesSelector } from '../../../store/slices/ksgTemplatesSlice'
import { msgTemplatesSelector } from '../../../store/slices/msgTemplatesSlice'
import { projectsSelector } from '../../../store/slices/projectsSlice'
import { pagesNames } from '../../../index'
import { HtmlTooltip } from '../../../shared/components/HtmlTooltip'

export default function SharedTemplateMenu({
    onRefresh,
    onApplyKsg,
    onApplyMsg,
    onApplyKsgView,
    onApplyMsgView,
    onChange,
    onCreate,
    ksgList,
    msgList,
}: {
    onRefresh: () => void
    onApplyKsg?: (v: templateBody) => void
    onApplyMsg?: (v: templateMsgBody) => void
    onApplyKsgView?: (v: templateBody) => void
    onApplyMsgView?: (v: templateMsgBody) => void
    onChange: () => void
    onCreate: () => void
    ksgList?: templateBody[]
    msgList?: templateMsgBody[]
}) {
    const { templateCpgState } = useTypedSelector(ksgTemplatesSelector)
    const { templateMsgState } = useTypedSelector(msgTemplatesSelector)
    const { pageName } = useTypedSelector(projectsSelector)

    const dispatch = useAppDispatch()

    const getMenuBackgroundColor = (name: string) => {
        if (
            (pageName === pagesNames.ksg && templateCpgState.name === name ) ||
            ( pageName === pagesNames.msg && templateMsgState.name === name)
        ) {
            return '#f0f5fc'
        }
    }

    const list = msgList ? msgList : ksgList
    return (
        <Box
            sx={{
                width: '18rem',
            }}
        >
            <MenuItem onClick={onRefresh}>
                <ListItemIcon>
                    <Autorenew sx={{ fill: '#7890b2' }} />
                </ListItemIcon>
                <Typography color="#2b3648" variant="body1">
                    Сбросить
                </Typography>
            </MenuItem>

            {list?.map((v) => (
                <MenuItem
                    sx={{
                        ...templateMenuItemS,
                        backgroundColor: getMenuBackgroundColor(v.name) || undefined,
                    }}
                    key={v.id}
                >
                    <ListItemIcon
                        onClick={() => {
                            if (onApplyKsg) {
                                onApplyKsg(v as templateBody)
                            } else {
                                if (!onApplyMsg) return
                                onApplyMsg(v as templateMsgBody)
                            }
                        }}
                        sx={{ paddingLeft: '0.25rem' }}
                    >
                        <SvgVerticalLinesTableTemplate fill="#7890b2" size={16} />
                    </ListItemIcon>
                    <HtmlTooltip title={v.name.length > 13 ? v.name : ''}>
                        <Typography
                            color="#2b3648"
                            variant="body1"
                            noWrap
                            onClick={() => {
                                if (onApplyKsg) {
                                    onApplyKsg(v as templateBody)
                                }
                                if (onApplyMsg) {
                                    onApplyMsg(v as templateMsgBody)
                                }
                            }}
                        >
                            {v.name}
                        </Typography>
                    </HtmlTooltip>
                    <IconButton
                        onClick={() => {
                            if (onApplyKsgView) {
                                onApplyKsgView(v as templateBody)
                            } else {
                                if (!onApplyMsgView) return
                                onApplyMsgView(v as templateMsgBody)
                            }
                            onChange()
                            dispatch(setTemplateId(v.id))
                            dispatch(setTemplateName(v.name))
                        }}
                        aria-label="edit"
                    >
                        <Edit sx={{ fill: '#0044b4' }} />
                    </IconButton>
                </MenuItem>
            ))}

            <MenuItem onClick={onCreate}>
                <ListItemIcon>
                    <Add sx={{ fill: '#7890b2' }} />
                </ListItemIcon>
                <Typography color="#2b3648" variant="body1">
                    Добавить шаблон
                </Typography>
            </MenuItem>
        </Box>
    )
}
