import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    background-color: #f6f7fb;
    border-radius: 6px;
    text-align: center;
`;

export const ArrowContainer = styled.span`
    width: 36px;
    position: relative;
    height: 2.5rem;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: #dbeafe;
    }
`;

export const EmptyArrowContainer = styled.div`
    width: 36px;
`;

export const Month = styled.h4`
    font-size: 13px;
    color: #2b3648;
    letter-spacing: 0.46px;
    font-weight: 500;
    padding: 0.2rem 0;
    width: 4.5rem;
`;

export const PrevArrow = styled(KeyboardArrowLeftIcon)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 24;
    width: 24;
`;

export const NextArrow = styled(KeyboardArrowRightIcon)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 24;
    width: 24;
`;
