import { api } from '../api'
import { getNotificationsListI, increaseVolumeResI } from './notificationsCenter.def'

export const notificationsCenterApi = api.injectEndpoints({
    endpoints: (build) => ({
        notificationsList: build.query<
            getNotificationsListI,
            {
                projectId: number
                limit?: number
                offset?: number
            }
        >({
            query: ({ projectId, limit, offset }) => ({
                url: `/projects/${projectId}/alerts/ksg/list`,
                params: {
                    limit,
                    offset,
                },
                method: 'GET',
            }),
            providesTags: ['Notifications'],
        }),

        notificationsLook: build.mutation<unknown, { projectId?: string }>({
            query: ({ projectId }) => ({
                url: `/projects/${projectId}/alerts/ksg/look`,
                method: 'POST',
            }),
        }),

        notificationsIncreaseVolume: build.mutation<
            increaseVolumeResI,
            { projectId: number; body: { alertIds: number[] } }
        >({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/alerts/ksg/inc-volume`,
                body,
                method: 'POST',
            }),
            invalidatesTags: ['Notifications'],
        }),

        notificationsIncreasePeriod: build.mutation<
            increaseVolumeResI,
            { projectId: number; body: { alertIds: number[] } }
        >({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/alerts/ksg/inc-period`,
                body,
                method: 'POST',
            }),
            invalidatesTags: ['Notifications'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useNotificationsListQuery,
    useNotificationsLookMutation,
    useNotificationsIncreasePeriodMutation,
    useNotificationsIncreaseVolumeMutation,
} = notificationsCenterApi
