import { Typography } from '@mui/material';
import styled from 'styled-components';

import { ResetButton } from '@/components/ui/UIButton';

export const Container = styled.div<{ width?: number; padding?: string }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8;
    height: 100%;
    width: 100%;
    padding: ${(props) => props.padding ?? 0};
`;

export const Text = styled(Typography)`
    flex: 1;
    text-align: center;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
`;

export const Button = styled(ResetButton)`
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
