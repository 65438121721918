import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface initialStateI {
    triggerBasePlan: boolean
    triggerBasePlanMsg: boolean
    isBasePlan: boolean
}

const initialState: initialStateI = {
    triggerBasePlan: false,
    triggerBasePlanMsg: false,
    isBasePlan: false,
}

const slice = createSlice({
    name: 'basePlan',
    initialState,
    reducers: {
        triggerKsgByBasePlanChange(state) {
            state.triggerBasePlan = !state.triggerBasePlan
        },

        triggerMsgByBasePlanChange(state) {
            state.triggerBasePlanMsg = !state.triggerBasePlanMsg
        },
    },
})

export const { triggerKsgByBasePlanChange, triggerMsgByBasePlanChange } = slice.actions
export default slice.reducer

export const basePlanSelector = (state: RootState) => state.basePlan
