import { ICellRendererParams } from 'ag-grid-community'
import cn from 'classnames'
import { isAfter, previousSunday } from 'date-fns'
import { useParams, useSearchParams } from 'react-router-dom'
import { WorkMsg, dailyChart } from '../../../../api/msg/msg.def'
import { EditChartCell } from '../../../../components/EditChartCell/EditChartCell'
import {
    getFactTypographyProps,
    getRemainedVolumeTooltipProps,
    isLessThanVolumeTotal,
} from '../../../../components/EditChartCell/EditChartCell.service'
import {
    DOES_ROLE_HAS_ACCESS_TO_FEATURE,
    DOES_ROLE_HAS_ACCESS_TO_PROJECT_TABLE,
    PERMISSIONS,
} from '../../../../shared/rolePermissions'
import { isNumber } from '../../../../shared/utils'
import { TChangeViewType, cpgViewSelector } from '../../../../store/slices/cpgViewSlice'
import { TPriceType, priceSettingsSelector } from '../../../../store/slices/priceSettings'
import { profileSelector } from '../../../../store/slices/profileSlice'
import { getSettings } from '../../../../store/slices/settings/settingsViewAgGrid'
import { useTypedSelector } from '../../../../store/store'
import { theme } from '../../../../styles/theme'
import '../../../AgGrid/AgGrid.scss'
import { useIsCashViewWithAllSettings } from '../../AgGridMsg.model'
import { GantCell } from '../GantCell/GantCell'
import { SendEmailOnMSGFactDifferenceButton } from '../SendEmailOnMSGFactDifferenceButton'
import {
    calculatePercentage,
    getSaldoBetweenDailyChartAndVolumeTotalPerMonth,
    submitDailyChart,
} from './MonthRendererMsg.service'

interface IMonthRendererMSGProps {
    cellRendererParams: ICellRendererParams<WorkMsg>
    chartIndex: number
    isToday: boolean
}

export default function MonthRendererMsg({ cellRendererParams, chartIndex, isToday }: IMonthRendererMSGProps) {
    const { profile } = useTypedSelector(profileSelector)
    const { msgForecast, verificationPlan, msgDailyPercentage } = useTypedSelector(getSettings)
    const { priceSettings } = useTypedSelector(priceSettingsSelector)
    const { msgView } = useTypedSelector(cpgViewSelector)
    const [searchParams, setSearchParams] = useSearchParams()
    const { projectId } = useParams()
    const isGant = searchParams.get('gant')

    const isRoot = cellRendererParams.data?.level === 0
    const isVolumeFloatedToParent = cellRendererParams.data?.unit && cellRendererParams.data?.hasChildren
    const isChild = !cellRendererParams.data?.hasChildren && cellRendererParams.data?.level !== 0

    const isCashViewWithAllSettings = useIsCashViewWithAllSettings()

    const CASH_PRICE_KEYS = {
        plan: {
            er: 'costPlan',
            gk: 'costPlanGK',
            sub: 'costPlanSub',
        },
        fact: {
            er: 'costFact',
            gk: 'costFactGK',
            sub: 'costFactSub',
        },
        forecast: {
            er: 'costForecast',
            gk: 'costForecastGK',
            sub: 'costForecastSub',
        },
    } as { [key in dailyChart as string]: Record<TPriceType, keyof dailyChart> }

    const CHART_KEYS: Record<TChangeViewType, { [key in dailyChart as string]: keyof dailyChart }> = {
        blank: {
            plan: 'plan',
            fact: 'fact',
            forecast: 'forecast',
            verifiedFact: 'verifiedFact',
        },
        cash: {
            plan: CASH_PRICE_KEYS.plan[priceSettings.msg.plan as TPriceType],
            fact: CASH_PRICE_KEYS.fact[priceSettings.msg.fact as TPriceType],
            forecast: CASH_PRICE_KEYS.forecast[priceSettings.msg.forecast as TPriceType],
            verifiedFact: 'costVerifiedFact',
        },
        digger: {
            plan: 'hourPlan',
            fact: 'hourFact',
            forecast: 'hourForecast',
            verifiedFact: 'hourVerifiedFact',
        },
    }

    const cellDate = new Date(
        cellRendererParams?.data?.year as unknown as number,
        (cellRendererParams?.data?.month as unknown as number) - 1,
        cellRendererParams.colDef?.headerName as unknown as number
    )

    const isFactEditable =
        profile.role === 'admin' ||
        (PERMISSIONS.msg.smr.fact?.roles?.includes(profile?.role) && isAfter(cellDate, previousSunday(new Date())))

    const dailyChart = cellRendererParams.api.getValue('dailyCharts', cellRendererParams.node)[chartIndex]
    const chartKeys = CHART_KEYS[msgView]

    const plan = dailyChart[chartKeys.plan]
    const fact = dailyChart[chartKeys.fact]
    const forecast = dailyChart[chartKeys.forecast]
    const verifiedFact = dailyChart[chartKeys.verifiedFact]
    const percentage = calculatePercentage(plan, fact)

    const isVisiblePercentage = msgDailyPercentage && isRoot && msgView === 'cash'

    const ensureColumnVisible = (
        columnName = 'executorName',
        position: 'auto' | 'start' | 'middle' | 'end' = 'middle'
    ) => cellRendererParams.api.ensureColumnVisible(columnName, position)

    const shouldShowSendEmailButton = () => {
        if (!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'SEND_EMAIL_ON_MSG_FACT_DIFFERENCE')) return null
        if (!isNumber(fact) || !isNumber(verifiedFact)) return null
        if (fact === verifiedFact) return null

        const executorId = cellRendererParams?.data?.executorId ?? null
        const objTitle = cellRendererParams?.data?.objTitle ?? null
        const year = cellRendererParams?.data?.year!
        const month = cellRendererParams?.data?.month!
        const day = dailyChart?.day! as number

        return (
            <SendEmailOnMSGFactDifferenceButton
                executorId={executorId}
                objTitle={objTitle}
                workID={cellRendererParams.data?.id!}
                year={year}
                month={month}
                day={day}
                projectID={Number(projectId)}
                ensureColumnVisible={ensureColumnVisible}
            />
        )
    }

    return !isGant ? (
        isVolumeFloatedToParent || isChild || msgView !== 'blank' ? (
            <div
                className={cn('month_container', {
                    'month_container--large': isRoot && isCashViewWithAllSettings,
                })}
            >
                <EditChartCell
                    cellRendererParams={cellRendererParams}
                    chartValueKey={'plan'}
                    chartIndex={chartIndex}
                    isEditable={
                        msgView === 'blank' && PERMISSIONS.msg.smr.plan?.roles?.includes(profile?.role) && isChild
                    }
                    defaultValue={plan}
                    rules={{
                        validate: {
                            isLessThanVolumeTotal: (value) =>
                                isLessThanVolumeTotal(
                                    value,
                                    getSaldoBetweenDailyChartAndVolumeTotalPerMonth(value, cellRendererParams, 'plan'),
                                    'Опер. план'
                                ),
                        },
                    }}
                    onSubmitData={(args) => submitDailyChart({ ...args })}
                    TooltipProps={(value) =>
                        getRemainedVolumeTooltipProps(
                            getSaldoBetweenDailyChartAndVolumeTotalPerMonth(value, cellRendererParams, 'plan')
                        )
                    }
                    isActive={isToday}
                    TypographyProps={() => ({
                        color: theme.palette.primary.main,
                    })}
                />
                <EditChartCell
                    cellRendererParams={cellRendererParams}
                    chartValueKey={'fact'}
                    chartIndex={chartIndex}
                    isEditable={
                        msgView === 'blank' &&
                        PERMISSIONS.msg.smr.fact?.roles?.includes(profile?.role) &&
                        isFactEditable &&
                        isChild
                    }
                    defaultValue={fact}
                    onSubmitData={(args) => submitDailyChart({ ...args })}
                    TypographyProps={(value) => ({
                        ...getFactTypographyProps(value, cellRendererParams, chartIndex, 'dailyCharts'),
                        ...(!isFactEditable && {
                            sx: {
                                background: `${theme.palette.grey[300]} !important`,
                                ':hover': {
                                    boxShadow: 'none',
                                },
                            },
                        }),
                    })}
                    isActive={isToday}
                />
                {msgForecast && (
                    <EditChartCell
                        cellRendererParams={cellRendererParams}
                        chartValueKey={'forecast'}
                        chartIndex={chartIndex}
                        isEditable={
                            msgView === 'blank' &&
                            PERMISSIONS.msg.smr.forecast?.roles?.includes(profile?.role) &&
                            isChild
                        }
                        defaultValue={forecast}
                        onSubmitData={(args) => submitDailyChart({ ...args })}
                        isActive={isToday}
                    />
                )}
                {verificationPlan && (
                    <EditChartCell
                        cellRendererParams={cellRendererParams}
                        chartValueKey={'verifiedFact'}
                        chartIndex={chartIndex}
                        isEditable={
                            msgView === 'blank' &&
                            PERMISSIONS.msg.smr.verifiedFact.roles?.includes(profile?.role) &&
                            DOES_ROLE_HAS_ACCESS_TO_PROJECT_TABLE(projectId as string, profile, 'smr') &&
                            isChild
                        }
                        defaultValue={verifiedFact}
                        onSubmitData={(args) => submitDailyChart({ ...args })}
                        viewComponent={shouldShowSendEmailButton()}
                        isActive={isToday}
                    />
                )}
                {isVisiblePercentage && (
                    <EditChartCell
                        cellRendererParams={cellRendererParams}
                        chartValueKey={'percentage'}
                        chartIndex={chartIndex}
                        isEditable={false}
                        defaultValue={percentage}
                        isActive={isToday}
                    />
                )}
            </div>
        ) : (
            <div className="grid_center"></div>
        )
    ) : (
        <GantCell
            cellRendererParams={cellRendererParams}
            chartIndex={chartIndex}
            supressEditRelationships={!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_LINK_GANT_BRIGADES')}
        />
    )
}
