import { Tooltip } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useSendEmailOnMSGFactDifferenceMutation } from '../../../../api/msg/msg.api'
import { ISendEmailOnMSGFactDifferenceReq } from '../../../../api/msg/msg.def'
import PlaneSVG from '../../../../shared/SVG/PlaneSVG'
import { ISendEmailOnMSGFactDifferenceButtonProps } from './SendEmailOnMSGFactDifferenceButton.dev'
import { StyledContainer, StyledIconBtn } from './SendEmailOnMSGFactDifferenceButton.styles'

export const SendEmailOnMSGFactDifferenceButton: React.FC<ISendEmailOnMSGFactDifferenceButtonProps> = ({
    executorId,
    objTitle,
    workID,
    year,
    month,
    day,
    projectID,
    ensureColumnVisible,
}) => {
    const { enqueueSnackbar } = useSnackbar()
    const [sendEmail] = useSendEmailOnMSGFactDifferenceMutation()

    const canEmailSend = () => {
        if (!executorId) {
            enqueueSnackbar('Установите исполнителя', { variant: 'error' })
            ensureColumnVisible()
            return false
        }

        if (!objTitle) {
            enqueueSnackbar('Заполните титул', { variant: 'error' })
            ensureColumnVisible('objTitle')
            return false
        }

        return true
    }

    const handleEmailSend = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()

        if (!canEmailSend()) return

        const data: ISendEmailOnMSGFactDifferenceReq = {
            projectID,
            executorId: executorId!,
            workID,
            year,
            month,
            day,
        }

        sendEmail(data)
            .unwrap()
            .then(() => {
                enqueueSnackbar('Письмо успешно отправлено', { variant: 'success' })
            })
            .catch(() => {
                enqueueSnackbar('При отправке письма произошла ошибка', { variant: 'error' })
            })
    }

    return (
        <StyledContainer>
            <Tooltip title="Отправить прорабу письмо о несоответствии факта">
                <StyledIconBtn onClick={handleEmailSend}>
                    <PlaneSVG />
                </StyledIconBtn>
            </Tooltip>
        </StyledContainer>
    )
}
