import React, { Fragment } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import ColoredTitle from '../components/ColoredTitle'
import { CardSubtitle, StyledAvatar } from '../UserCard/styles'
import { UserRoles, UserRolesColors, UserRolesEnToRu } from '../user'
import { StyledCardHeader } from './styles'
import { UserCardHeaderProps } from './UserCardHeader.types'
import PersonSharpIcon from '@mui/icons-material/PersonSharp'
import { formatMaskPhone } from '../components/PhoneFieldForm/utils'

function UserCardHeader({ data }: UserCardHeaderProps) {
    const { email, firstName, middleName, avatarPreview, lastName, phone, role: roleEn } = data
    const formattedName = (
        <Fragment>
            {lastName}
            <br />
            {firstName} {middleName}
        </Fragment>
    )
    const role: UserRoles = UserRolesEnToRu[roleEn]

    const formattedPhone = () => {
        return phone ? formatMaskPhone(phone) : phone
    }

    return (
        <StyledCardHeader
            avatar={
                <Box>
                    <ColoredTitle body={role} color={UserRolesColors[role]} />
                    <StyledAvatar src={avatarPreview}>
                        <PersonSharpIcon sx={{ width: '80px', height: '80px' }} />
                    </StyledAvatar>
                </Box>
            }
            title={
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    {formattedName}
                </Typography>
            }
            subheader={
                <Stack spacing={0.5}>
                    <CardSubtitle>Контакты:</CardSubtitle>
                    <Typography variant="body2">{formattedPhone()}</Typography>
                    <Typography variant="body2">{email}</Typography>
                </Stack>
            }
        />
    )
}

export default UserCardHeader
