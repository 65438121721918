import { Box, Input } from '@mui/material';
import styled from 'styled-components';

export const ProgressContainer = styled(Box)<{ minHeight: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: ${(props) => props.minHeight}px;
`;

export const Wrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    padding-block: 20px;
`;

export const Description = styled(Box).attrs(() => ({
    component: 'p',
}))`
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #2b3648;
    margin-bottom: 20px;
`;

export const SyncInput = styled(Input)`
    background: '#fff';

    border: 1px solid rgba(43, 54, 72, 0.12);
    border-radius: 6px;
    padding: 8px 16px 8px 8px;
    min-height: 40px;

    & .MuiInput-input {
        margin-left: 0.6rem;
        padding: 0;
        font-size: 16px;

        &::placeholder {
            color: #9aa2b0;
        }
    }
`;
