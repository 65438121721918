import {
    CellValueChangedEvent,
    EditableCallbackParams,
    ICellRendererParams,
    IHeaderParams,
    NewValueParams,
} from 'ag-grid-community'
import { t } from 'i18next'
import { ProviderContext } from 'notistack'
import { Dispatch, SetStateAction } from 'react'
import { msgPeopleEquipI } from '../../api/msgEquip/msgEquip.def'
import { WorkManagmentRecourceCell } from '../../components/WorkManagmentRecourceCell/WorkManagmentRecourceCell'
import { submitExecutorName } from '../../shared/utils'
import { HeaderNameEl } from '../AgGrid/components/HeaderRenderer'
import RowMonthHeaderRenderer from '../AgGrid/components/RowMonthHeaderRenderer'
import { HeaderFilterPeqEl } from '../AgGridKsgPeople/components/HeaderFilterPeople'
import { COL_DEF_TEMPLATE } from '../WorkManagment/components/AgGridService/AgGridColumnDef.service'
import { NumberCellRenderer } from '../WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer'
import { getHeadersMonthsMsgEquip } from './AgGridMsgEquip.service'
import MonthRendererMsgPeqAg from './components/MonthRendererMsgPeqAg/MonthRendererMsgPeqAg'

interface IGetMSGEquipColDefsArgs {
    projectId: string
    setOpenModal: Dispatch<SetStateAction<boolean>>
    getExecutors: any
    enqueueSnackbar: ProviderContext['enqueueSnackbar']
    msgEquip: any
    msgForecast: boolean
}

export const getMSGEquipColDefs = ({
    projectId,
    setOpenModal,
    getExecutors,
    enqueueSnackbar,
    msgEquip,
    msgForecast,
}: IGetMSGEquipColDefsArgs) => [
    {
        field: 'name',
        colId: 'name',
        flex: 2,
        minWidth: 250,
        headerName: 'Наименование',
        spanHeaderHeight: true,
        headerComponent: (data: IHeaderParams<msgPeopleEquipI, any>) => (
            <HeaderFilterPeqEl
                onFilterClick={() => {
                    setOpenModal(true)
                }}
                width={data.column.getActualWidth()}
                name={data.displayName}
            />
        ),
        cellRenderer: (params: ICellRendererParams<msgPeopleEquipI, any, any>) => {
            if (params.data?.isResource) {
                return <WorkManagmentRecourceCell>{params.data?.name}</WorkManagmentRecourceCell>
            } else {
                return COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA().cellRenderer(params)
            }
        },
    },
    {
        field: 'category',
        colId: 'category',
        flex: 2,
        minWidth: 250,
        headerName: 'Категория',
        spanHeaderHeight: true,
    },
    {
        field: 'avgCount',
        colId: 'avgCount',
        width: 180,
        headerName: 'Количество в месяц',
        spanHeaderHeight: true,
        cellRenderer: NumberCellRenderer,
    },
    {
        field: 'avgCount90',
        colId: 'avgCount90',
        width: 180,
        headerName: 'Общая средняя потребность, 90 дней',
        spanHeaderHeight: true,
        headerComponent: (data: IHeaderParams<msgPeopleEquipI, any>) => {
            return <HeaderNameEl width={data.column.getActualWidth()} name={data.displayName} />
        },
        cellRenderer: NumberCellRenderer,
    },
    {
        field: 'executorName',
        colId: 'executorName',
        width: 225,
        headerName: 'Исполнитель',
        spanHeaderHeight: true,
        editable: (params: EditableCallbackParams) => !params.data?.isResource,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.AUTOCOMPLETE,
        cellEditorParams: {
            dataType: 'autocomplete',
            options: getExecutors.data?.data.map((executor: any) => ({
                label: executor.name,
                value: executor.employeeID,
                ...executor,
            })),
            AutocompleteProps: {
                disableClearable: false,
            },
        },
        ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('executorName'),
        onCellValueChanged: (params: NewValueParams) =>
            submitExecutorName({
                resourceType: 'technics',
                params: params as CellValueChangedEvent,
                projectID: projectId as string,
                enqueueSnackbar: enqueueSnackbar,
                translate: t,
            }),
    },
    {
        field: '',
        colId: 'contacts',
        headerName: 'Контакты Исполнителя',
        children: [
            {
                field: 'executorEmail',
                colId: 'executorEmail',
                width: 225,
                headerName: 'Email',
            },
            {
                field: 'executorPhone',
                colId: 'executorPhone',
                width: 150,
                headerName: 'Телефон',
            },
        ],
    },
    {
        headerName: 'Текущая потребность',
        colId: 'current',
        children: [
            {
                field: 'currentPlan',
                colId: 'currentPlan',
                width: 150,
                headerName: 'План',
                cellRenderer: NumberCellRenderer,
            },
            {
                field: 'currentFact',
                colId: 'currentFact',
                width: 150,
                headerName: 'Факт',
                cellRenderer: NumberCellRenderer,
            },
            {
                field: 'currentFact',
                colId: 'currentFact',
                width: 150,
                headerName: 'Прогноз',
                cellRenderer: NumberCellRenderer,
            },
            {
                field: 'currentPercent',
                colId: 'currentPercent',
                width: 150,
                headerName: 'Обеспеченность',
                cellRenderer: NumberCellRenderer,
                cellRendererParams: {
                    NumericFormatProps: {
                        suffix: ' %',
                    },
                },
            },
        ],
    },
    {
        headerName: 'Общее количество нарастающим итогом',
        colId: 'total',
        children: [
            {
                field: 'totalPlan',
                colId: 'totalPlan',
                width: 150,
                headerName: 'План',
                cellRenderer: NumberCellRenderer,
            },
            {
                field: 'totalFact',
                colId: 'totalFact',
                width: 150,
                headerName: 'Факт',
                cellRenderer: NumberCellRenderer,
            },
            {
                field: 'totalPercent',
                colId: 'totalPercent',
                width: 150,
                headerName: 'Соответствие',
                cellRenderer: NumberCellRenderer,
                cellRendererParams: {
                    NumericFormatProps: {
                        suffix: ' %',
                    },
                },
            },
        ],
    },
    {
        field: 'verifiedFactTotal',
        colId: 'verifiedFactTotal',
        width: 180,
        spanHeaderHeight: true,
        headerName: 'Вер. факт нарастающим итогом',
        headerClass: 'ag-verFact',
        cellRenderer: NumberCellRenderer,
    },
    {
        field: '',
        width: 120,
        headerName: 'План/Факт',
        spanHeaderHeight: true,
        cellRenderer: (params: ICellRendererParams<msgPeopleEquipI, any, any>) => (
            <RowMonthHeaderRenderer cellRendererParams={params} isMSG isResourceTable />
        ),
    },

    getHeadersMonthsMsgEquip(msgEquip.data?.data[0]?.dailyCharts, (cellRendererParams, chartIndex) => {
        if (!cellRendererParams.data) return <></>
        return (
            <MonthRendererMsgPeqAg
                cellRendererParams={cellRendererParams}
                chartIndex={chartIndex}
                resourceType={'mim'}
            />
        )
    }),
]
