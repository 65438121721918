import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'

export const HtmlTooltip = styled(({ className, children, ...props }: TooltipProps) => (
    <Tooltip children={children} {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgb(151, 162, 181,0.85)',
        color: '#fff',
        fontSize: '1rem',
        border: '1px solid #dadde9',
    },
}))
