import styled from 'styled-components'
import { Box, Typography } from '@mui/material'
import { LegendColors } from '../../global'

interface StyledBoxProps {
    bgColor: LegendColors
}

export const StyledBox = styled(Box)<StyledBoxProps>`
    display: flex;
    width: fit-content;
    margin-bottom: ${(props) => props.theme.spacing(1)};
    padding: 5px 8px 3px;
    background-color: ${(props) => props.theme.palette.legends[props.bgColor]};
    border-radius: 4px;
`

export const StyledTypography = styled(Typography)`
    font-weight: 700;
    color: ${(props) => props.theme.palette.text.light};
`
