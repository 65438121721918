import styled from 'styled-components'

export const Container = styled.div``

export const Loader = styled.span`
    & span {
        opacity: 0;
        animation: ellipsis-dot 1s infinite;
    }

    & span:nth-child(1) {
        animation-delay: 0s;
    }
    & span:nth-child(2) {
        animation-delay: 0.1s;
    }
    & span:nth-child(3) {
        animation-delay: 0.2s;
    }

    @keyframes ellipsis-dot {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`
