import { PropsWithChildren } from 'react';

import { Notificator } from '../Notificator/Notificator';
import { Container, Text } from './NotificatorContainer.styles';

interface IProps extends PropsWithChildren {
    isVisible: boolean;
    title: NonNullable<React.ReactNode>;
    notificator?: React.ReactNode;
}

export const NotificatorContainer: React.FC<IProps> = ({ children, notificator, isVisible, title }) => {
    const renderNotificator = () => {
        if (!isVisible) return null;

        return notificator ?? <Notificator title={title} />;
    };

    return (
        <Container>
            <Text>{children}</Text>
            {renderNotificator()}
        </Container>
    );
};
