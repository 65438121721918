import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Select } from '@mui/material';
import styled from 'styled-components';

export const TaskManualModeIcon = () => {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M12.6667 13.2H3.33333V6.05H12.6667M12.6667 2.8H12V1.5H10.6667V2.8H5.33333V1.5H4V2.8H3.33333C2.97971 2.8 2.64057 2.93696 2.39052 3.18076C2.14048 3.42456 2 3.75522 2 4.1V13.2C2 13.5448 2.14048 13.8754 2.39052 14.1192C2.64057 14.363 2.97971 14.5 3.33333 14.5H12.6667C13.0203 14.5 13.3594 14.363 13.6095 14.1192C13.8595 13.8754 14 13.5448 14 13.2V4.1C14 3.75522 13.8595 3.42456 13.6095 3.18076C13.3594 2.93696 13.0203 2.8 12.6667 2.8Z'
                fill='#668FD2'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10.2573 7.8494C10.3584 7.95049 10.3584 8.1138 10.2573 8.2149L9.78297 8.68928L8.81088 7.71719L9.28526 7.24281C9.38636 7.14172 9.54967 7.14172 9.65077 7.24281L10.2573 7.8494ZM5.6665 11.8337V10.8616L8.53351 7.99456L9.5056 8.96665L6.63859 11.8337H5.6665Z'
                fill='#668FD2'
            />
        </svg>
    );
};

export const TaskAutoModeIcon = () => {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10 8.66699H11V10.547L12.6267 11.487L12.1267 12.3537L10 11.127V8.66699ZM12.6667 5.33366H3.33333V12.667H6.44667C6.16 12.0603 6 11.3803 6 10.667C6 9.42932 6.49167 8.24233 7.36684 7.36716C8.242 6.49199 9.42899 6.00033 10.6667 6.00033C11.38 6.00033 12.06 6.16033 12.6667 6.44699V5.33366ZM3.33333 14.0003C2.97971 14.0003 2.64057 13.8598 2.39052 13.6098C2.14048 13.3598 2 13.0206 2 12.667V3.33366C2 2.59366 2.59333 2.00033 3.33333 2.00033H4V0.666992H5.33333V2.00033H10.6667V0.666992H12V2.00033H12.6667C13.0203 2.00033 13.3594 2.1408 13.6095 2.39085C13.8595 2.6409 14 2.98004 14 3.33366V7.40033C14.8267 8.24033 15.3333 9.39366 15.3333 10.667C15.3333 11.9047 14.8417 13.0917 13.9665 13.9668C13.0913 14.842 11.9043 15.3337 10.6667 15.3337C9.39333 15.3337 8.24 14.827 7.4 14.0003H3.33333ZM10.6667 7.43366C9.80913 7.43366 8.98672 7.77431 8.38035 8.38068C7.77399 8.98705 7.43333 9.80946 7.43333 10.667C7.43333 12.4537 8.88 13.9003 10.6667 13.9003C11.0913 13.9003 11.5117 13.8167 11.904 13.6542C12.2963 13.4917 12.6527 13.2535 12.953 12.9533C13.2532 12.6531 13.4914 12.2966 13.6539 11.9043C13.8164 11.512 13.9 11.0916 13.9 10.667C13.9 8.88033 12.4533 7.43366 10.6667 7.43366Z'
                fill='#14B8A6'
            />
        </svg>
    );
};

export const TaskModeSelect = styled(Select)`
    background-color: ${({ theme }) => `${theme.palette.bg.white}`};
    height: 24px;
    .MuiInputBase-root {
        background-color: ${({ theme }) => `${theme.palette.bg.white}`};
        height: 16px;
    }
    .MuiSelect-select {
        display: flex;
        align-items: center;
        padding: 4px;
        padding-right: 16px !important;
    }
    .MuiPaper-root {
        background-color: ${({ theme }) => `${theme.palette.bg.white}`};
    }
`;

export const IconArrowUp = styled(KeyboardArrowUp)`
    font-size: 12px;
    .MuiSvgIcon-root {
        right: 4px;
    }
`;
export const IconArrowDown = styled(KeyboardArrowDown)`
    font-size: 12px;
    right: 4px;
`;
