import {
    RowDataUpdatedEvent,
    ColDef,
    ColGroupDef,
    ValueGetterParams,
    ValueGetterFunc,
    ICellRendererParams,
    AgGridEvent,
} from 'ag-grid-community'
import { AgGridReactProps } from 'ag-grid-react'
import { ITotalRowModel, ITotalRow } from './AgGrid.def'

function recalculateTotalRow(event: AgGridEvent, model: ITotalRowModel[], totalRow: ITotalRow<any>) {
    const _totalRow = event?.api?.getPinnedBottomRow(0)?.data || totalRow
    model?.map((column) => {
        _totalRow[column.field] = 0
        event.api.forEachNodeAfterFilter((node) => {
            _totalRow[column.field] += event.api.getValue(column.field as string, node) || 0
        })
    })
    event.api.setPinnedBottomRowData([totalRow])
}

function getColDefForTotalRow(
    event: RowDataUpdatedEvent,
    column: ColDef | ColGroupDef,
    model: ITotalRowModel[],
    colKeyForTotalWord: string
) {
    return {
        ...column,
        ...((column as ColDef).valueGetter && {
            valueGetter: (params: ValueGetterParams) => {
                if (params?.data?.id === 'totalRow' && ((column as ColDef)?.field as string)) {
                    return event?.api?.getPinnedBottomRow(0)?.data[(column as ColDef).field as string]
                } else {
                    if ((column as ColDef)?.valueGetter !== undefined) {
                        if (typeof (column as ColDef)?.valueGetter == 'function') {
                            return (
                                //@ts-ignore
                                (column as ColDef)?.valueGetter(params) as ValueGetterFunc<any>
                            )
                        } else {
                            return (column as ColDef)?.valueGetter
                        }
                    }
                }
            },
        }),
        editable: (params: ValueGetterParams) => {
            if (params?.data?.id === 'totalRow') {
                return false
            } else {
                if (typeof (column as ColDef)?.editable == 'function') {
                    return ((column as ColDef)?.editable as Function) && ((column as ColDef)?.editable as Function)(params)
                } else {
                    return (column as ColDef)?.editable
                }
            }
        },
        ...((column as ColDef).cellRenderer && {
            cellRenderer: (params: ICellRendererParams) => {
                const visibleColumnFields = [...model?.map((column) => column?.field), colKeyForTotalWord]
                if (params?.data?.id === 'totalRow') {
                    if (visibleColumnFields?.includes((column as ColDef)?.field as string)) {
                        return (column as ColDef)?.cellRenderer(params)
                    } else {
                        return undefined
                    }
                } else {
                    return (column as ColDef)?.cellRenderer(params)
                }
            },
        }),
    } as ColDef[] | ColGroupDef[]
}

function updateColDef(event: AgGridEvent, model: ITotalRowModel[], colKeyForTotalWord: string) {
    return (
        event.api.getColumnDefs()?.map((column, index, array) => ({
            ...getColDefForTotalRow(event, column, model, colKeyForTotalWord),
            children: (column as ColGroupDef).children?.map((childColumn) => ({
                ...getColDefForTotalRow(event, childColumn, model, colKeyForTotalWord),
            })),
        })) || []
    )
}

export function withRecalculatedTotalRow(
    totalRow: ITotalRow<any>,
    model: ITotalRowModel[],
    colKeyForTotalWord: string,
    staticTotal?: 'staticTotal'
) {
    return {
        getRowClass: (params) => (params?.data?.id === 'totalRow' ? 'ag-row-total' : ''),
        ...(!staticTotal
            ? model && {
                  onRowDataUpdated: (event) => {
                      const hasTotalRowAlready = event.api.getPinnedBottomRow(0)

                      //1. Calculating first render of total row value
                      !hasTotalRowAlready &&
                          model.map((column) => {
                              totalRow[column.field] = 0
                              event.api.forEachNode((row) => {
                                  totalRow[column.field] += row.data[column.field]
                              })
                          })

                      //2. Adding or removing total row depends on rows qty
                      event.api.getDisplayedRowCount() >= 1 &&
                          !hasTotalRowAlready &&
                          event.api.setPinnedBottomRowData([totalRow])

                      hasTotalRowAlready &&
                          event.api.getDisplayedRowCount() === 0 &&
                          event.api.setPinnedBottomRowData([])

                      //3. Updating colDef for correct total row behaviour
                      const updatedColDefs = updateColDef(event, model, colKeyForTotalWord)
                      event.api.setColumnDefs(updatedColDefs as unknown as ColDef[] | ColGroupDef[])
                  },
                  onCellEditingStopped: (event) => {
                      //4. Updating total row data if cell updated
                      recalculateTotalRow(event, model, totalRow)
                  },
                  onAsyncTransactionsFlushed: (event) => {
                      //5. Updating total row data if row deleted or added
                      recalculateTotalRow(event, model, totalRow)
                  },
                  onFilterChanged(event) {
                      recalculateTotalRow(event, model, totalRow)
                  },
              }
            : {
                  onGridReady: (event) => {
                      const updatedColDefs = updateColDef(event, model, colKeyForTotalWord)
                      event.api.setColumnDefs(updatedColDefs as unknown as ColDef[] | ColGroupDef[])
                      event.api.setPinnedBottomRowData([totalRow])
                  },
              }),
    } as AgGridReactProps
}
