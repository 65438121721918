export const DEFAULT_VALIDATION = {
    text: {
        maxLength: {
            value: 500,
            message: 'Не более 500 символов',
        },
        required: 'Обязательное поле',
    },
    autocomplete: {
    },
}
