import { Typography } from '@mui/material'
import { ColDef, ICellEditorParams, ICellRendererParams } from 'ag-grid-community'
import { Link } from 'react-router-dom'
import { Nullable } from '../../../shared/def'
import { COL_DEF_TEMPLATE } from '../../WorkManagment/components/AgGridService/AgGridColumnDef.service'
import { NumberCellRenderer } from '../../WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer'
import { COLORS } from '../../WorkManagment/components/PercentStatusValue/PercentStatusValue.style'
import { IDailyChart, IWorkFactData } from '../api/api.types'
import { getColorByVolumeDoneDeviation } from './WorksSubPage.service'
import { WorkNameCellRenderer } from './components/WorkNameCellRenderer'

export interface ISNZWorksTableRowData extends Omit<IWorkFactData, 'dailyChart'>, IDailyChart {}

export type IWorksTableFields = keyof ISNZWorksTableRowData

export type TContext = {
    projectID: string
    onWorkSelect: (work: Nullable<IWorkFactData>) => void
}

export interface IWorksTableColDef extends ColDef {
    field?: IWorksTableFields | 'dailyChart.fact' | 'dailyChart.forecast' | 'dailyChart.plan' | undefined
    cellRenderer?: (params: ICellRendererParams<IWorkFactData, number | string, TContext>) => React.ReactNode
    cellEditor?: ((params: ICellEditorParams<IWorkFactData, number | string, TContext>) => React.ReactNode) | string
    children?: ColDef<IWorkFactData>[]
}

export const SNZWorksTableColDef: IWorksTableColDef[] = [
    {
        headerName: 'Титул',
        field: 'objTitle',
        headerClass: 'ag-cell-left',
        cellClass: 'ag-cell-left',
        flex: 2,
        spanHeaderHeight: true,
        autoHeight: true,
        maxWidth: 200,
    },
    {
        headerName: 'Наименование работ',
        field: 'workName',
        headerClass: 'ag-cell-left',
        cellClass: 'work-name ag-cell-left',
        initialSort: 'asc',
        spanHeaderHeight: true,
        flex: 2,
        autoHeight: true,
        cellRenderer: WorkNameCellRenderer,
    },
    {
        headerName: 'Шифр РД',
        field: 'rdCode',
        flex: 2,
        spanHeaderHeight: true,
        autoHeight: true,
        maxWidth: 200,
    },
    {
        headerName: 'Ед. изм.',
        field: 'unit',
        width: 100,
        spanHeaderHeight: true,
    },
    {
        headerName: 'План',
        children: [
            {
                headerName: 'План на сегодня',
                field: 'dailyChart.plan',
                width: 150,
                cellRenderer: NumberCellRenderer,
            },
            {
                headerName: 'План на месяц',
                field: 'planMonth',
                width: 150,
                cellRenderer: NumberCellRenderer,
            },
        ],
    },
    {
        headerName: 'Прогноз на сегодня',
        spanHeaderHeight: true,
        field: 'dailyChart.forecast',
        width: 120,
        cellRenderer: NumberCellRenderer,
    },
    {
        headerName: 'Показатели с нарастающим итогом',
        children: [
            {
                headerName: 'План',
                field: 'volumeDonePlan',
                width: 120,
                cellRenderer: NumberCellRenderer,
            },
            {
                headerName: 'Факт',
                field: 'volumeDoneFact',
                width: 120,
                cellRenderer: NumberCellRenderer,
            },
            {
                headerName: 'Отклонение',
                field: 'volumeDoneDeviation',
                width: 120,
                cellRenderer: NumberCellRenderer,
                cellRendererParams: (params: ICellRendererParams) => ({
                    TypographyProps: {
                        color: getColorByVolumeDoneDeviation(params.value),
                    },
                }),
                valueFormatter: (params) => Math.abs(params.value).toString(),
            },
        ],
    },
    {
        headerName: 'Факт',
        field: 'dailyChart.fact',
        width: 110,
        editable: true,
        spanHeaderHeight: true,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('dailyChart.fact'),
        cellRenderer: (params) => {
            return params.data?.existResources ? (
                <Link
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    }}
                    to={
                        '/executor/resources/' +
                        params.context.projectID +
                        '/' +
                        params.data.workID +
                        '?resourceType=staff&listType=waitList'
                    }
                    reloadDocument={false}
                    state={{
                        relative: 'path',
                    }}
                >
                    <Typography
                        variant="button"
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        sx={{
                            height: '100%',
                            width: '100%',
                            margin: 'auto',
                            border: 'none',
                            background: COLORS.default.backgroundColor,
                            color: COLORS.default.color,
                            borderRadius: 'none',
                            ':hover': {
                                background: COLORS.default.color,
                                color: 'white',
                            },
                        }}
                    >
                        Ресурсы
                    </Typography>
                </Link>
            ) : params.data?.dailyChart?.fact == null ? (
                <Typography variant="tooltip" color={'primary'} sx={{ cursor: 'pointer' }}>
                    Введите факт
                </Typography>
            ) : (
                <NumberCellRenderer {...params} />
            )
        },
    },
]

export const HIDDEN_MOBILE_COLUMNS: Required<IWorksTableColDef['field'][]> = [
    'rdCode',
    'unit',
    'dailyChart.plan',
    'dailyChart.forecast',
    'planMonth',
    'volumeDonePlan',
    'volumeDoneFact',
    'volumeDoneDeviation',
    'objTitle',
]
