export const LIMIT = 500;

export const ROW_CLASS_BY_LEVEL = {
    0: 'level_zero',
    1: 'level_one',
    2: 'level_two',
    3: 'level_three',
    4: 'level_four',
    5: 'level_five',
    6: 'level_six',
    7: 'level_seven',
};
