import { api } from '../api'
import {
    autoSendSnzReq,
    getSnzListReq,
    getSnzListRes,
    getStatusAutoSendingResI,
    sendSnzToExecutorReq,
    sendSnzToExecutorRes,
} from './snz.def'

export const snzApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSnzList: build.query<getSnzListRes, getSnzListReq>({
            query: ({ id, contractor }) => ({
                url: `/projects/${id}/sending/snz-list`,
                params: { contractor },
                method: 'GET',
            }),
        }),

        sendSnzToExecutors: build.mutation<sendSnzToExecutorRes, sendSnzToExecutorReq>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/sending/snz-send`,
                body,
                method: 'POST',
            }),
        }),

        autoSendSnzToExecutors: build.mutation<any, autoSendSnzReq>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/sending/auto-send`,
                body,
                method: 'POST',
            }),
            invalidatesTags: ['SNZ'],
        }),

        getStatusAutoSending: build.query<getStatusAutoSendingResI, { projectId: number }>({
            query: ({ projectId }) => ({
                url: `/projects/${projectId}/sending/status`,
                method: 'GET',
            }),
            providesTags: ['SNZ'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetSnzListQuery,
    useSendSnzToExecutorsMutation,
    useAutoSendSnzToExecutorsMutation,
    useGetStatusAutoSendingQuery,
} = snzApi
