import { ICellRendererParams, IHeaderParams } from 'ag-grid-community';

import { Work } from '@/api/ksg/ksg.def';

import { ITotalRow } from '@/shared/components/AgGrid/AgGrid.def';

import { EstimateAPI } from '../../../../../api/EstimateAPI';
import { COL_DEF_TEMPLATE } from '../../../../../components/AgGridService/AgGridColumnDef.service';
import { NumberCellRenderer } from '../../../../../components/CellRenderers/NumberCellRenderer/NumberCellRenderer';
import { ColumnFilter } from '../../../../../components/ColumnFilters/ColumnFilter';
import { ESTIMATE_RESOURCE_TYPE_OPTIONS } from '../EstimateResourcesPage.service';
import { IEstimateResource } from '../EstimateResourcesPage.types';
import { IEstimateResourceColDef } from './EstimateResourceTable.types';

export const estimateResourceColDef: IEstimateResourceColDef[] = [
    {
        ...COL_DEF_TEMPLATE.ADD_NEW_ROW_TABLE_COL((params) => addNewEstimateRow(getNewEstimateResourceRow(), params)),
        spanHeaderHeight: true,
        editable: false,
    },
    {
        headerName: 'Наименование',
        field: 'name',
        editable: true,
        spanHeaderHeight: true,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.STRING,
        headerClass: 'ag-cell-left',
        cellClass: 'ag-cell-left ag-cell-editable',
    },
    {
        headerName: 'Тип',
        field: 'type',
        maxWidth: 145,
        editable: true,
        spanHeaderHeight: true,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.AUTOCOMPLETE,
        cellEditorParams: {
            dataType: 'autocomplete',
            options: ESTIMATE_RESOURCE_TYPE_OPTIONS,
        },
        headerClass: 'ag-cell-left',
        cellClass: 'ag-cell-left ag-cell-editable',
        onCellValueChanged: (params) => {
            const type = params.newValue?.label as IEstimateResource['type'];
            switch (type) {
                case 'Трудозатраты':
                case 'ТЗМ':
                    return params.node!.setDataValue('unit', 'ч.ч');
                case 'МиМ':
                    return params.node!.setDataValue('unit', 'м.ч');
                case 'Материалы':
                    return params.node!.setDataValue('unit', '');
            }
        },
        filter: ColumnFilter,
        filterParams: {
            options: ['ТЗМ', 'МиМ', 'Трудозатраты', 'Материалы'],
        },
    },
    {
        headerName: 'Ед. изм.',
        field: 'unit',
        maxWidth: 100,
        editable: (params) => params.data?.type?.label === 'Материалы',
        spanHeaderHeight: true,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.STRING,
    },
    {
        headerName: 'Кол-во',
        field: 'quantity',
        maxWidth: 120,
        editable: true,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('quantity'),
        spanHeaderHeight: true,
    },
    {
        headerName: 'Базовая стоимость',
        children: [
            {
                headerName: 'За ед., р.',
                field: 'costBase',
                maxWidth: 120,
                editable: true,
                ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('costBase'),
            },
            {
                headerName: 'Всего, р.',
                field: 'costBaseTotal',
                maxWidth: 120,
                cellRenderer: NumberCellRenderer,
                valueGetter: (params) => {
                    const costBase = params.getValue('costBase');
                    const quantity = params.getValue('quantity');
                    const targetValue = costBase * quantity;
                    return targetValue;
                },
            },
            {
                headerName: 'Всего на весь объем, р.',
                field: 'costBaseTotalVolume',
                maxWidth: 120,
                cellRenderer: NumberCellRenderer,
                valueGetter: (params) => {
                    const work: Work = JSON.parse(localStorage.getItem('work')!) || {};
                    const totalVolume = work?.volumeTotal || 0;
                    const costBaseTotal = params.getValue('costBaseTotal');
                    return totalVolume * costBaseTotal;
                },
            },
        ],
    },
    {
        headerName: 'Текущая стоимость',
        children: [
            {
                headerName: 'За ед., р.',
                field: 'costCurr',
                maxWidth: 120,
                editable: true,
                ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('costCurr'),
            },
            {
                headerName: 'Всего, р.',
                field: 'costCurrTotal',
                maxWidth: 120,
                cellRenderer: NumberCellRenderer,
                valueGetter: (params) => {
                    const costCurr = params.getValue('costCurr');
                    const quantity = params.getValue('quantity');
                    const targetValue = costCurr * quantity;
                    return targetValue;
                },
            },
            {
                headerName: 'Всего на весь объем, р.',
                field: 'costCurrTotalVolume',
                maxWidth: 120,
                cellRenderer: NumberCellRenderer,
                valueGetter: (params) => {
                    const work: Work = JSON.parse(localStorage.getItem('work')!) || {};
                    const totalVolume = work?.volumeTotal || 0;
                    const costCurrTotal = params.getValue('costCurrTotal');
                    return totalVolume * costCurrTotal;
                },
            },
        ],
    },
    {
        ...COL_DEF_TEMPLATE.DELETE_ROW_TABLE_COL((params) => removeEstimateRow(params)),
    },
];

export const newEstimateResourceRow: IEstimateResource = {
    id: 0,
    name: 'Новая строка',
    type: 'Трудозатраты',
    unit: 'ч.ч.',
    quantity: 0,
    costBase: 0,
    costBaseTotal: 0,
    costCurr: 0,
    costCurrTotal: 0,
};

function getNewEstimateResourceRow() {
    return {
        ...newEstimateResourceRow,
        id: Math.random(),
    };
}

export function addNewEstimateRow(templateRow: any, params: IHeaderParams) {
    EstimateAPI.addNewItem({
        estimateType: params.context.estimateType,
        projectID: params.context.projectID,
        workID: params.context.workID,
        body: templateRow,
    })
        .then((data) => {
            params.api.applyTransactionAsync({ add: [data] });
        })
        .catch((e) => {
            return;
        });
}

export function removeEstimateRow(params: ICellRendererParams) {
    EstimateAPI.deleteItem({
        estimateType: params?.context?.estimateType,
        projectID: params?.context?.projectID,
        workID: params?.context?.workID,
        estimateItemID: params?.data?.id,
    }).then(() => {
        params.api.applyTransactionAsync({ remove: [params?.data] });
    });
}

export const TOTAL_ESTIMATE_RESOURCE_ROW: ITotalRow<IEstimateResource> = {
    id: 'totalRow',
    name: 'ИТОГО',
    type: '',
    unit: '',
    quantity: '',
    costBase: 0,
    costBaseTotal: 0,
    costCurr: 0,
    costCurrTotal: 0,
};
