import styled from 'styled-components'
import { StyledBtn } from '../../WorkManagment/components/components.styles'
import { ButtonProps } from '@mui/material'

export const StyledBthWithBoolState = styled(StyledBtn).attrs({
    variant: 'outlined',
    fullWidth: true
})<ButtonProps & {on: boolean}>`
    background: ${({on}) => on ? 'rgba(25, 118, 210, 0.08)' : 'white'};
    border-color: 'rgba(140, 187, 232, 1)';
    color: ${({on, theme}) => on ? theme.palette.primary.main : theme.palette.text.dark };
`
