import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { FaAngleDown } from '@react-icons/all-files/fa/FaAngleDown';
import { FaAngleUp } from '@react-icons/all-files/fa/FaAngleUp';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';

import { filtersSelector, handleChangeFilters, levels } from '@/store/slices/filtersSlice';
import { setPage } from '@/store/slices/paginationSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import s from './CheckboxPopover.module.scss';

export const customPropertiesByLevelFilters = {
    1: { title: 'Уровень 1', color: '#0044B4' },
    2: { title: 'Уровень 2', color: '#6D9ADC' },
    3: { title: 'Уровень 3', color: '#6fccbc' },
    4: { title: 'Уровень 4', color: '#fbbf24' },
    5: { title: 'Уровень 5', color: '#9333ea' },
    6: { title: 'Уровень 6', color: '#4ade80' },
    7: { title: 'Уровень 7', color: '#db2777' },
};

export default function CheckboxesPart({
    arr,
    level,
    color,
    closeAllCondition,
}: {
    arr: string[] | undefined;
    level: levels;
    color: string;
    closeAllCondition?: boolean;
}) {
    const [open, setOpen] = useState(false);

    const { filters, queryTerm } = useTypedSelector(filtersSelector);
    const dispatch = useAppDispatch();

    const handleChangeParent = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(handleChangeFilters({ checked: event.target.checked, level, arr }));
        dispatch(setPage(1));
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>, term: string) => {
        dispatch(handleChangeFilters({ checked: e.target.checked, level, key: term }));
        dispatch(setPage(1));
    };

    const isCheckboxChecked = () => {
        if (!arr?.length) return false;
        return arr?.every((r) => filters[queryTerm][level]?.includes(r!));
    };

    const isCheckboxIndeterminate = () => {
        const some = arr?.some((r) => filters[queryTerm][level]?.includes(r!));
        const all = arr?.every((r) => filters[queryTerm][level]?.includes(r!));
        return some && !all;
    };

    useEffect(() => {
        closeAllCondition && setOpen(false);
    }, [closeAllCondition]);

    return (
        <div className={s.container_item}>
            <div className={s.label_open}>
                <FormControlLabel
                    label={customPropertiesByLevelFilters[level].title}
                    sx={{
                        '.MuiTypography-root': {
                            color: color,
                            inlineSize: 250,
                            overflowWrap: ' break-word',
                        },

                        '.MuiSvgIcon-root': {
                            fill: color,
                        },
                    }}
                    control={
                        <Checkbox
                            checked={isCheckboxChecked()}
                            indeterminate={isCheckboxIndeterminate()}
                            onChange={handleChangeParent}
                        />
                    }
                />
                {open || !arr?.length ? (
                    <FaAngleDown
                        style={{
                            cursor: 'pointer',
                        }}
                        color={color}
                        onClick={() => {
                            if (!arr?.length) setOpen(false);
                            setOpen((p) => !p);
                        }}
                    />
                ) : (
                    <FaAngleUp
                        style={{
                            cursor: 'pointer',
                        }}
                        color={color}
                        onClick={() => {
                            if (!arr?.length) setOpen(false);
                            setOpen((p) => !p);
                        }}
                    />
                )}
            </div>

            {open && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        ml: 3,
                    }}
                >
                    {arr?.map((v) => {
                        return (
                            <Fragment key={v}>
                                <FormControlLabel
                                    label={v}
                                    sx={{
                                        '.MuiSvgIcon-root': {
                                            fill: color,
                                        },
                                        '.MuiTypography-root': {
                                            inlineSize: 270,
                                            overflowWrap: ' break-word',
                                        },
                                    }}
                                    control={
                                        <Checkbox
                                            checked={filters[queryTerm][level]?.includes(v)}
                                            onChange={(e) => handleChange(e, v)}
                                        />
                                    }
                                />
                            </Fragment>
                        );
                    })}
                </Box>
            )}
        </div>
    );
}
