import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export type TVolumeType = 'base' | 'fact' | 'plan' | 'forecast'
export type TPriceType = 'er' | 'gk' | 'sub'

export type TPriceSettings = {
    [key in TVolumeType]?: TPriceType
}

interface IInitialState {
    msg: TPriceSettings
    ksg: TPriceSettings
}

const initialState: IInitialState = {
    msg: {
        forecast: 'er',
        fact: 'er',
        plan: 'er',
    },
    ksg: {
        base: 'er',
        fact: 'er',
        plan: 'er',
    },
}

const slice = createSlice({
    name: 'priceSettings',
    initialState,
    reducers: {
        handleChangePriceSettings(
            state,
            action: PayloadAction<Partial<IInitialState> & { tableType: keyof IInitialState }>
        ) {
            state[action.payload.tableType] = {
                ...state[action.payload.tableType],
                ...action?.payload[action?.payload?.tableType],
            }
        },
    },
})

export const { handleChangePriceSettings } = slice.actions
export default slice.reducer

export const priceSettingsSelector = (state: RootState) => state
