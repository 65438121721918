import { LoadingButton } from '@mui/lab';
import { Button, DialogProps, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Work } from '@/api/ksg/ksg.def';

import { req } from '@/pages/WorkManagment/api/api';

import { onEditAgKsg } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { deleteZeroValueFactDrawerSelector, toggleDeleteZeroValueFact } from '@/store/slices/drawersSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { Container, DialogWindow, Text, Title } from './DeleteZeroValueFactDialog.styles';

interface IProps extends DialogProps {}

export const DeleteZeroValueFactDialog: FC<IProps> = ({ open }) => {
    const { projectId } = useParams();
    const dispatch = useAppDispatch();
    const { data: zeroFactBlockers, cancelCallback } = useTypedSelector(deleteZeroValueFactDrawerSelector);
    const count = zeroFactBlockers?.length;
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);

    const handleConfirm = async () => {
        setIsLoading(true);
        try {
            const { data } = await req.post(`/projects/${projectId}/works/delete-zero-fact`, {
                toUpdate: zeroFactBlockers!,
            });
            const works = data.data as Work[];
            dispatch(onEditAgKsg(works));
            enqueueSnackbar('Нулевые значения успешно удалены', { variant: 'success' });
        } catch (error) {
            console.log('error', error);
            enqueueSnackbar('Произошла ошибка при удалении нулевых значений', { variant: 'error' });
        } finally {
            setIsLoading(false);
            closeDialog();
        }
    };

    const closeDialog = () => {
        dispatch(
            toggleDeleteZeroValueFact({
                open: false,
                data: null,
            })
        );
    };

    const handleCancel = () => {
        cancelCallback && cancelCallback();
        closeDialog();
    };

    return (
        <DialogWindow
            open={open}
            onClose={handleCancel}
        >
            <Container>
                <Title>Удалить работы с нулевыми значениями?</Title>
                <Text>
                    Обнаружено задач с нулевым значением: {count}. <br /> Они препятствуют корректировки <br /> плановых
                    значений.
                    <br /> Удалить их?
                </Text>
                <Stack
                    direction={'row'}
                    gap={1.5}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    marginTop={'auto'}
                >
                    <LoadingButton
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={handleConfirm}
                        autoFocus
                        color='success'
                        fullWidth
                        variant='contained'
                        style={{ maxWidth: 165 }}
                    >
                        Да
                    </LoadingButton>
                    <Button
                        onClick={handleCancel}
                        fullWidth
                        variant='contained'
                        style={{ maxWidth: 165 }}
                    >
                        Нет
                    </Button>
                </Stack>
            </Container>
        </DialogWindow>
    );
};
