import { Fragment } from 'react'
import UserManagement from '../UserManagement/UserManagement'
import { PublicUserProfile } from '../../../api/users/users.def'

function AddUser() {
    return (
        <Fragment>
            <UserManagement profile={{} as PublicUserProfile} />
        </Fragment>
    )
}

export default AddUser
