import UserManagement from '../UserManagement/UserManagement'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetUserByIdQuery } from '../../../api/users/users.api'
import { useTypedSelector } from '../../../store/store'
import { CircularProgress, Stack } from '@mui/material'
import { profileSelector } from '../../../store/slices/profileSlice'

function EditUser() {
    const { userId } = useParams()

    const navigate = useNavigate()
    const { profile } = useTypedSelector(profileSelector)

    const {
        data: profileById,
        isLoading: profileIsLoading,
        isFetching: profileIsFetching,
    } = useGetUserByIdQuery({ userId: Number(userId) }, { refetchOnMountOrArgChange: true })

    const isDataLoading = profileIsLoading || profileIsFetching

    useEffect(() => {
        if (profile.role === 'executor') {
            navigate('/users')
        }
    }, [navigate, profile.role])

    return (
        <>
            {isDataLoading ? (
                <Stack flex={1} alignItems="center" justifyContent="center" height="100%">
                    <CircularProgress />
                </Stack>
            ) : (
                <UserManagement profile={profileById} />
            )}
        </>
    )
}

export default EditUser
