import { AgGridReact } from 'ag-grid-react'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { MSGPrintColDef } from './MSGPrint.ColDef'
import { AsyncTransactionsFlushed, GetRowIdParams, GridReadyEvent, RowClassParams } from 'ag-grid-community'
import { WorkMsg } from '../../api/msg/msg.def'
import { CANVAS_ID, createGantCanvasV2, drawDefaultArrowsFromServer } from '../AgGridMsg/AgGridMsg.service'
import { req } from '../WorkManagment/api/api'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { filtersSelector } from '../../store/slices/filtersSlice'
import { monthMsgSelector } from '../../store/slices/monthMsgSlice'
import { useTypedSelector } from '../../store/store'
import '../AgGrid/AgGrid.scss'
import { IHiddenRow, IMSGPrintAgGridProps } from './MSGPrint.def'

export const MSGPrintAgGrid = memo(
    ({ setOpenBackdrop, setProgress }: IMSGPrintAgGridProps) => {
        const { projectId } = useParams()
        const { month, year } = useTypedSelector(monthMsgSelector)
        const { filters } = useTypedSelector(filtersSelector)
        const [searchParams, setSearchParams] = useSearchParams()
        const [workList, setWorkList] = useState<{
            data: WorkMsg[]
            total: number
        } | null>(null)
        const gridRef = useRef<AgGridReact>(null)
        const location = useLocation()

        useEffect(() => {
            const localFilters = JSON.parse(localStorage.getItem('filters')!)
            const month = JSON.parse(localStorage.getItem('month')!)
            const year = JSON.parse(localStorage.getItem('year')!)
            searchParams &&
                req
                    .post(`/projects/${projectId}/works/msg-list-next-generation`, localFilters || filters, {
                        limit: 999999,
                        offset: 0,
                        year: year,
                        month: month + 1,
                    })
                    .then(({ data }) => {
                        initializeWorkList(data)
                    })
            return () => {
                localStorage.removeItem('filters')
                localStorage.removeItem('month')
                localStorage.removeItem('year')
            }
        }, [searchParams])

        function initializeWorkList(data: { data: WorkMsg[]; total: number }) {
            const hiddenRowsIds: IHiddenRow[] | null = JSON.parse(localStorage.getItem('hiddenRowsIds')!)
            const worksWithHiddenRows = hiddenRowsIds
                ? data.data?.reduce((acc: WorkMsg[], prev: WorkMsg) => {
                      const idx = hiddenRowsIds.every((filItem) => {
                          if (prev.code === filItem.code) {
                              return true
                          }

                          return !filItem.code.split('-').every((v) => prev.code.split('-').includes(v))
                      })
                      return idx ? [...acc, prev] : acc
                  }, [])
                : data.data
            setWorkList(() => ({
                data: worksWithHiddenRows,
                total: worksWithHiddenRows.length,
            }))
            setProgress((prevState) => ({
                ...prevState,
                total: worksWithHiddenRows.length,
            }))
        }

        const getChartViewportDimensions = (
            rowHeight: number,
            colWidth: number,
            AgGridAPI: AgGridReact,
            workList: {
                data: WorkMsg[]
                total: number
            } | null
        ) => {
            if (AgGridAPI) {
                let width = 0
                let height = (workList?.data.length || 0) * rowHeight
                if (AgGridAPI) {
                    AgGridAPI?.columnApi?.getColumnState()?.map((columnState) => {
                        if (columnState.colId.includes('dailyCharts')) {
                            width += columnState?.width || colWidth
                        }
                    })
                }

                return {
                    chartsViewportWidth: width,
                    chartsViewportHeight: height,
                }
            }
            return {
                chartsViewportWidth: 0,
                chartsViewportHeight: 0,
            }
        }
        const drawInitialArrows = useCallback(() => {
            if (gridRef.current) {
                const { chartsViewportWidth, chartsViewportHeight } = getChartViewportDimensions(
                    80,
                    120,
                    gridRef.current,
                    workList
                )
                req.post(`/projects/${projectId}/dependencies/works/msg/info`, {
                    month: month + 1,
                    year: year,
                })
                    .then(({ data }) => {
                        if (gridRef.current) {
                            document.getElementById(CANVAS_ID + 'wrapper')?.remove()
                            createGantCanvasV2(CANVAS_ID, chartsViewportWidth, chartsViewportHeight)
                            drawDefaultArrowsFromServer(gridRef.current, data?.data)
                        }
                    })
                    .catch((e) => {})
            }
        }, [workList, projectId, month, year])

        const getRowClass = useCallback((params: RowClassParams<WorkMsg, any>) => {
            const stylesByLevel = {
                0: 'level_zero',
                1: 'level_one',
                2: 'level_two',
                3: 'level_three',
                4: 'level_four',
                5: 'level_five',
                6: 'level_six',
                7: 'level_seven',
            }

            return params.data?.hasChildren
                ? stylesByLevel[params.data?.level as keyof typeof stylesByLevel]
                : stylesByLevel[7]
        }, [])

        const getRowId = useCallback((params: GetRowIdParams) => {
            return params.data.id
        }, [])

        function uploadPerChunk(
            params: AsyncTransactionsFlushed<WorkMsg, any>,
            workList: { data: WorkMsg[]; total: number } | null
        ) {
            const worksPerChunk = 100
            const addedWorks = (params?.results && params?.results[0] && params?.results[0].add) || []
            const lastAddedWorkIndex = addedWorks[addedWorks.length - 1].rowIndex
            lastAddedWorkIndex &&
                setProgress((prevState) => ({
                    ...prevState,
                    current: lastAddedWorkIndex + 1,
                }))
            if (addedWorks.length > 1 && addedWorks.length < worksPerChunk) {
                setOpenBackdrop(() => false)
                searchParams.get('gant') && drawInitialArrows()
                return
            }
            if (typeof lastAddedWorkIndex === 'number') {
                const chunk = workList?.data?.slice(lastAddedWorkIndex + 1, lastAddedWorkIndex + 1 + worksPerChunk)

                gridRef.current?.api?.applyTransactionAsync({
                    add: chunk,
                })
            }
        }

        const handleGridReady = useCallback(
            (params: GridReadyEvent) => {
                const activeColumnTemplate = JSON.parse(localStorage.getItem('activeTemplate')!)
                activeColumnTemplate &&
                    params.columnApi?.applyColumnState({
                        state: activeColumnTemplate,
                    })
                if (workList?.data?.length) {
                    gridRef.current?.api?.applyTransactionAsync({ add: [workList.data[0]] })
                    setProgress((prevState) => ({
                        ...prevState,
                        current: 1,
                    }))
                }
            },
            [workList]
        )

        return workList?.data ? (
            <AgGridReact
                ref={gridRef}
                columnDefs={MSGPrintColDef({ workList: workList, location: location })}
                domLayout="print"
                rowHeight={80}
                headerHeight={40}
                groupHeaderHeight={40}
                onGridReady={handleGridReady}
                getRowId={getRowId}
                getRowClass={getRowClass}
                onAsyncTransactionsFlushed={(params) => {
                    uploadPerChunk(params, workList)
                }}
                defaultColDef={{
                    autoHeaderHeight: true,
                }}
            ></AgGridReact>
        ) : null
    },
    () => true
)
