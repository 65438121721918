export const PRESET_COLORS = [
    'rgba(240, 136, 170, 1)',
    'rgba(249, 168, 212, 1)',
    'rgba(255, 136, 157, 1)',
    'rgba(223, 103, 103, 1)',
    'rgba(252, 165, 165, 1)',
    'rgba(255, 151, 42, 1)',
    'rgba(253, 186, 116, 1)',
    'rgba(221, 190, 133, 1)',
    'rgba(253, 224, 71, 1)',
    'rgba(206, 210, 58, 1)',
    'rgba(190, 242, 100, 1)',
    'rgba(140, 204, 88, 1)',
    'rgba(48, 186, 143, 1)',
    'rgba(141, 212, 200, 1)',
    'rgba(73, 129, 223, 1)',
    'rgba(135, 186, 237, 1)',
    'rgba(147, 197, 253, 1)',
    'rgba(163, 148, 212, 1)',
    'rgba(151, 165, 214, 1)',
    'rgba(154, 162, 176, 1)',
]
export const PRESET_COLORS_V3 = [
    'rgba(255, 211, 211, 1)',
    'rgba(255, 215, 177, 1)',
    'rgba(255, 239, 120, 1)',
    'rgba(163, 233, 225, 1)',
    'rgba(191, 219, 254, 1)',
    'rgba(221, 214, 254, 1)',
    'rgba(251, 207, 232, 1)',
    'rgba(237, 239, 243, 1)',
    'rgba(217, 249, 157, 1)',
    'rgba(193, 240, 255, 1)',
    // 'rgba(229, 142, 142, 1)',
    // 'rgba(243, 170, 93, 1)',
    // 'rgba(236, 238, 145, 1)',
    // 'rgba(88, 186, 169, 1)',
    // 'rgba(132, 180, 239, 1)',
    // 'rgba(181, 169, 226, 1)',
    // 'rgba(239, 163, 188, 1)',
    // 'rgba(234, 212, 170, 1)',
    // 'rgba(184, 214, 133, 1)',
    // 'rgba(148, 208, 227, 1)',
]
export const PRESET_COLORS_V4 = [
    // Красный
    'rgba(255, 102, 102, 0.7)',
    // Розовый
    'rgba(255, 153, 255, 0.7)',
    // Фиолетовый
    'rgba(204, 153, 255, 0.7)',
    // Голубой
    'rgba(153, 204, 255, 0.7)',
    // Болотный
    'rgba(102, 204, 204, 0.7)',
    //Зеленый
    'rgba(153, 255, 204, 0.7)',
    // Желтый
    'rgba(255, 255, 153, 0.7)',
    //Оранжевый
    'rgba(255, 204, 153, 0.7)',
    //Серый
    'rgba(204, 204, 204, 0.7)',
    
]
