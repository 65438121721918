import { createSlice } from '@reduxjs/toolkit'
import { PublicUserProfile } from '../../api/users/users.def'
import { RootState } from '../store'

interface initialStateI {
    profile: PublicUserProfile
}

const initialState: initialStateI = {
    profile: {} as PublicUserProfile,
}

const slice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile: (state, action) => {
            state.profile = { ...action.payload }
        },
    },
})

export const { setProfile } = slice.actions
export default slice.reducer

export const profileSelector = (state: RootState) => state.profile
export const profileRoleSelector = (state: RootState) => state.profile.profile.role
