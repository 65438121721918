import { Tab, Tabs } from '@mui/material'
import styled from 'styled-components'
import { FlexRowWrapper } from '../../../../pages/NewExecutorView/components/components.styles'

export const StyledTabs = styled(Tabs)`
    width: 198px;
    min-height: auto;
    background: #f6f7fb;
    padding: ${({ theme }) => theme.spacing(0.25)};
    border-radius: ${({ theme }) => theme.spacing(0.75)};
    .MuiTabs-flexContainer {
        gap: 5px !important;
    }
    .MuiTabs-indicator {
        display: none;
    }
`
export const StyledTab = styled(Tab)`
    padding: ${({ theme }) => theme.spacing(1)};
    min-height: auto;
    min-width: auto;
    font-size: 12px;
    position: relative;
    border-radius: ${({ theme }) => theme.spacing(0.75)};
    letter-spacing: 0;
    text-transform: capitalize;
    overflow: visible;
    color: #7890b2;
    ::after {
        position: absolute;
        content: '';
        background: rgba(140, 187, 232, 0.3);
        border-width: 1px;
        height: 16px;
        margin-top: auto;
        margin-bottom: auto;
        width: 2px;
        right: -4px;
    }
    &.Mui-selected {
        background: white;
    }
`

export const StyledPriceSettingsRowWrapper = styled(FlexRowWrapper)`
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-top: ${({ theme }) => theme.spacing(1.5)};
    :not(:last-of-type) {
        padding-bottom: ${({ theme }) => theme.spacing(1.5)};
        border-bottom: 1px solid #edeff3;
    }
`

export const StyledArrow = styled(FlexRowWrapper)`
    background: white;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 46px;
    top: 6px;
    transform: rotate(45deg);
`
