import { Drawer, DrawerProps, TextField, Typography } from '@mui/material'
import styled from 'styled-components'
import { FlexColumnWrapper } from '../../pages/NewExecutorView/components/components.styles'
import { Search } from '@mui/icons-material'

export const StyledDrawer = styled(Drawer)<DrawerProps & { width?: number }>`
    .MuiPaper-root {
        display: flex;
        flex-direction: column;
        width: ${({ width }) => (width || 400) + 'px'};
        height: 100%;
        overflow: hidden;
    }
`

export const StyledDrawerTitle = styled(Typography)`
    color: ${({ theme }) => theme.palette.primary.main};
    padding: ${({ theme }) => theme.spacing(2)};
    text-align: center;
    width: 100%;
    border-bottom: 1px solid #d6d1fa;
    line-height: 24px;
`

export const StyledDrawerContent = styled(FlexColumnWrapper)`
    height: calc(100% - 57px);
    padding: ${({ theme }) => theme.spacing(2.5)};
    gap: ${({ theme }) => theme.spacing(2.5)};
    text-align: center;
    align-items: center;
    overflow: auto;
`

export const StyledDrawerAlert = styled(Typography)`
    color: #ed6c02;
`

export const StyledDrawerSearch = styled(TextField)`
    border-radius: ${({ theme }) => theme.spacing(0.75)};
    .MuiOutlinedInput-root {
        padding-left: 8px;
    }
    input {
        padding: 8px;
    }
`

export const StyledDrawerInput = styled(StyledDrawerSearch)`
    border-radius: ${({ theme }) => theme.spacing(0.75)};
    .MuiOutlinedInput-root {
        padding-left: 8px;
        background: ${({ theme }) => theme.palette.bg.shades};
    }
    input {
        text-align: center;
        padding: 8px;
    }
`

StyledDrawer.defaultProps = {
    anchor: 'right',
}

StyledDrawerTitle.defaultProps = {
    variant: 'h1',
}

StyledDrawerInput.defaultProps = {
    size: 'small',
    fullWidth: true,
}

StyledDrawerSearch.defaultProps = {
    size: 'small',
    InputProps: {
        startAdornment: <Search sx={{ color: 'rgba(120, 144, 178, 1)' }} />,
    },
    fullWidth: true
}
