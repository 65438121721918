import { Typography } from '@mui/material'
import { CellValueChangedEvent, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Nullable } from '../../../shared/def'
import { theme } from '../../../styles/theme'
import { AG_GRID_DEFAULT_PARAMS } from '../../WorkManagment/components/AgGridService/AgGridColumnDef.service'
import { StyledSNZAgGrid } from '../AgGridService/AgGrid.styles'
import { WorkWithResourcesAPI } from '../api/WorkWithResourcesAPI'
import { IWorkFactData } from '../api/api.types'
import { RelatedWorksDialog } from '../components/RelatedWorksDialog'
import { WorkDetails } from '../components/WorkDetails/WorkDetails'
import { isDesktopView, switchToMobileView } from '../components/components.service'
import { FlexColumnWrapper, FlexRowWrapper } from '../components/components.styles'
import { mapToWorkFactData } from './WorksSubPage.service'
import { HIDDEN_MOBILE_COLUMNS, SNZWorksTableColDef } from './WorksTable.colDef'
import { InfoButton } from './components/WorkNameCellRenderer'
import './styles.scss'

export function WorksSubPage() {
    const gridRef = useRef<AgGridReact>(null)
    const executorAuth = JSON.parse(localStorage.getItem('executorAuth')!)
    const [searchParams] = useSearchParams()
    const [waitList, setWaitList] = useState<IWorkFactData[]>()
    const [doneList, setDoneList] = useState<IWorkFactData[]>()
    const [selectedWork, setSelectedWork] = useState<Nullable<IWorkFactData>>(null)

    const projectId = executorAuth.id as string

    useEffect(() => {
        if (!executorAuth) return
        gridRef.current!?.api?.showLoadingOverlay()

        WorkWithResourcesAPI.getWorksWithResources({ projectID: executorAuth!.id })
            .then(({ project, doneList, waitList }) => {
                setWaitList(() => waitList.map(mapToWorkFactData))
                setDoneList(() => doneList.map(mapToWorkFactData))
            })
            .finally(() => {
                gridRef.current!.api?.hideOverlay()
            })
    }, [gridRef])

    function moveRowFromWaitToDoneList(params: CellValueChangedEvent) {
        setWaitList((prevValue) =>
            prevValue?.filter((listItem) => {
                return params.data.workID != listItem.workID
            })
        )
        setDoneList((prevValue) => [...(prevValue || []), params.data])
    }

    function moveRowFromDoneListToWaitList(params: CellValueChangedEvent) {
        setDoneList((prevValue) =>
            prevValue?.filter((listItem) => {
                return params.data.workID != listItem.workID
            })
        )
        setWaitList((prevValue) => [...(prevValue || []), { ...params.data, fact: params.oldValue }])
    }

    function editVolumeFact(params: CellValueChangedEvent, withMoveToDoneList: boolean) {
        withMoveToDoneList && moveRowFromWaitToDoneList(params)
        WorkWithResourcesAPI.updateItemFact({
            projectID: executorAuth!.id || '',
            itemType: 'work',
            body: {
                fact: params.newValue,
                id: params.data.workID,
            },
        }).catch((e) => moveRowFromDoneListToWaitList(params))
    }

    const handleWorkSelect = (work: Nullable<IWorkFactData>) => {
        setSelectedWork(() => work)
    }

    return (
        <>
            <RelatedWorksDialog projectId={projectId} selectedWork={selectedWork} onWorkSelect={handleWorkSelect} />
            <FlexColumnWrapper className="works-sub-page" height={'calc(100% - 67px)'}>
                <FlexColumnWrapper height={'100%'} gap={2}>
                    <StyledSNZAgGrid
                        ref={gridRef}
                        headerHeight={80}
                        rowData={searchParams.get('listType') == 'waitList' ? waitList : doneList}
                        columnDefs={SNZWorksTableColDef}
                        {...AG_GRID_DEFAULT_PARAMS()}
                        overlayLoadingTemplate="Получение данных..."
                        overlayNoRowsTemplate="Пока нет работ"
                        onCellValueChanged={(params: CellValueChangedEvent) =>
                            editVolumeFact(params, searchParams.get('listType') == 'waitList')
                        }
                        context={{
                            projectID: executorAuth!.id,
                            onWorkSelect: handleWorkSelect,
                        }}
                        onGridSizeChanged={(params) => {
                            switchToMobileView(params, HIDDEN_MOBILE_COLUMNS)
                            params.api.setColumnDefs(
                                SNZWorksTableColDef.map((column) => {
                                    return {
                                        ...column,
                                        ...(column.field == 'workName' &&
                                            !isDesktopView(params) && {
                                                cellRenderer: (
                                                    params: ICellRendererParams<IWorkFactData, string | number, any>
                                                ) => {
                                                    return (
                                                        <Fragment>
                                                            <FlexColumnWrapper
                                                                sx={{ gap: `${theme.spacing(1)} !important` }}
                                                                p={0}
                                                                pr={4}
                                                            >
                                                                <FlexRowWrapper>
                                                                    <Typography fontSize={14}>
                                                                        <b>{params.data?.objTitle}</b> .{' '}
                                                                        {params.data?.workName}
                                                                    </Typography>
                                                                    <InfoButton
                                                                        positionType="relative"
                                                                        onClick={() => handleWorkSelect(params.data!)}
                                                                    />
                                                                </FlexRowWrapper>
                                                                <WorkDetails work={params.data as IWorkFactData} />
                                                            </FlexColumnWrapper>
                                                        </Fragment>
                                                    )
                                                },
                                            }),
                                    }
                                })
                            )
                        }}
                        onGridReady={(params) => {
                            switchToMobileView(params, HIDDEN_MOBILE_COLUMNS)
                        }}
                    />
                </FlexColumnWrapper>
            </FlexColumnWrapper>
        </>
    )
}
