import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Fragment, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';

import { useGetExcelLinkEquipQuery, useUploadExcelEquipMutation } from '@/api/ksgEquip/ksgEquip.api';

import placeImg from '@/pages/Projects/component/Headline.png';
import s from '@/pages/Projects/component/ProjectsDrawer.module.scss';

import ErrorsList from '@/shared/components/ErrorsList/ErrorsList';

import { toggleUploadKsgEquip } from '@/store/slices/drawersSlice';
import { useAppDispatch } from '@/store/store';

import DescriptionAndSelectUploadDrawer from './components/DescriptionAndSelectUploadDrawer';
import UploadAgain from './components/UploadAgain';
import UploadButtons from './components/UploadButtons';

export default function UploadDrawerKsgEquip() {
    const [showErrorsList, setShowErrorsList] = useState(false);
    const [showUploadAgain, setShowUploadAgain] = useState(false);
    const [areDatesBinded, setAreDatesBinded] = useState(false);
    const [uploadExEquipReq, uploadExEquipRes] = useUploadExcelEquipMutation();

    const { t } = useTranslation('files');
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const exLinkEquip = useGetExcelLinkEquipQuery();

    const { projectId } = useParams();

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            acceptedFiles.forEach((file: File) => {
                const formData = new FormData();

                formData.append('binding', String(areDatesBinded));

                formData.append('file', file);
                uploadExEquipReq({
                    id: Number(projectId),
                    body: formData,
                });
            });
            setShowUploadAgain(false);
        },
        [areDatesBinded, projectId, uploadExEquipReq]
    );

    function onDropRejected() {
        enqueueSnackbar(t('upload.wrong_extension'), {
            variant: 'error',
        });
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDropRejected,
        accept: {
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        },
    });

    function disabledButton() {
        return uploadExEquipRes.status !== 'fulfilled' || uploadExEquipRes.data?.error !== null;
    }

    const handleUploadAgain = () => setShowUploadAgain(true);

    if (showErrorsList) {
        return (
            <ErrorsList
                setShowErrorsList={setShowErrorsList}
                errors={uploadExEquipRes.data?.error}
                title='Информация об ошибках'
            />
        );
    }

    return (
        <main className={s.main_awd}>
            <h1>Загрузка КСГ Механизмы</h1>
            <div className={s.awd_container}>
                <DescriptionAndSelectUploadDrawer />

                <div className={s.awd_loader}>
                    {uploadExEquipRes.isSuccess && uploadExEquipRes.data?.error !== null ? (
                        <Fragment>
                            {!showUploadAgain ? (
                                <UploadAgain
                                    length={uploadExEquipRes.data?.error.length}
                                    setShowUploadAgain={setShowUploadAgain}
                                    setShowErrorsList={setShowErrorsList}
                                    onUploadAgain={handleUploadAgain}
                                />
                            ) : (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <img
                                        src={placeImg}
                                        alt=''
                                    />
                                </div>
                            )}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {!uploadExEquipRes.isLoading ? (
                                <Fragment>
                                    {uploadExEquipRes.isSuccess ? (
                                        <div className={s.awd_loader_success}>
                                            <CheckCircleIcon
                                                sx={{
                                                    color: '#22c55e',
                                                    fontSize: '2rem',
                                                }}
                                            />
                                            <p>
                                                Успешно обработано! <br /> Всего добавлено строк:{' '}
                                                {uploadExEquipRes.data.create}
                                            </p>
                                        </div>
                                    ) : (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <img
                                                src={placeImg}
                                                alt=''
                                            />
                                        </div>
                                    )}
                                </Fragment>
                            ) : (
                                <FadeLoader />
                            )}
                        </Fragment>
                    )}
                </div>

                <div className={s.awd_download}>
                    <DownloadIcon />
                    <p onClick={() => window.open(exLinkEquip.data?.data, '_blank')}>СКАЧАТЬ ФОРМУ ДЛЯ КСГ</p>
                </div>

                <div className={s.awd_download}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={areDatesBinded}
                                    onChange={(e) => setAreDatesBinded(e.target.checked)}
                                />
                            }
                            label='Привязать даты'
                        />
                    </FormGroup>
                </div>

                <UploadButtons
                    disableButtons={disabledButton()}
                    isLoading={uploadExEquipRes.isLoading}
                    onClose={() => dispatch(toggleUploadKsgEquip())}
                />
            </div>
        </main>
    );
}
