import { Box, Popover, Typography } from '@mui/material';
import styled from 'styled-components';

import { ResetButton } from '@/components/ui/UIButton';

export const Container = styled(Popover).attrs({
    anchorOrigin: {
        vertical: 30,
        horizontal: 'left',
    },
})`
    & .MuiPaper-root {
        padding: 8px 16px;
        min-width: 219px;
        width: fit-content;
        min-height: 103px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        background-color: #fff;
    }
`;

export const TitleContainer = styled(Box)`
    padding-block: 4px;
    border-bottom: 1px solid #d1d8fa;
`;

export const Title = styled(Typography).attrs({
    variant: 'h5',
})`
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.02em;
    color: #1360e0;
`;

export const List = styled('ul')`
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-block: 4px;
`;

export const ListItem = styled('li')``;

export const ListItemButton = styled(ResetButton)`
    display: flex;
    gap: 4px;
    align-items: center;

    width: 100%;
    min-height: 20px;
    transition: filter 0.3s;

    :hover {
        filter: opacity(70%);
    }

    :disabled {
        filter: opacity(20%);
        cursor: default;
    }
`;

export const ListItemText = styled(Typography)<{ width?: number; fontSize?: number }>`
    font-weight: 400;
    font-size: ${(props) => props.fontSize ?? 10}px;
    line-height: 20px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #445570;
    white-space: nowrap;
    width: ${(props) => `${props.width}px` ?? 'auto'};
`;
