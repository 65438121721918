export const headerInput = {
    background: 'white',
    padding: '0.3rem 0.2rem 0.3rem 0.7rem',
    borderRadius: '4px',
    '.MuiInput-input': {
        transition: 'all 0.3s linear',
        marginLeft: '0.6rem',
        width: '7rem',
    },
    '.MuiInput-input:focus': {
        marginLeft: '1rem',
        width: '10rem',
    },
    svg: {
        fontSize: '1.4rem',
    },
}

export const headerButton = {
    backgroundColor: '#0044b4',
    color: '#fff',
    fill: '#fff',
    border: 'none',
    height: '2.5rem',
    width: '2.5rem',
    minWidth: '2.5rem',
    borderRadius: '6px',
    padding: 0,
    transition: 'all 0.3s linear',
    svg: {
        fill: '#fff',
    },
    '&:hover': {
        backgroundColor: '#1e3a8a',
        boxShadow: 'box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
    },
}
