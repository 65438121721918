import cn from 'classnames';
import * as Yup from 'yup';

import { workStatuses } from '@/api/works/works.def';

import { Nullable } from '@/shared/def';

import s from './AddLevelKsg.module.scss';

export const createKSGValidationSchema = Yup.object({
    // objTitle: Yup.string().required('Обязательное поле').max(150, 'Не больше 150 символов'),
    // objName: Yup.string().required('Обязательное поле').max(150, 'Не больше 150 символов'),
    workName: Yup.string().required('Обязательное поле').max(150, 'Не больше 150 символов'),
    // workGroup: Yup.string().required('Обязательное поле').max(150, 'Не больше 150 символов'),
    // rdGroup: Yup.string().required('Обязательное поле').max(150, 'Не больше 150 символов'),
    // rdCode: Yup.string().required('Обязательное поле').max(150, 'Не больше 150 символов'),
    operationalStartDate: Yup.date()
        .nullable()
        .typeError('Неверный формат')
        .min(new Date(2000, 0, 1), 'Не раньше 2000 года')
        .max(new Date(2050, 0, 1), 'Не позднее 2050 года'),
    operationalEndDate: Yup.date()
        .nullable()
        .typeError('Неверный формат, введите дату')
        .min(Yup.ref('operationalStartDate'), 'Не раньше начала')
        .max(new Date(2050, 0, 1), 'Не позднее 2050 года'),
    startDate: Yup.date()
        .nullable()
        .typeError('Неверный формат')
        .min(new Date(2000, 0, 1), 'Не раньше 2000 года')
        .max(new Date(2050, 0, 1), 'Не позднее 2050 года'),
    endDate: Yup.date()
        .nullable()
        .typeError('Неверный формат, введите дату')
        .min(Yup.ref('startDate'), 'Не раньше начала')
        .max(new Date(2050, 0, 1), 'Не позднее 2050 года'),
    rdDateApproval: Yup.date()
        .nullable()
        .typeError('Неверный формат')
        .min(new Date(2000, 0, 1), 'Не раньше 2000 года')
        .max(new Date(2050, 0, 1), 'Не позднее 2050 года'),
    rdDate: Yup.date()
        .nullable()
        .typeError('Неверный формат')
        .min(new Date(2000, 0, 1), 'Не раньше 2000 года')
        .max(new Date(2050, 0, 1), 'Не позднее 2050 года'),
    // humanHourCost: Yup.string().required('Обязательное поле'),
    // contractorCompany: Yup.string().required('Обязательное поле'),
    unit: Yup.string().required('Обязательное поле'),
    volumeTotal: Yup.string().required('Обязательное поле'),
    // machineHourCost: Yup.string().required('Обязательное поле'),
    // costER: Yup.string().required('Обязательное поле'),
    // groupOfWorks: Yup.string().required('Обязательное поле'),
    // typeOfWorks: Yup.string().required('Обязательное поле'),
});

export const initialFormValuesAg = {
    objTitle: '',
    objName: '',
    rdGroup: '',
    rdCode: '',
    rdStatus: '',
    rdDateApproval: null,
    rdVersion: '',
    rdDate: null,
    workGroup: '',
    contractorCompany: '',
    workName: '',
    unit: '',
    volumeTotal: '',
    startDate: null,
    endDate: null,
    humanHourCost: '',
    machineHourCost: '',
    costER: '',
    costRemaining: '',
};
export interface initialFormValuesAgI {
    costER: string;
    costRemaining: string;
    humanHourCost: string;
    machineHourCost: string;
    unit: string;
    volumeTotal: string;
    workName: string;
    contractorCompany: string;
    workGroup: string;
    objName: string;
    objTitle: string;
    rdCode: string;
    rdStatus: workStatuses | string;
    rdGroup: string;
    rdVersion: string;
    rdDateApproval: Date | null;
    rdDate: Date | null;
    startDate: Date | null;
    endDate: Date | null;
}

export interface ICreateKSGInitialFormValues {
    costER: string;
    costERGK: string;
    costERSub: string;
    humanHourCost: string;
    machineHourCost: string;
    unit: string;
    volumeTotal: string;
    workName: string;
    contractorCompany: string;
    workGroup: string;
    objName: string;
    objTitle: string;
    rdCode: string;
    rdStatus: workStatuses | string;
    rdGroup: string;
    rdVersion: string;
    rdDateApproval: Nullable<Date>;
    rdDate: Nullable<Date>;
    operationalStartDate: Nullable<Date>;
    operationalEndDate: Nullable<Date>;
    startDate: Nullable<Date>;
    endDate: Nullable<Date>;
    groupOfWorks: string;
    typeOfWorks: string;
    mainGroup: string;
}

export const createKSGInitialFormValues: ICreateKSGInitialFormValues = {
    objTitle: '',
    objName: '',
    rdGroup: '',
    rdCode: '',
    rdStatus: '',
    rdDateApproval: null,
    rdVersion: '',
    rdDate: null,
    workGroup: '',
    contractorCompany: '',
    workName: '',
    unit: '',
    volumeTotal: '',
    operationalStartDate: null,
    operationalEndDate: null,
    startDate: null,
    endDate: null,
    humanHourCost: '',
    machineHourCost: '',
    costER: '',
    costERGK: '',
    costERSub: '',
    groupOfWorks: '',
    typeOfWorks: '',
    mainGroup: '',
};

export const inputStylesAddWorkAg = {
    width: '10.375rem',
    backgroundColor: '#F6F7FB',
    '.MuiOutlinedInput-input': {
        padding: '0.31rem 0.5rem',
        color: '#2B3648',
    },
    '.MuiOutlinedInput-input::placeholder': {
        color: 'rgba(43, 54, 72, 0.38)',
    },
    '.MuiInputBase-root': {
        fontSize: 14,
        fontWeight: 400,
        borderRadius: '6px',
    },

    '.MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(43, 54, 72, 0.12)',
        borderWidth: '1px !important',
    },
};

export const calendarStylesAddWorkAg = {
    backgroundColor: '#F6F7FB',
    width: '10.375rem',

    '.MuiSvgIcon-root': {
        borderRadius: '6px',
        fontSize: '1.16rem',
    },

    '.MuiInputBase-input': {
        padding: '0.38rem 0.5rem',
        color: '#2B3648',
        fontSize: 14,
    },

    '.MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(43, 54, 72, 0.12)',
        borderWidth: '1px !important',
    },
};

export function FormErrorAg({ isTouched, error }: { isTouched?: boolean; error?: string }) {
    return (
        <div
            className={cn(s.error, {
                [s.errorVisible]: isTouched && error,
            })}
        >
            <div>{error}</div>
        </div>
    );
}
