import { IOnSubmitChartDataArgs } from '../../../../components/EditChartCell/EditChartCell.def'
import {
    parseStringToNumber,
    onSubmitSuccess,
    onSubmitRejected,
} from '../../../../components/EditChartCell/EditChartCell.service'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../../../shared/rolePermissions'
import { req } from '../../../WorkManagment/api/api'
import { TResourceType } from '../../../WorkManagment/api/api.types'

export async function submitResourceDailyChart(args: IOnSubmitChartDataArgs & { resourceType: TResourceType }) {
    const { cellRendererParams, formData, projectID, chartIndex, chartValueKey, resourceType, profile } = args
    cellRendererParams.eGridCell.style.pointerEvents = 'none'
    const updatedChart = cellRendererParams.api.getValue('dailyCharts', cellRendererParams.node)[chartIndex as number]
    const body = {
        day: updatedChart.day,
        fact: updatedChart.fact,
        forecast: updatedChart.forecast,
        plan: updatedChart.plan,
        verifiedFact: updatedChart.verifiedFact,
    }

    // if (!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile?.role, 'EDIT_VER_FACT_MSG_STAFF')) {
    //     delete body.verifiedFact
    // } else {
    //     if (profile?.role === 'controller') {
    //         delete body.fact
    //         delete body.forecast
    //         delete body.plan
    //     }
    // }

    await req
        .post(
            `/projects/${projectID}/${resourceType === 'staff' ? 'staffs' : 'technics'}/msg/update-charts?year=${
                cellRendererParams.data?.year
            }&month=${cellRendererParams.data?.month}`,
            {
                toUpdate: [
                    {
                        charts: [
                            {
                                ...body,
                                [chartValueKey]: parseStringToNumber(formData[chartValueKey]),
                            },
                        ],
                        id: cellRendererParams.data?.id,
                    },
                ],
            }
        )
        .then(({ data }) => {
            cellRendererParams.api.applyTransaction({ update: data.data })
            onSubmitSuccess(args)
        })
        .catch((e) => {
            onSubmitRejected(args)
        })
    cellRendererParams.eGridCell.style.pointerEvents = 'auto'
}
