import React from 'react'
import FilterNameEl from '../../components/CheckboxPopoverSufficiency/components/FilterNameEl'
import FilterDatePickerEl from '../../components/CheckboxPopoverSufficiency/components/FilterDatePickerEl'
import FilterCompletionEl from '../../components/CheckboxPopoverSufficiency/components/FilterCompletionEl'
import FilterDeliveredEl from '../../components/CheckboxPopoverSufficiency/components/FilterDeliveredEl'
import ButtonsFilters from '../../components/CheckboxPopoverSufficiency/components/ButtonsFilters'
import { store } from '../../store/store'
import {
    resetCompletion,
    resetDateDel,
    resetDelivered,
    resetTextFiltersSom,
    setSomModal,
    toggleTriggerSom,
} from '../../store/slices/filtersSomSlice'

export enum filtersNamesSmEnum {
    nameMto = 'nameMto',
    dateDel = 'dateDel',
    completion = 'completion',
    delivered = 'delivered',
}

const onSubmit = () => {
    store.dispatch(toggleTriggerSom())
    store.dispatch(setSomModal(false))
}

export const filterVarSom: Record<
    filtersNamesSmEnum,
    { elem: React.ReactElement; title: string; buttons: React.ReactElement }
> = {
    nameMto: {
        elem: <FilterNameEl />,
        title: 'Наименование МТР',
        buttons: <ButtonsFilters onSubmit={onSubmit} onReset={() => store.dispatch(resetTextFiltersSom())} />,
    },
    dateDel: {
        elem: <FilterDatePickerEl />,
        title: 'Дата поставки',
        buttons: <ButtonsFilters onSubmit={onSubmit} onReset={() => store.dispatch(resetDateDel())} />,
    },
    completion: {
        elem: <FilterCompletionEl />,
        title: 'Обеспеченность в %',
        buttons: <ButtonsFilters onSubmit={onSubmit} onReset={() => store.dispatch(resetCompletion())} />,
    },
    delivered: {
        elem: <FilterDeliveredEl />,
        title: 'Поставлено МТР',
        buttons: <ButtonsFilters onSubmit={onSubmit} onReset={() => store.dispatch(resetDelivered())} />,
    },
}
