import { api } from '../api'
import { UserProfile } from '../users/users.def'
import { setProfile } from '../../store/slices/profileSlice'

export const profileApi = api.injectEndpoints({
    endpoints: (build) => ({
        getProfile: build.query<UserProfile, void>({
            query: () => '/profile/get',
            providesTags: ['Profile'],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const result = await queryFulfilled
                dispatch(setProfile(result.data))
            },
        }),
    }),
    overrideExisting: false,
})
