import { ColDef, ICellRendererParams, ICellEditorParams } from 'ag-grid-community'
import { IWorkMimFactDataItem } from '../api/api.types'
import { COL_DEF_TEMPLATE } from '../../WorkManagment/components/AgGridService/AgGridColumnDef.service'
import { NumberCellRenderer } from '../../WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer'
import { Typography } from '@mui/material'

export interface IWorkMimResourceFactTableColDef extends ColDef {
    field?: keyof IWorkMimFactDataItem
    cellRenderer?: (params: ICellRendererParams<IWorkMimFactDataItem, number | string, any>) => React.ReactNode
    cellEditor?: ((params: ICellEditorParams<IWorkMimFactDataItem, number | string, any>) => React.ReactNode) | string
    children?: ColDef<IWorkMimFactDataItem>[]
}

export const SNZMimTableColDef: IWorkMimResourceFactTableColDef[] = [
    {
        headerName: 'Наименование',
        field: 'name',
        headerClass: 'ag-cell-left',
        cellClass: 'ag-cell-left',
    },
    {
        headerName: 'Факт',
        field: 'fact',
        maxWidth: 200,
        editable: true,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('fact'),
        cellRenderer: (params) => {
            return params.data?.fact == null ? (
                <Typography variant="tooltip" color={'primary'}>
                    Введите факт
                </Typography>
            ) : (
                <NumberCellRenderer {...params} />
            )
        },
    },
]
