import { Box, Button as MuiButton, Typography } from '@mui/material';
import styled from 'styled-components';

import { StyledTooltip } from '@/shared/components/StyledTooltip';

export const Tooltip = styled(StyledTooltip).attrs({
    componentsProps: {
        tooltip: {
            sx: {
                backgroundColor: '#fff',
                borderRadius: '8px',
                padding: '10px',
                maxWidth: 'none',
                width: 'fit-content',
                minHeight: 'fit-content',
            },
        },
        arrow: {
            sx: {
                color: '#fff',
            },
        },
    },
    PopperProps: {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [30, 0], // Смещение на 10px вниз по оси Y
                },
            },
        ],
    },
    arrow: true,
    placement: 'top-end',
    leaveDelay: 500,
})``;

export const Marker = styled(Box)`
    color: #2b3648;
    padding-inline: 8px;
`;

export const Text = styled(Typography)`
    font-size: 14px;
    line-height: 140%;
    color: #2b3648;
`;

export const RedText = styled(Text)`
    font-weight: 500;
    color: #f46b6b;
`;

export const Separator = styled.div`
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const Button = styled(MuiButton)`
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    background-color: rgba(25, 118, 210, 0.08);
    color: #0044b4;
    box-shadow: none;
    min-height: 36px;

    &:hover {
        background-color: rgba(25, 118, 210, 0.08);
        color: #0044b4;
        box-shadow: none;
        filter: opacity(70%);
    }
`;
