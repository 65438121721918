import { Stack } from '@mui/material'
import React, { useCallback } from 'react'

import { UserDataProps } from './UserData.types'
import ContactForm from '../ContactForm/ContactForm'
import AccountForm from '../AccountForm/AccountForm'
import { generateRandomString } from '../../utils/generateRandomString'

function UserData({
    name = '',
    values,
    isEditUser,
    setFieldValue,
    initialValues,
    phoneConfirmed,
    emailConfirmed,
}: UserDataProps) {
    const setLoginGeneration = useCallback(() => {
        setFieldValue('login', generateRandomString({}))
    }, [setFieldValue])

    const setPasswordGeneration = useCallback(() => {
        setFieldValue(
            'password',
            generateRandomString({
                length: 8,
                lower: true,
                symbols: true,
                numbers: true,
                upper: true,
            })
        )
    }, [setFieldValue])

    const resetPasswordValue = useCallback(() => {
        if (values?.password === '') {
            setFieldValue('password', '')
        }
    }, [setFieldValue, values])

    const isShowConfirmPhone = values.phone === initialValues.phone && !!values.phone
    const isShowConfirmEmail = values.email === initialValues.email && !!values.email

    return (
        <Stack
            sx={{
                minWidth: '396px',
                maxWidth: '476px',
            }}
            spacing={2}
            flex={1}
            mb={2}
        >
            <AccountForm name={name} isEditUser={isEditUser} />
            <ContactForm
                isEditUser={isEditUser}
                isShowConfirmPhone={isShowConfirmPhone}
                isShowConfirmEmail={isShowConfirmEmail}
                isPhoneConfirmed={phoneConfirmed}
                isEmailConfirmed={emailConfirmed}
                handlePasswordGeneration={setPasswordGeneration}
                handleLoginGeneration={setLoginGeneration}
                onFocusPassword={resetPasswordValue}
            />
        </Stack>
    )
}

export default UserData
