import { Button } from '@mui/material'
import s from '../CheckboxPopoverSufficiency.module.scss'

export default function ButtonsFilters({ onSubmit, onReset }: { onSubmit: () => void; onReset: () => void }) {
    const buttonStyles = {
        height: '2.5rem',
        borderRadius: '6px',
    }

    return (
        <div className={s.buttons_wrapper}>
            <Button fullWidth sx={buttonStyles} onClick={onSubmit} variant="contained" color="success" type="submit">
                применить
            </Button>
            <Button fullWidth sx={buttonStyles} onClick={onReset} variant="contained" color="primary">
                сбросить
            </Button>
        </div>
    )
}
