import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export enum ganttViewEnum {
    gantt = 'gantt',
    gantt_fact = 'gantt_fact',
}

export interface ganttViewI {
    gantt: boolean
    gantt_fact: boolean
}

export type fixShow = 'fix' | 'show'

interface initialStateI {
    msgView: TChangeViewType
    cpgView: TChangeViewType
    middleVisible: boolean
    ganttView: ganttViewI
    gantt_relations: boolean
}

export type TChangeViewType = 'cash' | 'digger' | 'blank'

export enum cpgViewEnum {
    cash = 'cash',
    digger = 'digger',
    blank = 'blank',
}

export function newDate(monthTerm: number) {
    return new Date(new Date().getFullYear(), monthTerm)
}

const initialState: initialStateI = {
    cpgView: cpgViewEnum.blank,
    msgView: cpgViewEnum.blank,
    middleVisible: true,
    ganttView: {
        gantt: false,
        gantt_fact: false,
    },
    gantt_relations: false,
}

const cpgViewSlice = createSlice({
    name: 'cpgView',
    initialState,
    reducers: {
        setCpgView(state, action: PayloadAction<TChangeViewType>) {
            state.cpgView = action.payload
        },
        setMsgView(state, action: PayloadAction<TChangeViewType>) {
            state.msgView = action.payload
        },
        setMiddleVisible(state, action: PayloadAction<boolean>) {
            state.middleVisible = action.payload
        },
    },
})

export const { setCpgView, setMsgView, setMiddleVisible } = cpgViewSlice.actions

export default cpgViewSlice.reducer

export const cpgViewSelector = (state: RootState) => state.cpgView
