import { ProgressBarComponent } from './ProgressBar.styles'
import { IProgressBarProps } from './ProgressBar.types'

export function ProgressBar({ currentValue, targetValue }: IProgressBarProps) {

    function getProgressInPercent(currentValue: number, targetValue: number) {
        const maxValue = 100
        let percentValue = 0.1
        if (!currentValue) {
            return percentValue
        }
        if (currentValue >= targetValue) {
            percentValue = maxValue
        }
        else {
            percentValue = currentValue ? (currentValue / targetValue) * 100 : 0
        }
        return percentValue
    }

    return (
        <>
            <ProgressBarComponent
                currentValue={currentValue || 0}
                targetValue={targetValue || 0}
                variant="determinate"
                value={getProgressInPercent(currentValue, targetValue)}
            />
        </>
    )
}
