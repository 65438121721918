import { Add, ArrowBack } from '@mui/icons-material'
import { Button, CircularProgress, Divider, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { OverflowableTypographyWithTooltip } from '../../components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip'
import EmptyPlaceholder from '../../shared/components/EmptyPlaceholder/EmptyPlaceholder'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../shared/rolePermissions'
import { profileSelector } from '../../store/slices/profileSlice'
import { useTypedSelector } from '../../store/store'
import { FlexColumnWrapper, FlexRowWrapper } from '../NewExecutorView/components/components.styles'
import { req } from '../WorkManagment/api/api'
import { StyledBtn } from '../WorkManagment/components/components.styles'
import { StyledCard, StyledCardWrapper } from './BrigadesPage.styles'

export function BrigadesPage() {
    const { profile } = useTypedSelector(profileSelector)
    const navigate = useNavigate()
    const { projectId } = useParams()
    const [brigades, setBrigades] = useState<null | any[]>(null)
    const location = useLocation()

    const prevRoute = location.state?.prevRoute ?? `/ksg/${projectId}`

    useEffect(() => {
        req.get(`/projects/${projectId}/brigades/list`).then(({ data }) => {
            setBrigades(() => data.data)
        })
    }, [])

    const handleGoBack = () => navigate(prevRoute)

    if (!brigades) {
        return <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%' }} />
    }
    if (brigades?.length === 0) {
        return (
            <FlexColumnWrapper height={'100%'} p={1.5}>
                <FlexRowWrapper height={40}>
                    <StyledBtn
                        variant="text"
                        startIcon={<ArrowBack />}
                        sx={{ lineHeight: '20px' }}
                        onClick={handleGoBack}
                    >
                        Назад
                    </StyledBtn>
                </FlexRowWrapper>
                <FlexColumnWrapper height={'100%'} justifyContent={'center'}>
                    {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_BRIGADE') ? (
                        <EmptyPlaceholder
                            text={`Здравствуйте, ${profile?.firstName}, справочник бригад еще не заполнен. Давайте добавим Вашу первую бригаду`}
                            onClick={() => navigate('add')}
                            icon={<Add />}
                            buttonText="Добавить бригаду"
                        />
                    ) : (
                        <EmptyPlaceholder
                            text={`Здравствуйте, ${profile?.firstName}, справочник бригад еще не заполнен.`}
                        />
                    )}
                </FlexColumnWrapper>
            </FlexColumnWrapper>
        )
    }

    return (
        <FlexColumnWrapper p={1.5}>
            <FlexRowWrapper justifyContent={'space-between'}>
                <StyledBtn variant="text" startIcon={<ArrowBack />} sx={{ lineHeight: '20px' }} onClick={handleGoBack}>
                    Назад
                </StyledBtn>
                {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_BRIGADE') && (
                    <Button
                        variant="outlined"
                        startIcon={<Add />}
                        onClick={() => navigate('add', { state: { prevRoute } })}
                    >
                        Добавить
                    </Button>
                )}
            </FlexRowWrapper>
            <StyledCardWrapper>
                {brigades!.map((brigade) => {
                    return (
                        <StyledCard
                            onClick={() => navigate(`edit/${brigade?.id}`, { state: { prevRoute } })}
                            height={198}
                        >
                            <FlexRowWrapper justifyContent={'space-between'}>
                                <Typography color={'primary'} fontWeight={500}>
                                    {brigade?.name}
                                </Typography>
                                <FlexRowWrapper
                                    sx={{ width: 58, height: 24, borderRadius: 3, background: brigade.color }}
                                />
                            </FlexRowWrapper>
                            <Divider sx={{ height: 2, bgcolor: 'rgba(209, 216, 250, 1)' }}></Divider>
                            <OverflowableTypographyWithTooltip maxRows={5}>
                                {brigade?.description}
                            </OverflowableTypographyWithTooltip>
                        </StyledCard>
                    )
                })}
            </StyledCardWrapper>
        </FlexColumnWrapper>
    )
}
