import AddIcon from '@mui/icons-material/Add';
import { Button, CircularProgress } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { usePermissionsByRole } from '@/hooks/usePermissionsByRole';

import { SvgAnalytics, SvgWorker } from '@/shared/SVG/Svg';

import { toggleAddProject } from '@/store/slices/drawersSlice';

import { useGetData, useGetFilteredData, useTabs } from './Projects.model';
import styles from './Projects.module.scss';
import { projectsTerms } from './Projects.service';
import { ChartsLegend } from './component/ChartsLegend';
import { ProjectList } from './component/ProjectList';
import { ProjectsEmptyPlaceholder } from './component/ProjectsEmptyPlaceholder';
import { Tabs } from './component/Tabs';

export default function Projects({ charts }: { charts?: boolean }) {
    const { tab, onChange: onTabChange } = useTabs();

    const { hasFeatureAccess } = usePermissionsByRole();

    const { projects, isLoading } = useGetData(charts);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { filteredProjects, placeholder } = useGetFilteredData(projects, tab);

    const isEmptyTab = useCallback((status: projectsTerms) => !projects.some((v) => v.status === status), [projects]);

    if (isLoading) {
        return (
            <div className='progress'>
                <CircularProgress />
            </div>
        );
    }

    if (projects.length === 0) return <ProjectsEmptyPlaceholder />;

    return (
        <main className={styles.main}>
            <section className={styles.top_bar}>
                <Tabs
                    tab={tab}
                    onChange={onTabChange}
                    isEmptyTab={isEmptyTab}
                />

                <div className={styles.top_right}>
                    {charts && <ChartsLegend />}
                    <div className={styles.buttons_container}>
                        <button
                            className={`${styles.left_view_button}  ${!charts && styles.active_button_view}`}
                            onClick={() => navigate('/')}
                        >
                            <SvgWorker fill={charts ? '#0044b4' : '#fff'} />
                        </button>
                        <button
                            className={`${styles.right_view_button}  ${charts && styles.active_button_view}`}
                            onClick={() => navigate('/charts')}
                        >
                            <SvgAnalytics fill={charts ? '#fff' : '#0044b4'} />
                        </button>
                    </div>

                    {hasFeatureAccess('CREATE_PROJECT') && (
                        <Button
                            onClick={() => dispatch(toggleAddProject())}
                            variant='outlined'
                            sx={{
                                padding: '5px 10px',
                                borderRadius: '6px',
                                backgroundColor: '#dce5f1',
                                letterSpacing: 0.1,
                                '&:hover': {
                                    backgroundColor: '#d4e1f3',
                                },
                            }}
                            startIcon={
                                <AddIcon
                                    sx={{
                                        width: '1.75rem',
                                        height: '1.75rem',
                                    }}
                                />
                            }
                        >
                            добавить
                        </Button>
                    )}
                </div>
            </section>

            <ProjectList
                isCharts={Boolean(charts)}
                projects={filteredProjects}
                placeholder={placeholder}
            />
        </main>
    );
}
