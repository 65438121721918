import * as yup from "yup";

export const validationProfile = yup.object({
  lastName: yup
    .string()
    .max(60, "Слишком длинная фамилия")
    .trim()
    .required("common:errors.required"),
  firstName: yup
    .string()
    .max(60, "Слишком длинное имя")
    .trim()
    .required("common:errors.required"),
  middleName: yup.string().max(60, "Слишком длинное отчество").trim(),
  companyName: yup.string().max(256, "Слишком длинное название").trim(),
  position: yup
    .string()
    .max(128, "Слишком длинное название")
    .trim()
    .required("common:errors.required"),
  phone: yup.string().required("common:errors.required"),
  email: yup
    .string()
    .max(50, "Слишком длинный email")
    .email("common:errors.email")
    .required("common:errors.required"),
  login: yup.string().trim(),
  password: yup
    .string()
    .max(128, "Слишком длинный пароль")
    .min(4, "common:errors.min")
    .required("common:errors.required"),
  projects: yup.array(),
});

export const validationEditProfile = yup.object({
  lastName: yup
    .string()
    .max(60, "Слишком длинная фамилия")
    .trim()
    .required("common:errors.required"),
  firstName: yup
    .string()
    .max(60, "Слишком длинное имя")
    .trim()
    .required("common:errors.required"),
  middleName: yup.string().max(60, "Слишком длинное отчество").trim(),
  companyName: yup.string().max(256, "Слишком длинное название").trim(),
  position: yup
    .string()
    .max(128, "Слишком длинное название")
    .trim()
    .required("common:errors.required"),
  phone: yup.string().required("common:errors.required"),
  email: yup
    .string()
    .max(50, "Слишком длинный email")
    .email("common:errors.email")
    .required("common:errors.required"),
  login: yup.string().trim(),
  projects: yup.array(),
});
