import { api } from '../api'
import {
    modulesStatusesType,
    pickSyncModuleType,
    saveImportSettingsReqI,
    saveImportSettingsResI,
    startImportSyncResI,
} from './importingData.def'
import { setSyncModuleData } from '../../store/slices/drawersSlice'
import { store } from '../../store/store'

// /projects/{id}/import/modules/status

export const importingDataApi = api.injectEndpoints({
    endpoints: (build) => ({
        getModulesList: build.query<{ modules: pickSyncModuleType[] }, { id: number }>({
            query: ({ id }) => ({
                url: `/projects/${id}/import/works/status-list`,
                method: 'GET',
            }),
            providesTags: ['ImportModules'],
        }),

        getModulesStatuses: build.query<
            {
                data: {
                    module: pickSyncModuleType
                    status: modulesStatusesType
                }[]
            },
            { id: number }
        >({
            query: ({ id }) => ({
                url: `/projects/${id}/import/modules/status`,
                method: 'GET',
            }),
            providesTags: ['ImportModules'],
        }),

        getStatusImport: build.mutation<
            {
                lastImportSuccess: boolean
                lastImportTime: string
                module: pickSyncModuleType
                moduleProjectId: number
                moduleProjectName: string
            },
            {
                id: number
                body: {
                    module: pickSyncModuleType
                }
            }
        >({
            query: ({ id, body }) => ({
                url: `/projects/${id}/import/works/status-bind`,
                method: 'POST',
                body,
            }),
        }),

        startImportSynchronize: build.mutation<
            startImportSyncResI,
            { projectId: number; body: { module: pickSyncModuleType } }
        >({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/import/works/update`,
                body,
                method: 'POST',
            }),

            async onQueryStarted({ projectId, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedStatus } = await queryFulfilled
                    dispatch(
                        setSyncModuleData({
                            module: store.getState().drawers.syncModuleData?.module || 'PragmaDesign',
                            moduleProjectName: store.getState().drawers.syncModuleData?.moduleProjectName || '',
                            lastTime: updatedStatus.lastImportTime,
                        })
                    )
                } catch (e) {
                    console.error(e)
                }
            },
        }),

        saveImportSettings: build.mutation<saveImportSettingsResI, saveImportSettingsReqI>({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/import/works/bind`,
                body,
                method: 'POST',
            }),
            invalidatesTags: ['ImportModules'],
            async onQueryStarted({ projectId, body, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(
                        setSyncModuleData({
                            module: body.module,
                            moduleProjectName: body.moduleProjectName,
                            lastTime: null,
                        })
                    )
                } catch {}
            },
        }),

        unbindProject: build.mutation<
            unknown,
            {
                id: number
                body: {
                    module: pickSyncModuleType
                }
            }
        >({
            query: ({ id, body }) => ({
                url: `/projects/${id}/import/works/unbind`,
                body,
                method: 'POST',
            }),
            invalidatesTags: ['ImportModules'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useStartImportSynchronizeMutation,
    useSaveImportSettingsMutation,
    useGetModulesListQuery,
    useGetStatusImportMutation,
    useUnbindProjectMutation,
    useGetModulesStatusesQuery,
} = importingDataApi
