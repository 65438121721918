import { useCallback } from 'react';

import { toggleDeleteZeroValueFact } from '@/store/slices/drawersSlice';
import { useAppDispatch } from '@/store/store';

import { TShiftErrorsData } from '@/types/error.types';

export type TOpenDeleteZeroValueFactDialog = (
    data: TShiftErrorsData['zeroFactBlockers'],
    cancelCallback?: () => void
) => void;

export const useOpenDeleteZeroValueFactDialog = () => {
    const dispatch = useAppDispatch();

    const open: TOpenDeleteZeroValueFactDialog = useCallback((data, cancelCallback) => {
        dispatch(
            toggleDeleteZeroValueFact({
                open: true,
                data: data,
                cancelCallback,
            })
        );
    }, []);

    return open;
};
