import { Box, Tab, Tabs as MuiTabs } from "@mui/material";
import { ReactNode } from "react";
import { TabsEntities, TabsProps } from "./Tabs.types";

const Tabs = <T extends TabsEntities>({
    currentTab,
    onTabChange,
    tabsData,
}: TabsProps<T> & { children?: ReactNode }) => {
    return (
        <Box>
            <MuiTabs
                sx={{
                    // p: 1,
                    ".MuiButtonBase-root": {
                        fontWeight: 600,
                        fontSize: 14,
                    },
                }}
                value={currentTab}
                onChange={onTabChange}>
                {tabsData.map(({ body, disabled }) => (
                    <Tab
                        label={<>{body}</>}
                        value={body}
                        disabled={disabled}
                        key={`${body}`}
                    />
                ))}
            </MuiTabs>
        </Box>
    );
};

export default Tabs;
