import React, { PropsWithChildren } from 'react'
import { Container, Loader } from './UIEllipsisLoader.styles'

interface IProps extends PropsWithChildren {}

export const UIEllipsisLoader: React.FC<IProps> = ({ children }) => {
    return (
        <Container>
            {children}{' '}
            <Loader>
                <span>.</span>
                <span>.</span>
                <span>.</span>
            </Loader>
        </Container>
    )
}
