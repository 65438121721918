import { useEffect, useRef, useState } from 'react'
import s from './Synchronize.module.scss'
import RequestAccessEl from './components/RequestAccessEl'
import { SyncProjects } from './components/SyncProjects'

import { Button } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useGetModulesStatusesQuery } from '../../api/importingData/importingData.api'
import { pickSyncModuleType } from '../../api/importingData/importingData.def'
import { SvgCloudDone } from '../../shared/SVG/Svg'
import { toggleSynchronize } from '../../store/slices/drawersSlice'
import { useAppDispatch } from '../../store/store'
import { moduleCardPicture, widthAndColorByStatus } from './Synchronize.service'

export default function SynchronizeDrawer() {
    const [pickedView, setPickedView] = useState<pickSyncModuleType | null>(null)

    const { projectId } = useParams()
    const dispatch = useAppDispatch()
    const ref = useRef<HTMLDivElement | null>(null)

    const modulesList = useGetModulesStatusesQuery({
        id: Number(projectId),
    })

    useEffect(() => {
        function handleClickOutside(evt: any) {
            if (ref.current && !ref.current.contains(evt.target)) {
                setPickedView(null)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])

    const handleSendingReqBool = () => {
        return modulesList.data?.data.find((f) => f.module === pickedView)?.status === 'АКТИВНО'
    }

    const activeProjectsCount = modulesList.data?.data.filter((f) => f.status === 'АКТИВНО').length
    const accessedProjectsOnly =
        modulesList.data?.data.filter((f) => f.status === 'ДОСТУПНО').length === modulesList.data?.data.length

    const toShowRequestPage = (name: pickSyncModuleType) => {
        const project = modulesList.data?.data.find((f) => f.module === name)
        return project?.status === 'ДОСТУПНО'
    }

    const description = () => {
        const l = modulesList.data?.data.filter((f) => f.status === 'АКТИВНО').length

        if (activeProjectsCount) {
            return (
                <>
                    <p>У вас есть подключенные проекты ({l}).</p>
                    <span> Для проведения синхронизации </span>
                    <br />
                    <span>
                        нажмите на иконку <SvgCloudDone /> в КСГ
                    </span>
                </>
            )
        }
        if (accessedProjectsOnly) {
            return <>У вас нет подключенных проектов, отправьте запрос на подключение</>
        }
        return <>У вас нет привязанных проектов, выберите модуль и проект</>
    }

    return (
        <>
            <h4 className={s.sync_header}>Интеграции по api:</h4>
            <section className={s.synchronize_main}>
                <div className={s.pic_buttons}>
                    {modulesList.data?.data
                        .filter((f) => f.module !== 'PragmaOffer')
                        .map((moduleName) => (
                            <div
                                key={moduleName.module}
                                className={`${s.card_container} ${
                                    pickedView === moduleName.module ? s.blue_border : ''
                                }`}
                            >
                                <p
                                    style={{
                                        backgroundColor: widthAndColorByStatus[moduleName.status].color,
                                        width: widthAndColorByStatus[moduleName.status].width,
                                    }}
                                >
                                    {moduleName.status}
                                </p>
                                <img
                                    className="pointer"
                                    src={moduleCardPicture[moduleName.module]}
                                    alt={moduleName.module}
                                    onClick={() => setPickedView(moduleName.module)}
                                />
                            </div>
                        ))}
                </div>

                {pickedView !== null ? (
                    <div ref={ref} className={s.wrapper}>
                        {toShowRequestPage(pickedView) ? (
                            <RequestAccessEl moduleName={pickedView} />
                        ) : (
                            <SyncProjects moduleName={pickedView} sendingReqBool={handleSendingReqBool} />
                        )}
                    </div>
                ) : (
                    <div>
                        <div className={s.start_placeholder}>{description()}</div>
                        <Button
                            onClick={() => dispatch(toggleSynchronize())}
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            Закрыть
                        </Button>
                    </div>
                )}
            </section>
        </>
    )
}
