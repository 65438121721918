import { Drawer } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { FC, useState } from 'react';

import { AddLevelKsg } from '@/components/AddLevelKsg';

import useConfirmDialog from '@/hooks/useConfirmDialog';

import { Nullable } from '@/shared/def';

type TData = {
    id: Nullable<number>;
    index: number;
    level: Nullable<number>;
};

interface IProps {
    data: TData;
    isOpen: boolean;
    grid: Nullable<AgGridReact>;
    onClose: () => void;
}

export const KsgAddLevelDialog: FC<IProps> = ({ isOpen, data, grid, onClose }) => {
    const handleConfirm = (confirm: boolean) => {
        if (confirm) {
            onClose();
        }
    };
    const { ConfirmDialog, openConfirm } = useConfirmDialog({ handleConfirm });
    const [hasValues, setHasValues] = useState(false);

    const handleClose = (isCloseWithoutConfirm = false) => {
        if (isCloseWithoutConfirm) {
            onClose();
            return;
        }

        if (hasValues) {
            openConfirm();
            return;
        }

        onClose();
    };

    return (
        <>
            <ConfirmDialog />
            <Drawer
                anchor='right'
                open={isOpen}
                onClose={() => handleClose()}
            >
                <AddLevelKsg
                    grid={grid}
                    addLevelData={data}
                    onClose={handleClose}
                    setHasValues={setHasValues}
                />
            </Drawer>
        </>
    );
};
