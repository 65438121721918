import { FlexColumnWrapper, FlexRowWrapper } from '../../NewExecutorView/components/components.styles'
import { IChooseVerFactPermsProps } from './ChooseVerFactPerms.def'
import { VER_FACT_TERMS } from './ChooseVerFactPerms.service'
import { StyledBthWithBoolState } from './ChooseVerFactPerms.styles'
import { useTranslation } from 'react-i18next'
import { theme } from '../../../styles/theme'
import { Divider, Typography } from '@mui/material'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { useFormikContext } from 'formik'
import { UserFormData } from '../components/UserForm/UserForm.types'
import { TVerFactPermTerms, UserProjectAccessInfo } from '../../../api/users/users.def'

export function ChooseVerFactPerms({ project }: IChooseVerFactPermsProps) {
    const { t } = useTranslation('user')
    const { values: formValues, setFieldValue } = useFormikContext<UserFormData>()

    function switchTerm(term: TVerFactPermTerms) {
        setFieldValue(
            'projects',
            formValues.projects?.map((_project) => {
                if (project.id === _project.id) {
                    return {
                        ..._project,
                        verFactPerms: {
                            ...(_project.verFactPerms ||
                                (Object.fromEntries(
                                    VER_FACT_TERMS.map((term: TVerFactPermTerms) => [term, false])
                                ) as UserProjectAccessInfo['verFactPerms'])),
                            [term]: _project.verFactPerms ? !_project.verFactPerms[term] : true,
                        },
                    }
                }
                return _project
            })
        )
    }

    return (
        <FlexColumnWrapper gap={1}>
            <Divider />
            <FlexRowWrapper height={40} gap={1}>
                <LockOpenIcon style={{ fontSize: 20, color: theme.palette.secondary.dark }} />
                <Typography variant="subtitle1" color={theme.palette.secondary.dark} fontWeight={500} component="span">
                    Заполнение данных о верификационный факте:
                </Typography>
            </FlexRowWrapper>
            <FlexRowWrapper width={'100%'}>
                {VER_FACT_TERMS.map((term) => {
                    return (
                        <StyledBthWithBoolState
                            onClick={() => switchTerm(term)}
                            on={project.verFactPerms && project.verFactPerms[term]}
                        >
                            {t('verFactPerms.' + term)}
                        </StyledBthWithBoolState>
                    )
                })}
            </FlexRowWrapper>
        </FlexColumnWrapper>
    )
}
