import { req } from "./api"
import { IRequestResourceParams } from "./api.types"

export class ResourceAPI {
    static baseURL: string = `/projects`

    static async getList({ resourceType, projectID, workID }: IRequestResourceParams) {
        return await req.get(this.baseURL + `/${projectID}/works/${workID}/cabinet/resources/${resourceType}/list`)
            .then(({ data }) => {
                return data
            })
            .catch(e => console.log(e))
    }

    static async addNewItem({ resourceType, projectID, workID, body }: IRequestResourceParams) {
        return await req.post(this.baseURL + `/${projectID}/works/${workID}/cabinet/resources/${resourceType}/add`, body)
            .then(({ data }) => {
                return data
            })
            .catch(e => console.log(e))
    }

    static async updateItem({ resourceType, projectID, workID, resourceID, body }: IRequestResourceParams) {
        return await req.post(this.baseURL + `/${projectID}/works/${workID}/cabinet/resources/${resourceType}/${resourceID}/update`, body)
            .then(({ data }) => {
                return data
            })
            .catch(e => console.log(e))
    }

    static async deleteItem({ resourceType, projectID, workID, resourceID }: IRequestResourceParams) {
        return await req.delete(this.baseURL + `/${projectID}/works/${workID}/cabinet/resources/${resourceType}/${resourceID}/delete`)
            .then(({ data }) => {
                return data
            })
            .catch(e => console.log(e))
    }
}