import { Button } from '@mui/material';
import { IoMdTrash } from '@react-icons/all-files/io/IoMdTrash';
import { ColDef, ColumnState } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Dispatch, FC, SetStateAction } from 'react';
import { Control, Controller } from 'react-hook-form';

import {
    StyledDrawer,
    StyledDrawerContent,
    StyledDrawerInput,
    StyledDrawerTitle,
} from '@/components/StyledDrawer/StyledDrawer.styles';

import { FlexColumnWrapper, FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';

import { Nullable } from '@/shared/def';

import { theme } from '@/styles/theme';

import { TMode } from '../../ConfigureAgGridColDefTemplate';
import { StyledColumnSettingsRow } from '../../StyledColumnSettingsRow/StyledColumnSettingsRow';

type TControl = Control<
    {
        name: string;
    },
    any
>;

interface IProps {
    mode: Nullable<TMode>;
    isOpen: boolean;
    control: TControl;
    columns: (ColDef<any> | ColumnState | undefined)[];
    isSaveButtonDisabled: boolean;
    agGrid: AgGridReact<any> | null;
    onClose: () => void;
    onSave: () => void;
    onDelete: () => void;
    onIsFormChanged: Dispatch<SetStateAction<boolean>>;
}

export const UIAddEditDrawer: FC<IProps> = ({
    mode,
    isOpen,
    isSaveButtonDisabled,
    control,
    columns,
    agGrid,
    onSave,
    onDelete,
    onClose,
    onIsFormChanged,
}) => {
    return (
        <StyledDrawer
            open={isOpen}
            onClose={onClose}
            sx={{ zIndex: 999999 }}
        >
            <StyledDrawerTitle>{mode === 'edit' ? 'Редактировать' : 'Создать'} шаблон таблицы</StyledDrawerTitle>
            <FlexRowWrapper p={2.5}>
                <Controller
                    name='name'
                    control={control}
                    defaultValue=''
                    rules={{
                        required: {
                            value: true,
                            message: 'Обязательное поле',
                        },
                        maxLength: {
                            value: 96,
                            message: 'Не более 96 символов',
                        },
                    }}
                    render={({ field, fieldState }) => {
                        return (
                            <StyledDrawerInput
                                placeholder='Введите наименование шаблона'
                                {...field}
                                value={field.value}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                            />
                        );
                    }}
                />
            </FlexRowWrapper>
            <StyledDrawerContent
                sx={{ padding: `${theme.spacing(0, 2.5)} !important`, gap: `${theme.spacing(1)} !important` }}
            >
                {columns.map((colDef) => {
                    return (
                        <StyledColumnSettingsRow
                            key={colDef?.colId}
                            colDef={colDef as ColDef}
                            AgGrid={agGrid}
                            setIsFormChanged={onIsFormChanged}
                        />
                    );
                })}
            </StyledDrawerContent>
            <FlexColumnWrapper
                gap={2}
                p={2.5}
            >
                <FlexRowWrapper bgcolor={'white'}>
                    <Button
                        variant='contained'
                        color='success'
                        fullWidth
                        onClick={onSave}
                        disabled={isSaveButtonDisabled}
                    >
                        Сохранить
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        fullWidth
                        onClick={onClose}
                    >
                        Отмена
                    </Button>
                </FlexRowWrapper>
                {mode === 'edit' && (
                    <Button
                        fullWidth
                        sx={{
                            color: '#f46b6b',
                        }}
                        color='warning'
                        startIcon={<IoMdTrash />}
                        onClick={onDelete}
                    >
                        Удалить шаблон
                    </Button>
                )}
            </FlexColumnWrapper>
        </StyledDrawer>
    );
};
