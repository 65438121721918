import { formatNumberWithComma, isNumber } from '../../../../shared/utils'
import { theme } from '../../../../styles/theme'
import { IRowProps, ITooltipContentProps, IVolumeTotalNotificatorProps } from './VolumeTotalNotificator.def'
import { Circle, StyledCircularProgress, StyledContainer, StyledTypography } from './VolumeTotalNotificator.styles'
import { Stack, Tooltip } from '@mui/material'
import { useState } from 'react'

const Row: React.FC<IRowProps> = ({ value, percent, label, backgroundColor }) => {
    return (
        <Stack flexWrap={'wrap'} gap={1} justifyContent={'space-between'} alignItems={'center'} direction="row">
            <Stack direction="row" alignItems={'center'} gap={1}>
                <Circle backgroundColor={backgroundColor} />
                <StyledTypography>{label}</StyledTypography>
                <StyledTypography>{formatNumberWithComma(value)}</StyledTypography>
            </Stack>
            {isNumber(percent) && <StyledTypography>{percent}%</StyledTypography>}
        </Stack>
    )
}

const TooltipContent: React.FC<ITooltipContentProps> = ({
    volumePlan,
    volumePlanPercent,
    volumeBasePlan,
    volumeBasePlanPercent,
    volumeBasePlanLabel = 'БП:',
    volumePlanLabel = 'ОП:',
}) => {
    return (
        <Stack gap={0.5}>
            {isNumber(volumeBasePlan) && (
                <Row value={volumeBasePlan} percent={volumeBasePlanPercent} label={volumeBasePlanLabel} />
            )}
            {isNumber(volumePlan) && (
                <Row value={volumePlan} percent={volumePlanPercent} label={volumePlanLabel} backgroundColor="#14B8A6" />
            )}
        </Stack>
    )
}

export const VolumeTotalNotificator: React.FC<IVolumeTotalNotificatorProps> = ({
    data,
    hideFields = [],
    options = {},
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = (value: boolean) => () => setIsOpen(value)

    const renderVolumeBasePlan = () => {
        if (hideFields?.includes('volumeBasePlan')) return null

        return (
            <>
                <StyledCircularProgress
                    colorValue={theme.palette.legends.red}
                    variant="determinate"
                    value={100}
                    size={24}
                    thickness={6}
                />
                {isNumber(data.volumeBasePlanPercent) && (
                    <StyledCircularProgress
                        variant="determinate"
                        value={data.volumeBasePlanPercent!}
                        colorValue="#6D9ADC"
                        thickness={6}
                        size={24}
                    />
                )}
            </>
        )
    }

    const renderVolumePlan = () => {
        if (hideFields?.includes('volumePlan')) return null

        return (
            <>
                <StyledCircularProgress
                    colorValue={theme.palette.legends.red}
                    variant="determinate"
                    value={100}
                    thickness={10}
                    size={14}
                />
                {isNumber(data.volumePlanPercent) && (
                    <StyledCircularProgress
                        variant="determinate"
                        value={data.volumePlanPercent!}
                        colorValue="#14B8A6"
                        thickness={10}
                        size={14}
                    />
                )}
            </>
        )
    }

    return (
        <Tooltip
            onOpen={toggle(true)}
            onClose={toggle(false)}
            componentsProps={{
                tooltip: {
                    sx: {
                        boxShadow: '0 1px 2px 0 rgba(16, 24, 40, 0.06), 0 1px 3px 0 rgba(16, 24, 40, 0.1)',
                        opacity: 0.9,
                        padding: '6px',
                        backgroundColor: '#7890b2',
                        minWidth: '100px',
                        width: 'fit-content',
                        maxWidth: '500px',
                        marginLeft: '0px !important',
                    },
                },
            }}
            placement="right-start"
            title={<TooltipContent {...data} {...options} />}
        >
            <StyledContainer isOpen={isOpen}>
                {renderVolumeBasePlan()}
                {renderVolumePlan()}
            </StyledContainer>
        </Tooltip>
    )
}
