import styled from 'styled-components'
import { FlexColumnWrapper } from '../NewExecutorView/components/components.styles'
import { Backdrop, Box, BoxProps } from '@mui/material'
import { theme } from '../../styles/theme'

export const StyledMSGPrintWrapper = styled(FlexColumnWrapper).attrs({
    id: 'print_wrapper',
    gap: 2,
    p: 2,
})<BoxProps & { openBackdrop: boolean }>`
    height: calc(100% + 60px);
    width: 100%;
    justify-content: flex-start;
    position: relative;
    top: -70px;
    z-index: 999;
    background-color: white;
    overflow: ${({ openBackdrop }) => (openBackdrop ? 'hidden' : 'visible')};
`

export const StyledMSGPrintBackdrop = styled(Backdrop)`
    color: '#FFFFFF';
    position: 'fixed';
    margin: ${({ theme }) => theme.spacing('60px', 0, 2, 2)};
    top: 0;
    z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`

export const StyledAgGridWrapper = styled(Box)`
    height: 100%;
    width: 100%;
    padding-right: 0.5rem;
    position: relative;
    overflow: auto;
`
