import './styles.scss'
import { CircularProgress, Typography } from '@mui/material'
import { FlexColumnWrapper, FlexRowWrapper } from '../NewExecutorView/components/components.styles'
import { MSGPrintAgGrid } from './MSGPrintAgGrid'
import { StyledBtn } from '../WorkManagment/components/components.styles'
import { Print } from '@mui/icons-material'
import { memo, useState } from 'react'
import { StyledAgGridWrapper, StyledMSGPrintBackdrop, StyledMSGPrintWrapper } from './MSGPrint.styles'

const MemoizedCircularProgress = memo(
    () => <CircularProgress sx={{ color: 'white' }} />,
    () => true
)

export function MSGPrint() {
    const [openBackdrop, setOpenBackdrop] = useState(true)
    const [progress, setProgress] = useState({
        current: 0,
        total: 0,
    })

    return (
        <StyledMSGPrintWrapper openBackdrop={openBackdrop}>
            <StyledMSGPrintBackdrop open={openBackdrop}>
                <FlexColumnWrapper alignItems={'center'} justifyContent={'center'}>
                    <Typography color={'white'}>
                        Идет генерация таблицы для печати {progress.current}/{progress.total}
                    </Typography>
                    <MemoizedCircularProgress />
                </FlexColumnWrapper>
            </StyledMSGPrintBackdrop>
            <FlexRowWrapper height={40}>
                <StyledBtn
                    variant="contained"
                    startIcon={<Print />}
                    onClick={() => window.print()}
                    disabled={openBackdrop}
                >
                    Печатать
                </StyledBtn>
            </FlexRowWrapper>

            <StyledAgGridWrapper className="ag-theme-alpine">
                <MSGPrintAgGrid setOpenBackdrop={setOpenBackdrop} setProgress={setProgress} />
            </StyledAgGridWrapper>
        </StyledMSGPrintWrapper>
    )
}
