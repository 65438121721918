import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import AvatarFieldForm from '../../AvatarFieldForm'
import FieldForm from '../FieldForm'
import { AccountFormProps } from './AccountForm.types'
import { useTranslation } from 'react-i18next'

function AccountForm({ name, isEditUser }: AccountFormProps) {
    const { t } = useTranslation('user')

    return (
        <Box>
            <Stack direction="row" spacing={2.5} mb={2.5}>
                <AvatarFieldForm
                    name="avatar"
                    needCrop
                    isEditUser={isEditUser}
                    placeholder={
                        <Typography alignItems="center" textAlign="center" px={2} variant="body2">
                            {t('common:buttons.avatar')}
                        </Typography>
                    }
                />
                <Stack flex={1} justifyContent="space-between">
                    <FieldForm
                        version="profile"
                        name="lastName"
                        title={t('lastName')}
                        placeholder={t('placeholders.lastName')}
                        disabled={isEditUser}
                    />
                    <FieldForm
                        version="profile"
                        name="firstName"
                        title={t('firstName')}
                        placeholder={t('placeholders.firstName')}
                        disabled={isEditUser}
                    />
                    <FieldForm
                        version="profile"
                        name="middleName"
                        title={t('middleName')}
                        placeholder={t('placeholders.notRequired')}
                        disabled={isEditUser}
                    />
                </Stack>
            </Stack>
            <Stack spacing={2}>
                <FieldForm
                    version="profile"
                    name="companyName"
                    title={t('companyName')}
                    placeholder="Укажите полное название компании пользователя"
                    disabled={isEditUser}
                />
                <FieldForm
                    version="profile"
                    name="position"
                    title={t('position')}
                    placeholder={t('placeholders.position')}
                    disabled={isEditUser}
                />
            </Stack>
        </Box>
    )
}

export default AccountForm
