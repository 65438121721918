import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { api } from '../../api/api'
import { AuthEndpoints, LoginResponse } from '../../api/auth/auth.def'

let initialState: Partial<LoginResponse> = {
    refreshToken: '',
    token: '',
}

if (localStorage.getItem('auth')) {
    initialState = JSON.parse(localStorage.getItem('auth') || '{}')
}

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<LoginResponse>) => {
            const { token, refreshToken } = action.payload
            state.token = token
            state.refreshToken = refreshToken
            localStorage.setItem(
                'auth',
                JSON.stringify({
                    token,
                    refreshToken,
                })
            )
        },
        onLogout: (state) => {
            localStorage.removeItem('auth')
            state.token = ''
            state.refreshToken = ''
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (props) => !!(api.endpoints as AuthEndpoints).loginByToken?.matchFulfilled(props),
            (state, action: PayloadAction<LoginResponse>) => {
                const { refreshToken, token } = action.payload
                if (token) {
                    state.token = token
                    state.refreshToken = refreshToken
                    localStorage.setItem(
                        'auth',
                        JSON.stringify({
                            token,
                            refreshToken,
                        })
                    )
                }
            }
        )
    },
})

export default slice.reducer

export const { setCredentials, onLogout } = slice.actions
export const isAuthSelector = (state: RootState) => !!state.auth.token
export const authSelector = (state: RootState) => state.auth
