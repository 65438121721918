import FormControl from '@mui/material/FormControl'
import { IListProps, IPlaceholderProps } from './SyncList.def'
import { StyledContainer, StyledPlaceholder, StyledRadioGroup } from './SyncList.styles'
import { Item } from './SyncListItem'

const Placeholder: React.FC<IPlaceholderProps> = ({ height }) => {
    return (
        <StyledContainer height={height}>
            <StyledPlaceholder>
                <p> По вашему запросу ничего не найдено</p>
            </StyledPlaceholder>
        </StyledContainer>
    )
}

export const List: React.FC<IListProps> = ({
    projects,
    moduleProjectId,
    handleChangeProject,
    project,
    handleUnbind,
    height = 484,
}) => {
    const handleChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
        handleChangeProject(value)
    }

    if (projects.data.length === 0) return <Placeholder height={height} />

    return (
        <StyledContainer height={height}>
            <FormControl fullWidth>
                <StyledRadioGroup name="controlled-radio-buttons-group" value={project} onChange={handleChange}>
                    {projects.data.map((project) => (
                        <Item
                            key={project.id}
                            project={project}
                            hasMatchingProjectId={moduleProjectId === project.id}
                            onUnbind={handleUnbind}
                        />
                    ))}
                </StyledRadioGroup>
            </FormControl>
        </StyledContainer>
    )
}
