import { Info } from '@mui/icons-material';
import { Typography } from '@mui/material';
import styled from 'styled-components';

import { ResetButton } from '@/components/ui/UIButton';

export const Text = styled(Typography)`
    font-size: inherit;
    flex: 1;
`;

type TInfoButtonProps = {
    positionType?: 'absolute' | 'relative';
};

export const InfoButton = styled(ResetButton).attrs<TInfoButtonProps>(({ positionType = 'relative' }) => ({
    positionType,
}))<TInfoButtonProps>`
    ${({ positionType }) =>
        positionType === 'absolute'
            ? {
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  right: '10px',
              }
            : {}}
`;

export const Icon = styled(Info)`
    color: #668fd2;
    font-size: 20px;
`;
