import { isNumber } from '../../../shared/utils'
import { theme } from '../../../styles/theme'
import { IWorkFactData, IWorkWithResourcesFactList } from '../api/api.types'

const calculateVolumeDoneDeviation = (volumeDonePlan?: number | null, volumeDoneFact?: number | null) => {
    if (!isNumber(volumeDonePlan) || !isNumber(volumeDoneFact)) return null
    return volumeDoneFact - volumeDonePlan
}

export const mapToWorkFactData = (listItem: IWorkWithResourcesFactList): IWorkFactData => ({
    ...listItem.work,
    existResources: listItem.existResources,
    volumeDoneDeviation: calculateVolumeDoneDeviation(listItem?.work?.volumeDonePlan, listItem?.work?.volumeDoneFact),
})

export const getColorByVolumeDoneDeviation = (value: number | null) => {
    if (!isNumber(value)) return null

    if (Math.sign(value) === -1) return theme.palette.error.main
    return theme.palette.success.main
}
