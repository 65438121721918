import { Tab } from "@mui/material"
import { TextTabs } from "../../../WorkManagment/components/components.styles"
import { useSearchParams } from "react-router-dom"

export function SwitchListTypeTabs() {

    const [searchParams, setSearchParams] = useSearchParams()

    return (
        <TextTabs
            value={searchParams.get('listType')}
            onChange={(e, newValue) =>
                setSearchParams((params) => {
                    params.set('listType', newValue)
                    return params
                })
            }
        >
            <Tab label={'Ожидают отправки'} value="waitList" />
            <Tab label={'Факт отправлен'} value="doneList" />
        </TextTabs>
    )

}