import axios, { AxiosError, AxiosRequestConfig, AxiosStatic, AxiosPromise, CancelToken } from "axios";
import qs from "qs";
import { currentCompanyName, deployLinks } from "../../../shared/config/config";


export interface IQueryObj {
    [key: string]: string | number | undefined
}

export function parseObjectToQueryString(query: any) {
    if (!query) {
        return ''
    }
    else {
        return "?" + qs.stringify(query, { arrayFormat: 'repeat' })
    }
}

interface ITokenData {
    refreshDurationMs: number,
    refreshToken: string,
    token: string,
    tokenDurationMs: number

}

const shouldIntercept = (error: AxiosError) => {
    try {
        return error?.response?.status === 401
    } catch (e) {
        return false;
    }
};

const setTokenData = (tokenData = {} as ITokenData, axiosClient: any) => {
    localStorage.setItem('auth', JSON.stringify(tokenData))
};

const handleTokenRefresh = () => {
    const refreshToken = JSON.parse(localStorage.getItem('auth')!).refreshToken
    return new Promise((resolve, reject) => {
        req.post('/auth/refresh', { token: refreshToken })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            })
    });
};

const attachTokenToRequest = (request: AxiosRequestConfig, token: string) => {
    if (request.headers) request.headers['Authorization'] = 'Bearer ' + token;

};

function applyAppTokenRefreshInterceptor(axiosClient: AxiosStatic, customOptions = {}) {
    let isRefreshing = false;
    let failedQueue: { resolve: (value: unknown) => void; reject: (reason?: any) => void }[] = [];

    const options = {
        attachTokenToRequest,
        handleTokenRefresh,
        setTokenData,
        shouldIntercept,
        ...customOptions,
    };
    const processQueue = (error: AxiosError | null, token = null as string | null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        failedQueue = [];
    };

    const interceptor = (error: any) => {
        if (!options.shouldIntercept(error)) {
            if (error.config.url.includes('/auth/refresh')) {
                localStorage.setItem('auth', "{}")
                window.location.replace(`${deployLinks.core}?source=finance${currentCompanyName ? `&company=${currentCompanyName}` : ''}`)
                isRefreshing = false
                return
            }
            else {
                console.log(error.message)
            }
            return Promise.reject(error);
        }

        if (error.config._retry || error.config._queued) {
            return Promise.reject(error);
        }

        const originalRequest = error.config;
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject })
            }).then((token: unknown) => {
                originalRequest._queued = true;
                options.attachTokenToRequest(originalRequest, token as string);
                return axiosClient.request(originalRequest);
            }).catch(err => {
                return Promise.reject(error); // Игнорирует перехватчик, и возвращает ошибку с кодом != 401 дальше в промис
            })
        }

        originalRequest._retry = true;
        isRefreshing = true;
        return new Promise((resolve, reject) => {
            options.handleTokenRefresh.call(options.handleTokenRefresh)
                .then((tokenData) => {
                    options.setTokenData(tokenData as ITokenData, axiosClient);
                    options.attachTokenToRequest(originalRequest, (tokenData as ITokenData).token);
                    processQueue(null, (tokenData as ITokenData).token);
                    resolve(axiosClient.request(originalRequest));
                })
                .catch((err) => {

                    processQueue(err, null);
                    reject(err);
                })
                .finally(() => {
                    isRefreshing = false;
                })
        });
    };

    axiosClient.interceptors.response.use(undefined, interceptor);
};

applyAppTokenRefreshInterceptor(axios)

export const req = new class {
    private getHeaders() {
        const token = JSON.parse(localStorage!.getItem('auth')!).token
        return token ? { 'Authorization': 'Bearer ' + token } : undefined
    }
    get(url: string, params?: Object, token?: { Authorization: string }): AxiosPromise {
        return axios(
            deployLinks.server + url, {
            params,
            headers: token ?? this.getHeaders(), 
            // paramsSerializer: {
            //     encode: params => {
            //         return qs.stringify(params, { arrayFormat: 'repeat' })
            //     }
            // },
        }
        )
    }

    post(url: string,
        body?: Object,
        params?: { [key: string]: string | number },
        cancelToken?: CancelToken,
        token?: { Authorization: string }): AxiosPromise {
        let URLWithParams = url + parseObjectToQueryString(params)
        return axios.post(
            deployLinks.server + URLWithParams, body, { headers: token ?? this.getHeaders(), cancelToken },
        )
    }

    patch(url: string,
        body?: Object,
        params?: { [key: string]: string | number },
        cancelToken?: CancelToken,
        token?: { Authorization: string }): AxiosPromise {
        let URLWithParams = url + parseObjectToQueryString(params)
        return axios.patch(
            deployLinks.server + URLWithParams, body, { headers: token ?? this.getHeaders(), cancelToken },
        )
    }

    put(url: string, params?: Object, token?: { Authorization: string }): AxiosPromise {
        return axios.put(
            deployLinks.server + url, params, { headers: token ?? this.getHeaders() }
        )
    }

    delete(url: string, params?: Object, token?: { Authorization: string }): AxiosPromise {
        return axios.delete(
            deployLinks.server + url, { params, headers: token ?? this.getHeaders() }
        )
    }
}()


// export function successHandler(message: string) {
//     ReactDOM.render(<Snack message={message} randomKey={Math.random()} severity="success" />, document.getElementById('snack'))
// }

// export function errorHandler(error: AxiosError | any) {
//     console.log('errorHandler: ', error);
//     ReactDOM.render(<Snack error={error.response} randomKey={Math.random()} severity="error" />, document.getElementById('snack'))
// }
// export function errorUploadHandler(error: AxiosError | any, type?: string) {
//     console.log('errorUploadHandler: ', error);
//     ReactDOM.render(<Snack error={error} randomKey={Math.random()} severity="error" type={type} />, document.getElementById('snack'))
// }
// function dispatch(arg0: any) {
//     throw new Error('Function not implemented.')
// }

