import React from 'react'
import { FormControl, Stack, Typography } from '@mui/material'
import { theme } from '../../../../styles/theme'
import { StyledSelectMenuItem } from './styles'
import { userRolesArray, UserRolesRuToEn } from '../../user'
import FieldForm from '../FieldForm'
import { UserFormData } from '../UserForm/UserForm.types'
import { useFormikContext } from 'formik'

function UserRoleSelection() {
    const { setFieldValue } = useFormikContext<UserFormData>()

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1" color={theme.palette.text.dark}>
                Роль пользователя:
            </Typography>

            <FormControl style={{ width: '100%', maxWidth: '276px' }}>
                <FieldForm
                    version="select"
                    name="role"
                    onChange={(e) => {
                        setFieldValue('role', e.target.value)
                        setFieldValue('projects', [])
                    }}
                >
                    {userRolesArray.map((role) => (
                        <StyledSelectMenuItem value={UserRolesRuToEn[role]} key={role}>
                            {role}
                        </StyledSelectMenuItem>
                    ))}
                </FieldForm>
            </FormControl>
        </Stack>
    )
}

export default UserRoleSelection
