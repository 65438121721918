import { ICellRendererParams, IHeaderParams } from 'ag-grid-community';
import React from 'react';

import { updateChartI, Work } from '@/api/ksg/ksg.def';
import { KsgPeopleI } from '@/api/ksgPeople/ksgPeople.def';

import { monthsList } from '@/shared/constants/date';

import { ISubmitKSGPeopleData } from './AgGridKSGPeople.colDef';

export const getHeadersMonthsPeq = (
    monthsArr: updateChartI[] | undefined,
    callback: (data: ICellRendererParams<KsgPeopleI, any, any>, index: number) => React.ReactElement
) => {
    return (
        monthsArr?.reduce((acc: any[], month, idx) => {
            const header = acc.find((f) => f.headerName === month.year);
            if (header) {
                header.children.push({
                    field: 'monthlyCharts',
                    minWidth: 120,
                    maxWidth: 120,
                    headerName: monthsList[month.month - 1],
                    headerComponent: (data: IHeaderParams<Work, any>) => (
                        <div
                            style={{
                                textAlign: 'center',
                                width: '100%',
                                borderRadius: '0.5rem',
                                padding: '0.5rem 0',
                                backgroundColor:
                                    month.month - 1 === new Date().getMonth() && month.year === new Date().getFullYear()
                                        ? '#3e5a81'
                                        : undefined,
                            }}
                        >
                            {data.displayName}
                        </div>
                    ),
                    suppressMovable: true,
                    cellRenderer: (data: ICellRendererParams<KsgPeopleI, any, any>) => callback(data, idx),
                });
            } else {
                acc.push({
                    headerName: month.year,
                    children: [
                        {
                            field: 'monthlyCharts',
                            minWidth: 120,
                            maxWidth: 120,
                            headerName: monthsList[month.month - 1],
                            headerComponent: (data: IHeaderParams<Work, any>) => (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        width: '100%',
                                        borderRadius: '0.5rem',
                                        padding: '0.5rem 0',
                                        backgroundColor:
                                            month.month - 1 === new Date().getMonth() &&
                                            month.year === new Date().getFullYear()
                                                ? '#3e5a81'
                                                : undefined,
                                    }}
                                >
                                    {data.displayName}
                                </div>
                            ),
                            suppressMovable: true,
                            cellRenderer: (data: ICellRendererParams<KsgPeopleI, any, any>) => callback(data, idx),
                        },
                    ],
                });
            }
            return acc;
        }, []) || []
    );
};

export const emptyWorkKsgPeq = (monthsCount: number) => {
    return {
        id: 4353246543,
        name: '',
        numOrder: 643345634,
        isMain: false,
        monthlyCharts: Array(monthsCount)
            .fill(0)
            .map(() => {
                return {
                    year: 2022,
                    month: 1,
                    plan: null,
                    fact: null,
                };
            }),
        avgCount: 1,
        avgCount90: 0,
        currentPlan: 0,
        currentFact: 0,
        currentPercent: null,
        totalPlan: 0,
        totalFact: 0,
        totalPercent: 0,
    };
};

export function onSubmitSuccess({ event, enqueueSnackbar, translate }: ISubmitKSGPeopleData) {
    enqueueSnackbar(
        `Значение поля ${translate && translate(event?.colDef?.field || '')} успешно изменено c ${event.oldValue} на ${
            event.newValue
        }`,
        {
            variant: 'success',
        }
    );
}

export function onSubmitRejected({ enqueueSnackbar }: ISubmitKSGPeopleData) {
    enqueueSnackbar('Ошибка. Обратитесь к администратору', {
        variant: 'error',
    });
}

export function submitCellData(args: ISubmitKSGPeopleData) {
    const { projectID, event, request } = args;

    request
        .then(({ data }) => {
            event.api.applyTransaction({ update: [data.data] });
            onSubmitSuccess(args);
        })
        .catch((e) => onSubmitRejected(args));
}
