import { Work } from "../../../api/ksg/ksg.def"
import { req } from "./api"
import { IKsgWorkResponse, IWorkRequestParams } from "./api.types"

export class KsgAPI {
    static baseURL: string = `/projects`

    static async get({ projectID, workID }: IWorkRequestParams): Promise<IKsgWorkResponse> {
        return await req.get(this.baseURL + `/${projectID}/works/${workID}/get`)
            .then(({ data }) => {
                return data
            })
            .catch(e => console.log(e))
    }

}