import { NumericFormat } from 'react-number-format';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

export const Label = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #5c6e8c;
`;

export const GridContainer = styled.div`
    border: 1px solid rgba(43, 54, 72, 0.12);
    border-radius: 8px;
    padding: 6px;
    width: 100%;
    min-height: 118px;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
`;

export const RowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
    flex-wrap: nowrap;
`;

export const LabelCell = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #5c6e8c;
    flex-grow: 0;
`;

export const CellContainer = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    overflow: hidden;
`;

export const CellValue = styled(NumericFormat).attrs({
    displayType: 'text',
    thousandSeparator: ' ',
    allowedDecimalSeparators: [',', '.'],
    decimalSeparator: ',',
    decimalScale: 2,
})`
    color: #2b3648;
    font-size: 14px;
    line-height: 130%;
    white-space: nowrap;
`;

export const Currency = styled.span`
    color: #2b3648;
    font-size: 14px;
    line-height: 130%;
    white-space: nowrap;
`;
