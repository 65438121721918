import { Box, Popover, Stack, Tabs, Typography } from '@mui/material';
import { FaFilter } from '@react-icons/all-files/fa/FaFilter';
import styled from 'styled-components';

import { ResetButton } from '@/components/ui/UIButton';

import { BREAKPOINTS } from '../../../../../pages/NewExecutorView/components/components.service';

export const StatusIconContainer = styled(Box)`
    color: ${(props) => props.theme.palette.primary.main};
    width: 24px;
    height: 24px;
`;

export const StatusText = styled(Typography)`
    color: ${(props) => props.theme.palette.primary.main};
`;

export const StyledTabs = styled(Tabs)`
    .MuiTabs-flexContainer {
        gap: 0 !important;
    }

    .MuiTabs-indicator {
        display: none;
    }
    min-height: 24px !important;
    border-radius: ${(props) => props.theme.spacing(0.5)};

    .MuiTab-root {
        border-width: 1px;
        border-style: solid;

        &:first-of-type {
            border-radius: ${(props) => props.theme.spacing(0.5, 0, 0, 0.5)};
        }

        &:last-of-type {
            border-right: ${(props) => `1px solid #9433e4`};
            border-radius: ${(props) => props.theme.spacing(0, 0.5, 0.5, 0)};
        }

        &.MuiButtonBase-root {
            padding: ${(props) => `${props.theme.spacing('2px', 2)} !important`};

            font-weight: 400;
            font-size: 12px;

            line-height: 1;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            min-height: 24px !important;

            color: ${(props) => props.theme.palette.legends!.disabled};
            border-color: ${(props) => props.theme.palette.legends!.disabled};
        }

        &.Mui-selected {
            color: #8719e0;
            border-color: #9433e4;
        }

        &.Mui-disabled {
            pointer-events: none;
            color: ${(props) => props.theme.palette.legends!.disabled};
            border-color: ${(props) => props.theme.palette.legends!.disabled};
        }

        &[aria-selected='true'] {
            &.Mui-disabled {
                background-color: ${(props) => props.theme.palette.bg!.gray};
            }
            background-color: #f2ebfe;
        }
    }
`;
export const Period = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #8719e0;
    border-radius: 4px;
    padding: 4px 16px;
    min-height: 24px;

    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #8719e0;

    @media screen and (max-width: ${BREAKPOINTS.LAYOUT}px) {
        padding: 4px 10px;
        font-size: 12px;
    }
`;

export const FactGatheringPercentage = styled(Typography)`
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #8719e0;
`;

export const FilterButton = styled(ResetButton)``;

export const FilterStack = styled(Stack).attrs({
    direction: 'row',
    gap: 1,
    alignItems: 'center',
})``;

export const FilterText = styled(Typography)`
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #909090;
`;

export const FilterIcon = styled(FaFilter).attrs({
    size: 16,
})<{ isActive: boolean }>`
    color: ${({ isActive }) => (isActive ? '#8719e0' : '#909090')};
    transition: color 0.3s;
`;

export const FiltersMenu = styled(Popover).attrs({
    anchorOrigin: {
        vertical: 30,
        horizontal: -20,
    },
})`
    & .MuiPaper-root {
        padding: 8px 16px;
        min-width: 212px;
        width: fit-content;
        min-height: fit-content;
        background-color: #fff;
        box-shadow: none;
    }
`;

export const ResetList = styled('ul')`
    padding: 0;
    margin: 0;
    list-style-type: none;
`;

export const FiltersMenuList = styled(ResetList)`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const FiltersMenuItem = styled('li')`
    display: flex;
    align-items: center;
    min-height: 24px;
`;

export const SwitchButton = styled(ResetButton)`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const SwitchIcon = styled('div')<{ isActive: boolean }>`
    position: relative;
    border: 2px solid transparent;
    border-color: ${({ isActive }) => (isActive ? '#8719e0' : '#5c6e8c')};
    width: 22px;
    height: 12px;
    border-radius: 8px;
    transition: border-color 0.3s;
`;

export const SwitchCircle = styled('div')<{ isActive: boolean }>`
    position: absolute;
    transform: ${({ isActive }) => (isActive ? 'translate(9px, -50%)' : 'translate(0, -50%)')};
    top: 50%;
    left: 1px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: ${({ isActive }) => (isActive ? '#8719e0' : '#5c6e8c')};
    transition: transform 0.3s, background-color 0.3s;
`;

export const SwitchText = styled(Typography)<{ isActive: boolean }>`
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: ${({ isActive }) => (isActive ? '#8719e0' : '#5c6e8c')};
    transition: color 0.3s;
`;
