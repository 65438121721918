import {
    EditableCallbackParams,
    ICellEditorParams,
    ICellRendererParams,
    ValueGetterParams,
    ValueSetterParams,
} from 'ag-grid-community';

import { isWorkPosition } from '@/shared/guards/works.guards';

import { WorkOrWorkPosition } from '../../api/ksg/ksg.def';
import { OverflowableTypographyWithTooltip } from '../../components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';
import { isNumber } from '../../shared/utils';
import { COL_DEF_TEMPLATE } from '../WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { NumberCellRenderer } from '../WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer';

export const OFFER_COL_DEFS = {
    lsrCiphers: [
        {
            headerName: 'Шифры ЛСР',
            colId: 'lsrCiphers',
            children: [
                {
                    field: 'lsr',
                    width: 180,
                    headerName: 'Шифр ЛСР',
                    editable: false,
                    spanHeaderHeight: true,
                    cellRenderer: (params: ICellRendererParams) => (
                        <OverflowableTypographyWithTooltip maxRows={2}>
                            {params.value}
                        </OverflowableTypographyWithTooltip>
                    ),
                },
                {
                    field: 'chapter',
                    width: 180,
                    headerName: 'Раздел',
                    editable: false,
                    spanHeaderHeight: true,
                    cellRenderer: (params: ICellRendererParams) => (
                        <OverflowableTypographyWithTooltip maxRows={2}>
                            {params.value}
                        </OverflowableTypographyWithTooltip>
                    ),
                },
                {
                    field: 'header',
                    width: 180,
                    headerName: 'Заголовок',
                    editable: false,
                    spanHeaderHeight: true,
                    cellRenderer: (params: ICellRendererParams) => (
                        <OverflowableTypographyWithTooltip maxRows={2}>
                            {params.value}
                        </OverflowableTypographyWithTooltip>
                    ),
                },
            ],
        },
    ],
    reportingPeriodInfo: [
        {
            headerName: 'На начало отчетного периода',
            colId: 'atStartOfReportingPeriod',
            children: [
                {
                    headerName: 'Выполнено',
                    colId: 'atStartOfReportingPeriodExecuted',
                    children: [
                        {
                            field: 'executedAtStart',
                            width: 94,
                            headerName: 'Выполнено',
                            editable: false,
                            cellRenderer: NumberCellRenderer,
                            valueGetter: (params: ValueGetterParams<WorkOrWorkPosition>) => {
                                if (!params.data) return null;
                                if (isWorkPosition(params.data)) return params.data?.preparedAct?.executedAtStart;

                                return params.data?.executedAtStart;
                            },
                        },
                        {
                            field: 'remainingAtStart',
                            width: 94,
                            headerName: 'Остаток',
                            editable: false,
                            cellRenderer: NumberCellRenderer,
                            valueGetter: (params: ValueGetterParams<WorkOrWorkPosition>) => {
                                if (!params.data) return null;
                                if (isWorkPosition(params.data)) return params.data?.preparedAct?.remainingAtStart;

                                return params.data?.remainingAtStart;
                            },
                        },
                    ],
                },
                {
                    headerName: 'Закрыто',
                    colId: 'atStartOfReportingPeriodClosed',
                    children: [
                        {
                            field: 'closedAtStart',
                            width: 94,
                            headerName: 'Закрыто',
                            editable: false,
                            cellRenderer: NumberCellRenderer,
                            valueGetter: (params: ValueGetterParams<WorkOrWorkPosition>) => {
                                if (!params.data) return null;
                                if (isWorkPosition(params.data)) return params.data?.preparedAct?.closedAtStart;

                                return params.data?.closedAtStart;
                            },
                        },
                        {
                            field: 'closedRemainingAtStart',
                            width: 94,
                            headerName: 'Остаток',
                            editable: false,
                            cellRenderer: NumberCellRenderer,
                            valueGetter: (params: ValueGetterParams<WorkOrWorkPosition>) => {
                                if (!params.data) return null;
                                if (isWorkPosition(params.data))
                                    return params.data?.preparedAct?.closedRemainingAtStart;

                                return params.data?.closedRemainingAtStart;
                            },
                        },
                    ],
                },
                {
                    field: 'nzp',
                    width: 94,
                    headerName: 'НЗП',
                    editable: false,
                    spanHeaderHeight: true,
                    cellRenderer: NumberCellRenderer,
                    valueGetter: (params: ValueGetterParams<WorkOrWorkPosition>) => {
                        if (!params.data) return null;
                        if (isWorkPosition(params.data)) return params.data?.preparedAct?.nzp;

                        return params.data?.nzp;
                    },
                },
            ],
        },
        // {
        //     field: 'executedAtPeriod',
        //     width: 124,
        //     headerName: 'Выполнено за период',
        //     editable: false,
        //     spanHeaderHeight: true,
        //     cellRenderer: NumberCellRenderer,
        //     valueGetter: (params: ValueGetterParams<WorkOrWorkPosition>) => params.data?.preparedAct?.executedAtPeriod,
        // },
        {
            headerName: 'На конец отчетного периода',
            colId: 'atEndOfReportingPeriod',
            children: [
                {
                    field: 'availableToClose',
                    width: 150,
                    headerName: 'Доступно к закрытию',
                    editable: false,
                    spanHeaderHeight: true,
                    cellRenderer: NumberCellRenderer,
                    valueGetter: (params: ValueGetterParams<WorkOrWorkPosition>) => {
                        if (!params.data) return null;
                        if (isWorkPosition(params.data)) return params.data?.preparedAct?.availableToClose;

                        return params.data?.availableToClose;
                    },
                },
                {
                    field: 'toClose',
                    width: 150,
                    headerName: 'К выполнению',
                    editable: (params: EditableCallbackParams<WorkOrWorkPosition>) => {
                        if (!params.data) return false;
                        if (isWorkPosition(params.data)) return true;
                        if (params.data?.hasChildren) return false;
                        return true;
                    },
                    spanHeaderHeight: true,
                    ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER(),
                    valueGetter: (params: ValueGetterParams<WorkOrWorkPosition>) => {
                        if (!params.data) return null;
                        if (isWorkPosition(params.data)) return params.data?.preparedAct?.toClose;

                        return params.data?.toClose;
                    },
                    valueSetter: (params: ValueSetterParams<WorkOrWorkPosition>) => {
                        if (isWorkPosition(params.data)) {
                            params.api.applyTransaction({
                                update: [
                                    {
                                        ...params.data,
                                        // TODO: fix type
                                        // @ts-ignore
                                        preparedAct: {
                                            ...params.data.preparedAct,
                                            toClose: params.newValue,
                                        },
                                    },
                                ],
                            });
                        } else {
                            params.api.applyTransaction({
                                update: [{ ...params.data, toClose: params.newValue }],
                            });
                        }

                        return true;
                    },
                    cellEditorParams: (params: ICellEditorParams<WorkOrWorkPosition>) => {
                        const maxValue = params.data.preparedAct?.availableToClose ?? 0;
                        const getInfoMessage = (value: number) => {
                            if (!isNumber(value)) return '';
                            const diff = (maxValue - value).toFixed(2);

                            return `Оставшийся объем: ${diff.replace(/\.0+$/, '').replaceAll('.', ',')}`;
                        };

                        return {
                            validation: {
                                required: false,
                                validate: {
                                    maxValue: () => true,
                                },
                            },
                            getInfoMessage: getInfoMessage,
                        };
                    },
                },
            ],
        },
    ],
};
