import { api } from '../api'
import { getProjectsDesignRes, sendPrimaveraReq, sendPrimaveraRes } from './bindingExternalModules.def'

export const synchronizeApi = api.injectEndpoints({
    endpoints: (build) => ({
        getProjectsDesign: build.query<getProjectsDesignRes, { limit?: number; offset?: number }>({
            query: () => ({
                url: '/cross-module/design/get-project-list',
                method: 'GET',
            }),
        }),

        getProjectsProcurement: build.query<
            {
                data: {
                    id: number
                    name: string
                }[]
                total: number
            },
            { limit?: number; offset?: number; search?: string }
        >({
            query: ({ limit, offset, search }) => ({
                url: '/cross-module/procurement/get-project-list',
                method: 'GET',
                params: {
                    limit,
                    offset,
                    search,
                },
            }),
        }),

        getProjectsOffer: build.query<
            {
                data: {
                    id: number
                    title: string
                }[]
                total: number
            },
            { limit?: number; offset?: number }
        >({
            query: ({ limit, offset }) => ({
                url: '/cross-module/offer/get-project-list',
                method: 'GET',
                params: {
                    limit,
                    offset,
                },
            }),
        }),

        sendAccessRequest: build.mutation<sendPrimaveraRes, sendPrimaveraReq>({
            query: ({ body }) => ({
                url: '/cross-module/bind/send-mail',
                body,
                method: 'POST',
            }),
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetProjectsDesignQuery,
    useGetProjectsProcurementQuery,
    useSendAccessRequestMutation,
    useGetProjectsOfferQuery,
} = synchronizeApi
