import { Breadcrumbs, BreadcrumbsProps } from "@mui/material";

export function MyBreadCrumbs(props: BreadcrumbsProps) {
    return (
        <Breadcrumbs
            sx={{
                ".MuiBreadcrumbs-ol": {
                    display: "flex !important",
                    flexWrap: "noWrap",
                    justifyContent: "flex-start"
                },
                color: '#2B3648',
            }}
        >
            {props.children}
        </Breadcrumbs>
    )
}