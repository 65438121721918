import { IRowNode } from 'ag-grid-community';
import { useCallback } from 'react';

import { drawersSelector } from '@/store/slices/drawersSlice';
import { useTypedSelector } from '@/store/store';

export const useUpdateAgGridRowNodes = () => {
    const { AgGrid } = useTypedSelector(drawersSelector);

    const update = useCallback(
        <T extends Record<string, any>>(data: T[], getRowNodeId: (data: T) => string) => {
            AgGrid?.api.applyTransaction({ update: data });
            const rowNodes = data.map((v) => AgGrid?.api.getRowNode(getRowNodeId(v))) as IRowNode[];
            AgGrid?.api.redrawRows({ rowNodes });
        },
        [AgGrid]
    );

    return update;
};
