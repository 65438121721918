import { FilterAlt } from '@mui/icons-material';
import {
    ClickAwayListener,
    FormControl,
    FormControlLabel,
    IconButton,
    Paper,
    Popper,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { RefObject, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDidMountEffect } from '../../../../hooks/useDidMountEffect';
import { drawersSelector } from '../../../../store/slices/drawersSlice';
import { useTypedSelector } from '../../../../store/store';
import { FlexColumnWrapper, FlexRowWrapper } from '../../../NewExecutorView/components/components.styles';
import { StyledBtn } from '../../../WorkManagment/components/components.styles';
import { IColumnFilterProps } from './ColumnFilter.def';
import { ActiveFilterNotificator } from './ColumnFilter.styles';

export function ColumnFilter({
    title,
    options,
    IconButtonProps,
    ActiveFilterNotificatorProps,
    onChange,
    defaultValue = null,
    onReset,
    resetDeps = [],
    filterIconSx = {},
}: IColumnFilterProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [chosenOption, setChosenOption] = useState<string | null>(defaultValue);
    const { projectId } = useParams();
    const { AgGrid } = useTypedSelector(drawersSelector);
    const ref = useRef() as RefObject<HTMLButtonElement>;

    useDidMountEffect(() => {
        let timeout = setTimeout(() => {
            onChange(chosenOption);
        }, 500);
        return () => {
            clearTimeout(timeout);
        };
    }, [chosenOption]);

    useDidMountEffect(() => {
        setChosenOption(null);
    }, [projectId, ...resetDeps]);

    useEffect(() => {
        const AgGridViewport = document.getElementsByClassName('ag-center-cols-viewport')[0];
        function closePopperByScroll() {
            setIsOpen(() => false);
        }
        AgGrid && AgGridViewport && AgGridViewport.addEventListener('scroll', closePopperByScroll);
        return () => {
            AgGrid && AgGridViewport && AgGridViewport.removeEventListener('scroll', closePopperByScroll);
        };
    }, [AgGrid]);

    return (
        <ClickAwayListener onClickAway={() => setIsOpen(() => false)}>
            <FlexRowWrapper ref={ref}>
                <IconButton
                    onClick={() => setIsOpen((prevState) => !prevState)}
                    sx={{ color: 'white', p: 0, position: 'relative' }}
                    {...IconButtonProps}
                >
                    {chosenOption && (
                        <ActiveFilterNotificator {...ActiveFilterNotificatorProps}></ActiveFilterNotificator>
                    )}
                    <FilterAlt sx={filterIconSx} />
                </IconButton>

                <Popper
                    open={isOpen}
                    anchorEl={ref.current}
                    sx={{ zIndex: 99999 }}
                    placement='bottom-end'
                >
                    <Paper elevation={8}>
                        <FlexColumnWrapper
                            p={2}
                            bgcolor={'white'}
                            borderRadius={0.75}
                            pb={0.75}
                        >
                            <FlexRowWrapper>
                                <Typography
                                    fontSize={16}
                                    fontWeight={500}
                                >
                                    {title}
                                </Typography>
                                <StyledBtn
                                    variant='outlined'
                                    size='small'
                                    disabled={!chosenOption}
                                    onClick={() => {
                                        onReset && onReset(chosenOption);
                                        setChosenOption(null);
                                    }}
                                >
                                    Сбросить фильтр
                                </StyledBtn>
                            </FlexRowWrapper>
                            <FlexColumnWrapper gap={0}>
                                {options.map((option) => {
                                    return (
                                        <FormControl key={option.value}>
                                            <RadioGroup
                                                onChange={(e, value) => {
                                                    setChosenOption(() => value);
                                                }}
                                                value={chosenOption}
                                            >
                                                <FormControlLabel
                                                    value={option.value}
                                                    control={
                                                        <Radio
                                                            size='small'
                                                            sx={{ p: 1.25 }}
                                                        />
                                                    }
                                                    label={<Typography>{option.label}</Typography>}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    );
                                })}
                            </FlexColumnWrapper>
                        </FlexColumnWrapper>
                    </Paper>
                </Popper>
            </FlexRowWrapper>
        </ClickAwayListener>
    );
}
