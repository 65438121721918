import { Typography, TypographyProps, styled } from '@mui/material'

export const FELabel = styled(Typography)<TypographyProps & { required?: boolean }>`
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
    ${({ required }) =>
        required && {
            ':after': {
                content: "'*'",
                position: 'absolute',
                right: -2,
                top: 8,
                color: 'red',
                fontWeight: 500,
            },
        }}
`
export const FEInputWrapper = styled(Typography)`
    display: flex;
    position: relative;
    gap: ${({ theme }) => theme.spacing(1.5)};
    width: 100%;
    input {
        text-align: left;
    }
`
FELabel.defaultProps = {
    variant: 'body2',
    color: 'secondary',
}
