import { api } from '../api'

export const templatesGetSet = api.injectEndpoints({
    endpoints: (build) => ({
        setPatternKsg: build.mutation<unknown, { projectId: number; patId: number }>({
            query: ({ projectId, patId }) => ({
                url: `/projects/${projectId}/patterns/ksg/${patId}/set`,
                method: 'POST',
            }),
            invalidatesTags: ['GetTemplatesState'],
        }),

        setPatternMsg: build.mutation<unknown, { projectId: number; patId: number }>({
            query: ({ projectId, patId }) => ({
                url: `/projects/${projectId}/patterns/msg/${patId}/set`,
                method: 'POST',
            }),
            invalidatesTags: ['GetTemplatesState'],
        }),

        resetPatternKsg: build.mutation<unknown, { projectId: number }>({
            query: ({ projectId }) => ({
                url: `/projects/${projectId}/patterns/ksg/reset`,
                method: 'POST',
            }),
            invalidatesTags: ['GetTemplatesState'],
        }),

        resetPatternMsg: build.mutation<unknown, { projectId: number }>({
            query: ({ projectId }) => ({
                url: `/projects/${projectId}/patterns/msg/reset`,
                method: 'POST',
            }),
            invalidatesTags: ['GetTemplatesState'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useSetPatternKsgMutation,
    useSetPatternMsgMutation,
    useResetPatternKsgMutation,
    useResetPatternMsgMutation,
} = templatesGetSet
