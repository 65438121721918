import Close from '@mui/icons-material/Close';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Button, CircularProgress } from '@mui/material';
import { IoMdTrash } from '@react-icons/all-files/io/IoMdTrash';
import { format, parse } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { req } from '@/pages/WorkManagment/api/api';

import {
    useDeleteProjectMutation,
    useEditProjectMutation,
    useGetProjectByIdQuery,
} from '../../../api/projects/projects.api';
import { ProjectFullStatuses } from '../../../api/projects/projects.def';
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../../shared/rolePermissions';
import { drawersSelector, toggleChangeProject } from '../../../store/slices/drawersSlice';
import { profileSelector } from '../../../store/slices/profileSlice';
import { useTypedSelector } from '../../../store/store';
import FormikFormC, { formObjType } from './FormikFormC';
import s from './ProjectsDrawer.module.scss';

function ChangeProjectDrawer() {
    const [showWarning, setShowWarning] = useState(false);
    const { profile } = useTypedSelector(profileSelector);
    const { changeProject } = useTypedSelector(drawersSelector);

    const projectId = changeProject.id;

    const { data, isSuccess } = useGetProjectByIdQuery({ id: projectId });
    const [edit] = useEditProjectMutation();
    const [deleteReq, deleteRes] = useDeleteProjectMutation();

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');

    const deleteProject = async () => {
        try {
            await deleteReq({ id: projectId }).unwrap();

            if (changeProject.isImportFromOffer) {
                await req.post(`/cross-module/offer/${projectId}/unsync`);
            }

            enqueueSnackbar(t('project_deleted'), {
                variant: 'success',
            });
            dispatch(toggleChangeProject());
        } catch (error) {
            console.log('error', error);

            enqueueSnackbar('Ошибка', {
                variant: 'error',
            });
        }
    };

    const onSubmit = useCallback(
        (values: formObjType) => {
            edit({
                id: changeProject.id,
                abbr: values.abbr || '',
                description: values.description || '',
                endDate: format(values.dateEnd as Date, 'dd.MM.yyyy'),
                startDate: format(values.dateStart as Date, 'dd.MM.yyyy'),
                status: (values.status as ProjectFullStatuses) || 'Активный',
                timezone: values.timezone || 3,
                title: values.title || '',
            })
                .unwrap()
                .then((res) => {
                    if (!res.success) {
                        return enqueueSnackbar(String(res.description), {
                            variant: 'error',
                        });
                    } else {
                        enqueueSnackbar(t('project_added'), {
                            variant: 'success',
                        });
                    }
                })
                .catch((e) => {
                    enqueueSnackbar('Ошибка', {
                        variant: 'error',
                    });
                    console.error(e);
                });
            dispatch(toggleChangeProject());
        },
        [changeProject.id, dispatch, edit, enqueueSnackbar, t]
    );

    if (!isSuccess) return <div className={s.empty_drawer_width}></div>;

    return (
        <React.Fragment>
            <div className={s.title}>Редактирование проекта</div>
            <main className={s.main_chp}>
                <FormikFormC
                    onSubmit={onSubmit}
                    serverValues={{
                        title: data?.data.title,
                        abbr: data?.data.abbr,
                        description: data?.data.description,
                        status: data?.data.status,
                        timezone: data?.data.timezone,
                        dateStart: parse(data?.data.startDate as string, 'dd.MM.yyyy', new Date()),
                        dateEnd: parse(data?.data.endDate as string, 'dd.MM.yyyy', new Date()),
                    }}
                    onClose={() => dispatch(toggleChangeProject())}
                />
                <div>
                    {showWarning && (
                        <div className={s.warning}>
                            <div className={s.warning_container}>
                                <div>
                                    <WarningAmberOutlinedIcon />
                                    <h4>Удалить</h4>
                                </div>
                                <div>
                                    <h4 onClick={deleteProject}>ДА</h4>
                                    <Close onClick={() => setShowWarning((prev) => !prev)} />
                                </div>
                            </div>
                            <p>
                                Вы действительно хотите удалить проект, это повлечет удаление всех связанных данных с
                                проектом? Это действие нельзя отменить.
                            </p>
                        </div>
                    )}
                </div>
                {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'DELETE_PROJECT') ? (
                    !deleteRes.isLoading ? (
                        <section>
                            <div>
                                <Button
                                    fullWidth
                                    sx={{
                                        color: '#f46b6b',
                                    }}
                                    color='warning'
                                    startIcon={<IoMdTrash />}
                                    onClick={() => setShowWarning((prev) => !prev)}
                                >
                                    Удалить проект
                                </Button>
                            </div>
                        </section>
                    ) : (
                        <div className='progress'>
                            Удаляем
                            <CircularProgress />
                        </div>
                    )
                ) : null}
            </main>
        </React.Fragment>
    );
}

export default ChangeProjectDrawer;
