import { Typography } from '@mui/material'
import { ICellRendererParams } from 'ag-grid-community'
import { FlexRowWrapper } from '../../../../NewExecutorView/components/components.styles'
import { RowGroupExpandRenderer } from '../RowGroupExpandRenderer/RowGroupExpandRenderer'
import React from 'react'

export interface IRowGroupRendererPrarms extends ICellRendererParams {
    children?: React.ReactNode | React.ReactNode[]
}

export function RowGroupRenderer({ children, ...rest }: IRowGroupRendererPrarms) {
    return (
        <FlexRowWrapper gap={1}>
            <RowGroupExpandRenderer {...rest} />
            {children}
        </FlexRowWrapper>
    )
}
