import { ListItemIcon, Stack, Typography } from '@mui/material'
import { icons, iconsVariants, MenuItemStyled } from '../RemainingItemsMenu.service'
import React, { useState } from 'react'

export default function MenuItemEl({
    onClick,
    iconsVar,
    title,
    description,
    disabled
}: {
    onClick: () => void
    iconsVar: iconsVariants
    title: string
    description: string
    disabled?: boolean
}) {
    const [isShown, setIsShown] = useState(false)

    const pickIconColor = isShown ? '#0044b4' : '#7890b2'

    return (
        <MenuItemStyled
            onClick={onClick}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            sx={{
                alignItems: 'flex-start',
            }}
            disabled={disabled}
        >
            <ListItemIcon>{icons(pickIconColor)[iconsVar]}</ListItemIcon>
            <Stack width="15rem">
                <Typography color={isShown ? '#0044b4' : '#2B3648'} variant="body1" noWrap>
                    {title}
                </Typography>
                <Typography
                    color="#7890b2"
                    variant="subtitle2"
                    fontWeight={400}
                    sx={{
                        whiteSpace: 'break-spaces',
                    }}
                >
                    {description}
                </Typography>
            </Stack>
        </MenuItemStyled>
    )
}
