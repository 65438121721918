import { Button, IconButton, Typography, styled } from '@mui/material'
import { FlexColumnWrapper } from '../../../../NewExecutorView/components/components.styles'

export const DialogBtn = styled(Button)``

DialogBtn.defaultProps = {
    variant: 'contained',
    fullWidth: true,
}

export const CloseIconBtn = styled(IconButton)`
    position: absolute;
    right: 2px;
    top: 2px;
    color: #7890b2;
`

export const DialogWrapper = styled(FlexColumnWrapper)`
    padding: ${({ theme }) => theme.spacing(4)};
    align-items: center;
    gap:  ${({ theme }) => theme.spacing(2.5)};
    border-radius:  ${({ theme }) => theme.spacing(1)};;
`
