import { RowHeightParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useMemo } from 'react';

import { filtersSelector } from '@/store/slices/filtersSlice';

import { WorkMsg } from '../../api/msg/msg.def';
import { cpgViewSelector } from '../../store/slices/cpgViewSlice';
import { getSettings } from '../../store/slices/settings/settingsViewAgGrid';
import { useTypedSelector } from '../../store/store';

const DEFAULT_ROW_HEIGHT = 40;

export const calculateRowHeight = (count: number) => DEFAULT_ROW_HEIGHT + (DEFAULT_ROW_HEIGHT / 2) * count;

export const useCalculateRowHeight = () => {
    const { msgForecast, verificationPlan, msgDailyPercentage } = useTypedSelector(getSettings);
    const { msgView } = useTypedSelector(cpgViewSelector);

    const count = [msgForecast, verificationPlan].filter((v) => v).length;
    const rowHeight = calculateRowHeight(count);
    const rootRowHeight = calculateRowHeight(count + Number(msgView === 'cash' && msgDailyPercentage));

    return { rowHeight, rootRowHeight };
};

export const useIsCashViewWithAllSettings = () => {
    const { msgView } = useTypedSelector(cpgViewSelector);
    const { msgForecast, verificationPlan, msgDailyPercentage } = useTypedSelector(getSettings);
    return msgView === 'cash' && [msgForecast, verificationPlan, msgDailyPercentage].every((v) => v);
};

export const useRowHeight = (grid: AgGridReact | null) => {
    const { rowHeight, rootRowHeight } = useCalculateRowHeight();

    useEffect(() => {
        if (!grid?.api) return;

        grid?.api.resetRowHeights();
    }, [grid, rowHeight, rootRowHeight]);

    const getRowHeight = (params: RowHeightParams<WorkMsg>) => {
        if (params.data?.level === 0) return rootRowHeight;
        return rowHeight;
    };

    return {
        getRowHeight,
    };
};

export const useGetAppliedFilters = () => {
    const { filters } = useTypedSelector(filtersSelector);

    const appliedFilters = useMemo(() => {
        const result = [];

        // TODO:
        // 1. Отрефакторить все условия фильтров
        if (filters.workGroupList.length > 0 || filters.showEmptyWorkGroups) {
            result.push('workGroup');
        }

        if (filters.rdCodes.length > 0) {
            result.push('rdCode');
        }

        if (filters.contractors.length > 0) {
            result.push('contractorCompany');
        }

        if (filters.brigades.length > 0) {
            result.push('brigade');
        }

        if (Object.values(filters.workName).some((v) => v.length > 0)) {
            result.push('workName');
        }

        if (Object.values(filters.objTitle).some((v) => v.length > 0)) {
            result.push('objTitle');
        }

        if (Object.values(filters.objName).some((v) => v.length > 0)) {
            result.push('objName');
        }

        if (Object.values(filters.rdStatus).some((v) => v.length > 0)) {
            result.push('rdStatus');
        }

        return result;
    }, [filters]);

    return appliedFilters;
};

export const useGetMinMaxLevels = (works: WorkMsg[] | undefined, listMode: boolean) => {
    const [maxLevel, minLevel] = useMemo(() => {
        if (!works) return [null, null];

        const levels = works.map((v) => v.level) as number[];
        return [Math.max(...levels), Math.min(...levels)];
    }, [works]);

    const minLevelByListMode = listMode ? minLevel : null;

    return {
        maxLevel: maxLevel,
        minLevel: minLevel,
        minLevelByListMode: minLevelByListMode,
    };
};
