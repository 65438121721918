import { ColDef, ColGroupDef, ICellRendererParams, IHeaderParams } from 'ag-grid-community'
import { NumberCellRenderer } from '../../../../../components/CellRenderers/NumberCellRenderer/NumberCellRenderer'
import { Box, Typography } from '@mui/material'
import { RowGroupRenderer } from '../../../../../components/CellRenderers/RowGroupRenderer/RowGrooupRenderer'
import { theme } from '../../../../../../../styles/theme'

export const estimateTotalColDef: Partial<ColDef | ColGroupDef>[] = [
    {
        headerName: 'Тип ресурса',
        field: 'type',
        minWidth: 300,
        headerClass: 'ag-cell-left',
        cellClass: 'ag-cell-left',
        cellRenderer: (params: ICellRendererParams) => (
            <RowGroupRenderer {...params}>
                <Box
                    sx={{
                        ...((params?.data?.children?.length === 0 || !params?.data?.children) && {
                            pl: 4,
                        }),
                        ...(params.data.isChild
                            ? { pl: `${theme.spacing(6)} !important` }
                            : { textTransform: 'uppercase' }),
                    }}
                >
                    {params.data.type == 'ТЗМ' ? 'В т.ч. ТЗМ' : params.data.type}
                </Box>
            </RowGroupRenderer>
        ),
        headerComponent: (params: IHeaderParams) => (
            <Typography ml={4} fontWeight={500} variant="body2">
                {params.displayName}
            </Typography>
        ),
    },
    {
        headerName: 'Ед. изм.',
        field: 'unit',
        minWidth: 110,
    },
    {
        headerName: 'На ЕР',
        children: [
            {
                headerName: 'Кол-во',
                field: 'qty',
                minWidth: 110,
                cellRenderer: NumberCellRenderer,
            },
            {
                headerName: 'Базовая стоимость, р.',
                field: 'costBaseTotal',
                minWidth: 170,
                cellRenderer: NumberCellRenderer,
            },
            {
                headerName: 'Текущая стоимость, р.',
                field: 'costCurrTotal',
                minWidth: 170,
                cellRenderer: NumberCellRenderer,
            },
        ],
    },
    {
        headerName: 'На весь объем',
        children: [
            {
                headerName: 'Кол-во',
                field: 'qtyTotal',
                minWidth: 110,
                cellRenderer: NumberCellRenderer,
            },
            {
                headerName: 'Базовая стоимость, р.',
                field: 'costBaseTotalVolume',
                minWidth: 170,
                cellRenderer: NumberCellRenderer,
            },
            {
                headerName: 'Текущая стоимость, р.',
                field: 'costCurrTotalVolume',
                minWidth: 170,
                cellRenderer: NumberCellRenderer,
            },
        ],
    },
]
