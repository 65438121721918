import { UpdatedWorkPosition, WorkOrWorkPosition } from '@/api/ksg/ksg.def';

export const isWorkPosition = (data: WorkOrWorkPosition | undefined): data is UpdatedWorkPosition => {
    if (!data) return false;
    return (
        Object.hasOwn(data, 'isWorkPosition') &&
        typeof (data as UpdatedWorkPosition).isWorkPosition === 'boolean' &&
        (data as UpdatedWorkPosition).isWorkPosition === true
    );
};
