import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface initialStateI {
    templateId: number
    templateName: string
    templatesKsgNamesList: string[]
    templatesMsgNamesList: string[]
}

const initialState: initialStateI = {
    templateId: 0,
    templateName: '',
    templatesKsgNamesList: [],
    templatesMsgNamesList: [],
}

const slice = createSlice({
    name: 'templatesShared',
    initialState,
    reducers: {
        setTemplateId(state, action: PayloadAction<number>) {
            state.templateId = action.payload
        },

        setTemplateName(state, action: PayloadAction<string>) {
            state.templateName = action.payload.trim()
        },

        setTemplatesKsgNamesList(state, action: PayloadAction<string[]>) {
            state.templatesKsgNamesList = action.payload
        },

        setTemplatesMsgNamesList(state, action: PayloadAction<string[]>) {
            state.templatesMsgNamesList = action.payload
        },
        updateTemplatesMsgNamesList(state, { payload: templateName }: PayloadAction<string>) {
            state.templatesMsgNamesList = state.templatesMsgNamesList.filter(name => name !== templateName)
        },
    },
})

export const {
    setTemplateId,
    setTemplateName,
    updateTemplatesMsgNamesList,
    setTemplatesKsgNamesList,
    setTemplatesMsgNamesList,
} = slice.actions
export default slice.reducer

export const sharedTemplatesSelector = (state: RootState) => state.templatesShared
