import { req } from "../../WorkManagment/api/api"
import { getHeaders } from "./api.service"
import { ISNZRequestParams, IWorkResourcesListServerResponse, IWorkWithResourcesFactList, IUpdateWorkFactBySingleItemReqParams } from "./api.types"

export class WorkWithResourcesAPI {
    static baseURL = (projectID: string) => `/projects/${projectID}/works/msg/resources/executor`
   
    static async getWorksWithResources({projectID}: ISNZRequestParams):Promise<IWorkResourcesListServerResponse> {
        return await req.get(this.baseURL(projectID), undefined, getHeaders())
            .then(({ data }) => {
                return data
            })
            .catch(e => console.log(e))
    }

    static async getWorkWithResourcesByID({projectID, workID}: ISNZRequestParams):Promise<IWorkWithResourcesFactList> {
        return await req.get(this.baseURL(projectID) + "/" + workID, undefined, getHeaders())
            .then(({ data }) => {
                return data?.data
            })
            .catch(e => console.log(e))
    }

    static async updateItemFact({projectID, itemType, body}: IUpdateWorkFactBySingleItemReqParams) {
        return await req.post(this.baseURL(projectID) + `/update-${itemType}`, body, undefined, undefined, getHeaders())
            .then(({ data }) => {
                return data
            })
    }

}