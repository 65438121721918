import React, { Fragment, useMemo, useState } from 'react'
import { checkboxSettings, helpText, ICheckParams } from '../UploadDrawer.service'
import InfoSvg from '../../../shared/SVG/InfoSvg'
import { Box, Checkbox, Typography } from '@mui/material'
import { Wrapper, WrapperCheckbox } from './CheckParams.style'
import { StyledTooltip } from '../../../pages/WorkManagment/components/components.styles'

const CheckParams: React.FC<ICheckParams> = ({ activeType, checkFn, mode, state, disabled }) => {
    const [showTooltip, setShowTooltip] = useState('')

    const checkShow = (name?: string) => setShowTooltip(name || '')

    const checkboxParams = useMemo(() => {
        return checkboxSettings.filter(
            (setting) => setting.templateTypes.includes(activeType) && setting.uploadTypes.includes(mode)
        )
    }, [activeType, mode])

    return (
        <Wrapper>
            {checkboxParams.map((param, index) => {
                return (
                    <Fragment>
                        {param.workType === 'new' && checkboxParams[index - 1].workType === 'old' && (
                            <Typography fontWeight={500} mb={1} mt={1} textAlign={'left'}>
                                Для новых работ
                            </Typography>
                        )}
                        <WrapperCheckbox>
                            {param.helpText && (
                                <StyledTooltip
                                    placement="top"
                                    title={param.helpText as NonNullable<React.ReactNode>}
                                    disableFocusListener
                                    disableInteractive
                                >
                                    <Box
                                        p={0.25}
                                        display={'flex'}
                                        alignItems={'center'}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            checkShow(showTooltip === param.name ? '' : param.name)
                                        }}
                                    >
                                        <InfoSvg viewBox="0 0 20 20" sx={{ color: '#668FD2', fontSize: '20px' }} />
                                    </Box>
                                </StyledTooltip>
                            )}
                            <Typography variant={'body2'} textAlign={'left'}>
                                {param.title}
                            </Typography>
                            <Box ml={'auto'}>
                                <Checkbox
                                    disableRipple
                                    checked={state[param.name]}
                                    onChange={() => checkFn(param)}
                                    sx={{
                                        color: '#0044b4',
                                        '&.Mui-checked': {
                                            color: '#0044b4',
                                        },
                                        p: 0,
                                    }}
                                    disabled={!!disabled}
                                />
                            </Box>
                        </WrapperCheckbox>
                    </Fragment>
                )
            })}
        </Wrapper>
    )
}

export default CheckParams
